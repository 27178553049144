import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, EMPTY, Observable, throwError } from 'rxjs';
import { AuthFormService } from '../modules/auth/services/auth-form.service';
import { AuthService } from '../modules/auth/services/auth.service';
import { LoaderService } from '../modules/shared/services/loader.service';
import { EventsService, EventType } from '../services/events.service';
import { SnackbarService } from '../services/snackbar.service';

@Injectable()
export class ServerErrorInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private authService: AuthService,
    public loaderService: LoaderService,
    private eventService: EventsService,
    private authFormService: AuthFormService,
    private snackbarService: SnackbarService,
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401 && !this.authFormService.IsFormOpenned) {
          // Logout and redirect unauthorized
          this.authService.removeTokens();
          this.authService.refreshLocalPermissions();
          localStorage.removeItem('email-alert-activated');
          // For VS-538
          // this.authFormService.openSignIn();
          this.loaderService.hide();
          this.eventService.emit(EventType.RequestFailed);
          // prettier-ignore
          error.url?.includes('portfolio/add-ticker') && this.snackbarService.error(error.error.message);

          return EMPTY;
        } else if (error.status === 403) {
          // Forbidden, go to premium
          this.router.navigateByUrl('/premium');
          this.loaderService.hide();
          this.eventService.emit(EventType.RequestFailed);
          return EMPTY;
        } else if (error.status === 404) {
          return throwError(() => error);
        } else if (
          error.url?.includes('stock-page/projection') &&
          error.status === 500
        ) {
          return throwError(() => error);
        } else if (error.url?.includes('win-rate')) {
          return throwError(() => error);
        } else if (error.url?.includes('canClaim') && error.status === 400) {
          return throwError(() => error);
        }

        console.debug(error);

        // Get error message
        let errorMessage = '';
        errorMessage = error.error.message;

        // снекбар только для апишек v2
        error.url?.includes('v2') && this.snackbarService.error(errorMessage);

        return throwError(() => new Error(errorMessage));
      }),
    );
  }
}
