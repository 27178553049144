<div
  *ngFor="
    let row of data
      | slice
        : 0
        : (config.pagination?.displayAll
            ? data.length
            : config.pagination?.count ?? data.length)
  "
  [class.highlight]="config.highlight?.mobile"
  [class]="classes?.row?.host ?? 'p-[1rem] pb-6 gap-[1rem]'"
  class="grid-row"
>
  <ng-container *ngFor="let column of columns" class="item">
    <div
      class="header"
      [ngClass]="[classes?.row?.header?.host ?? '', classes?.row?.header?.specific?.[column.id] ?? '']"
    >
      {{ column.text }}
    </div>
    <div
      [ngClass]="[classes?.row?.content?.host ?? 'text-xs text-accent', classes?.row?.content?.specific?.[column.id] ?? '']"
      class="value self-center"
    >
      <span class="table-grid-column-value" [innerHtml]="row[column.id]"></span>
    </div>
  </ng-container>
</div>
