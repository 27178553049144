import { Component, EventEmitter, Input, Output } from '@angular/core';

import { SortDirection } from '@shared/components/table-sort/table-sort.model';

@Component({
  selector: 'shared-table-sort',
  templateUrl: './table-sort.component.html',
  styleUrls: ['./table-sort.component.scss'],
})
export class TableSortComponent {
  @Input() sortDirection: SortDirection = 'none';
  @Input() isDisabled = false;

  @Output() sortChanged = new EventEmitter<SortDirection>();

  private readonly sortingMap: Record<SortDirection, SortDirection> = {
    none: 'asc',
    asc: 'desc',
    desc: 'none',
  };

  changeSort(): void {
    if (this.isDisabled) {
      return;
    }

    this.sortDirection = this.sortingMap[this.sortDirection];
    this.sortChanged.emit(this.sortDirection);
  }
}
