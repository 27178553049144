import { Component, EventEmitter, Output } from '@angular/core';
import {
  BehaviorSubject,
  debounceTime,
  distinctUntilChanged,
  from,
  Subject,
} from 'rxjs';

@Component({
  selector: 'app-ticker-search-field',
  templateUrl: './ticker-search-field.component.html',
  styleUrls: ['./ticker-search-field.component.scss'],
})
export class TickerSearchFieldComponent {
  @Output() textChanged = new EventEmitter<string>();
  searchText = '';

  private searchTextChangeLimiter = new Subject<string>();

  constructor() {
    this.initSearchTextLimiter();
  }

  onTextChanged(searchText: string) {
    this.searchTextChangeLimiter.next(searchText);
  }

  private initSearchTextLimiter() {
    this.searchTextChangeLimiter
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((searchText) => this.textChanged.emit(searchText));
  }

}
