import { Component, HostBinding } from '@angular/core';
import { AnalyticService } from 'src/app/services/analytic.service';
import { NpsVotingModel } from '../models/nps-voting.model';

@Component({
  selector: 'app-nps-voting',
  templateUrl: './nps-voting.component.html',
  styleUrls: ['./nps-voting.component.scss']
})
export class NpsVotingComponent {

  votings: Array<NpsVotingModel> = [
    { question: "How much do you benefit from data on this page?", active: true },
    { question: "How often will you use this data?", active: false },
    { question: "How likely is it that you will share/recommend this data?", active: false },
  ]

  showThankYou = false;

  @HostBinding('class.hidden') isNpsHidden: boolean = false;

  constructor(private analyicService: AnalyticService) {}

  onAnswerClick(question: string, answer: number) {

    this.analyicService.send("survey_answer", new Map([
      ["question", question],
      ["answer", `${answer}`]
    ]));
    for(let i = 0; i < this.votings.length; i++) {
      const vote = this.votings[i];
      if (vote.active) {
        vote.active = false;
        if (i + 1 < this.votings.length) {
          this.votings[i + 1].active = true;
          return;
        } else {
          this.showThanks();
        }
      }
    }
  }

  showThanks() {
    this.showThankYou = true;
    setTimeout(() => {
      this.isNpsHidden = true;
    }, 2000);
  }

}
