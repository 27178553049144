<app-form-layout [formName]="'Member Sign In'">
  <app-login-form-content
    (loginSuccessful)="loginSuccessful.emit()"
    (goToSignUp)="goToSignUp.emit()"
  ></app-login-form-content>

  <div class="login-form-bottom-text">
    Forgot password?
    <span (click)="goResetPassword.emit()" class="auth-form-link">Reset</span>
    it or <span (click)="goToSignUp.emit()" class="auth-form-link">Email me</span> a sign in link. If you
    continue to experience login issue, please write to
    <a class="auth-form-link" href="mailto:support@visualsectors.com"
      >support@visualsectors.com</a
    >
  </div>
</app-form-layout>
