import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
} from '@angular/core';
import {
  BehaviorSubject,
  filter,
  finalize,
  map,
  Observable,
  shareReplay,
  startWith,
} from 'rxjs';

import { SettingsService } from '@main/profile/services/settings.service';
import { OAuthDriver, UserProfile } from './settings.model';

@Component({
  templateUrl: './settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsComponent implements AfterViewInit {
  public readonly OAuthDriver = OAuthDriver;

  public readonly isLoading$ = new BehaviorSubject<boolean>(true);
  public readonly userData$: Observable<UserProfile | null> =
    this.settingsService.userProfile$.pipe(
      startWith({
        name: '',
        email: '',
        oauth_driver: null,
        is_password_specified: false,
      } as UserProfile),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  public readonly loginViaGoogle$: Observable<boolean> = this.userData$.pipe(
    filter(Boolean),
    map(({ oauth_driver }) => oauth_driver === OAuthDriver.GOOGLE),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  constructor(private readonly settingsService: SettingsService) {}

  // TODO move request to template when update angular
  ngAfterViewInit(): void {
    this.settingsService
      .getUserProfile()
      .pipe(finalize(() => this.isLoading$.next(false)))
      .subscribe();
  }
}
