<ng-container *ngIf="userType !== null">
  <div
    *ngIf="userType === UserTypeModel.Unregistered"
    class="header-account-login"
  >
    <button
      (click)="signIn()"
      class="header-account-signin hover:!bg-[#74748014]"
    >
      Sign In
    </button>
    <button
      (click)="signUp()"
      class="header-account-signup hover:!bg-[#4E9C64]"
    >
      Sign Up
    </button>
  </div>

  <app-header-account-premium
    *ngIf="userType === UserTypeModel.Logged"
    class="flex max-md:hidden"
  >
  </app-header-account-premium>

  <div
    *ngIf="userType !== UserTypeModel.Unregistered"
    (click)="showContextMenu($event)"
    class="header-account-user-profile relative"
  >
    <div class="flex gap-2 items-center">
      <app-icon icon="profile"></app-icon>
      <app-icon icon="arrow-down" classes="w-[9px]"></app-icon>
    </div>

    <app-context-menu
      *ngIf="contextMenu.visible"
      @scaleY
      [items]="contextMenu.items"
      (itemClick)="headerService.contextMenuClick($event)"
      (itemToggle)="contextMenuItemToggle($event)"
      class="max-md:hidden flex absolute right-0 top-[58px] origin-top"
    ></app-context-menu>
  </div>
</ng-container>
