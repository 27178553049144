import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { interval, Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-subscription-canceled-dialog',
  templateUrl: './subscription-changed-dialog.component.html',
})
export class SubscriptionChangedDialogComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  interval$: Subscription | undefined;
  interval: Observable<number> = interval(1000);
  intervalValue: number = 5;

  tariffType: string = '';
  tariffName: string = '';

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { tariffName: string; tariffType: string },
    private dialog: MatDialogRef<SubscriptionChangedDialogComponent>,
  ) {}

  ngOnInit(): void {
    this.tariffType = this.data.tariffType;
    this.tariffName = this.data.tariffName;
  }

  ngAfterViewInit(): void {
    this.interval$ = this.interval.subscribe({
      next: () => {
        if (this.intervalValue === 1) {
          this.interval$?.unsubscribe();

          this.close();

          this.intervalValue = 5;
        } else {
          this.intervalValue = this.intervalValue - 1;
        }
      },
      error: (error: any) => console.error(error),
    });
  }

  ngOnDestroy(): void {
    [this.interval$].forEach(($: Subscription | undefined) => $?.unsubscribe());
  }

  close(): void {
    this.dialog.close();
  }
}
