import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RiskRewardHeatmapDataTransformService } from 'src/app/modules/widgets/risk-reward-heatmap/services/risk-reward-heatmap-data-transform.service';
import { RiskRewardHeatmapDataService } from 'src/app/modules/widgets/risk-reward-heatmap/services/risk-reward-heatmap-data.service';
import { WidgetsHttpClient } from 'src/app/services/widgets-http-client.service';

import {
  RiskRewardHeatmapDialogModel,
  RiskRewardHeatmapDialogPeriod
} from '../../../models/risk-reward-heatmap-dialog.model';
import { RiskRewardHeatmapItemModel } from '../../../models/risk-reward-heatmap-item.model';

@Component({
  selector: 'app-risk-reward-heatmap-dialog',
  templateUrl: './risk-reward-heatmap-dialog.component.html',
  styleUrls: ['./risk-reward-heatmap-dialog.component.scss'],
  providers: [ RiskRewardHeatmapDataService, RiskRewardHeatmapDataTransformService ]
})
export class RiskRewardHeatmapDialogComponent {

  public model: RiskRewardHeatmapDialogModel;
  private heatmapItem: RiskRewardHeatmapItemModel;

  constructor(private dialogRef: MatDialogRef<RiskRewardHeatmapDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private dataService: RiskRewardHeatmapDataService,
              private dataTransformService: RiskRewardHeatmapDataTransformService)
  {
    this.heatmapItem = data.model;
    this.loadData(data.model.id);
  }

  async loadData(id: string): Promise<void>  {
    const sectorPage = await this.dataService.getSectorPage({
      sectorId: id,
      preiod: 0
    })

    this.model = this.dataTransformService.pageSectorResponseToHeatMapDialog(sectorPage, this.heatmapItem);
  }

  close(): void {
    this.dialogRef.close();
  }

  onAddMap() {}

  onSelectPeriod(period: RiskRewardHeatmapDialogPeriod) {
    this.model.periods.forEach(period => period.selected = false);
    period.selected = true;
  }

}
