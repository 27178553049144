import { Injectable } from "@angular/core";
import { Subject, lastValueFrom } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class ShareHttpResultService {

    private subjectMap = new Map<string, Subject<any>>();

    async invoke<T>(key: string, method: () => Promise<T>): Promise<T> {
        const hasSubject = this.subjectMap.has(key);
        if (!hasSubject) {
            const subject = new Subject<T>();
            this.subjectMap.set(key, subject);
            const response = await method();
            subject.next(response);
            subject.complete();
            this,this.subjectMap.delete(key);
            return response;
        } else {
            return lastValueFrom(this.subjectMap.get(key)!);
        }
    }
}