import { IMenu } from './../../structures/ui';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-menu-list',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent {
  @Input() menus: IMenu[] = [];
  @Input() activeId?: string;

  @Output() menuClick = new EventEmitter<IMenu>();

  constructor() {}
}
