import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { IAuthSubscriptionPrice } from 'src/app/modules/main/dialogs/auth-subscirption-dialog/models/auth-subscription-dialog.model';
import { IAuthRegistrContentData } from '../../models/auth-ui.models';

@Component({
  selector: 'app-auth-form-content',
  templateUrl: './auth-form-content.component.html',
  styleUrls: ['./auth-form-content.component.scss'],
})
export class AuthFormContentComponent {
  @Input() showLogin = true;
  @Input() data: {
    registr?: {
      contentData?: IAuthRegistrContentData;
      signUpSubscription?: IAuthSubscriptionPrice;
      signUpEnable?: boolean;
      registerLicense?: TemplateRef<any>;
    };
  } = {};
  @Output() loginSuccess = new EventEmitter<void>();

  constructor() {}
}
