import { AfterViewInit, Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { interval, Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-subscription-pay-success-dialog',
  templateUrl: './subscription-pay-success-dialog.component.html',
})
export class SubscriptionPaySuccessDialogComponent
  implements AfterViewInit, OnDestroy
{
  interval$: Subscription | undefined;
  interval: Observable<number> = interval(1000);
  intervalValue: number = 5;

  tariffName!: string;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { tariffName: string },
    private dialog: MatDialogRef<SubscriptionPaySuccessDialogComponent>,
    private router: Router,
  ) {
    this.tariffName = this.data.tariffName;
  }

  ngAfterViewInit(): void {
    this.interval$ = this.interval.subscribe({
      next: () => {
        if (this.intervalValue === 1) {
          this.interval$?.unsubscribe();

          this.close();

          this.intervalValue = 5;
        } else {
          this.intervalValue = this.intervalValue - 1;
        }
      },
      error: (error: any) => console.error(error),
    });
  }

  ngOnDestroy(): void {
    [this.interval$].forEach(($: Subscription | undefined) => $?.unsubscribe());
  }

  // prettier-ignore
  close(): void {
    this.dialog.close();

    this.router.navigate([], {
      queryParams: { 'pay-status': null },
      queryParamsHandling: 'merge',
    })
      .then(() => console.debug('Router changed'));
  }
}
