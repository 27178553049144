
<ng-container *ngFor="let vote of votings;">
  <div *ngIf="vote.active" class="nps-voting">

    <div class="nps-voting-question">{{ vote.question }}</div>
    <div class="nps-voting-answers">
      <div (click)="onAnswerClick(vote.question, 1)" class="nps-voting-answer">1</div>
      <div (click)="onAnswerClick(vote.question, 2)" class="nps-voting-answer">2</div>
      <div (click)="onAnswerClick(vote.question, 3)" class="nps-voting-answer">3</div>
      <div (click)="onAnswerClick(vote.question, 4)" class="nps-voting-answer">4</div>
      <div (click)="onAnswerClick(vote.question, 5)" class="nps-voting-answer">5</div>
    </div>
  </div>
</ng-container>
<div *ngIf="showThankYou" class="nps-voting thank-you">
  Thank you!
</div>
