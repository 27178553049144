import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class OnboardingHintEventService {
  private subject = new Subject<OnboardingHintEventType>();

  emit(e: OnboardingHintEventType) {
    this.subject.next(e);
  }

  subscription(): Observable<OnboardingHintEventType> {
    return this.subject.asObservable();
  };
}

export enum OnboardingHintEventType {
  Portfolio,
  Alerts,
  Disable,
  Enable
}
