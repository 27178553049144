import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SubscriptionChange } from '../models/subscriptions-new.model';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionChangeService {
  // prettier-ignore
  currentChangeSubject: BehaviorSubject<SubscriptionChange> = new BehaviorSubject<SubscriptionChange>('info');
}
