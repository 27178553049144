import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-app-toast',
  templateUrl: './app-toast.component.html',
  styleUrls: ['./app-toast.component.scss']
})
export class AppToastComponent {

  text: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.text = data.text;
  }

}
