import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BannerPage, SidebarModel, SidebarPage, SidebarSectionField } from '../models/sidebar.model';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {

  model: SidebarModel = {
    pages: [
      {
        urls: ["default"],
        sections: [
          // {
          //   selected: false,
          //   title: "Cycle widgets",
          //   imgUrl: "/assets/images/sidebar/cycle-widgets.svg",
          //   url: "",
          //   fields: [
          //     { name: "Markets", selected: false, url: "/sector-trends?type=markets" },
          //     { name: "Sectors", selected: false, url: "/sector-trends?type=sectors" },
          //   ]
          // },
          // {
          //   selected: false,
          //   title: "Risk heatmaps",
          //   imgUrl: "/assets/images/sidebar/risk-heatmap.svg",
          //   url: "",
          //   fields: [
          //     { name: "Markets", selected: false, url: "/risk-reward-heatmap?type=markets" },
          //     { name: "Sectors", selected: false, url: "/risk-reward-heatmap?type=sectors" },
          //   ]
          // },
          {
            selected: false,
            title: "Money flows widgets",
            imgUrl: "/assets/images/sidebar/money-flows.svg",
            url: "",
            fields: [
              { name: "Markets", selected: false, url: "/money-flow?type=markets" },
              { name: "Sectors", selected: false, url: "/money-flow?type=sectors" },
            ]
          },
          // {
          //   selected: false,
          //   title: "Portfolio allocation widget",
          //   imgUrl: "/assets/images/sidebar/portfolio-alloc-widget.svg",
          //   url: "/building-portfolio",
          //   fields: []
          // }
        ]
      },
      {
        urls: ["/trades-and-transactions", "/portfolio-upside-calculator", "/portfolio-risk-heatmap"],
        sections: [
          {
            selected: false,
            title: "My Portfolio",
            imgUrl: "/assets/images/sidebar/my-portfolio.svg",
            url: "/portfolio",
            fields: [
              { name: "Risk heatmap", selected: false, url: "/portfolio-risk-heatmap" },
              { name: "Trades", selected: false, url: "/trades-and-transactions" },
              //{ name: "Upside calculation", selected: false, url: "/portfolio-upside-calculator" },
            ]
          }
        ]
      },
      {
        urls: ["/portfolio"],
        sections: [
          {
            selected: false,
            title: "My Portfolio",
            imgUrl: "/assets/images/sidebar/my-portfolio.svg",
            url: "/portfolio",
            fields: [
              { name: "Flows heatmap", selected: false, url: "/portfolio-money-flow" }
            ]
          }
        ]
      },
      {
        urls: ["/sectors-and-industries", "/single-industry", "/single-sector", "/set-alerts", "/single-stock"],
        sections: []
      }
    ],
    banners: [
      // {
      //   pageUrl: "/money-flow",
      //   bannerImage: "/assets/images/banners/portfolio.png",
      //   bannerUrl: "/portfolio"
      // },
      {
        pageUrl: "",
        bannerImage: null,
        bannerUrl: ""
      }
    ]
  };

  banner: BannerPage | null = {
    bannerImage: "/assets/images/banners/money-flows.png",
    bannerUrl: "/money-flow",
    pageUrl: ""
  };

  page: SidebarPage | undefined;

  constructor(private router: Router) {
    this.processBaseUrl(router.url);
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        let baseUrl = this.router.url.substring(0, this.router.url.indexOf("?"));
        if (baseUrl === "") {
          // no query params
          baseUrl = this.router.url;
        }

        this.processBaseUrl(baseUrl);        
      }
    });
  }

  private processBaseUrl(baseUrl: string) {
    this.selectPage(baseUrl);
    this.selectSections(baseUrl);
    this.selectBanner(baseUrl);
  }

  private selectBanner(baseUrl: string): void {
    this.model.banners.forEach(banner => {
      if (baseUrl.includes(banner.pageUrl)) {
        if (banner.bannerImage !== null) {
          this.banner = banner;
        } else {
          this.banner = null;
        }
      }
    });
  }

  private selectPage(baseUrl: string): void {
    let match = false;
    this.model.pages.forEach(page => {
      page.urls.forEach(url =>  {
        if (baseUrl.includes(url)) {
          match = true;
        }
      })
      if (match) {
        if (page?.sections.length != 0) {
          this.page = page;
        }
        return;
      }
    });

    if (!match) {
      this.page = this.model.pages.find(p => p.urls.includes("default"));
    }
  }

  private selectSections(baseUrl: string): void {
    this.page?.sections.forEach(section => {
      if (section.url.includes(baseUrl)) {
        section.selected = true;
      }

      section.fields.forEach(f => {
        if (f.url.includes(baseUrl)) {
          section.selected = true;
          const routerParams = this.getQueryParams(this.router.url)
          const fieldType = this.getQueryParams(f.url)[0];
          if (routerParams.includes(fieldType)) {
            f.selected = true;
          }
        }
      });
      return;
    });
  }

  private getQueryParams(url: string): Array<string> {
    const paramsStart = url.indexOf("?") + 1;
    const params = url.substring(paramsStart, url.length - 1);
    return params.split("&");
  }

}
