import { AuthService } from "../../auth/services/auth.service";
import { INavigationMenu } from "../../shared/modules/basic/structures/app";
import { OnboardingHintEventService, OnboardingHintEventType } from "../../shared/modules/basic/components/onboarding-hint/onboarding-hint-event.service";
import { PortfolioService } from "../../widgets/portfolio/services/portfolio.service";

export class OnboardingService {

    constructor(private authService: AuthService,
                private portfolioService: PortfolioService,
                private onboardingEventService: OnboardingHintEventService,
                private menuItems: Array<INavigationMenu>) {
        this.onboardingEventService.subscription().subscribe(type => {
            if (type === OnboardingHintEventType.Portfolio) {
                const portfolioItem = this.menuItems.find(x => x.route?.includes('portfolio'));
                portfolioItem!.onboardingHint = "Add 5 stocks to your Portfolio to get top flow bets list";
            } else if (type === OnboardingHintEventType.Alerts) {
                const portfolioItem = this.menuItems.find(x => x.route?.includes('portfolio'));
                portfolioItem!.onboardingHint = undefined;
        
                const alertsItem = this.menuItems.find(x => x.route?.includes('alerts'));
                alertsItem!.onboardingHint = "Set alerts for a free trial";
            }
        });
    }

    public async updateNumberOfEvents(menuItems: Array<INavigationMenu>): Promise<void> {
        const portfolioItem = menuItems.find(x => x.route?.includes('portfolio'));
        if (portfolioItem) {
          portfolioItem.numberOfEvents = await this.getNumberOfStocksInPortfolio();
        }
    
        const alertsItem = menuItems.find(x => x.route?.includes('alerts'));
        if (alertsItem) {
          alertsItem.numberOfEvents = 0;
        }
      }
    
      private async getNumberOfStocksInPortfolio(): Promise<number> {
        const isAuthenticated = await this.authService.isAuthenticated();
        if (isAuthenticated) {
          const response = await this.portfolioService.loadTickers(true);
          return response.tickers.length;
        }
        
        return 0;
      }
}