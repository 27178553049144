import { ANIM_SCALE_Y } from 'src/app/modules/shared/modules/basic/animations/scale.animation';
import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-expansion-panel',
  templateUrl: './expansion-panel.component.html',
  styleUrls: ['./expansion-panel.component.scss'],
  animations: [ANIM_SCALE_Y],
})
export class ExpansionPanelComponent {
  @Input() text: string;
  @Input() locked = false;
  @Input() visible = false;
  @Output() visibleChanged = new EventEmitter<boolean>();

  constructor() {}

  toggle() {
    this.visible = !this.visible;
    this.visibleChanged.emit(this.visible);
  }
}
