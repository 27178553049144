import {
  Component,
  EventEmitter,
  Inject,
  Input,
  LOCALE_ID,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Selection } from 'd3';
import * as d3Selection from 'd3-selection';
import * as d3Scale from 'd3-scale';
import {
  CashFlowExposureTooltipObj,
  ExpTypeKey,
  SvgSetting,
} from '../../models/cash-flow-chart.model';
import {
  CashFlowTotalExposureData,
  CashFlowTotalExposureDataOpenInterest,
} from '../../models/cash-flow-total-exposure.modal';
import { filter, Subscription } from 'rxjs';
import { ExpirationTypeService } from '../../services/expiration-type.service';
import { DOCUMENT, formatNumber } from '@angular/common';
import { arrow, computePosition, flip, offset } from '@floating-ui/dom';
import { ChartService } from '../../services/chart.service';
import { UserTypeModel } from '../../../../shared/models/user-type.model';
import { AuthService } from '../../../../auth/services/auth.service';
import { AnalyticService } from '@app/services/analytic.service';
import { AuthFormService } from '../../../../auth/services/auth-form.service';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-cash-flow-total-exposure',
  templateUrl: './cash-flow-total-exposure.component.html',
  styleUrls: ['./cash-flow-total-exposure.component.scss'],
})
export class CashFlowTotalExposureComponent implements OnInit, OnDestroy {
  cleanChartService$!: Subscription;
  expirationTypeService$!: Subscription;
  totalExposureDataService$!: Subscription;

  // prettier-ignore
  @Input()
  set appCashFlowTotalExposureSettingsSvg(settings: { selector: string; tickerName: string }) {
    this.runStartTime = new Date().getTime();

    this.svgSelector = settings.selector;
    this.cashFlowTotalExposureDataTicker = settings.tickerName;

    this.svg = d3Selection.select(this.svgSelector + ' > ' + this.svgTotalExposureSelector);

    /** узел для индикатора */
    if (this.svg.empty()) {
      this.svg = d3Selection.select(this.svgSelector).append('g').attr('id', this.svgTotalExposureSelector.substring(1));
    }

    const svgTotalExposurePriceLineSelector: Selection<any, any, any, any> = d3Selection.select(this.svgSelector + ' > #cash-flow-chart-draggable-long > ' + this.svgTotalExposurePriceLineSelector);

    /** узел для линии текущей цены */
    if (svgTotalExposurePriceLineSelector.empty()) {
      d3Selection.select('#cash-flow-chart-draggable-long').append('g').attr('id', this.svgTotalExposurePriceLineSelector.substring(1));
    }

    /** на загрузку */
    this.chartService.getCurrentTotalExposureData(this.cashFlowTotalExposureDataTicker);
  }

  @Input()
  set appCashFlowTotalExposureSvgSetting(svgSetting: SvgSetting) {
    this.svgSetting = svgSetting;
  }

  // prettier-ignore
  @Input()
  set appCashFlowTotalExposureScaleY(svgScaleY: d3Scale.ScaleLinear<number, number>) {
    this.svgScaleY = svgScaleY;
  }

  @Input()
  set appCashFlowTotalExposureScaleYData(scaleYData: number[]) {
    this.cashFlowTotalExposureScaleYData = scaleYData;
  }

  // prettier-ignore
  @Output() cashFlowTotalExposureTooltip: EventEmitter<CashFlowExposureTooltipObj[]> = new EventEmitter();

  userType: UserTypeModel = UserTypeModel.Unregistered;

  cashFlowTotalExposureData!: CashFlowTotalExposureData;
  cashFlowTotalExposureDataInUpdate: boolean = false;
  cashFlowTotalExposureScaleYData: number[] = [];

  cashFlowTotalExposureDataTicker!: string;
  cashFlowTotalExposureDataActiveExpType: ExpTypeKey = 'month';
  cashFlowTotalExposureActivePeriod: string = '1d'; // 1d | 5d | 30d
  cashFlowTotalExposurePeriodList: string[] = ['1d', '5d', '30d'];

  svg: Selection<any, any, any, any>;
  svgSetting: SvgSetting;
  svgScaleY: d3Scale.ScaleLinear<number, number>;

  svgWrapperWidth: number = 246;

  svgSelector: string;
  svgTotalExposureSelector: string = '#cash-flow-chart-total-exposure';
  // prettier-ignore
  svgTotalExposureWrapperSelector: string = '#cash-flow-chart-total-exposure-wrapper';
  // prettier-ignore
  svgTotalExposurePriceSelector: string = '#cash-flow-chart-total-exposure-price';
  // prettier-ignore
  svgTotalExposurePriceLineSelector: string = '#cash-flow-chart-total-exposure-price-line';
  // prettier-ignore
  svgTotalExposureLevelSelector: string = '#cash-flow-chart-total-exposure-level';
  // prettier-ignore
  svgTotalExposureOpenInterestSelector: string = '#cash-flow-chart-total-exposure-open-interest';
  svgSelectorExposureTooltip: string = '#cash-flow-chart-exposure-tooltip';
  svgSelectorExposureTooltipPopper: any;

  exposureTooltipList: CashFlowExposureTooltipObj[] = [];

  levels: Array<{
    text: string;
    name: string;
    background: string;
    top: string;
    right: string;
    width: string;
  }> = [
    {
      text: 'Strong Resistance',
      name: 'resistance',
      background: '#F16D6A',
      top: '0',
      right: '266px',
      width: '0',
    },
    {
      text: 'Strong Support',
      name: 'support',
      background: '#41B1A6',
      top: '0',
      right: '266px',
      width: '0',
    },
  ];

  runStartTime: number;

  constructor(
    @Inject(DOCUMENT)
    private document: Document,
    @Inject(LOCALE_ID)
    private locale: string,
    private router: Router,
    private chartService: ChartService,
    private expirationTypeService: ExpirationTypeService,
    private analyticService: AnalyticService,
    private authFormService: AuthFormService,
    private authService: AuthService,
    private cookieService: CookieService,
  ) {}

  // prettier-ignore
  ngOnInit(): void {
    this.authService.getUserType().then((userTypeModel: UserTypeModel) => {
      this.userType = userTypeModel;

      if (this.userType < 2) {
        // @ts-ignore
        this.document.querySelector(this.svgTotalExposureSelector)?.style.display = 'none';
      } else {
        // @ts-ignore
        this.document.querySelector(this.svgTotalExposureSelector)?.style.display = 'block';
      }
    });

    /** переключение тикера */
    this.cleanChartService$ = this.chartService.cleanSubject
      .pipe(filter((clean: boolean) => clean))
      .subscribe(() => {
        /** чистим для отрисовки заново */
        this.cleanSvg();
      });

    /** переключение зума */
    this.expirationTypeService$ = this.expirationTypeService.currentExpTypeSubject
      .pipe(filter((expTypeKey: ExpTypeKey) => this.cashFlowTotalExposureDataActiveExpType !== expTypeKey))
      .subscribe((expTypeKey: ExpTypeKey) => {
        this.cashFlowTotalExposureDataActiveExpType = expTypeKey;

        /** чистим для отрисовки заново */
        this.cleanSvg();

        /** отправляем на загрузку */
        this.chartService.getCurrentTotalExposureData(this.cashFlowTotalExposureDataTicker);
      });

    this.totalExposureDataService$ = this.chartService.currentTotalExposureData.subscribe((currentExposureData) => {
      if (currentExposureData) {
        this.cashFlowTotalExposureData = currentExposureData.openInterest;

        /** Стартуем отрисовку */
        this.setSvgWrapper();
        this.setSvgPrice();
        this.setSvgOpenInterest();
        this.setSvgLevels();

        this.setSvgExposureLevelTooltipEvents();
        this.setSvgExposureBetsTooltipEvents();
      } else {
        /** чистим svg */
        this.cleanSvg();
      }
    });
  }

  ngOnDestroy(): void {
    [
      this.cleanChartService$,
      this.expirationTypeService$,
      this.totalExposureDataService$,
    ].forEach(($: Subscription) => $?.unsubscribe());
  }

  /** удаляем ненужное для отрисовки заново */
  // prettier-ignore
  cleanSvg(): void {
    /** удаляем данные */
    this.cashFlowTotalExposureData = {} as CashFlowTotalExposureData;

    /** удаляем wrapper */
    d3Selection.selectAll(this.svgTotalExposureWrapperSelector + ' > *').remove();

    /** удаляем price */
    d3Selection.selectAll(this.svgTotalExposurePriceSelector + ' > *').remove();

    /** удаляем line price */
    d3Selection.selectAll(this.svgTotalExposurePriceLineSelector + ' > *').remove();

    /** удаляем levels */
    d3Selection.selectAll(this.svgTotalExposureLevelSelector + ' > *').remove();

    /** удаляем bars */
    d3Selection.selectAll(this.svgTotalExposureOpenInterestSelector + ' > *').remove();
  }

  // prettier-ignore
  setSvgWrapper(): void {
    /** порядок создание узлов важен */
    /** PRICE */
    let priceParent = () => this.svg.select(this.svgTotalExposurePriceSelector);

    if (priceParent().empty()) {
      this.svg.append('g').attr('id', this.svgTotalExposurePriceSelector.substring(1));
    }

    /** WRAPPER */
    let wrapperParent = () => this.svg.select(this.svgTotalExposureWrapperSelector);

    if (wrapperParent().empty()) {
      this.svg.append('g').attr('id', this.svgTotalExposureWrapperSelector.substring(1));
    }

    wrapperParent()
      .append('rect')
      .attr('fill', '#fff')
      .attr('opacity', 0.7)
      .attr('x', this.svgSetting.width - this.svgSetting.marginRight - this.svgWrapperWidth)
      .attr('y', 0)
      .attr('width', this.svgWrapperWidth)
      .attr('height', this.svgSetting.height)


    /** LEVELS */
    let levelsParent = () => this.svg.select(this.svgTotalExposureLevelSelector);

    if (levelsParent().empty()) {
      this.svg.append('g').attr('id', this.svgTotalExposureLevelSelector.substring(1));
    }

    /** OPEN INTEREST */
    let openInterestParent = () => this.svg.select(this.svgTotalExposureOpenInterestSelector);

    if (openInterestParent().empty()) {
      this.svg.append('g').attr('id', this.svgTotalExposureOpenInterestSelector.substring(1));
    }
  }

  // prettier-ignore
  setSvgPrice(): void {
    let priceLineParent = () => d3Selection.select(this.svgTotalExposurePriceLineSelector);
    let priceParent = () => this.svg.select(this.svgTotalExposurePriceSelector);

    priceLineParent()
      .append('line')
      .style('stroke', '#808080')
      .style("stroke-dasharray", ("9, 5"))
      .style('stroke-width', 1.5)
      .attr('x1', -34560)
      .attr('x2', 0)
      .attr('y1', this.svgScaleY(this.cashFlowTotalExposureData.close))
      .attr('y2', this.svgScaleY(this.cashFlowTotalExposureData.close))
      .transition()
      .duration(1200)
      .delay(500)
      .attr('x2', 34560)

    /** short line */
    priceParent()
      .append('line')
      .style('stroke', '#808080')
      .style("stroke-dasharray", ("6, 3"))
      .style('stroke-width', 1.5)
      .attr('x1', 1460)
      .attr('x2', this.svgSetting.width - this.svgSetting.marginRight + 16)
      .attr('y1', this.svgScaleY(this.cashFlowTotalExposureData.close))
      .attr('y2', this.svgScaleY(this.cashFlowTotalExposureData.close));

    priceParent()
      .append('rect')
      .attr('fill', '#696969')
      .attr('opacity', 1)
      .attr('x', this.svgSetting.width - this.svgSetting.marginRight + 16)
      .attr('y', this.svgScaleY(this.cashFlowTotalExposureData.close) - 9)
      .attr('width', 0)
      .attr('height', 16)
      .attr("rx", 6)
      .transition()
      .duration(800)
      .delay(500)
      .attr('width', 59)

    priceParent()
      .append('text')
      .attr('x', this.svgSetting.width - this.svgSetting.marginRight + 18)
      .attr('y', this.svgScaleY(this.cashFlowTotalExposureData.close) + 3)
      .attr('opacity', 1)
      .style('fill', '#FFFFFF')
      .style('font-family', 'B612 Mono')
      .style('font-size', '12px')
      .style('font-weight', 400)
      .style('line-height', '16px')
      .style('cursor', 'pointer')
      .text('$' + formatNumber(this.cashFlowTotalExposureData.close, this.locale, '1.2-2'))
  }

  // prettier-ignore
  setSvgLevels(): void {
    let levelsParent = () => this.svg.select(this.svgTotalExposureLevelSelector);

    /** SUPPORT */
    let supportParent = () => this.svg.select(this.svgTotalExposureLevelSelector + ' > g[data-name="support"]');

    if(supportParent().empty()) {
      levelsParent().append('g').attr('data-name', 'support');
    }

    /** line */
    supportParent()
      .data([this.cashFlowTotalExposureData.support])
      .append('line')
      .style('stroke', '#41B1A6')
      .style('stroke-width', 1)
      .attr('x1', this.svgSetting.width - this.svgSetting.marginRight)
      .attr('x2', this.svgSetting.width - this.svgSetting.marginRight + 16)
      .attr('y1', this.svgScaleY(this.cashFlowTotalExposureData.support))
      .attr('y2', this.svgScaleY(this.cashFlowTotalExposureData.support))
      .style('cursor', 'pointer')
      .transition()
      .duration(900)
      .delay(500)
      .attr('x1', this.svgSetting.width - this.svgSetting.marginRight - this.svgWrapperWidth - 56)

    /** label left */
    this.levels[1].top = this.svgScaleY(this.cashFlowTotalExposureData.support) - 8.5 + 'px';
    setTimeout(() => { this.levels[1].width = '130px'; }, 600)

    /** label right */
    supportParent()
      .append('rect')
      .attr('fill', '#41B1A6')
      .attr('opacity', 1)
      .attr('x', this.svgSetting.width - this.svgSetting.marginRight + 16)
      .attr('y', this.svgScaleY(this.cashFlowTotalExposureData.support) - 9)
      .attr('width', 0)
      .attr('height', 16)
      .attr("rx", 6)
      .style('cursor', 'pointer')
      .transition()
      .duration(800)
      .delay(500)
      .attr('width', 59)

    supportParent()
      .append('text')
      .attr('x', this.svgSetting.width - this.svgSetting.marginRight + 18)
      .attr('y', this.svgScaleY(this.cashFlowTotalExposureData.support) + 3)
      .attr('opacity', 1)
      .style('fill', '#FFFFFF')
      .style('font-family', 'B612 Mono')
      .style('font-size', '12px')
      .style('font-weight', 400)
      .style('line-height', '16px')
      .style('cursor', 'pointer')
      .text('$' + formatNumber(this.cashFlowTotalExposureData.support, this.locale, '1.2-2'))

    /** RESISTANCE */
    let resistanceParent = () => this.svg.select(this.svgTotalExposureLevelSelector + ' > g[data-name="resistance"]');

    if(resistanceParent().empty()) {
      levelsParent().append('g').attr('data-name', 'resistance');
    }

    /** line */
    resistanceParent()
      .data([this.cashFlowTotalExposureData.resistance])
      .append('line')
      .style('stroke', '#F16D6A')
      .style('stroke-width', 1)
      .attr('x1', this.svgSetting.width - this.svgSetting.marginRight)
      .attr('x2', this.svgSetting.width - this.svgSetting.marginRight + 16)
      .attr('y1', this.svgScaleY(this.cashFlowTotalExposureData.resistance))
      .attr('y2', this.svgScaleY(this.cashFlowTotalExposureData.resistance))
      .transition()
      .duration(900)
      .delay(500)
      .attr('x1', this.svgSetting.width - this.svgSetting.marginRight - this.svgWrapperWidth - 79)

    /** label left */
    this.levels[0].top = this.svgScaleY(this.cashFlowTotalExposureData.resistance) - 8.5 + 'px';
    setTimeout(() => { this.levels[0].width = '153px'; }, 600);

    /** label right */
    resistanceParent()
      .append('rect')
      .attr('fill', '#F16D6A')
      .attr('opacity', 1)
      .attr('x', this.svgSetting.width - this.svgSetting.marginRight + 16)
      .attr('y', this.svgScaleY(this.cashFlowTotalExposureData.resistance) - 9)
      .attr('width', 0)
      .attr('height', 16)
      .attr("rx", 6)
      .transition()
      .duration(800)
      .delay(500)
      .attr('width', 59)

    resistanceParent()
      .append('text')
      .attr('x', this.svgSetting.width - this.svgSetting.marginRight + 18)
      .attr('y', this.svgScaleY(this.cashFlowTotalExposureData.resistance) + 3)
      .attr('opacity', 1)
      .style('fill', '#FFFFFF')
      .style('font-family', 'B612 Mono')
      .style('font-size', '12px')
      .style('font-weight', 400)
      .style('line-height', '16px')
      .style('cursor', 'pointer')
      .text('$' + formatNumber(this.cashFlowTotalExposureData.resistance, this.locale, '1.2-2'))
  }

  // prettier-ignore
  setSvgOpenInterest(): void {
    const minPrice: number = Math.min(...this.cashFlowTotalExposureScaleYData);
    const maxPrice: number = Math.max(...this.cashFlowTotalExposureScaleYData);
    const step: number = (maxPrice - minPrice) / 35 // "35" - допустимое кол-во баров на всю высоту
    let clearOIList: CashFlowTotalExposureDataOpenInterest[][] = [];
    let sortedClearOIList: CashFlowTotalExposureDataOpenInterest[][] = [];

    let openInterestParent = () => this.svg.select(this.svgTotalExposureOpenInterestSelector);

    for(let i: number = 0; i <= 35; i++) {
      const isFirst: boolean = i === 0;
      const isLast: boolean = i === 35;

      clearOIList.push(
        this.cashFlowTotalExposureData.oi.filter((openInterest: CashFlowTotalExposureDataOpenInterest) => {
          if (isFirst) {
            return openInterest.price > maxPrice - step * i - step / 2;
          }

          if (isLast) {
            return openInterest.price < maxPrice - step * i + step / 2;
          }

          return openInterest.price < maxPrice - step * i + step / 2 && openInterest.price > maxPrice - step * i - step / 2;
        }),
      );
    }

    sortedClearOIList = clearOIList.map((openInterestList: CashFlowTotalExposureDataOpenInterest[]) => {
      return openInterestList.sort((a: CashFlowTotalExposureDataOpenInterest, b: CashFlowTotalExposureDataOpenInterest) => {
        // @ts-ignore
        const maxA: number = Math.max(Math.abs(a.decline_oi_sum), Math.abs(a.growth_oi_sum));
        // @ts-ignore
        const maxB: number = Math.max(Math.abs(b.decline_oi_sum), Math.abs(b.growth_oi_sum));

        return maxB - maxA;
      }).reduce((accumulator: CashFlowTotalExposureDataOpenInterest[], current: CashFlowTotalExposureDataOpenInterest) => {
        // чистим от дублей по страйку(price)
        if (accumulator.filter((item: CashFlowTotalExposureDataOpenInterest) => item.price === current.price).length === 0) {
          accumulator.push(current);
        }

        return accumulator;
      }, []);
    });

    const maxWidthBar: number = 160;
    const maxSum: number = Math.max(...sortedClearOIList.map((openInterestList: CashFlowTotalExposureDataOpenInterest[]) => {
      return openInterestList.length ? openInterestList[0].decline_oi_sum + openInterestList[0].growth_oi_sum : 0;
    }));

    const multiplier: number = maxWidthBar / maxSum;

    sortedClearOIList.forEach((openInterestList: CashFlowTotalExposureDataOpenInterest[], index: number) => {
      if (openInterestList.length) {
        let barParent = () => this.svg.select(this.svgTotalExposureOpenInterestSelector + ' > g[data-price="' + openInterestList[0].price + '"]');

        if (barParent().empty()) {
          openInterestParent().append('g').attr('data-price', openInterestList[0].price);
        }

        const price: number = maxPrice - step * index;
        const growWidth: number = Math.abs(openInterestList[0].growth_oi_sum) * multiplier > 4 ? Math.abs(openInterestList[0].growth_oi_sum) * multiplier : 4;
        const declineWidth: number = Math.abs(openInterestList[0].decline_oi_sum) * multiplier > 4 ? Math.abs(openInterestList[0].decline_oi_sum) * multiplier : 4;

        barParent()
          .data([openInterestList])
          .append('rect')
          .attr('data-grow-sum', openInterestList[0].growth_oi_sum)
          .attr('fill', '#41B1A6')
          .attr('opacity', 0.5)
          .attr('x', this.svgSetting.width - this.svgSetting.marginRight)
          .attr('y', this.svgScaleY(price) - 4)
          .attr('width', 0)
          .attr('height', 8)
          .style('cursor', 'pointer')
          .transition()
          .duration(500)
          .delay(300)
          .attr('width', growWidth)
          .attr('x', this.svgSetting.width - this.svgSetting.marginRight - growWidth)

        barParent()
          .append('rect')
          .attr('data-decline-sum', openInterestList[0].decline_oi_sum)
          .attr('fill', '#F16D6A')
          .attr('opacity', 0.5)
          .attr('x', this.svgSetting.width - this.svgSetting.marginRight)
          .attr('y', this.svgScaleY(price) - 4)
          .attr('width', 0)
          .attr('height', 8)
          .style('cursor', 'pointer')
          .transition()
          .duration(500)
          .delay(300)
          .attr('width', declineWidth)
          .attr('x', this.svgSetting.width - this.svgSetting.marginRight - growWidth - declineWidth)
      }
    });
  }

  // prettier-ignore
  setSvgExposureBetsTooltipEvents(): void {
    const tooltip: HTMLElement | null = this.document.querySelector(this.svgSelectorExposureTooltip);
    const tooltipArrow: HTMLElement | null = this.document.querySelector(this.svgSelectorExposureTooltip + ' > .arrow');

    d3Selection
      .select(this.svgTotalExposureOpenInterestSelector)
      .selectAll('g[data-price]')
      .on('mouseover', (event: any) => {
        const parent: any = d3Selection.select(event.target.parentElement);
        const grow: any = parent.select('rect[data-grow-sum]');
        const decline: any = parent.select('rect[data-decline-sum]');

        const openInterest: CashFlowTotalExposureDataOpenInterest[] = parent.data()[0];

        if (tooltip && tooltipArrow) {
          this.svgSelectorExposureTooltipPopper = computePosition(
            event.target.parentElement,
            tooltip,
            {
              middleware: [
                flip(),
                offset(18.5),
                arrow({
                  element: tooltipArrow,
                }),
              ],
              placement: 'left',
            },
          ).then(({ x, y, middlewareData }) => {
            if (middlewareData.arrow) {
              const { x, y } = middlewareData.arrow;

              Object.assign(tooltipArrow.style, {
                left: x != null ? `${x}px` : '',
                top: y != null ? `${y}px` : '',
              });
            }

            Object.assign(tooltip.style, {
              left: `${x}px`,
              top: `${y}px`,
            });
          });

          /** устанавливаем значения для тултипа и передаем наверх */
          this.setTooltip(openInterest.slice(0, 5), null);

          tooltip.classList.remove('!hidden');
          tooltip.classList.add('arrow-right');

          grow
            .transition()
            .duration(300)
            .attr('opacity', 1);

          decline
            .transition()
            .duration(300)
            .attr('opacity', 1);
        }
      })
      .on('mouseout', (event: any) => {
        const parent: any = d3Selection.select(event.target.parentElement);
        const grow: any = parent.select('rect[data-grow-sum]');
        const decline: any = parent.select('rect[data-decline-sum]');

        if (tooltip) {
          tooltip.classList.add('!hidden');
          tooltip.classList.remove('arrow-right');

          grow
            .transition()
            .duration(300)
            .attr('opacity', 0.5);

          decline
            .transition()
            .duration(300)
            .attr('opacity', 0.5);
        }
      });
  }

  // prettier-ignore
  setSvgExposureLevelTooltipEvents(): void {
    d3Selection
      .select(this.svgTotalExposureLevelSelector)
      .selectAll('g[data-name]')
      .on('mouseover', (event: any) => this.onMouseover(event.target.parentElement.dataset.name))
      .on('mouseout', () => this.onMouseout());
  }

  // prettier-ignore
  onMouseover(name: string): void {
    const tooltip: HTMLElement | null = this.document.querySelector(this.svgSelectorExposureTooltip);
    const tooltipArrow: HTMLElement | null = this.document.querySelector(this.svgSelectorExposureTooltip + ' > .arrow');

    const el: any = this.document.querySelector(this.svgTotalExposureLevelSelector + ' > g[data-name="' + name + '"]');
    const parent: any = d3Selection.select(el);
    const ivLabel: string = name; // event.target.parentElement.dataset.name;

    const openInterest: CashFlowTotalExposureDataOpenInterest = this.cashFlowTotalExposureData.oi.filter((oi: CashFlowTotalExposureDataOpenInterest) => oi.price === parent.data()[0])[0];

    if (tooltip && tooltipArrow) {
      this.svgSelectorExposureTooltipPopper = computePosition(
        el,
        tooltip,
        {
          middleware: [
            flip(),
            offset(18.5),
            arrow({
              element: tooltipArrow,
            }),
          ],
          placement: 'left',
        },
      ).then(({ x, y, middlewareData }) => {
        if (middlewareData.arrow) {
          const { x, y } = middlewareData.arrow;

          Object.assign(tooltipArrow.style, {
            left: x != null ? `${x}px` : '',
            top: y != null ? `${y}px` : '',
          });
        }

        Object.assign(tooltip.style, {
          left: `${x}px`,
          top: `${y}px`,
        });
      });

      /** устанавливаем значения для тултипа и передаем наверх */
      this.setTooltip([openInterest], ivLabel[0].toUpperCase() + ivLabel.substring(1));

      tooltip.classList.remove('!hidden');
      tooltip.classList.add('arrow-right');
    }
  }

  // prettier-ignore
  onMouseout(): void {
    const tooltip: HTMLElement | null = this.document.querySelector(this.svgSelectorExposureTooltip);

    if (tooltip) {
      tooltip.classList.add('!hidden');
      tooltip.classList.remove('arrow-right');
    }
  }

  // prettier-ignore
  setTooltip(openInterestList: CashFlowTotalExposureDataOpenInterest[], ivLevel: string | null): void {
    this.exposureTooltipList = [];

    const maxWidthBar: number = 176;
    let maxNumberList: number[] = [];

    openInterestList.forEach((openInterest: CashFlowTotalExposureDataOpenInterest) => {
      const maxNumber: number = Math.max(
        Math.abs(openInterest.growth_oi_sum),
        // @ts-ignore
        Math.abs(openInterest.growth_oi_sum) + Math.abs(openInterest['growth_bets_' + this.cashFlowTotalExposureActivePeriod]),
        Math.abs(openInterest.decline_oi_sum),
        // @ts-ignore
        Math.abs(openInterest.decline_oi_sum) + Math.abs(openInterest['decline_bets_' + this.cashFlowTotalExposureActivePeriod])
      );

      maxNumberList.push(maxNumber);
    });

    openInterestList.forEach((openInterest: CashFlowTotalExposureDataOpenInterest) => {
      const maxNumber: number = Math.max(...maxNumberList);
      const multiplier: number = maxWidthBar / maxNumber;

      // @ts-ignore
      const growPercentWidth: number = multiplier * Math.abs(openInterest['growth_bets_' + this.cashFlowTotalExposureActivePeriod])
      // @ts-ignore
      const declinePercentWidth: number = multiplier * Math.abs(openInterest['decline_bets_' + this.cashFlowTotalExposureActivePeriod])

      this.exposureTooltipList.push({
        ivLevel,
        winRate: openInterest.win_rate,
        strike: openInterest.price,
        growSum: Math.abs(openInterest.growth_oi_sum),
        growSumWidth: multiplier * Math.abs(openInterest.growth_oi_sum) > 4 ? multiplier * Math.abs(openInterest.growth_oi_sum) : 4,
        // @ts-ignore
        growPercentWidth: growPercentWidth > 4 ? growPercentWidth : 4,
        // @ts-ignore
        growPercent: openInterest['growth_bets_' + this.cashFlowTotalExposureActivePeriod + '_per_oi_sum_percent'],
        declineSumWidth: multiplier * Math.abs(openInterest.decline_oi_sum) > 4 ? multiplier * Math.abs(openInterest.decline_oi_sum) : 4,
        declineSum: Math.abs(openInterest.decline_oi_sum),
        // @ts-ignore
        declinePercentWidth: declinePercentWidth > 4 ? declinePercentWidth : 4,
        // @ts-ignore
        declinePercent: openInterest['decline_bets_' + this.cashFlowTotalExposureActivePeriod + '_per_oi_sum_percent'],
        period: this.cashFlowTotalExposureActivePeriod
      });
    });

    /** отправляем тултип выше */
    this.cashFlowTotalExposureTooltip.emit(this.exposureTooltipList);
  }

  /** клик по заблокированному индикатору */
  onShowIndicator(): void {
    /** запоминаем страницу с которой пошли на покупку */
    this.cookieService.delete('page-before-payment', '/');
    this.cookieService.set('page-before-payment', this.router.url, {
      path: '/',
    });

    /** Не авторизован */
    if (this.userType === 0) {
      this.analyticService.send(
        'click_sign_up',
        new Map([['name', 'В индикаторе "total exposure"']]),
      );
      this.authFormService.openSignUp();
    }

    /** Авторизован */
    if (this.userType > 0) {
      // prettier-ignore
      this.router.navigate(['/premium'], { fragment: 'tariffs' }).then(() => console.debug('Route changed'));
    }
  }
}
