<div class="flex items-center !gap-3">
  <div
    appDropdown
    *ngIf="{ value: false } as toggle"
    (toggled)="toggle.value = $event"
  >
    <button
      class="flex !gap-3 rounded bg-surface-4 text-interface-control-3-semibold text-default-neutral-1 !p-2.5 duration-300 disabled:cursor-not-allowed disabled:opacity-40"
      type="button"
      slot="target"
    >
      <span class="block px-1">
        {{ showItemsCount }}
      </span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        class="duration-300"
        [class.-rotate-180]="toggle.value"
      >
        <path
          d="M13.1381 5.52864C12.8777 5.26829 12.4556 5.26829 12.1953 5.52864L7.99999 9.7239L3.80473 5.52864C3.54438 5.26829 3.12227 5.26829 2.86192 5.52864C2.60157 5.78899 2.60157 6.2111 2.86192 6.47145L7.52859 11.1381C7.78893 11.3985 8.21105 11.3985 8.47139 11.1381L13.1381 6.47145C13.3984 6.2111 13.3984 5.78899 13.1381 5.52864Z"
          fill="#303D50"
        />
      </svg>
    </button>
    <div
      class="flex dropdown-shadow p-2 rounded mt-2 w-[80px] h-[170px] bg-surface-1"
      slot="content"
    >
      <ul class="w-full">
        <li
          *ngFor="let count of showItemsCountValues"
          (click)="changeItemsCount(count)"
          class="flex items-center justify-between hover:rounded group p-0.5 hover:bg-[#a3b0c233] duration-300 cursor-pointer border-b border-[#DADADA] hover:border-transparent"
        >
          <span class="block px-2 py-1 text-[12px] text-[#393939] truncate">
            {{ count }}
          </span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            class="flex-shrink-0"
            *ngIf="count === showItemsCount"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M18.6181 6.77853C18.2197 6.43708 17.62 6.48321 17.2785 6.88157L9.42839 16.0401L6.72112 12.8816C6.37966 12.4832 5.77993 12.4371 5.38157 12.7785C4.98321 13.12 4.93708 13.7197 5.27853 14.1181L8.7071 18.1181C8.88758 18.3286 9.15106 18.4498 9.42839 18.4498C9.70572 18.4498 9.9692 18.3286 10.1497 18.1181L18.7211 8.11807C19.0626 7.71971 19.0164 7.11998 18.6181 6.77853Z"
              fill="#696969"
            />
          </svg>
        </li>
      </ul>
    </div>
  </div>

  <div class="flex text-low text-interface-caption-2-regular">
    {{ currentPageNumber * showItemsCount - showItemsCount + 1 }}
    -
    {{
      currentPageNumber * showItemsCount > totalPagesCount
        ? totalPagesCount
        : currentPageNumber * showItemsCount
    }}
    of {{ totalPagesCount }}
  </div>
</div>
