export const POPULAR_STOCKS = [
  {
    added: false,
    ticker: {
      id: '',
      name: 'Tesla Inc',
      ticker: 'TSLA',
    },
  },
  {
    added: false,
    ticker: {
      id: '',
      name: 'Apple Inc',
      ticker: 'AAPL',
    },
  },
  {
    added: false,
    ticker: {
      id: '',
      name: 'Amazon.com Inc.',
      ticker: 'AMZN',
    },
  },
];
