import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  BehaviorSubject,
  catchError,
  debounceTime,
  finalize,
  map,
  merge,
  Observable,
  of,
  startWith,
  Subject,
  switchMap,
  takeUntil,
  takeWhile,
  tap,
  timer,
} from 'rxjs';

import { HelperService } from '@app/services/helper.service';
import { SettingsService } from '@main/profile/services/settings.service';
import { CODE_LENGTH } from '../dialog.component';

@Component({
  selector: 'profile-enter-new-email-code',
  templateUrl: './enter-new-email-code.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnterNewEmailCodeComponent implements OnInit, OnDestroy {
  public readonly email$ = this.settingsService.latestNewEmailValue$;

  @Output() next = new EventEmitter<void>();
  @Output() back = new EventEmitter<void>();

  private readonly destroy$ = new Subject<void>();

  public readonly codeForm = new FormGroup({
    code: new FormControl('', {
      validators: [
        Validators.required,
        Validators.minLength(CODE_LENGTH),
        Validators.maxLength(CODE_LENGTH),
      ],
    }),
  });

  public readonly sendCode$ = new Subject<void>();
  public readonly resendTime$: Observable<number> = merge(
    this.sendCode$.pipe(startWith(void 0)),
  ).pipe(
    switchMap(() =>
      timer(0, 1000).pipe(
        map((value) => 60 - value), // 60 sec
        takeWhile((value) => value >= 0),
      ),
    ),
    startWith(60),
  );
  public readonly formIsSubmitting$ = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly helperService: HelperService,
    private readonly settingsService: SettingsService,
  ) {}

  ngOnInit(): void {
    const codeControl = this.codeForm.get('code');

    if (codeControl) {
      codeControl.valueChanges
        .pipe(
          debounceTime(500),
          tap(
            (code: string) =>
              code?.trim()?.length === CODE_LENGTH &&
              !this.formIsSubmitting$.value &&
              this.submit(),
          ),
          takeUntil(this.destroy$),
        )
        .subscribe();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  sendCode(): void {
    this.settingsService
      .sendNewConfirmationCode(this.email$.value)
      .pipe(
        tap(() => this.sendCode$.next()),
        catchError(() => {
          this.sendCode$.next();
          return of(null);
        }),
      )
      .subscribe();
  }

  submit(): void {
    if (this.helperService.getFormValidation(this.codeForm)) {
      this.formIsSubmitting$.next(true);
      this.settingsService
        .confirmNew(this.codeForm.get('code')?.value?.trim())
        .pipe(
          tap(() => this.next.emit()),
          catchError(() => of(null)),
          finalize(() => this.formIsSubmitting$.next(false)),
        )
        .subscribe();
    }
  }
}
