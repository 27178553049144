<div class="relative w-screen h-full">
  <div class="absolute -left-[143px] md:!-left-[290px] top-0">
    <svg
      class="block md:!hidden"
      width="286"
      height="440"
      viewBox="0 0 286 440"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M125 -6.53267e-05L286 440C254.07 438.287 221.109 434.86 186.776 429.72C157.249 425.607 126.005 419.782 93.3878 411.558C60.7707 403.676 29.527 392.025 0 376.604L125 -6.53267e-05Z"
        fill="#F8FAFC"
      />
    </svg>
    <svg
      class="hidden md:!block"
      width="572"
      height="880"
      viewBox="0 0 572 880"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M250 -0.000130653L572 880C508.139 876.573 442.219 869.72 373.551 859.439C314.497 851.215 252.01 839.564 186.776 823.115C121.541 807.352 59.054 784.05 0 753.209L250 -0.000130653Z"
        fill="#F8FAFC"
      />
    </svg>
  </div>
  <div class="absolute -right-[173px] md:!-right-[360px] top-0">
    <svg
      class="block md:!hidden"
      width="286"
      height="440"
      viewBox="0 0 286 440"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M125 440L286 -2.50029e-05C254.07 1.71337 221.109 5.14017 186.776 10.2804C157.249 14.3925 126.005 20.2181 93.3878 28.4424C60.7707 36.324 29.527 47.9751 5.54222e-06 63.3956L125 440Z"
        fill="#F8FAFC"
      />
    </svg>
    <svg
      class="hidden md:!block"
      width="572"
      height="880"
      viewBox="0 0 572 880"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M250 880L572 -5.00058e-05C508.139 3.42675 442.219 10.2803 373.551 20.5607C314.497 28.785 252.01 40.4361 186.776 56.8847C121.541 72.648 59.054 95.9502 1.10844e-05 126.791L250 880Z"
        fill="#F8FAFC"
      />
    </svg>
  </div>
</div>
