<app-login-form-content
  *ngIf="showLogin"
  (goToSignUp)="showLogin = false"
  (loginSuccessful)="loginSuccess.emit()"
></app-login-form-content>
<app-registration-form-content
  *ngIf="!showLogin"
  [data]="data.registr?.contentData ?? {}"
  [signUpSubscription]="data.registr?.signUpSubscription"
  [signUpEnable]="data.registr?.signUpEnable"
  [registerLicense]="data.registr?.registerLicense"
  (goToSignIn)="showLogin = true"
  (loginSuccessful)="loginSuccess.emit()"
>
</app-registration-form-content>
