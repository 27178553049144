import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-scrollable',
  templateUrl: './scrollable.component.html',
  styleUrls: ['./scrollable.component.scss'],
})
export class ScrollableComponent {
  @Input() height: string;
  @Input() width: string;
  @Input() classes = '';

  get style(): string {
    let res = this.height ? `height: ${this.height};` : ""
    res += this.width ? `width: ${this.width};` : "";
    return res;
  }
}
