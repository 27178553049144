<div class="flex flex-col bg-white w-full h-full overflow-y-auto !p-5">
  <div class="flex justify-end">
    <button (click)="close()">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.7349 12L6 7.26512L7.26512 6L12 10.7349L16.7349 6L18 7.26512L13.2651 12L18 16.7349L16.7349 18L12 13.2651L7.26512 18L6 16.7349L10.7349 12Z"
          fill="#333333"
        />
      </svg>
    </button>
  </div>
  <!-- CHART -->
  <div #chartParent class="w-full">
    <div #chartMain>
      <app-cash-flow-main [appCashFlowTickerName]="'SPY'"></app-cash-flow-main>
    </div>
  </div>
</div>
