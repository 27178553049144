import { Injectable } from '@angular/core';
import { ToFixedOptions } from './interfaces/to-fixed-options';

@Injectable()
export class TextFormattingService {
  public signed(value: number | string): string {
    const number = this.toNumber(value);

    let signedNumber: string;
    if (number > 0) {
      signedNumber = `+${value.toString()}`;
    } else {
      signedNumber = value.toString();
    }

    return signedNumber;
  }

  public toFixed(value: number | string, userOptions?: ToFixedOptions): string {
    const options = this.getToFixedOptionsWithDefaults(userOptions);
    const number = this.toNumber(value);

    let result = number.toFixed(options.fractionDigits);

    if (options.signed) {
      result = this.signed(result);
    }

    return result;
  }

  public toNumber(value: number | string | undefined | null): number {
    let number: number;
    if (value === undefined || value === null) {
      number = 0;
    } else if (typeof value === 'string') {
      number = parseFloat(value);
      if (number === NaN) {
        number = 0;
      }
    } else {
      number = value;
    }

    return number;
  }

  private getToFixedOptionsWithDefaults(
    userOptions?: ToFixedOptions,
  ): ToFixedOptions {
    return {
      fractionDigits: userOptions?.fractionDigits ?? 2,
      signed: userOptions?.signed ?? false,
    };
  }
}
