import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { map, Observable } from 'rxjs';

import { UserProfile } from '@main/profile/pages/settings/settings.model';
import { SettingsService } from '@main/profile/services/settings.service';

@Component({
  templateUrl: './password-created-successfully.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PasswordCreatedSuccessfullyComponent {
  public readonly email$: Observable<UserProfile['email']> =
    this.settingsService.userProfile$.pipe(map((user) => user?.email ?? ''));

  constructor(
    private readonly settingsService: SettingsService,
    private readonly dialog: MatDialogRef<PasswordCreatedSuccessfullyComponent>,
  ) {}

  close(): void {
    this.dialog.close();
  }
}
