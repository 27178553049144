<div
  class="flex flex-col bg-white w-screen md:max-w-[480px] h-screen md:h-auto !p-4 md:rounded-2xl"
>
  <div class="flex justify-end">
    <button (click)="close()">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.7349 12L6 7.26512L7.26512 6L12 10.7349L16.7349 6L18 7.26512L13.2651 12L18 16.7349L16.7349 18L12 13.2651L7.26512 18L6 16.7349L10.7349 12Z"
          fill="#333333"
        />
      </svg>
    </button>
  </div>
  <div class="flex flex-col items-center justify-start gap-[24px] !p-4">
    <div class="flex !p-3 bg-default-primary-1 rounded-full">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
      >
        <path
          d="M31.1663 12C30.4997 11.3333 29.4997 11.3333 28.833 12L16.333 24.5L11.1663 19.3333C10.4997 18.6667 9.49967 18.6667 8.83301 19.3333C8.16634 20 8.16634 21 8.83301 21.6667L15.1663 28C15.4997 28.3333 15.833 28.5 16.333 28.5C16.833 28.5 17.1663 28.3333 17.4997 28L31.1663 14.3333C31.833 13.6667 31.833 12.6667 31.1663 12Z"
          fill="#62C37D"
        />
      </svg>
    </div>
    <div class="flex flex-col gap-2 items-center justify-center">
      <span
        class="text-center text-default-neutral-1 text-interface-title-3-semibold"
      >
        You are already signed in!
      </span>
      <p
        class="text-center text-default-neutral-3 text-interface-body-2-regular"
      >
        Explore all our key features, using S&P 500 as an example or search any
        other ticker you are interested in
      </p>
    </div>
    <button
      (click)="openTickerPage()"
      class="kw-btn kw-btn-primary kw-btn-lg kw-btn-active w-full"
    >
      <span>Explore Our Features</span>
    </button>
  </div>
</div>
