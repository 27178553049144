import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { createPopper, Instance } from '@popperjs/core';
import { DOCUMENT } from '@angular/common';
import { UserTypeModel } from '../../shared/models/user-type.model';
import { AuthService } from '../../auth/services/auth.service';
import { AnalyticService } from '@app/services/analytic.service';
import { AuthFormService } from '../../auth/services/auth-form.service';
import { ActivatedRoute, Router } from '@angular/router';
import { first, from, fromEvent, Subscription } from 'rxjs';
import { MainHttpClient } from '@app/services/main-http-client.service';
import {
  MeteredUsageInfo,
  Tariff,
  TariffPrice,
} from './models/premium-new.model';
import {
  SubscriptionChangeTo,
  SubscriptionsNew,
} from '../profile/subscriptions-new/models/subscriptions-new.model';
import { SubscriptionDialogService } from '../profile/subscriptions-new/services/dialog.service';
import { SubscriptionChangeService } from '../profile/subscriptions-new/services/subscription-change.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-premium-new',
  templateUrl: './premium-new.component.html',
})
export class PremiumNewComponent implements AfterViewInit, OnInit, OnDestroy {
  // prettier-ignore
  @ViewChild('tableFirstRow') tableFirstRow: ElementRef<HTMLElement> | undefined;
  @ViewChild('table') table: ElementRef<HTMLElement> | undefined;

  subscriptionChangeService$!: Subscription;
  activatedRoute$!: Subscription;
  windowAction$!: Subscription;

  mySubscriptionsNew: SubscriptionsNew | undefined | null = undefined;
  meteredUsageInfo: MeteredUsageInfo | undefined = undefined;

  settingTariffForm$: Subscription | undefined;
  settingTariffForm: FormGroup;

  tariffList: Tariff[] | undefined;

  typeProTariffForm: FormGroup;
  typeProTariffForm$: Subscription | undefined;
  typePremiumTariffForm: FormGroup;
  typePremiumTariffForm$: Subscription | undefined;
  typeUltimateTariffForm: FormGroup;
  typeUltimateTariffForm$: Subscription | undefined;

  tariffNameList: string[] = ['ultimate_', 'premium_', 'pro_'];
  // prettier-ignore
  tariffViewState: any = {
    pro_: {
      title: 'Pro',
      colorClass: 'text-default-primary-2',
      icon: 'pro',
      licensedMonth: undefined,
      licensedYear: undefined,
      metered: undefined,
      typeView: 'licensed', // licensed | metered
      labelLicensedList: ['Accurate Support and Resistance', '3D Options Flow'],
      labelMeteredList: ['Accurate Support and Resistance', 'Pay If You Win', '3D Options Flow'],
      descriptionLicensedList: [
        {
          title: 'Accurate Support and Resistance',
          list: [
            {
              icon: 'check-green',
              labelList: [
                { text: '3000+ stocks', classes: 'text-interface-caption-2-semibold md:text-interface-caption-1-semibold text-default-primary-2' },
                { text: ' covered', classes: 'text-interface-caption-2-regular md:text-interface-caption-1-regular text-default-neutral-1' }
              ]
            },
            {
              icon: 'check-green',
              labelList: [
                { text: '76%', classes: 'text-interface-caption-2-semibold md:text-interface-caption-1-semibold text-default-primary-2' },
                { text: ' historic accuracy', classes: 'text-interface-caption-2-regular md:text-interface-caption-1-regular text-default-neutral-1' }
              ]
            },
          ],
        },
        {
          title: 'Pay If You Win',
          list: [
            {
              icon: 'cross',
              labelList: [
                { text: 'Monthly win rate report', classes: 'text-interface-caption-2-regular md:text-interface-caption-1-regular text-default-neutral-3' }
              ]
            },
            {
              icon: 'cross',
              labelList: [
                { text: 'Opt out of payment if you are not making money', classes: 'text-interface-caption-2-regular md:text-interface-caption-1-regular text-default-neutral-3' }
              ]
            },
          ],
          type: 'cross'
        },
        {
          title: '3D Options Flow',
          list: [
            {
              icon: 'check-green',
              labelList: [
                { text: 'Bullish/bearish', classes: 'text-interface-caption-2-semibold md:text-interface-caption-1-semibold text-default-primary-2' },
                { text: ' indication', classes: 'text-interface-caption-2-regular md:text-interface-caption-1-regular text-default-neutral-1' },
              ]
            },
            {
              icon: 'check-green',
              labelList: [
                { text: 'Specific timing', classes: 'text-interface-caption-2-semibold md:text-interface-caption-1-semibold text-default-primary-2' },
                { text: ' of sentiment', classes: 'text-interface-caption-2-regular md:text-interface-caption-1-regular text-default-neutral-1' },
              ]
            },
            {
              icon: 'check-green',
              labelList: [
                { text: 'Events, levels and accumulation patterns', classes: 'text-interface-caption-2-semibold md:text-interface-caption-1-semibold text-default-primary-2' },
              ]
            }
          ],
        },
      ],
      descriptionMeteredList: [
        {
          title: 'Accurate Support and Resistance',
          list: [
            {
              icon: 'check-green',
              labelList: [
                { text: '3000+ stocks', classes: 'text-interface-caption-2-semibold md:text-interface-caption-1-semibold text-default-primary-2' },
                { text: ' covered', classes: 'text-interface-caption-2-regular md:text-interface-caption-1-regular text-default-neutral-1' }
              ]
            },
            {
              icon: 'check-green',
              labelList: [
                { text: '76%', classes: 'text-interface-caption-2-semibold md:text-interface-caption-1-semibold text-default-primary-2' },
                { text: ' historic accuracy', classes: 'text-interface-caption-2-regular md:text-interface-caption-1-regular text-default-neutral-1' }
              ]
            },
          ],
        },
        {
          title: 'Pay If You Win',
          list: [
            {
              icon: 'check-green',
              labelList: [
                { text: 'Monthly', classes: 'text-interface-caption-2-semibold md:text-interface-caption-1-semibold text-default-primary-2' },
                { text: ' win rate report', classes: 'text-interface-caption-2-regular md:text-interface-caption-1-regular text-default-neutral-1' }
              ]
            },
            {
              icon: 'check-green',
              labelList: [
                { text: 'Opt out of payment', classes: 'text-interface-caption-2-semibold md:text-interface-caption-1-semibold text-default-primary-2' },
                { text: ' if you are not making money', classes: 'text-interface-caption-2-regular md:text-interface-caption-1-regular text-default-neutral-1' }
              ]
            },
          ],
        },
        {
          title: '3D Options Flow',
          list: [
            {
              icon: 'check-green',
              labelList: [
                { text: 'Bullish/bearish', classes: 'text-interface-caption-2-semibold md:text-interface-caption-1-semibold text-default-primary-2' },
                { text: ' indication', classes: 'text-interface-caption-2-regular md:text-interface-caption-1-regular text-default-neutral-1' },
              ]
            },
            {
              icon: 'check-green',
              labelList: [
                { text: 'Specific timing', classes: 'text-interface-caption-2-semibold md:text-interface-caption-1-semibold text-default-primary-2' },
                { text: ' of sentiment', classes: 'text-interface-caption-2-regular md:text-interface-caption-1-regular text-default-neutral-1' },
              ]
            },
            {
              icon: 'check-green',
              labelList: [
                { text: 'Events, levels and accumulation patterns', classes: 'text-interface-caption-2-semibold md:text-interface-caption-1-semibold text-default-primary-2' },
              ]
            }
          ],
        },
      ],
    },
    premium_: {
      title: 'Premium',
      colorClass: 'text-default-secondary-2',
      icon: 'premium',
      licensedMonth: undefined,
      licensedYear: undefined,
      metered: undefined,
      typeView: 'metered',
      labelLicensedList: ['70% + Win Rate Blue Indicators', 'All Sentiment tools'],
      labelMeteredList: ['70% + Win Rate Blue Indicators', 'Pay If You Win', 'All Sentiment tools'],
      descriptionLicensedList: [
        {
          title: '70% + Win Rate Blue Indicators',
          list: [
            {
              icon: 'check-green',
              labelList: [
                { text: '500+ stocks', classes: 'text-interface-caption-2-semibold md:text-interface-caption-1-semibold text-default-primary-2' },
                { text: ' covered', classes: 'text-interface-caption-2-regular md:text-interface-caption-1-regular text-default-neutral-1' }
              ]
            },
            {
              icon: 'check-green',
              labelList: [
                { text: 'Backtest Tool', classes: 'text-interface-caption-2-semibold md:text-interface-caption-1-semibold text-default-primary-2' },
              ]
            },
          ],
        },
        {
          title: 'Pay If You Win',
          list: [
            {
              icon: 'cross',
              labelList: [
                { text: 'Monthly win rate report', classes: 'text-interface-caption-2-regular md:text-interface-caption-1-regular text-default-neutral-3' }
              ]
            },
            {
              icon: 'cross',
              labelList: [
                { text: 'Opt out of payment if you are not making money', classes: 'text-interface-caption-2-regular md:text-interface-caption-1-regular text-default-neutral-3' }
              ]
            },
          ],
          type: 'cross'
        },
        {
          title: 'All Sentiment tools',
          list: [
            {
              icon: 'check-green',
              labelList: [
                { text: '3D Options Flow', classes: 'text-interface-caption-2-semibold md:text-interface-caption-1-semibold text-default-primary-2' },
              ]
            },
            {
              icon: 'check-green',
              labelList: [
                { text: 'Key Levels', classes: 'text-interface-caption-2-semibold md:text-interface-caption-1-semibold text-default-primary-2' },
              ]
            },
            {
              icon: 'check-green',
              labelList: [
                { text: 'Key Events', classes: 'text-interface-caption-2-semibold md:text-interface-caption-1-semibold text-default-primary-2' },
              ]
            }
          ],
        },
      ],
      descriptionMeteredList: [
        {
          title: '70% + Win Rate Blue Indicators',
          list: [
            {
              icon: 'check-green',
              labelList: [
                { text: '500+ stocks', classes: 'text-interface-caption-2-semibold md:text-interface-caption-1-semibold text-default-primary-2' },
                { text: ' covered', classes: 'text-interface-caption-2-regular md:text-interface-caption-1-regular text-default-neutral-1' }
              ]
            },
            {
              icon: 'check-green',
              labelList: [
                { text: 'Backtest Tool', classes: 'text-interface-caption-2-semibold md:text-interface-caption-1-semibold text-default-primary-2' },
              ]
            },
          ],
        },
        {
          title: 'Pay If You Win',
          list: [
            {
              icon: 'check-green',
              labelList: [
                { text: 'Monthly', classes: 'text-interface-caption-2-semibold md:text-interface-caption-1-semibold text-default-primary-2' },
                { text: ' win rate report', classes: 'text-interface-caption-2-regular md:text-interface-caption-1-regular text-default-neutral-1' }
              ]
            },
            {
              icon: 'check-green',
              labelList: [
                { text: 'Opt out of payment', classes: 'text-interface-caption-2-semibold md:text-interface-caption-1-semibold text-default-primary-2' },
                { text: ' if you are not making money', classes: 'text-interface-caption-2-regular md:text-interface-caption-1-regular text-default-neutral-1' }
              ]
            },
          ],
        },
        {
          title: 'All Sentiment tools',
          list: [
            {
              icon: 'check-green',
              labelList: [
                { text: '3D Options Flow', classes: 'text-interface-caption-2-semibold md:text-interface-caption-1-semibold text-default-primary-2' },
              ]
            },
            {
              icon: 'check-green',
              labelList: [
                { text: 'Key Levels', classes: 'text-interface-caption-2-semibold md:text-interface-caption-1-semibold text-default-primary-2' },
              ]
            },
            {
              icon: 'check-green',
              labelList: [
                { text: 'Key Events', classes: 'text-interface-caption-2-semibold md:text-interface-caption-1-semibold text-default-primary-2' },
              ]
            }
          ],
        },
      ],
    },
    ultimate_: {
      title: 'Ultimate',
      colorClass: 'text-active-quaternary-1',
      icon: 'ultimate',
      licensedMonth: undefined,
      licensedYear: undefined,
      metered: undefined,
      typeView: 'metered',
      labelLicensedList: ['80% + Win Rate Gold Indicators', 'All Sentiment tools'],
      labelMeteredList: ['80% + Win Rate Gold Indicators', 'Pay If You Win', 'All Sentiment tools'],
      descriptionLicensedList: [
        {
          title: '80% + Win Rate Gold Indicators',
          list: [
            {
              icon: 'check-green',
              labelList: [
                { text: 'Beat "Buy and hold" Guarantee', classes: 'text-interface-caption-2-semibold md:text-interface-caption-1-semibold text-default-primary-2' },
              ]
            },
            {
              icon: 'check-green',
              labelList: [
                { text: 'Backtest Tool', classes: 'text-interface-caption-2-semibold md:text-interface-caption-1-semibold text-default-primary-2' },
              ]
            },
          ],
        },
        {
          title: 'Pay If You Win',
          list: [
            {
              icon: 'cross',
              labelList: [
                { text: 'Monthly win rate report', classes: 'text-interface-caption-2-regular md:text-interface-caption-1-regular text-default-neutral-3' }
              ]
            },
            {
              icon: 'cross',
              labelList: [
                { text: 'Opt out of payment if you are not making money', classes: 'text-interface-caption-2-regular md:text-interface-caption-1-regular text-default-neutral-3' }
              ]
            },
          ],
          type: 'cross'
        },
        {
          title: 'All Sentiment tools',
          list: [
            {
              icon: 'check-green',
              labelList: [
                { text: '3D Options Flow', classes: 'text-interface-caption-2-semibold md:text-interface-caption-1-semibold text-default-primary-2' },
              ]
            },
            {
              icon: 'check-green',
              labelList: [
                { text: 'Key Levels', classes: 'text-interface-caption-2-semibold md:text-interface-caption-1-semibold text-default-primary-2' },
              ]
            },
            {
              icon: 'check-green',
              labelList: [
                { text: 'Key Events', classes: 'text-interface-caption-2-semibold md:text-interface-caption-1-semibold text-default-primary-2' },
              ]
            }
          ],
        },
      ],
      descriptionMeteredList: [
        {
          title: '80% + Win Rate Gold Indicators',
          list: [
            {
              icon: 'check-green',
              labelList: [
                { text: 'Beat "Buy and hold" Guarantee', classes: 'text-interface-caption-2-semibold md:text-interface-caption-1-semibold text-default-primary-2' },
              ]
            },
            {
              icon: 'check-green',
              labelList: [
                { text: 'Backtest Tool', classes: 'text-interface-caption-2-semibold md:text-interface-caption-1-semibold text-default-primary-2' },
              ]
            },
          ],
        },
        {
          title: 'Pay If You Win',
          list: [
            {
              icon: 'check-green',
              labelList: [
                { text: 'Monthly', classes: 'text-interface-caption-2-semibold md:text-interface-caption-1-semibold text-default-primary-2' },
                { text: ' win rate report', classes: 'text-interface-caption-2-regular md:text-interface-caption-1-regular text-default-neutral-1' }
              ]
            },
            {
              icon: 'check-green',
              labelList: [
                { text: 'Opt out of payment', classes: 'text-interface-caption-2-semibold md:text-interface-caption-1-semibold text-default-primary-2' },
                { text: ' if you are not making money', classes: 'text-interface-caption-2-regular md:text-interface-caption-1-regular text-default-neutral-1' }
              ]
            },
          ],
        },
        {
          title: 'All Sentiment tools',
          list: [
            {
              icon: 'check-green',
              labelList: [
                { text: '3D Options Flow', classes: 'text-interface-caption-2-semibold md:text-interface-caption-1-semibold text-default-primary-2' },
              ]
            },
            {
              icon: 'check-green',
              labelList: [
                { text: 'Key Levels', classes: 'text-interface-caption-2-semibold md:text-interface-caption-1-semibold text-default-primary-2' },
              ]
            },
            {
              icon: 'check-green',
              labelList: [
                { text: 'Key Events', classes: 'text-interface-caption-2-semibold md:text-interface-caption-1-semibold text-default-primary-2' },
              ]
            }
          ],
        },
      ],
    },
  };

  comparePlanList: Array<{
    backgroundRow: 'white' | 'gray' | 'dark';
    list: Array<{
      type:
        | 'text-regular'
        | 'text-semibold'
        | 'icon-cross'
        | 'icon-check'
        | 'empty';
      value: string;
      classes?: string;
    }>;
  }> = [
    {
      backgroundRow: 'white',
      list: [
        { type: 'text-semibold', value: 'Features' },
        { type: 'text-semibold', value: 'Free Plan' },
        { type: 'text-semibold', value: 'Pro Plan' },
        { type: 'text-semibold', value: 'Premium Plan' },
        { type: 'text-semibold', value: 'Ultimate Plan' },
      ],
    },
    {
      backgroundRow: 'gray',
      list: [
        { type: 'text-semibold', value: 'Indicators' },
        { type: 'empty', value: '' },
        { type: 'empty', value: '' },
        { type: 'empty', value: '' },
        { type: 'empty', value: '' },
      ],
    },
    {
      backgroundRow: 'white',
      list: [
        { type: 'text-regular', value: 'Indicators Access' },
        { type: 'icon-cross', value: '' },
        { type: 'icon-cross', value: '' },
        { type: 'text-regular', value: '6 Indicators' },
        { type: 'text-regular', value: '6 Indicators' },
      ],
    },
    {
      backgroundRow: 'dark',
      list: [
        { type: 'text-regular', value: 'Guaranteed Win Rate' },
        { type: 'icon-cross', value: '' },
        { type: 'icon-cross', value: '' },
        { type: 'text-regular', value: '70%' },
        { type: 'text-regular', value: '80%' },
      ],
    },
    {
      backgroundRow: 'white',
      list: [
        { type: 'text-regular', value: 'Models Selected' },
        { type: 'icon-cross', value: '' },
        { type: 'icon-cross', value: '' },
        { type: 'text-regular', value: '6 Models with 70-80% Win Rate' },
        { type: 'text-regular', value: '80% Win Rate Models' },
      ],
    },
    {
      backgroundRow: 'gray',
      list: [
        { type: 'text-semibold', value: 'Sentiment Indicator' },
        { type: 'empty', value: '' },
        { type: 'empty', value: '' },
        { type: 'empty', value: '' },
        { type: 'empty', value: '' },
      ],
    },
    {
      backgroundRow: 'white',
      list: [
        { type: 'text-regular', value: 'Sentiment Indicator Access' },
        { type: 'icon-cross', value: '' },
        { type: 'icon-check', value: '' },
        { type: 'icon-check', value: '' },
        { type: 'icon-check', value: '' },
      ],
    },
    {
      backgroundRow: 'white',
      list: [
        { type: 'text-regular', value: 'Historical Data' },
        { type: 'icon-cross', value: '' },
        { type: 'text-regular', value: '3 Years of History' },
        { type: 'text-regular', value: 'Full History' },
        { type: 'text-regular', value: 'Full History' },
      ],
    },
    {
      backgroundRow: 'white',
      list: [
        { type: 'text-regular', value: 'Forward Data' },
        { type: 'icon-cross', value: '' },
        { type: 'text-regular', value: 'Full Forward Data' },
        { type: 'text-regular', value: 'Full Forward Data' },
        { type: 'text-regular', value: 'Full Forward Data' },
      ],
    },
    {
      backgroundRow: 'gray',
      list: [
        { type: 'text-semibold', value: 'Key Levels' },
        { type: 'empty', value: '' },
        { type: 'empty', value: '' },
        { type: 'empty', value: '' },
        { type: 'empty', value: '' },
      ],
    },
    {
      backgroundRow: 'white',
      list: [
        { type: 'text-regular', value: 'Average Win Rate' },
        { type: 'icon-cross', value: '' },
        { type: 'text-regular', value: 'Guaranteed 70% Win Rate' },
        { type: 'text-regular', value: '76% Win Rate' },
        { type: 'text-regular', value: '76% Win Rate' },
      ],
    },
    {
      backgroundRow: 'white',
      list: [
        { type: 'text-regular', value: 'Historical Data' },
        { type: 'icon-cross', value: '' },
        { type: 'text-regular', value: '3 Years of History' },
        { type: 'text-regular', value: 'Full History' },
        { type: 'text-regular', value: 'Full History' },
      ],
    },
    {
      backgroundRow: 'white',
      list: [
        { type: 'text-regular', value: 'Forward Data' },
        { type: 'icon-cross', value: '' },
        { type: 'text-regular', value: 'Full Forward Data' },
        { type: 'text-regular', value: 'Full Forward Data' },
        { type: 'text-regular', value: 'Full Forward Data' },
      ],
    },
    {
      backgroundRow: 'gray',
      list: [
        { type: 'text-semibold', value: 'Money Flows' },
        { type: 'empty', value: '' },
        { type: 'empty', value: '' },
        { type: 'empty', value: '' },
        { type: 'empty', value: '' },
      ],
    },
    {
      backgroundRow: 'white',
      list: [
        { type: 'text-regular', value: 'Data Access' },
        { type: 'text-regular', value: '1 Month Forward Data' },
        { type: 'text-regular', value: 'Unlimited Data' },
        { type: 'text-regular', value: 'Unlimited Data' },
        { type: 'text-regular', value: 'Unlimited Data' },
      ],
    },
    {
      backgroundRow: 'white',
      list: [
        { type: 'text-regular', value: 'Key Levels' },
        { type: 'text-regular', value: '4 Key Levels' },
        { type: 'text-regular', value: 'All Key Levels' },
        { type: 'text-regular', value: 'All Key Levels' },
        { type: 'text-regular', value: 'All Key Levels' },
      ],
    },
    {
      backgroundRow: 'white',
      list: [
        { type: 'text-regular', value: 'Net Flows' },
        { type: 'text-regular', value: '3 Days History' },
        { type: 'text-regular', value: 'Full History' },
        { type: 'text-regular', value: 'Full History' },
        { type: 'text-regular', value: 'Full History' },
      ],
    },
    {
      backgroundRow: 'white',
      list: [
        { type: 'text-regular', value: 'Open Interest' },
        { type: 'text-regular', value: '3 Days History' },
        { type: 'text-regular', value: 'Full History' },
        { type: 'text-regular', value: 'Full History' },
        { type: 'text-regular', value: 'Full History' },
      ],
    },
    {
      backgroundRow: 'white',
      list: [
        { type: 'text-regular', value: 'Popular Trades' },
        { type: 'text-regular', value: '3 Days History' },
        { type: 'text-regular', value: 'Full History' },
        { type: 'text-regular', value: 'Full History' },
        { type: 'text-regular', value: 'Full History' },
      ],
    },
    {
      backgroundRow: 'gray',
      list: [
        { type: 'text-semibold', value: 'Key Events' },
        { type: 'empty', value: '' },
        { type: 'empty', value: '' },
        { type: 'empty', value: '' },
        { type: 'empty', value: '' },
      ],
    },
    {
      backgroundRow: 'white',
      list: [
        { type: 'text-regular', value: 'Chart Data' },
        { type: 'text-regular', value: '2 Weeks' },
        { type: 'text-regular', value: 'Full Data' },
        { type: 'text-regular', value: 'Full Data' },
        { type: 'text-regular', value: 'Full Data' },
      ],
    },
    {
      backgroundRow: 'white',
      list: [
        { type: 'text-regular', value: 'News Expectations' },
        { type: 'text-regular', value: '5 Events' },
        { type: 'text-regular', value: 'Full Data' },
        { type: 'text-regular', value: 'Full Data' },
        { type: 'text-regular', value: 'Full Data' },
      ],
    },
    {
      backgroundRow: 'gray',
      list: [
        { type: 'text-semibold', value: 'Option Flows' },
        { type: 'empty', value: '' },
        { type: 'empty', value: '' },
        { type: 'empty', value: '' },
        { type: 'empty', value: '' },
      ],
    },
    {
      backgroundRow: 'white',
      list: [
        { type: 'text-regular', value: 'Exposure' },
        { type: 'text-regular', value: '2 Weeks' },
        { type: 'text-regular', value: 'Full History' },
        { type: 'text-regular', value: 'Full History' },
        { type: 'text-regular', value: 'Full History' },
      ],
    },
    {
      backgroundRow: 'white',
      list: [
        { type: 'text-regular', value: 'Net Flows' },
        { type: 'text-regular', value: '2 Weeks' },
        { type: 'text-regular', value: 'Full History' },
        { type: 'text-regular', value: 'Full History' },
        { type: 'text-regular', value: 'Full History' },
      ],
    },
    {
      backgroundRow: 'white',
      list: [
        { type: 'text-regular', value: 'Expanded View' },
        { type: 'icon-cross', value: '' },
        { type: 'icon-check', value: '' },
        { type: 'icon-check', value: '' },
        { type: 'icon-check', value: '' },
      ],
    },
    {
      backgroundRow: 'white',
      list: [
        { type: 'text-regular', value: 'Other Indicators' },
        { type: 'icon-cross', value: '' },
        { type: 'icon-check', value: '' },
        { type: 'icon-check', value: '' },
        { type: 'icon-check', value: '' },
      ],
    },
    {
      backgroundRow: 'gray',
      list: [
        { type: 'text-semibold', value: 'Fundamentals & Technicals' },
        { type: 'empty', value: '' },
        { type: 'empty', value: '' },
        { type: 'empty', value: '' },
        { type: 'empty', value: '' },
      ],
    },
    {
      backgroundRow: 'white',
      list: [
        {
          type: 'text-regular',
          value: 'Ration & Indicators',
          classes: 'min-h-[36px]',
        },
        { type: 'text-regular', value: 'Limited' },
        {
          type: 'text-regular',
          value: 'Full Fundamental Data <br /> (Standard&Poors)',
        },
        {
          type: 'text-regular',
          value: 'Full Fundamental Data <br /> (Standard&Poors)',
        },
        {
          type: 'text-regular',
          value: 'Full Fundamental Data <br /> (Standard&Poors)',
        },
      ],
    },
    {
      backgroundRow: 'gray',
      list: [
        { type: 'text-semibold', value: 'Win Rate Report' },
        { type: 'empty', value: '' },
        { type: 'empty', value: '' },
        { type: 'empty', value: '' },
        { type: 'empty', value: '' },
      ],
    },
    {
      backgroundRow: 'white',
      list: [
        { type: 'text-regular', value: 'Market Win Rate Report' },
        { type: 'icon-check', value: '' },
        { type: 'icon-check', value: '' },
        { type: 'icon-check', value: '' },
        { type: 'icon-check', value: '' },
      ],
    },
    {
      backgroundRow: 'white',
      list: [
        { type: 'text-regular', value: 'Portfolio Win Rate Report' },
        { type: 'icon-check', value: '' },
        { type: 'icon-check', value: '' },
        { type: 'icon-check', value: '' },
        { type: 'icon-check', value: '' },
      ],
    },
    {
      backgroundRow: 'gray',
      list: [
        { type: 'text-semibold', value: 'Portfolio & Alerts' },
        { type: 'empty', value: '' },
        { type: 'empty', value: '' },
        { type: 'empty', value: '' },
        { type: 'empty', value: '' },
      ],
    },
    {
      backgroundRow: 'white',
      list: [
        { type: 'text-regular', value: 'Stocks in Portfolio' },
        { type: 'text-regular', value: 'Unlimited' },
        { type: 'text-regular', value: 'Unlimited' },
        { type: 'text-regular', value: 'Unlimited' },
        { type: 'text-regular', value: 'Unlimited' },
      ],
    },
    {
      backgroundRow: 'white',
      list: [
        { type: 'text-regular', value: 'Alerts Customization' },
        { type: 'icon-cross', value: '' },
        { type: 'icon-check', value: '' },
        { type: 'icon-check', value: '' },
        { type: 'icon-check', value: '' },
      ],
    },
    {
      backgroundRow: 'white',
      list: [
        { type: 'text-regular', value: 'Alerts Frequency' },
        { type: 'text-regular', value: 'Weekly Only' },
        { type: 'text-regular', value: 'Instant / Daily / Weekly' },
        { type: 'text-regular', value: 'Instant / Daily / Weekly' },
        { type: 'text-regular', value: 'Instant / Daily / Weekly' },
      ],
    },
    {
      backgroundRow: 'white',
      list: [
        { type: 'text-regular', value: 'Channels' },
        { type: 'text-regular', value: 'E-mail Only' },
        { type: 'text-regular', value: 'E-mail Only' },
        { type: 'text-regular', value: 'E-mail Only' },
        { type: 'text-regular', value: 'E-mail Only' },
      ],
    },
  ];

  userType: UserTypeModel = UserTypeModel.Unregistered;

  // prettier-ignore
  expertList: any[] = [
    {
      id: 1,
      videoUrl: 'https://storage.googleapis.com/visual-sectors-landing/someone.mp4',
      posterUrl: './assets/images/landing/landing-experts-video/someone.jpg',
      link: ''
    },
    {
      id: 2,
      videoUrl: 'https://storage.googleapis.com/visual-sectors-landing/David_Chapman.mp4',
      posterUrl: './assets/images/landing/landing-experts-video/David_Chapman.jpg',
      link: 'https://www.linkedin.com/in/david-chapman-56bb6635/'
    },
    {
      id: 3,
      videoUrl: 'https://storage.googleapis.com/visual-sectors-landing/Lenny_Dendunnen.mp4',
      posterUrl: './assets/images/landing/landing-experts-video/Lenny_Dendunnen.jpg',
      link: 'https://www.linkedin.com/in/lenny-dendunnen-bba6a35/'
    },
    {
      id: 4,
      videoUrl: 'https://storage.googleapis.com/visual-sectors-landing/James_Kisner.mp4',
      posterUrl: './assets/images/landing/landing-experts-video/James_Kisner.jpg',
      link: 'https://www.linkedin.com/in/james-kisner/'
    },
    {
      id: 5,
      videoUrl: 'https://storage.googleapis.com/visual-sectors-landing/Michele_Filippig.mp4',
      posterUrl: './assets/images/landing/landing-experts-video/Michele_Filippig.jpg',
      link: 'https://www.linkedin.com/in/michele-filippig-b0762510b/'
    },
  ];

  videoIsMuted: boolean = true;
  videoIsActiveId: number = 0;
  videoFirstPlayback: boolean = false;

  hashUrl: string | null;

  isDisabledBtnGoToPlan: boolean = false;

  daysInMonth: number = 0;

  paymentUrl: string | null = null;

  rowOffsetLeft: string = '0px';
  isShowTableFixedRow: boolean = false;

  constructor(
    @Inject(DOCUMENT)
    private document: Document,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private analyticService: AnalyticService,
    private authFormService: AuthFormService,
    private http: MainHttpClient,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private subscriptionDialogService: SubscriptionDialogService,
    private subscriptionChangeService: SubscriptionChangeService,
    private cookieService: CookieService,
  ) {
    // prettier-ignore
    this.settingTariffForm = this.formBuilder.group({
      period: this.formBuilder.control('monthly', [Validators.required]),
    });

    // prettier-ignore
    this.typeProTariffForm = this.formBuilder.group({
      type: this.formBuilder.control('licensed', [Validators.required]),
    });

    // prettier-ignore
    this.typePremiumTariffForm = this.formBuilder.group({
      type: this.formBuilder.control('metered', [Validators.required]),
    });

    // prettier-ignore
    this.typeUltimateTariffForm = this.formBuilder.group({
      type: this.formBuilder.control('metered', [Validators.required]),
    });
  }

  ngOnInit(): void {
    this.authService.getUserType().then((userTypeModel: UserTypeModel) => {
      this.userType = userTypeModel;

      if (this.userType > 0) {
        this.getMySubscription();
      }
    });

    this.subscriptionChangeService$ =
      this.subscriptionChangeService.currentChangeSubject.subscribe(() => {
        if (this.userType > 0) {
          this.getMySubscription();
        }
      });

    this.getTariffList();

    this.windowAction$ = fromEvent(window, 'scroll').subscribe({
      next: () => {
        this.setShowTableFixedRow();
      },
      error: (error: any) => console.error(error),
    });

    this.activatedRoute$ = this.activatedRoute.fragment.subscribe(
      (hash: string | null) => {
        this.hashUrl = hash ? hash : null;

        this.hashUrl && this.goToActiveHash();
      },
    );

    // prettier=ignore
    this.settingTariffForm$ = this.settingTariffForm
      .get('period')
      ?.valueChanges.subscribe((value: string) => {
        if (value !== 'monthly') {
          [
            this.typeProTariffForm,
            this.typePremiumTariffForm,
            this.typeUltimateTariffForm,
          ].forEach((form: FormGroup) => {
            form.get('type')?.setValue('licensed');
          });
        }
      });

    // prettier=ignore
    this.typeProTariffForm$ = this.typeProTariffForm
      .get('type')
      ?.valueChanges.subscribe((value: string) => {
        this.tariffViewState.pro_.typeView = value;
      });

    // prettier=ignore
    this.typePremiumTariffForm$ = this.typePremiumTariffForm
      .get('type')
      ?.valueChanges.subscribe((value: string) => {
        this.tariffViewState.premium_.typeView = value;
      });

    // prettier=ignore
    this.typeUltimateTariffForm$ = this.typeUltimateTariffForm
      .get('type')
      ?.valueChanges.subscribe((value: string) => {
        this.tariffViewState.ultimate_.typeView = value;
      });
  }

  // prettier-ignore
  ngAfterViewInit(): void {
    const elementNameList: string[] = ['flat-fee', 'pay-if-you-win'];

    const showEventList: string[] = ['mouseenter', 'focus'];
    const hideEventList: string[] = ['mouseleave', 'blur'];

    elementNameList.forEach((name: string) => {
      const target: HTMLElement | null = this.document.querySelector(`#${name}-target`);
      const tooltip: HTMLElement | null = this.document.querySelector(`#${name}-tooltip`);

      if (target && tooltip) {
        const popperInstance: Instance = createPopper(target, tooltip, {
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 8],
              },
            },
          ],
          placement: 'bottom-end',
        });

        /** set events */
        showEventList.forEach((event: string) => {
          target.addEventListener(event, () => {
            tooltip.classList.remove('hidden');

            popperInstance.update().then((r) => console.debug('update popper'));
          });
        });

        hideEventList.forEach((event: string) => {
          target.addEventListener(event, () => tooltip.classList.add('hidden'));
        });
      }
    });

    /** скролл к нужному диву */
    setTimeout(() => { this.hashUrl && this.goToActiveHash(); }, 1000);

    if (this.tableFirstRow) {
      this.rowOffsetLeft = this.tableFirstRow.nativeElement.offsetLeft + 16 + 'px';

      let count: number = 5;
      // @ts-ignore
      let interval: NodeJS.Timeout = setInterval(() => {
        count--;
        count === 0 && clearInterval(interval);

        this.setShowTableFixedRow();
      }, 500);
    }
  }

  // prettier-ignore
  private setShowTableFixedRow(): void {
    if(this.table) {
      const rect = this.table.nativeElement.getBoundingClientRect();

      this.isShowTableFixedRow = rect.top <= 98 && rect.bottom >= 98;

    }
  }

  ngOnDestroy(): void {
    [
      this.subscriptionChangeService$,
      this.activatedRoute$,
      this.settingTariffForm$,
      this.typeProTariffForm$,
      this.typePremiumTariffForm$,
      this.typeUltimateTariffForm$,
      this.windowAction$,
    ].forEach(($: Subscription | undefined) => $?.unsubscribe());

    /** если покидаем страницу, то чистим куку с урл страницы на которой были до оплаты */
    this.cookieService.delete('page-before-payment', '/');
  }

  // prettier-ignore
  goToActiveHash(): void {
    const htmlElement: HTMLElement | null = this.document.querySelector('#' + this.hashUrl);
    const headerOffset: number = 98;

    if (htmlElement) {
      const elementPosition: number = htmlElement.getBoundingClientRect().top;
      const offsetPosition: number = elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({ top: offsetPosition, behavior: 'smooth' });
    }


    setTimeout(() => {
      this.router.navigate([], { fragment: undefined }).then(() => console.debug('Router changed'));
    }, 3000);
  }

  // prettier-ignore
  getMySubscription(): void {
    this.mySubscriptionsNew = undefined;

    from(this.http.get<{ data: SubscriptionsNew | [] }>('/v2/subscription/info'))
      .pipe(first())
      .subscribe((data: { data: SubscriptionsNew | [] }) => {
        /** если пустой массив, то нет подписки */
        if (Array.isArray(data.data)) {
          this.mySubscriptionsNew = null;
        } else {
          this.mySubscriptionsNew = data.data;

          /** Если нет триала или триал закончился и тип тарифа metered, то забираем доп. инфу для отмены */
          if (
            (!this.mySubscriptionsNew.trial_ends_at || this.mySubscriptionsNew.trial_ends_at && this.mySubscriptionsNew.is_trial_expired)
            && this.mySubscriptionsNew.price.usage_type === 'metered'
          ) {
            this.getMeteredUsageInfo();
          }

          !this.paymentUrl && this.getPaymentUrl();
        }
      });
  }

  getPaymentUrl(): void {
    from(this.http.get<{ data: { paymentUrl: string } }>('/v2/pay'))
      .pipe(first())
      .subscribe((data: { data: { paymentUrl: string } }) => {
        this.paymentUrl = data.data.paymentUrl;
      });
  }

  // prettier-ignore
  getMeteredUsageInfo(): void {
    from(this.http.get<{ data: MeteredUsageInfo }>('/v2/subscription/metered/usage-info'))
      .pipe(first())
      .subscribe({
        next: (data: { data: MeteredUsageInfo }) => {
          this.meteredUsageInfo = data.data;
        },
      });
  }

  getTariffList(): void {
    from(this.http.get<Tariff[]>('/v2/tariff/list'))
      .pipe(first())
      .subscribe({
        next: (tariffList: Tariff[]) => {
          this.tariffList = tariffList;

          this.setTariffViewState();
          this.getDaysInMonth();
        },
      });
  }

  // prettier-ignore
  setTariffViewState(): void {
    if (this.tariffList) {
      const priceList: TariffPrice[] = [];

      this.tariffList.forEach((tariff: Tariff) => {
        priceList.push(...tariff.prices);
      });

      this.tariffNameList.forEach((tariffName: string) => {
        priceList.forEach((priceTariff: TariffPrice) => {
          if (priceTariff.lookup_key.includes(tariffName)) {
            if (priceTariff.usage_type === 'licensed') {
              if (priceTariff.interval === 'month') {
                this.tariffViewState[tariffName].licensedMonth = priceTariff;
              }

              if (priceTariff.interval !== 'month') {
                this.tariffViewState[tariffName].licensedYear = priceTariff;
              }
            }

            if (priceTariff.usage_type === 'metered') {
              this.tariffViewState[tariffName].metered = priceTariff;
            }
          }
        });
      });
    }
  }

  // prettier-ignore
  getDaysInMonth(): void {
    const currentDate: Date = new Date();

    currentDate.setMonth(currentDate.getMonth() + 1, 0);

    this.daysInMonth = currentDate.getDate();
  }

  // prettier-ignore
  goToPaymentUrl(price_id: string, trial: boolean): void {
    this.isDisabledBtnGoToPlan = true;

    from(this.http.post<{ needToPay: true; paymentUrl: string }>('/v2/subscribe', { price_id, trial }))
      .pipe(first())
      .subscribe({
        next: (data: { paymentUrl: string }) => {
          this.isDisabledBtnGoToPlan = false;

          window.location.href = data.paymentUrl;
        },
        error: () => {
          this.isDisabledBtnGoToPlan = false;
        },
      });
  }

  // prettier-ignore
  onGetPlan(tariffPrice: TariffPrice): void {
    /** Не авторизован */
    if (this.userType === 0) {
      this.analyticService.send(
        'click_sign_up',
        new Map([['name', 'страница тарифов']]),
      );
      this.authFormService.openSignUp();
    }

    /** авторизован и без активного тарифа */
    if (this.userType === 1) {
      this.goToPaymentUrl(tariffPrice.id, true);
      return;
    }

    /** авторизован и с просроченным триалом */
    if (this.userType === 7) {
      this.goToPaymentUrl(tariffPrice.id, false);
      return;
    }

    /** авторизован, есть активный тариф или триалл */
    if (this.userType > 1) {
      /** если тариф подключен, то в мои подписки, иначе на сценарий смены тарифа */
      if (this.mySubscriptionsNew?.price?.id === tariffPrice.id) {
        // prettier-ignore
        this.router.navigate(['/profile/subscriptions']).then(() => console.debug('Route changed'));
      } else {
        if (this.mySubscriptionsNew) {
          let changeTo: SubscriptionChangeTo | null;

          let tariffNameFrom: string = '';
          switch (true) {
            case this.mySubscriptionsNew.price.lookup_key.includes('pro_'):
              tariffNameFrom = 'Pro';
              break;
            case this.mySubscriptionsNew.price.lookup_key.includes('premium_'):
              tariffNameFrom = 'Premium';
              break;
            case this.mySubscriptionsNew.price.lookup_key.includes('ultimate_'):
              tariffNameFrom = 'Ultimate';
              break;
            default:
              tariffNameFrom = 'Pro';
              break;
          }

          let tariffNameTo: string = '';
          switch (true) {
            case tariffPrice.lookup_key.includes('pro_'):
              tariffNameTo = 'Pro';
              break;
            case tariffPrice.lookup_key.includes('premium_'):
              tariffNameTo = 'Premium';
              break;
            case tariffPrice.lookup_key.includes('ultimate_'):
              tariffNameTo = 'Ultimate';
              break;
            default:
              tariffNameTo = 'Pro';
              break;
          }

          /** Если меняем на Flat Fee */
          if (tariffPrice.usage_type === 'licensed') {
            if (this.mySubscriptionsNew.price.usage_type === 'licensed') {
              if (tariffPrice.interval === 'month') {
                changeTo = 'Year->Month';
              } else {
                changeTo = 'Month->Year';
              }
            } else {
              if (tariffPrice.interval === 'month') {
                changeTo = 'PayIfYouWin->Month';
              } else {
                changeTo = 'PayIfYouWin->Year';
              }
            }
          } else {
            /** Если меняем на PayIfYouWin */
            if (this.mySubscriptionsNew.price.interval === 'month') {
              changeTo = 'Month->PayIfYouWin';
            } else {
              changeTo = 'Year->PayIfYouWin';
            }
          }

          if (changeTo) {
            this.subscriptionDialogService.openChangeSubscription({
              mySubscriptions: this.mySubscriptionsNew,
              meteredUsageInfo: this.meteredUsageInfo,
              tariffList: this.tariffList || [],
              changeTo,
              tariffNameFrom,
              tariffNameTo,
            });
          }
        }
      }
    }
  }

  getVideoElement(expertId: number): HTMLVideoElement | null {
    return this.document.querySelector('#expert-video-' + expertId);
  }

  onMute(event: Event, isMute: boolean): void {
    event.preventDefault();
    event.stopPropagation();

    this.videoIsMuted = isMute;

    this.expertList.forEach((expert: any): void => {
      // prettier-ignore
      const videoElement: HTMLVideoElement | null = this.getVideoElement(expert.id);

      if (videoElement) {
        videoElement.muted = this.videoIsMuted;
      }
    });
  }

  onVideoPause(): void {
    this.videoIsActiveId = 0;

    this.expertList.forEach((expert: any): void => {
      // prettier-ignore
      const videoElement: HTMLVideoElement | null = this.getVideoElement(expert.id);

      videoElement?.pause();
    });
  }

  onVideoPlay(expertId: number, onSound: boolean = false): void {
    this.videoIsActiveId = expertId;

    // prettier-ignore
    this.expertList.forEach((expert: any): void => {
      const videoElement: HTMLVideoElement | null = this.getVideoElement(expert.id);

      // вкл звук на мобилке
      if (onSound) {
        this.videoFirstPlayback = true;
        this.videoIsMuted = false;
      }

      if (videoElement && expertId === expert.id) {
        videoElement.muted = this.videoIsMuted;

        videoElement.play().then(() => console.debug('play video ' + expert.id));

        return;
      }

      if (videoElement) {
        videoElement.muted = this.videoIsMuted;
        videoElement.pause();
      }
    });
  }

  onScrollTable(event: Event): void {
    // @ts-ignore
    // prettier-ignore
    this.rowOffsetLeft = event.target.offsetLeft + 16 - event.target.scrollLeft + 'px';
  }
}
