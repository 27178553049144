<div class="relative flex w-full h-[52px]">
  <div
    class="absolute top-[36px] left-0 w-full h-2 bg-active-neutral-2 rounded-full"
  ></div>
  <div
    class="absolute top-[36px] left-0 h-2 bg-active-neutral-1 rounded-full"
    [ngClass]="disabled ? 'bg-chart-neutral-2' : 'bg-active-neutral-1'"
    [ngStyle]="{
      transform: 'translateX(' + (translateXLeft + 18) + 'px)',
      width: translateXRight - translateXLeft + 'px'
    }"
  ></div>
  <div
    class="absolute top-0 left-0 flex flex-col items-center justify-center w-[36px] !gap-1.5"
    [ngStyle]="{ transform: 'translateX(' + (translateXLeft - 4) + 'px)' }"
    #pickerLeft
  >
    <form [formGroup]="numberForm" (ngSubmit)="onSubmit('left')">
      <fieldset [disabled]="disabled">
        <!-- prettier-ignore -->
        <input
          appOnlyNumber
          type="number"
          class="kw-number-control outline-none h-6 rounded !p-0.5 text-interface-control-3-regular badge-shadow bg-[#fff] text-center focus:bg-default-secondary-1"
          [ngClass]="disabled ? 'cursor-not-allowed text-low' : 'text-default-neutral-1'"
          [ngStyle]="{width: numberForm.get('valueLeft')?.value.replaceAll(',', '').length * 10 + 2 + 'px' }"
          formControlName="valueLeft"
          (blur)="onSubmit('left')"
        />
      </fieldset>
    </form>
    <div
      class="w-7 h-5 rounded-md badge-shadow duration-300"
      [ngClass]="
        disabled
          ? 'cursor-not-allowed bg-chart-neutral-2'
          : 'cursor-pointer bg-active-neutral-1 hover:bg-default-neutral-1'
      "
    ></div>
  </div>
  <div
    class="absolute top-0 left-0 flex flex-col items-center justify-center w-[36px] !gap-1.5"
    [ngStyle]="{ transform: 'translateX(' + (translateXRight - 4) + 'px)' }"
    #pickerRight
  >
    <form [formGroup]="numberForm" (ngSubmit)="onSubmit('right')">
      <fieldset [disabled]="disabled">
        <!-- prettier-ignore -->
        <input
          appOnlyNumber
          type="number"
          class="kw-number-control outline-none h-6 rounded !p-0.5 text-interface-control-3-regular badge-shadow bg-[#fff] text-center focus:bg-default-secondary-1"
          [ngClass]="disabled ? 'cursor-not-allowed text-low' : 'text-default-neutral-1'"
          [ngStyle]="{width: numberForm.get('valueRight')?.value.replaceAll(',', '').length * 10 + 2 + 'px' }"
          formControlName="valueRight"
          (blur)="onSubmit('right')"
        />
      </fieldset>
    </form>
    <div
      class="w-7 h-5 rounded-md badge-shadow duration-300"
      [ngClass]="
        disabled
          ? 'cursor-not-allowed bg-chart-neutral-2'
          : 'cursor-pointer bg-active-neutral-1 hover:bg-default-neutral-1'
      "
    ></div>
  </div>
</div>
<div class="flex items-center justify-between w-full">
  <span class="text-chart-caption -1-regular text-default-neutral-3">
    {{ labelLeft }}
  </span>
  <span class="text-chart-caption -1-regular text-default-neutral-3">
    {{ labelRight }}
  </span>
</div>
