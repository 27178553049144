import { Injectable } from "@angular/core";
import { RiskRewardHeatmapPageResponse } from "src/app/interfaces/api/widgets/risk-reward-heatmap/risk-reward-heatmap-page";
import {
  RiskRewardHeatmapSectorPageRequest,
  RiskRewardHeatmapSectorPageResponse
} from "src/app/interfaces/api/widgets/risk-reward-heatmap/risk-reward-heatmap-sector-page";
import { WidgetsHttpClient } from "src/app/services/widgets-http-client.service";

@Injectable()
export class RiskRewardHeatmapDataService {
  constructor(private http: WidgetsHttpClient) {

  }

  getPage(): Promise<RiskRewardHeatmapPageResponse> {
    return this.http.post("/risk-reward-heatmap/page");
  }

  getSectorPage(body: RiskRewardHeatmapSectorPageRequest): Promise<RiskRewardHeatmapSectorPageResponse> {
    return this.http.post("/risk-reward-heatmap/sector-page", body);
  }
}
