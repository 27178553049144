import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SubscriptionsNew } from '../../models/subscriptions-new.model';
import { SubscriptionChangeService } from '../../services/subscription-change.service';

@Component({
  selector: 'app-subscription-cancel-dialog',
  templateUrl: './subscription-renew-dialog.component.html',
})
export class SubscriptionRenewDialogComponent implements OnInit {
  nextPaymentDate: Date | undefined = undefined;

  leftDaysOfTrial: number = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { mySubscriptions: SubscriptionsNew },
    private dialog: MatDialogRef<SubscriptionRenewDialogComponent>,
    private subscriptionChangeService: SubscriptionChangeService,
  ) {}

  // prettier-ignore
  ngOnInit(): void {
    this.nextPaymentDate = this.data.mySubscriptions.ends_at ? new Date(this.data.mySubscriptions.ends_at) : undefined;

    if (this.nextPaymentDate) {
      this.leftDaysOfTrial = Math.ceil((this.nextPaymentDate.getTime() - new Date().getTime()) / 1000 / 86400);
    }
  }

  close(): void {
    this.dialog.close();
  }

  onRenew(): void {
    this.close();

    this.subscriptionChangeService.currentChangeSubject.next('renew');
  }
}
