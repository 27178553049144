import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ToastService } from 'src/app/modules/shared/services/toast.service';
import { AnalyticService } from 'src/app/services/analytic.service';

declare var FB: any;

@Component({
  selector: 'app-feedback-dialog',
  templateUrl: './feedback-dialog.component.html',
  styleUrls: ['./feedback-dialog.component.scss']
})
export class FeedbackDialogComponent {

  constructor(private analyticService: AnalyticService,
              private dialogRef: MatDialogRef<FeedbackDialogComponent>,
              private toastService: ToastService) { }

  clickMark(value: number) {
    this.analyticService.send("survey_answer", new Map([
      ["question", "How much do you benefit from the data on this page?"],
      ["answer", `${value}`]
    ]));
    this.toastService.show("Thank you for your feedback");
  }

  close() {
    this.dialogRef.close();
  }

  share() {
    FB.ui({
      method: 'share',
      href: 'https://developers.facebook.com/docs/'
    }, function(response: any){});
  }
}
