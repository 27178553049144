import { AppEnvironment } from './interfaces/environment';

export const environment: AppEnvironment = {
  production: false,
  apiWidgetsUrl: 'https://kw-vs.ru/widgets/api',
  apiMainUrl: 'https://kw-vs.ru/api',
  apiAdminUrl: 'https://kw-vs.ru/admin/api/v1',
  facebookAppId: '1223629191596356',
  googleClientId:
    '111343545927-su55a88rn1hvab6adc3u5jli110a8ctk.apps.googleusercontent.com',
};
