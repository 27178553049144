import { Injectable } from '@angular/core';
import { SnackbarService } from '../../../services/snackbar.service';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(private snackbarService: SnackbarService) {}

  show(text: string): boolean {
    this.snackbarService.neutral(text, 4000);

    return true;
  }
}
