import { createReducer, on } from '@ngrx/store';
import { setPortfolioTickers } from './vs.actions';
import { PortfolioTicker } from '../interfaces/api/main/portfolio/domain/portfolio-ticker';

export interface VSStore {
    tickers: Array<PortfolioTicker>;
};

export const initialState: VSStore = {
    tickers: []
};

export const vsReducer = createReducer(
  initialState,
  on(setPortfolioTickers, (state, { tickers }) => ({
    ...state,
    tickers: tickers
  }))
);