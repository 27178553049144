import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from '../modules/auth/services/auth.service';
import {
  COUNT_SECTORS_IN_FAVORITE,
  COUNT_TICKERS_IN_PORTFOLIO,
  SECTORS_IN_FAVORITE,
  TICKERS_IN_PORTFOLIO,
} from '../constants/analytic-service.constants';

declare var dataLayer: any;

@Injectable({
  providedIn: 'root',
})
export class AnalyticService {
  constructor(
    private cookieService: CookieService,
    private authService: AuthService,
  ) {}

  send(eventName: string, params?: Map<string, string>) {
    this.sendToGoogleTagManager(eventName, params);
    this.sendToYandexMetrica(eventName, params);
  }

  sendFullUserData() {
    const payload = this.authService.getPayload();
    if (payload !== null) {
      const tickers = localStorage.getItem(TICKERS_IN_PORTFOLIO);
      const tickersCount = localStorage.getItem(COUNT_TICKERS_IN_PORTFOLIO);
      const sectors = localStorage.getItem(SECTORS_IN_FAVORITE);
      const sectorsCount = localStorage.getItem(COUNT_SECTORS_IN_FAVORITE);

      const parameters = new Map([
        ['user_id', payload.userId],
        ['user_email', payload.userEmail],
      ]);
      if (tickers !== null) {
        parameters.set('tickers_in_portfolio', tickers);
      }
      if (tickersCount !== null) {
        parameters.set('count_tickers_in_portfolio', tickersCount);
      }
      if (sectors !== null) {
        parameters.set('sectors_in_favorite', sectors);
      }
      if (sectorsCount !== null) {
        parameters.set('count_sectors_in_favorite', sectorsCount);
      }

      this.send('user_data', parameters);
    }
  }

  public googleClientId(): string | undefined {
    let id: string | undefined;
    const ga = this.cookieService.get('_ga') || undefined;
    if (ga) {
      const parsedGa = ga.split('.');
      id = `${parsedGa[2]}.${parsedGa[3]}`;
    }

    return id;
  }

  public async yandexClientId(): Promise<string | undefined> {
    return new Promise((resolve) => {
      setTimeout(() => {
        const yandexClient = this.getYandexMetricClient();
        if (!yandexClient) {
          resolve(undefined);
          return;
        }

        yandexClient(89954312, 'getClientID', function (clientID: string) {
          resolve(clientID);
        });

        setTimeout(() => {
          // Something wrong
          resolve(undefined);
        }, 1000);
      }, 1000);
    });
  }

  private sendToGoogleTagManager(
    eventName: string,
    params?: Map<string, string>,
  ) {
    const layer: any = {
      event: eventName,
    };
    if (params != undefined) {
      for (let entery of params.entries()) {
        layer[entery[0]] = entery[1];
      }
    }

    // @ts-ignore
    window.dataLayer && dataLayer.push(layer);
  }

  private sendToYandexMetrica(eventName: string, params?: Map<string, string>) {
    const yandexClient = this.getYandexMetricClient();
    if (!yandexClient) {
      console.error('Yandex Metric client is not defined');
      return;
    }
    if (params !== undefined) {
      const ymParameters: any = {};
      if (params != undefined) {
        for (let entery of params.entries()) {
          ymParameters[entery[0]] = entery[1];
        }
      }
      yandexClient(89954312, eventName, ymParameters);
    } else {
      yandexClient(89954312, eventName);
    }
  }

  private getYandexMetricClient(): Function | null {
    let client: Function | null = null;
    if ((window as any).ym !== undefined) {
      client = (window as any).ym as Function;
    }

    return client;
  }
}
