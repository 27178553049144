import { HttpClient } from "@angular/common/http";
import { lastValueFrom, share } from "rxjs";

export class BaseHttpClient {
  constructor(private http: HttpClient, private baseUrl: string) {

  }

  public async post<T>(url: string, body: any = {}): Promise<T> {
    const response = this.http.post<T>(`${this.baseUrl}${url}`, body).pipe(share());
    return lastValueFrom(response);
  }

  public async get<T>(url: string): Promise<T> {
    const response = this.http.get<T>(`${this.baseUrl}${url}`).pipe(share());
    return lastValueFrom(response);
  }
}
