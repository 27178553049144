<div class="mobile-sidebar-container" *ngIf="section">
  <div [class.selected]="section.selected" class="sidebar-top">
    <svg [class.disabled]="disabledLeft" (click)="left()" xmlns="http://www.w3.org/2000/svg" class="sidebar-left-control" viewBox="0 0 384 512"><path fill="currentColor" d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 278.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"/></svg>
    <div class="sidebar-section-name">
      <a class="sidebar-section-name-text" *ngIf="section.url.length !== 0" [href]="section.url">{{ section.title }}</a>
      <div class="sidebar-section-name-text" *ngIf="section.url.length === 0">{{ section.title }}</div>
    </div>
    <svg [class.disabled]="disabledRight" (click)="right()" xmlns="http://www.w3.org/2000/svg" class="sidebar-right-control" viewBox="0 0 384 512"><path fill="currentColor" d="M342.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L274.7 256 105.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"/></svg>
  </div>
  <div class="sidebar-bottom">
    <a class="sidebar-section-field" [class.selected]="field.selected" [href]="field.url" *ngFor="let field of section.fields">{{ field.name }}</a>
  </div>
</div>
