import { ChangeDetectorRef, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { LoaderService } from '../../services/loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {

  @Input() name: string | undefined;

  @Output() stateChanged = new EventEmitter<boolean>();

  @HostBinding('class.active') get active() { return this.isLoading; }

  public isLoading: boolean = false;

  constructor(private loaderService: LoaderService) {
    this.loaderService.subscription().subscribe(x => {
      if (x.name === this.name) {
        this.isLoading = x.value;
        this.stateChanged.emit(this.isLoading);
      }
    });
  }

}
