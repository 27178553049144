<div (click)="googleSignIn()" class="auth-form-social-button">
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.1713 8.36794H17.5V8.33335H10V11.6667H14.7096C14.0225 13.6071 12.1763 15 10 15C7.23877 15 5.00002 12.7613 5.00002 10C5.00002 7.23877 7.23877 5.00002 10 5.00002C11.2746 5.00002 12.4342 5.48085 13.3171 6.26627L15.6742 3.90919C14.1859 2.5221 12.195 1.66669 10 1.66669C5.39794 1.66669 1.66669 5.39794 1.66669 10C1.66669 14.6021 5.39794 18.3334 10 18.3334C14.6021 18.3334 18.3334 14.6021 18.3334 10C18.3334 9.44127 18.2759 8.89585 18.1713 8.36794Z"
        fill="#FFC107"
      />
      <path
        d="M2.6275 6.12127L5.36542 8.12919C6.10625 6.29502 7.90042 5.00002 10 5.00002C11.2746 5.00002 12.4342 5.48085 13.3171 6.26627L15.6742 3.90919C14.1858 2.5221 12.195 1.66669 10 1.66669C6.79917 1.66669 4.02334 3.47377 2.6275 6.12127Z"
        fill="#FF3D00"
      />
      <path
        d="M9.99999 18.3333C12.1525 18.3333 14.1083 17.5096 15.5871 16.17L13.0079 13.9875C12.1431 14.6451 11.0864 15.0008 9.99999 15C7.83249 15 5.99207 13.6179 5.29874 11.6891L2.58124 13.7829C3.9604 16.4816 6.76124 18.3333 9.99999 18.3333Z"
        fill="#4CAF50"
      />
      <path
        d="M18.1713 8.3679H17.5V8.33331H10V11.6666H14.7096C14.3809 12.5902 13.7889 13.3971 13.0067 13.9879L13.0079 13.9871L15.5871 16.1696C15.4046 16.3354 18.3333 14.1666 18.3333 9.99998C18.3333 9.44123 18.2758 8.89581 18.1713 8.3679Z"
        fill="#1976D2"
      />
    </svg>
    <div>Continue with Google</div>
  </div>
  
  <div (click)="facebookSignIn()" class="auth-form-social-button">
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 0C6.61305 0 4.32387 0.948212 2.63604 2.63604C0.948212 4.32387 0 6.61305 0 9C0 11.387 0.948212 13.6761 2.63604 15.364C4.32387 17.0518 6.61305 18 9 18C11.387 18 13.6761 17.0518 15.364 15.364C17.0518 13.6761 18 11.387 18 9C18 6.61305 17.0518 4.32387 15.364 2.63604C13.6761 0.948212 11.387 0 9 0Z"
        fill="#039BE5"
      />
      <path
        d="M10.2183 11.3855H12.5474L12.9131 9.0194H10.2178V7.72624C10.2178 6.74335 10.539 5.87177 11.4584 5.87177H12.9358V3.80698C12.6763 3.77193 12.1273 3.69519 11.0899 3.69519C8.92374 3.69519 7.65379 4.83914 7.65379 7.44535V9.0194H5.427V11.3855H7.65379V17.8887C8.09479 17.955 8.54148 18 9 18C9.41448 18 9.819 17.9621 10.2183 17.9081V11.3855Z"
        fill="white"
      />
    </svg>
    <div>Sign up with Facebook</div>
  </div>