import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from './modules/auth/services/auth.service';
import { UtmService } from '@shared/modules/utm/services/utm.service';
import { EventsService, EventType } from './services/events.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TurnDeviceService } from './services/turn-device.service';
import { debounceTime, filter, Subscription } from 'rxjs';
import { LinkMinkService } from './modules/affiliate/services/LinkMink.service';
import { TuneAffiliateService } from './modules/affiliate/services/TuneAffiliateService';
import { ExternalSignIn } from './modules/auth/services/ExternalSignIn';
import { AuthFormService } from '@app/modules/auth/services/auth-form.service';
import { UserTypeModel } from '@app/modules/shared/models/user-type.model';
import { ScaleService } from '@app/modules/shared/services/scale.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit, OnInit, OnDestroy {
  scaleService$!: Subscription;
  routeQueryParams$!: Subscription;
  turnDeviceService$: Subscription;

  showSignUp: boolean = false;

  showTurnDevice: boolean = false;
  isBlockedShowTurnDevice: boolean = false;

  marginTop: number = 113;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authFormService: AuthFormService,
    private authService: AuthService,
    private eventService: EventsService,
    private utmService: UtmService,
    private router: Router,
    private turnDeviceService: TurnDeviceService,
    private linkMinkService: LinkMinkService,
    private tuneAffiliateService: TuneAffiliateService,
    externalSignIn: ExternalSignIn,
    private scaleService: ScaleService,
  ) {
    if (externalSignIn.requested()) {
      externalSignIn.handle();
    }
  }

  async ngOnInit(): Promise<void> {
    this.routeQueryParams$ = this.activatedRoute.queryParams
      .pipe(filter((queryParams: Params) => 'modal' in queryParams))
      .subscribe({
        next: (queryParams: Params) => {
          // prettier-ignore
          if ('modal' in queryParams) {
            switch (queryParams['modal']) {
              case 'login':
                this.authService.getUserType().then((userType: UserTypeModel): void => {
                  userType === UserTypeModel.Unregistered && this.authFormService.openSignIn();
                });
                break;
              default:
                break;
            }

            this.router.navigate([], { queryParams: {'modal': null},  queryParamsHandling: 'merge'})
          }
        },
      });

    this.turnDeviceService$ = this.turnDeviceService.turnSubject.subscribe(
      (isTurnDevice: boolean) => {
        this.showTurnDevice = isTurnDevice;
      },
    );

    if (!(await this.authService.isAuthenticated())) {
      this.showSignUp = true;
    }

    this.scaleService$ = this.scaleService.currentScaleSubject
      .pipe(debounceTime(1000), filter(Boolean))
      .subscribe((scale) => {
        this.marginTop = 65 + 48 * scale;
      });
  }

  async ngAfterViewInit(): Promise<void> {
    this.eventService.emit(EventType.PageResized);
    this.collectUtm();
    if (await this.linkMinkService.isReferral()) {
      this.linkMinkService.retrieveAndSaveReferralDataToLocalStorage();
    }

    if (await this.tuneAffiliateService.isAffiliateLink()) {
      this.tuneAffiliateService.retrieveDataFromAffiliateLinkAndStoreIt();
    }
  }

  ngOnDestroy(): void {
    [
      this.turnDeviceService$,
      this.routeQueryParams$,
      this.scaleService$,
    ].forEach(($: Subscription) => $?.unsubscribe());
  }

  public async collectUtm(): Promise<void> {
    const utm = await this.utmService.createUtm();
    this.utmService.setUtmData(utm);
  }

  onResize(): void {
    this.eventService.emit(EventType.PageResized);

    /** FOR TURN DEVICE */
    const scale: number = window.innerWidth / 1636;

    if (scale < 1) {
      const screenWidth: number = window.screen.width;
      const screenHeight: number = window.screen.height;

      const isTargetUrl: boolean = ['events-timeline', 'single-stock'].some(
        (path: string) => this.router.url.includes(path),
      );

      // prettier-ignore
      this.turnDeviceService.turnSubject.next(screenWidth < screenHeight && isTargetUrl);
    }
  }
}
