import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UserTypeModel } from '../../../shared/models/user-type.model';
import { AuthService } from '../../../auth/services/auth.service';
import { AuthFormService } from '../../../auth/services/auth-form.service';
import { AnalyticService } from '../../../../services/analytic.service';
import { Subscription } from 'rxjs';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { ONBOARDING_PASSED_NAME } from '../../../../constants/onboarding-constants';
import {
  OnboardingHintEventService,
  OnboardingHintEventType,
} from '../../../shared/modules/basic/components/onboarding-hint/onboarding-hint-event.service';
import { PortfolioService } from '../../../shared/services/portfolio.service';
import { Portfolio } from '../../../main/portfolio-new/models/portfolio.model';
import { EventsService, EventType } from '../../../../services/events.service';

@Component({
  selector: 'app-header-new-account',
  templateUrl: './header-new-account.component.html',
})
export class HeaderNewAccountComponent implements OnInit, OnDestroy {
  @Input()
  set appClassSignInBtn(classSingInBtn: string) {
    this.classSingInBtn = classSingInBtn;
  }

  @Input()
  set appClassSignUpBtn(classSingUpBtn: string) {
    this.classSingUpBtn = classSingUpBtn;
  }

  changePortfolioService$!: Subscription;

  hintForm: FormGroup;

  classSingInBtn: string = 'kw-btn kw-btn-on-dark kw-btn-active kw-btn-lg';
  classSingUpBtn: string = 'kw-btn kw-btn-primary kw-btn-active kw-btn-lg';

  userType: UserTypeModel = UserTypeModel.Unregistered;

  totalInPortfolio: number = 0;

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private authFormService: AuthFormService,
    private analyticService: AnalyticService,
    private onboardingEventService: OnboardingHintEventService,
    private portfolioService: PortfolioService,
    private eventService: EventsService,
  ) {
    // prettier-ignore
    this.hintForm = this.formBuilder.group({hint: this.formBuilder.control(true, [])});
  }

  // prettier-ignore
  ngOnInit(): void {
    /** когда сделаны изменения в составе портфолио */
    this.changePortfolioService$ = this.portfolioService.currentPortfolioSubject
      .subscribe((portfolio: Portfolio[]) => {
        this.totalInPortfolio = portfolio.length;
      });

    this.eventService.subscription().subscribe(async (type) => {
      if (type === EventType.UserTypeChanged) {
        this.getUserType();
      }
    });

    this.getUserType();
  }

  ngOnDestroy(): void {
    [this.changePortfolioService$].forEach(($: Subscription) =>
      $.unsubscribe(),
    );
  }

  getUserType(): void {
    this.authService.getUserType().then((userType: UserTypeModel): void => {
      this.userType = userType;

      this.portfolioService.changePortfolioSubject.next(true);
    });
  }

  get UserTypeModel(): typeof UserTypeModel {
    return UserTypeModel;
  }

  signUp(): void {
    this.analyticService.send(
      'click_sign_up',
      new Map([['name', 'В шапке https://skr.sh/sJQhqCMN5mn']]),
    );
    this.authFormService.openSignUp();
  }

  signIn(): void {
    this.authFormService.openSignIn();
  }

  onLogout(): void {
    this.authService.logout().then(() => console.debug('Logout successful'));
  }

  onCheckHint(): void {
    const checkboxHint: AbstractControl | null = this.hintForm.get('hint');

    if (checkboxHint) {
      const value: boolean = checkboxHint.value;

      if (value) {
        const passed = localStorage.getItem(ONBOARDING_PASSED_NAME);
        if (passed !== null) {
          localStorage.removeItem(ONBOARDING_PASSED_NAME);
        }
        this.onboardingEventService.emit(OnboardingHintEventType.Enable);
      } else {
        localStorage.setItem(ONBOARDING_PASSED_NAME, 'TRUE');
        this.onboardingEventService.emit(OnboardingHintEventType.Disable);
      }
    }
  }
}
