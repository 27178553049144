import { HeaderService } from './../header/services/header.service';
import { AppService } from 'src/app/services/app.service';
import { Component, OnInit } from '@angular/core';
import { INavigationMenu } from '../../shared/modules/basic/structures/app';
import { OnboardingService } from '../sevices/onboarding.service';
import { AuthService } from '../../auth/services/auth.service';
import { OnboardingHintEventService } from '../../shared/modules/basic/components/onboarding-hint/onboarding-hint-event.service';
import { EventType, EventsService } from 'src/app/services/events.service';
import { PortfolioService } from '../../widgets/portfolio/services/portfolio.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  menuItems: Array<INavigationMenu> = [];
  private readonly onboardingService: OnboardingService;

  constructor(
    public appService: AppService,
    public headerService: HeaderService,
    private authService: AuthService,
    private portfolioService: PortfolioService,
    private onboardingEventService: OnboardingHintEventService,
    private eventService: EventsService,
    public sanitizer: DomSanitizer,
  ) {
    this.eventService.subscription().subscribe(async (type) => {
      if (type === EventType.NumberOfStocksInPortfolioChanged) {
        await this.onboardingService.updateNumberOfEvents(this.menuItems);
      }
    });

    this.menuItems = this.appService.mobileMenus;
    this.onboardingService = new OnboardingService(
      this.authService,
      this.portfolioService,
      this.onboardingEventService,
      this.menuItems,
    );
  }

  ngOnInit(): void {
    this.onboardingService.updateNumberOfEvents(this.menuItems);
  }

  get items() {
    return this.appService.footerItems;
  }

  itemClick(route?: string) {
    if (route) {
      if (route.startsWith('http')) {
        window.open(route);
      } else {
        this.headerService.gotoRoute(route);
      }
    }
  }
}
