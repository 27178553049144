export const TOOLS: readonly { label: string; link: string }[] = [
  { label: 'SPY Indicators', link: '/single-stock;ticker=SPY' },
  { label: 'Knowledge Hub', link: '/knowledgehub' },
  { label: 'Key Events', link: '/events-timeline' },
  { label: 'Win Rate Report', link: '/win-rate/market' },
  { label: 'Markets: Money Flows', link: '/money-flow?type=markets' },
  { label: 'Sectors: Money Flows', link: '/money-flow?type=sectors' },
  { label: 'Dashboard', link: '/dashboard' },
  { label: 'Portfolio', link: '/portfolio' },
  { label: 'Alerts', link: '/alerts' },
];

export const COMPANY: readonly { label: string; link: string }[] = [
  { label: 'About Us', link: '' },
  { label: 'Core Features', link: '' },
  { label: 'Pricing', link: '/premium' },
  { label: 'Expert Use Cases', link: '' },
  { label: 'Research Papers', link: '' },
  { label: 'Terms & Conditions', link: '/pages/terms' },
  { label: 'Privacy Policy', link: '/pages/privacy-policy' },
];
