<div
  class="flex flex-col bg-white w-screen md:max-w-[480px] h-screen md:h-auto md:rounded-2xl shadow-shadow-modal-shadow"
>
  <div class="flex justify-end !p-4">
    <button (click)="close()">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.7349 12L6 7.26512L7.26512 6L12 10.7349L16.7349 6L18 7.26512L13.2651 12L18 16.7349L16.7349 18L12 13.2651L7.26512 18L6 16.7349L10.7349 12Z"
          fill="#333333"
        />
      </svg>
    </button>
  </div>
  <div class="flex flex-col !gap-6 !p-8 !pt-0">
    <div class="flex flex-col !gap-2">
      <span class="text-interface-title-3-semibold text-default-neutral-1">
        Renew Subscription?
      </span>
      <p class="text-interface-body-1-regular text-default-neutral-1">
        You can cancel your subscription at any time. Access to all pro features
        will remain until <br />
        {{ nextPaymentDate | date: 'MMM d, y' }}.
      </p>
    </div>
    <div class="flex flex-col !gap-2 !p-4 rounded-lg bg-default-neutral-2">
      <span class="text-interface-body-2-semibold text-default-neutral-1">
        Current Subscription Terms:
      </span>
      <div class="flex flex-col">
        <ul class="flex items-center !gap-1">
          <li class="text-interface-body-2-semibold text-default-neutral-1">
            Plan:
          </li>
          <li class="text-interface-body-2-regular text-default-neutral-1">
            Pro
            {{
              data.mySubscriptions.price.usage_type === 'licensed'
                ? 'Flat Fee'
                : 'Pay If You Win'
            }}
          </li>
          <li class="text-interface-body-2-regular text-default-neutral-3">
            {{
              data.mySubscriptions.price.interval === 'month'
                ? 'Monthly'
                : 'Annually'
            }}
          </li>
        </ul>
        <ul class="flex items-center !gap-1">
          <li class="text-interface-body-2-semibold text-default-neutral-1">
            Price:
          </li>
          <!-- TRIAL EXPIRED -->
          <ng-container *ngIf="data.mySubscriptions.is_trial_expired">
            <li class="text-interface-body-2-regular text-default-neutral-1">
              ${{ data.mySubscriptions.price.amount }}
            </li>
            <li class="text-interface-body-2-regular text-default-neutral-3">
              /{{ data.mySubscriptions.price.interval }}
            </li>
          </ng-container>
          <!-- TRIAL NOT EXPIRED -->
          <ng-container *ngIf="!data.mySubscriptions.is_trial_expired">
            <li class="text-interface-body-2-regular text-default-neutral-1">
              {{ leftDaysOfTrial }} Days Free Trial
            </li>
            <li class="text-interface-body-2-regular text-default-neutral-3">
              Then ${{ data.mySubscriptions.price.amount }} /{{
                data.mySubscriptions.price.interval
              }}
            </li>
          </ng-container>
        </ul>
        <ul class="flex items-center !gap-1">
          <li class="text-interface-body-2-semibold text-default-neutral-1">
            Next Payment:
          </li>
          <li class="text-interface-body-2-regular text-default-neutral-1">
            On {{ nextPaymentDate | date: 'MMM d, y' }}
          </li>
        </ul>
      </div>
    </div>
    <div class="flex !gap-2">
      <button
        class="kw-btn kw-btn-lg kw-btn-active kw-btn-light"
        (click)="close()"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="currentColor"
        >
          <path
            d="M15.7071 4.29289C16.0976 4.68342 16.0976 5.31658 15.7071 5.70711L9.41421 12L15.7071 18.2929C16.0976 18.6834 16.0976 19.3166 15.7071 19.7071C15.3166 20.0976 14.6834 20.0976 14.2929 19.7071L7.29289 12.7071C6.90237 12.3166 6.90237 11.6834 7.29289 11.2929L14.2929 4.29289C14.6834 3.90237 15.3166 3.90237 15.7071 4.29289Z"
          />
        </svg>
        <span class="block"> Go Back </span>
      </button>
      <button
        class="kw-btn kw-btn-lg kw-btn-active kw-btn-primary w-full"
        type="button"
        (click)="onRenew()"
      >
        <span class="block"> Renew Subscription </span>
      </button>
    </div>
  </div>
</div>
