import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { ExpTypeKey, SvgSetting } from '../../models/cash-flow-chart.model';
import { filter, first, from, Subscription } from 'rxjs';
import { ExpirationTypeService } from '../../services/expiration-type.service';
import { ChartService } from '../../services/chart.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MainHttpClient } from '@app/services/main-http-client.service';
import {
  CASH_FLOW_FORECAST_ICON_OBJ,
  CashFlowForecast,
  CashFlowForecastIcon,
  CashFlowForecastIconKey,
  CashFlowForecastPrepare,
} from '../../models/cash-flow-forecast.model';
import { DOCUMENT } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { UserTypeModel } from '../../../../shared/models/user-type.model';
import { AuthService } from '../../../../auth/services/auth.service';
import { AnalyticService } from '@app/services/analytic.service';
import { Router } from '@angular/router';
import { AuthFormService } from '../../../../auth/services/auth-form.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-cash-flow-forecast',
  templateUrl: './cash-flow-forecast.component.html',
})
export class CashFlowForecastComponent implements OnInit, OnDestroy {
  expirationTypeService$!: Subscription;
  cleanChartService$!: Subscription;
  startForecastChartService$!: Subscription;

  @Input()
  set appCashFlowForecastSettings(tickerName: string) {
    this.tickerName = tickerName;
  }

  @Input()
  set appCashFlowForecastTranslateX(svgTranslateX: number) {
    this.svgTranslateX = svgTranslateX;
  }

  @Input()
  set appCashFlowForecastSvgSetting(svgSetting: SvgSetting) {
    this.svgSetting = svgSetting;
  }

  svgTranslateX: number = 0;
  svgSetting: SvgSetting;
  activeExpType: ExpTypeKey = 'month';

  tickerName: string;

  forecastList: CashFlowForecast[] = [];
  forecastPrepareList: CashFlowForecastPrepare[] = [];
  forecastIconObj: CashFlowForecastIcon = CASH_FLOW_FORECAST_ICON_OBJ;

  userType: UserTypeModel = UserTypeModel.Unregistered;
  fakeData: {
    minXFake: number;
    maxXFake: number;
    width: number;
  } = {
    minXFake: 0,
    maxXFake: 0,
    width: 0,
  };

  constructor(
    @Inject(DOCUMENT)
    private document: Document,
    private http: MainHttpClient,
    private router: Router,
    private chartService: ChartService,
    private expirationTypeService: ExpirationTypeService,
    private sanitizer: DomSanitizer,
    private authService: AuthService,
    private analyticService: AnalyticService,
    private authFormService: AuthFormService,
    private cookieService: CookieService,
  ) {}

  ngOnInit(): void {
    this.authService.getUserType().then((userTypeModel: UserTypeModel) => {
      this.userType = userTypeModel;
    });

    /** переключение тикера */
    this.cleanChartService$ = this.chartService.cleanSubject
      .pipe(filter((clean: boolean) => clean))
      .subscribe(() => {
        /** чистим для отрисовки заново */
        this.cleanForecast();
      });

    // prettier-ignore
    this.startForecastChartService$ = this.chartService.startForecastSubject
      .pipe(filter((start: boolean) => start))
      .subscribe(() => {
        const getTomorrow = (): string => {
          const tomorrow: Date = new Date(new Date().getTime() + 86400000);

          return `${tomorrow.getFullYear()}-${tomorrow.getMonth() + 1 < 10 ? '0' + (tomorrow.getMonth() + 1) : tomorrow.getMonth() + 1}-${tomorrow.getDate()}`;
        }

        /** стартуем отрисовку */
        this.getForecast(getTomorrow());
      });

    /** переключение зума */
    this.expirationTypeService$ =
      this.expirationTypeService.currentExpTypeSubject
        .pipe(
          filter((expTypeKey: ExpTypeKey) => this.activeExpType !== expTypeKey),
        )
        .subscribe((expTypeKey: ExpTypeKey) => {
          this.activeExpType = expTypeKey;

          /** чистим для отрисовки заново */
          this.cleanForecast();
        });
  }

  ngOnDestroy(): void {
    [
      this.cleanChartService$,
      this.startForecastChartService$,
      this.expirationTypeService$,
    ].forEach(($) => $?.unsubscribe());
  }

  cleanForecast(): void {
    this.forecastList = [];
    this.forecastPrepareList = [];

    this.fakeData = { width: 0, minXFake: 0, maxXFake: 0 };
  }

  /** сборка для отображения */
  // prettier-ignore
  setForecastPrepare(forecastList: CashFlowForecast[]): void {
    const isReplay: boolean = forecastList.map((forecast: CashFlowForecast) => {
      return Boolean(this.forecastList.find((currentForecast: CashFlowForecast): boolean => currentForecast.date === forecast.date))
    }).every((value: boolean) => value);

    this.forecastList = [...this.forecastList, ...forecastList];

    // сортируем по дате
    this.forecastList = this.forecastList.sort(
      (a: CashFlowForecast, b: CashFlowForecast) => {
        return new Date(a.date).getTime() - new Date(b.date).getTime();
      },
    );

    this.forecastPrepareList = [];

    this.forecastList.forEach((forecast: CashFlowForecast, index: number) => {
      const isIncludeForecast: boolean = !!this.forecastPrepareList.find((forecastPrepare: CashFlowForecastPrepare): boolean => forecastPrepare.date === forecast.date);

      if (isIncludeForecast) {
        this.forecastPrepareList.pop();
      }

      const getDayElement = (date: string): Element | null => {
        const getActualDate = (date: string): string => {
          const barNetFlows: Element | null = this.document.querySelector('#cash-flow-chart-draggable-net-flows > g[data-date="' + date + '"]');

          if (barNetFlows) {
            const nextDate: Date = new Date(new Date(date).getTime() + 86400000);

            return getActualDate(`${nextDate.getFullYear()}-${nextDate.getMonth() + 1 < 10 ? '0' + (nextDate.getMonth() + 1) : nextDate.getMonth() + 1}-${nextDate.getDate()}`);
          } else {
            return date;
          }
        }

        let actualDate: string = getActualDate(date);

        return this.document.querySelector('#cash-flow-chart-draggable-x-ticker > [data-id="days"] > [data-date="' + actualDate + '"]');
      }

      let dayEl: Element | null = getDayElement(forecast.date);

      let limit: number = 0;

      /** поиск ближайшей доступной даты для установки прогноза */
      while (!dayEl && limit < 30) {
        limit++;

        const nextDay: Date = new Date(new Date(forecast.date).getTime() + (86400000 * limit));

        dayEl = getDayElement(`${nextDay.getFullYear()}-${nextDay.getMonth() + 1 < 10 ? '0' + (nextDay.getMonth() + 1) : nextDay.getMonth() + 1}-${nextDay.getDate()}`);
      }

      if (dayEl) {
        const positionX: number = Number(dayEl.getAttribute('x')) || 0;

        const nextPositionX: number = Number(this.forecastList.length - 1 === index ? 250 : this.document.querySelector('#cash-flow-chart-draggable-x-ticker > [data-id="days"] > [data-date="' + this.forecastList[index + 1].date + '"]')?.getAttribute('x')) || 0;

        const width: number = Math.abs(nextPositionX) - Math.abs(positionX) < 0 ? 500 : Math.abs(nextPositionX) - Math.abs(positionX) > 630 ? 630 : Math.abs(nextPositionX) - Math.abs(positionX);

        const icon: string = forecast.expectation_type ? this.forecastIconObj[forecast.expectation_type](0, 0) : this.forecastIconObj['Mixed'](0, 0);
        const gradient: string = this.getCashFlowForecastGradient(forecast.expectation_type);

        if (index === 0 && Math.abs(nextPositionX) - Math.abs(positionX) > 200 || index > 0) {
          this.forecastPrepareList.push({
            ...forecast,
            positionX,
            width,
            icon: this.sanitizer.bypassSecurityTrustHtml(icon),
            gradient
          });
        }
      }
    });

    /** BLUR FORECAST */
    const firstFakedForecast: CashFlowForecastPrepare | undefined = this.forecastPrepareList.find((forecast) => forecast.is_faked);

    const lastDate: Element | null = this.document.querySelector('#cash-flow-chart-draggable-x-ticker > g[data-id="days"] > text:last-child');

    if (firstFakedForecast && lastDate) {
      const minXFake: number = firstFakedForecast.positionX
      const maxXFake: number = Number(lastDate.getAttribute('x'));

      this.fakeData = { minXFake, maxXFake, width: maxXFake - minXFake }
    } else {
      this.fakeData = { width: 0, minXFake: 0, maxXFake: 0 };
    }

    // если добавили новые элементы, то идем на проверку
    if (!isReplay) {
      /** проверка на догрузку */
      this.checkForecastData();
    }
  }

  // prettier-ignore
  checkForecastData(): void {
    const forecastLastDate: string = this.forecastList[this.forecastList.length - 1].date;
    // @ts-ignore
    const chartLastDate: string = this.document.querySelector('#cash-flow-chart-draggable-x-ticker [data-id="days"]')?.lastElementChild?.dataset.date;

    if (new Date(forecastLastDate) < new Date(chartLastDate)) {
      this.getForecast(forecastLastDate)
    }
  }

  // prettier-ignore
  getForecast(date: string): void {
    from(this.http.get<{data: CashFlowForecast[]}>('/v2/stock-page/predictions?ticker=' + this.tickerName + '&exp_type=' + this.activeExpType + '&date_from=' + date))
      .pipe(first())
      .subscribe({
        next: (forecastList: { data: CashFlowForecast[] }) => {
          this.setForecastPrepare(forecastList.data);
        },
        error: (error: HttpErrorResponse) => console.error(error),
      });
  }

  getCashFlowForecastGradient(iconName: CashFlowForecastIconKey): string {
    switch (iconName) {
      case 'Sideways':
      case 'Range': {
        return 'bg-yellow-gradient';
      }
      case 'Mixed': {
        return 'bg-gray-gradient';
      }
      case 'Bullish profit taking':
      case 'Bullish trend':
      case 'Short squeeze':
      case 'Support test':
      case 'Bullish pivot':
      case 'Bullish breakout':
      case 'Pullback': {
        return 'bg-green-gradient';
      }
      case 'Bearish profit taking':
      case 'Bearish trend':
      case 'Long squeeze':
      case 'Resistance test':
      case 'Bearish pivot':
      case 'Bearish breakout':
      case 'Bounce back': {
        return 'bg-red-gradient';
      }
      default: {
        return 'bg-gray-gradient';
      }
    }
  }

  /** клик по заблокированному индикатору */
  onShowIndicator(): void {
    /** запоминаем страницу с которой пошли на покупку */
    this.cookieService.delete('page-before-payment', '/');
    this.cookieService.set('page-before-payment', this.router.url, {
      path: '/',
    });

    /** Не авторизован */
    if (this.userType === 0) {
      this.analyticService.send(
        'click_sign_up',
        new Map([['name', 'В прогнозах "cash-flow"']]),
      );
      this.authFormService.openSignUp();
    }

    /** Авторизован */
    if (this.userType > 0) {
      // prettier-ignore
      this.router.navigate(['/premium'], { fragment: 'tariffs' }).then(() => console.debug('Route changed'));
    }
  }
}
