import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { ExpTypeKey } from '../models/cash-flow-chart.model';

@Injectable({
  providedIn: 'root',
})
export class ExpirationTypeService {
  // prettier-ignore
  currentExpTypeSubject: BehaviorSubject<ExpTypeKey> = new BehaviorSubject<ExpTypeKey>('month');
}
