import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { ValidationErrors } from '@angular/forms';

const getErrorMap = (formControlName: string): any => {
  switch (formControlName) {
    case 'email':
      return {
        required: 'Required E-mail address',
        email: 'Wrong E-mail address',
      };
    case 'password':
      return {
        required: 'Required password',
        minlength: '6 characters minimum',
        isPassword: 'Invalid password',
      };
    case 'currentPassword':
      return {
        required: 'Fill in the field',
        minlength: 'Password must be at least 8 characters long',
      };
    case 'newPassword':
      return {
        required: 'Fill in the field',
        minlength: 'Password must be at least 8 characters long',
        notMatch: "Passwords doesn't match",
      };
    case 'confirmNewPassword':
      return {
        required: 'Fill in the field',
        minlength: 'Password must be at least 8 characters long',
        notMatch: "Passwords doesn't match",
      };
    case 'name':
      return {
        required: 'Fill in the field',
        maxlength: 'Name is too long',
      };
    case 'code':
      return {
        required: 'Fill in the field',
        minlength: 'Invalid confirmation code',
        maxlength: 'Invalid confirmation code',
      };
    case 'message':
      return {
        required: 'Fill this field',
      };
    case 'startDate':
    case 'endDate':
      return {
        required: 'Required this field',
        isDate: 'Invalid Date',
      };
    default: {
      return null;
    }
  }
};

@Component({
  selector: 'app-error-tooltip',
  templateUrl: './error-tooltip.component.html',
})
export class ErrorTooltipComponent {
  @ViewChild('errorElement') errorElement!: ElementRef;

  @HostListener('document:click', ['$event'])
  onClick(event: any) {
    const elementParent: HTMLElement =
      this.errorElement?.nativeElement.parentElement.parentElement;
    const inParent: boolean = elementParent?.contains(event.target);

    this.closed.emit(inParent);
  }

  @Output() closed = new EventEmitter<boolean>();

  @Input()
  set appErrorsField(errorsField: ValidationErrors | null | undefined) {
    if (errorsField) {
      this.errorsControl = errorsField;
      this.onUpdateErrors();
    } else {
      this.errorsControl = null;
      this.onUpdateErrors();
    }
  }

  @Input()
  set appFormControlName(formControlName: string) {
    this.formControlName = formControlName;
    this.onUpdateErrors();
  }

  errorsControl?: ValidationErrors | null;
  formControlName: string = '';

  errors: string[] = [];

  constructor() {}

  onUpdateErrors(): void {
    this.errors = [];

    if (this.errorsControl) {
      const keyValidationErrors: string[] = Object.keys(this.errorsControl);
      const errorMap = getErrorMap(this.formControlName);

      keyValidationErrors.forEach((keyError: string) => {
        if (errorMap && errorMap[keyError]) {
          this.errors.push(errorMap[keyError]);
        }
      });
    }
  }
}
