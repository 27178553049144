import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, finalize, tap } from 'rxjs';

import { HelperService } from '@app/services/helper.service';
import { SnackbarService } from '@app/services/snackbar.service';
import { SettingsService } from '@main/profile/services/settings.service';
import { UserProfile } from '../settings.model';

@Component({
  selector: 'profile-settings-change-name',
  templateUrl: './change-name.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeNameComponent {
  @Input() set name(name: UserProfile['name']) {
    this.nameForm.get('name')?.setValue(name);
    this.initialName = name;
  }

  private initialName: UserProfile['name'] | null = null;

  public readonly nameForm = new FormGroup({
    name: new FormControl('', {
      validators: [Validators.required, Validators.maxLength(50)],
    }),
  });

  public readonly formIsSubmitting$ = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly helperService: HelperService,
    private readonly snackBarService: SnackbarService,
    private readonly settingsService: SettingsService,
  ) {}

  submit(): void {
    if (this.helperService.getFormValidation(this.nameForm)) {
      const name = this.nameForm
        .get('name')
        ?.value.trim() as UserProfile['name'];

      if (name === this.initialName) {
        this.snackBarService.success('Changes Saved');
        return;
      }

      this.formIsSubmitting$.next(true);
      this.settingsService
        .changeName(name)
        .pipe(
          tap(() => this.snackBarService.success('Changes Saved')),
          finalize(() => this.formIsSubmitting$.next(false)),
        )
        .subscribe();
    }
  }
}
