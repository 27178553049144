import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { WidgetType } from "../models/widget-type.model";

@Injectable({
  providedIn: 'root'
})
export class WidgetsService {
  private subject = new Subject<WidgetType>()

  setContent(type: WidgetType) {
    this.subject.next(type);
  }

  subscribe(f: Function) {
    this.subject.subscribe((x: WidgetType) => f(x));
  }
}
