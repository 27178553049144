import { Component, Inject, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthFormComponent } from '../auth-form/auth-form.component';

@Component({
  selector: 'app-form-layout',
  templateUrl: './form-layout.component.html',
  styleUrls: ['./form-layout.component.scss']
})
export class FormLayoutComponent {

  @Input() formName: string;

  constructor(private dialogRef: MatDialogRef<AuthFormComponent>) {

  }

  close() {
    this.dialogRef.close();
  }
}
