<div
  id="snapshot-market-reaction-box"
  class="bg-[#FAF7F7] rounded flex flex-col gap-6 px-8 py-6 max-h-[414px]"
>
  <div class="flex gap-2 items-center justify-between">
    <div class="flex flex-col">
      <span class="text-[#333] text-2xl"> 1-day Snapshot Market Reaction </span>
      <span class="text-[#504F4F] text-sm">
        Volume / Open Interest Change %
      </span>
    </div>
    <div class="flex gap-6">
      <div class="flex flex-col gap-2 max-w-[100px]">
        <span class="text-[#333] text-sm font-light leading-[0.875rem]">
          Avg. OI Change
        </span>
        <p class="text-[#504F4F] text-sm font-semibold leading-[0.875rem]">
          {{ snapshotMarketReaction.avrOIValue }}
          <span class="text-[#41B1A6]">
            ({{ snapshotMarketReaction.avrOIPercent }}%)
          </span>
        </p>
      </div>
      <div class="flex flex-col gap-2 max-w-[100px]">
        <span class="text-[#333] text-sm font-light leading-[0.875rem]">
          Avg. Volume
        </span>
        <p class="text-[#504F4F] text-sm font-semibold leading-[0.875rem]">
          {{ snapshotMarketReaction.avrValue }}
          <span class="text-[#41B1A6]">
            ({{ snapshotMarketReaction.avrPercent }}%)
          </span>
        </p>
      </div>
    </div>
  </div>
  <div class="flex items-center justify-end w-full gap-6">
    <div class="flex items-center gap-2">
      <div class="bg-[#41B1A6] w-4 h-4"></div>
      <span class="text-[#0009] text-[12.519px] leading-[14.084px] font-B612">
        Bullish Change %
      </span>
    </div>
    <div class="flex items-center gap-2">
      <div class="bg-[#F16D6A] w-4 h-4"></div>
      <span class="text-[#0009] text-[12.519px] leading-[14.084px] font-B612">
        Bearish Change %
      </span>
    </div>
    <div class="flex items-center gap-2">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="8"
        viewBox="0 0 16 8"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4.12602 5H0V3H4.12602C4.57006 1.27477 6.13616 0 8 0C9.86384 0 11.4299 1.27477 11.874 3H16V5H11.874C11.4299 6.72523 9.86384 8 8 8C6.13616 8 4.57006 6.72523 4.12602 5Z"
          fill="#FDCD25"
        />
      </svg>
      <span class="text-[#0009] text-[12.519px] leading-[14.084px] font-B612">
        Avg. Change %
      </span>
    </div>
  </div>
  <div
    class="relative flex w-full h-full"
    id="events-timeline-details-past-chart"
  ></div>
</div>
