import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthFormService } from '../../services/auth-form.service';
import { AuthService } from '../../services/auth.service';
import { HelperService } from '../../../../services/helper.service';
import { SnackbarService } from '../../../../services/snackbar.service';

@Component({
  selector: 'app-auth-reset',
  templateUrl: './auth-reset.component.html',
})
export class AuthResetComponent {
  resetForm: FormGroup;
  resetFormIsSubmitting: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialogRef<AuthResetComponent>,
    private formBuilder: FormBuilder,
    private authFormService: AuthFormService,
    private authService: AuthService,
    private helperService: HelperService,
    private snackbarService: SnackbarService,
  ) {
    this.resetForm = this.formBuilder.group({
      email: this.formBuilder.control('', [
        Validators.required,
        Validators.email,
      ]),
    });
  }

  onClose(): void {
    this.dialog.close();
  }

  async onReset(): Promise<void> {
    if (this.helperService.getFormValidation(this.resetForm)) {
      try {
        this.resetFormIsSubmitting = true;

        const email = this.resetForm.get('email')?.value.toLocaleLowerCase();

        const response = await this.authService.resetPassword({ email });

        if (response.status) {
          this.onGoToSignIn();

          this.resetFormIsSubmitting = false;

          this.snackbarService.success(response.data.message);
        }
      } catch (error: any) {
        this.resetFormIsSubmitting = false;
      }
    }
  }

  onGoToSignIn(): void {
    this.onClose();

    setTimeout(() => this.authFormService.openSignIn(), 200);
  }
}
