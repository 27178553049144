import { Injectable } from "@angular/core";
import { RiskRewardSector } from "src/app/interfaces/api/widgets/risk-reward-heatmap/domain/risk-reward-sector";
import { RiskRewardSectorChartData } from "src/app/interfaces/api/widgets/risk-reward-heatmap/domain/risk-reward-sector-chart-data";
import { RiskRewardHeatmapPageResponse } from "src/app/interfaces/api/widgets/risk-reward-heatmap/risk-reward-heatmap-page";
import { RiskRewardHeatmapSectorPageResponse } from "src/app/interfaces/api/widgets/risk-reward-heatmap/risk-reward-heatmap-sector-page";
import { RiskRewardHeatmapModel } from "../models/risk-reward-heatmap.model";
import { Field } from "src/app/models/field.model";
import { Field as SectorPageField } from 'src/app/interfaces/api/domain/parameter';
import { RiskRewarParameter } from "src/app/interfaces/api/widgets/risk-reward-heatmap/domain/risk-reward-parameter";
import { RiskRewardHeatmapDialogModel, RiskRewardHeatmapDialogSectionSubsectionField } from "src/app/modules/shared/models/risk-reward-heatmap-dialog.model";
import { RiskRewardHeatmapItemModel, RiskRewardChartCoordinate, RiskRewardChartModel } from "src/app/modules/shared/models/risk-reward-heatmap-item.model";

@Injectable()
export class RiskRewardHeatmapDataTransformService {
  pageResponseToHeatmaps(response: RiskRewardHeatmapPageResponse): RiskRewardHeatmapModel {

    const mapSectorToHeatmap = (sector: RiskRewardSector): RiskRewardHeatmapItemModel => {

      let x = 0;
      const mapChart = (chartData: RiskRewardSectorChartData): RiskRewardChartCoordinate => {
        return {
          location: { x: x++, y: chartData.riskRewardRatio },
          text: chartData.date
        }
      };

      const filteredChartData = sector.chartData.filter(chart => chart !== null);
      return {
        id: sector.id,
        name: sector.name,
        risk: sector.risk,
        reward: sector.reward,
        fillColor: "#DFF3E5",
        chart: {
          coordinates: filteredChartData.map(mapChart),
          points: [],
          color: "#62C37D",
          width: 220,
          height: 100
        }
      }
    };

    const maps = response.sectors.map(mapSectorToHeatmap);
    return {
      maps: maps
    }
  }

  pageSectorResponseToHeatMapDialog(
    response: RiskRewardHeatmapSectorPageResponse,
    heatmapItem: RiskRewardHeatmapItemModel): RiskRewardHeatmapDialogModel {

    const mapChart = (chartData: Array<RiskRewardSectorChartData>): RiskRewardChartModel => {
      let x = 0;
      const mapChartCoordinate = (source: RiskRewardSectorChartData): RiskRewardChartCoordinate => {
        return {
          location: { x: x++, y: source.riskRewardRatio },
          text: source.date
        }
      };

      return {
        color: heatmapItem.chart.color,
        coordinates: chartData.filter(x => x !== null).map(mapChartCoordinate),
        points: [],
        width: 220,
        height: 100
      }
    }

    const mapTopItem = (field: SectorPageField): Field => {
      return {
        name: field.name,
        value: `${field.value}`
      }
    }

    const mapSubsectionFields = (parameter: RiskRewarParameter): RiskRewardHeatmapDialogSectionSubsectionField => {
      return {
        name: parameter.name,
        now: parameter.value,
        fair: parameter.fair,
        percent: `${parameter.difference}%`
      }
    }

    return {
      title: response.name,
      fillColor: heatmapItem.fillColor,
      risk: heatmapItem.risk,
      reward: heatmapItem.reward,
      periods: [
        { name: "1D", selected: false },
        { name: "1W", selected: true },
        { name: "1M", selected: false },
        { name: "6M", selected: false },
        { name: "1Y", selected: false },
        { name: "All", selected: false }
      ],
      chart: mapChart(response.chartData),
      sections: [
        {
          name: "Risk",
          topItems: response.risk.map(mapTopItem),
          subsections: [
            {
              name: "Valuation",
              fields: response.parameters.map(mapSubsectionFields)
            }
          ]
        },
        {
          name: "Reward",
          topItems: response.reward.map(mapTopItem),
          subsections: [

          ]
        },
      ]
    }
  }
}
