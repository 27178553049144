<div class="trial-banner">
    <div class="trial-banner-left">
        <div class="trial-banner-title">Get more data for free</div>
        <div class="trial-banner-subtitle">
            <ng-content></ng-content>
        </div>
    </div>
    <div class="trial-banner-right">
        <a (click)="authFormService.openSignUp()" class="trial-banner-button">{{ buttonText }}</a>
    </div>
</div>