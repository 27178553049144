<div
  class="flex-center rounded-lg flex-grow cursor-pointer text-center gap-[8px] transition-all"
  [ngClass]="[color.bg, classes]"
>
  <app-icon
    *ngIf="iconData"
    [icon]="iconData.icon"
    [color]="iconData.color ?? {}"
    [class]="iconData.classes?.host ?? ''"
    [classes]="iconData.classes?.content ?? 'w-5 h-5'"
  ></app-icon>
  <span [class]="color.text">{{ text | translation }}</span>
</div>
