export const POPULAR_STOCKS = [
  {
    added: false,
    ticker: {
      id: '',
      name: '',
      ticker: 'TSLA',
    },
  },
  {
    added: false,
    ticker: {
      id: '',
      name: '',
      ticker: 'AAPL',
    },
  },
  {
    added: false,
    ticker: {
      id: '',
      name: '',
      ticker: 'AMZN',
    },
  },
];
