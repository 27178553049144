import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormService } from '../../../../../../../services/form.service';
import { SubscriptionsDialogService } from '../services/dialog.service';

@Component({
  selector: 'app-consultation-dialog',
  templateUrl: './consultation-dialog.component.html',
})
export class ConsultationDialogComponent {
  formForm: FormGroup;

  constructor(
    private dialog: MatDialogRef<ConsultationDialogComponent>,
    private formBuilder: FormBuilder,
    private formService: FormService,
    private dialogService: SubscriptionsDialogService,
  ) {
    this.formForm = this.formBuilder.group({
      findOut: this.formBuilder.control(false, []),
      feedback: this.formBuilder.control(false, []),
    });
  }

  close(success: boolean = false): void {
    this.dialog.close(success);
  }

  onSubmit(): void {
    this.formForm = this.formService.onFormSubmit(this.formForm);

    if (this.formForm.valid) {
      /** ЗАПРОС? Куда отправить? */

      this.formForm.reset();
      this.close();

      /** на календли */
      window.open('calendly', '_blank');
    }
  }

  onCancel(): void {
    /** Продолжаем отмену подписки, идем на модалку со скидкой  */
    this.formForm.reset();
    this.close();

    this.dialogService.openDiscountDialog();
  }
}
