import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-landing-video-dialog',
  templateUrl: './landing-video-dialog.component.html',
})
export class LandingVideoDialogComponent {
  // prettier-ignore
  @ViewChild('frameVideo') frameVideo: ElementRef<HTMLIFrameElement> | undefined;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { urlVideo: string; title: string },
    private dialog: MatDialogRef<LandingVideoDialogComponent>,
    private sanitizer: DomSanitizer,
  ) {
    this.data.urlVideo = <string>(
      this.sanitizer.bypassSecurityTrustResourceUrl(data.urlVideo)
    );
  }

  close(success: boolean = false) {
    this.dialog.close(success);
  }

  // prettier-ignore
  playFullscreen(): void {
    const iFrame: HTMLIFrameElement | undefined = this.frameVideo?.nativeElement;
    if (iFrame) {
      // @ts-ignore
      const requestFullScreen = iFrame?.requestFullscreen || iFrame?.mozRequestFullScreen || iFrame?.webkitRequestFullScreen;

      if (requestFullScreen) {
        requestFullScreen.bind(iFrame)();
      }
    }
  }
}
