import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-subscription-pay-success-dialog',
  templateUrl: './pay-success-dialog.component.html',
})
export class PaySuccessDialogComponent {
  constructor(
    private dialog: MatDialogRef<PaySuccessDialogComponent>,
    private router: Router,
  ) {}

  // prettier-ignore
  close(): void {
    this.dialog.close();

    this.router.navigate([], {
      queryParams: { 'pay-status': null },
      queryParamsHandling: 'merge',
    })
      .then(() => console.debug('Router changed'));
  }
}
