<div>
  <shared-triangles-background
    class="hidden md:block"
  ></shared-triangles-background>

  <div class="w-full flex justify-center md:pb-10">
    <div
      class="w-full max-w-[1520px] !px-4 md:!px-6 flex flex-col !gap-6 xl:!gap-10"
    >
      <p
        class="md:text-center text-interface-title-2-semibold text-default-neutral-1"
      >
        Account Settings
      </p>

      <app-loader-new-2
        *ngIf="isLoading$ | async"
        class="absolute top-1/2 left-2/4 -translate-x-1/2 -translate-y-1/2"
      ></app-loader-new-2>
      <div
        *ngIf="!(isLoading$ | async) && (loginViaGoogle$ | async) !== null"
        class="relative bg-white flex flex-col w-full mx-auto md:max-w-[728px] rounded-lg md:shadow-shadow-block-shadow gap-4 md:!gap-0"
      >
        <div
          *ngIf="loginViaGoogle$ | async"
          class="flex !gap-3 py-[14px] !px-4 bg-surface-4 w-full rounded-lg md:rounded-b-none items-center text-interface-caption-1-semibold"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M6.49082 10.0906C7.28605 7.67679 9.55058 5.94321 12.2328 5.94321C13.6747 5.94321 14.977 6.45571 16.0003 7.29436L18.977 4.31252C17.1631 2.72842 14.8375 1.75 12.2328 1.75C8.19955 1.75 4.72703 4.05481 3.05762 7.43027L6.49082 10.0906Z"
              fill="#FF3D00"
            />
            <path
              d="M15.6786 17.1352C14.7493 17.7362 13.5685 18.0562 12.233 18.0562C9.56104 18.0562 7.30359 16.3359 6.50022 13.9365L3.05566 16.5564C4.723 19.9377 8.19543 22.2494 12.233 22.2494C14.7339 22.2494 17.1236 21.3589 18.9134 19.6866L15.6786 17.1352Z"
              fill="#4CAF50"
            />
            <path
              d="M18.9127 19.6876C20.7844 17.9387 21.9999 15.3348 21.9999 12.0004C21.9999 11.3947 21.9069 10.7424 21.7673 10.1367L12.2324 10.1367V14.097H17.7208C17.45 15.4287 16.7231 16.4602 15.678 17.1362L18.9127 19.6876Z"
              fill="#1976D2"
            />
            <path
              d="M6.49976 13.9366C6.29625 13.3288 6.18605 12.6774 6.18605 11.9994C6.18605 11.3317 6.29294 10.6898 6.49056 10.0899L3.05736 7.42962C2.37228 8.80518 2 10.3555 2 11.9994C2 13.639 2.37927 15.1856 3.0552 16.5564L6.49976 13.9366Z"
              fill="#FFC107"
            />
          </svg>

          <p class="text-default-neutral-1">
            You are synchronized with
            <a
              href="https://myaccount.google.com"
              target="_blank"
              class="text-default-secondary-2"
            >
              Google
            </a>
          </p>
        </div>

        <div
          class="relative w-full flex flex-col p-0 md:!p-12 md:!pb-8 !gap-6 md:!gap-8"
        >
          <profile-settings-change-name
            [name]="(userData$ | async)!.name"
          ></profile-settings-change-name>

          <hr class="text-default-neutral-2 border-2 !opacity-100" />

          <profile-settings-change-email
            [email]="(userData$ | async)!.email"
            [loginViaGoogle]="!!(loginViaGoogle$ | async)"
          ></profile-settings-change-email>

          <hr class="text-default-neutral-2 border-2 !opacity-100" />

          <profile-settings-change-password
            [isPasswordSpecified]="(userData$ | async)!.is_password_specified"
            [loginViaGoogle]="!!(loginViaGoogle$ | async)"
          ></profile-settings-change-password>

          <hr class="text-default-neutral-2 border-2 !opacity-100" />

          <p class="text-medium text-interface-caption-1-regular">
            If you experience any problems, please write to
            <a
              href="mailto:support@visualsectors.com"
              class="text-default-secondary-2"
            >
              support&#64;visualsectors.com
            </a>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
