import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { LoginFormComponent } from './components/login-form/login-form.component';
import { RegistrationFormComponent } from './components/registration-form/registration-form.component';
import { ResetPasswordFormComponent } from './components/reset-password-form/reset-password-form.component';
import { AuthorizedGuard } from './guards/authorized.guard';
import { AuthService } from './services/auth.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UtmModule } from '@shared/modules/utm/utm.module';
import { FormLayoutComponent } from './components/form-layout/form-layout.component';
import { SharedModule } from '@shared/shared.module';
import { AuthFormComponent } from './components/auth-form/auth-form.component';
import { AnalyticGuard } from './guards/analytic.guard';
import { LoginFormContentComponent } from './components/login-form/login-form-content/login-form-content.component';
import { RegistrationFormContentComponent } from './components/registration-form/registration-form-content/registration-form-content.component';
import { AuthFormContentComponent } from './components/auth-form-content/auth-form-content.component';
import { AuthFormSocialButtonsComponent } from './components/auth-form-social-buttons/auth-form-social-buttons.component';
import { BasicModule } from '@shared/modules/basic/basic.module';
import { RouterModule } from '@angular/router';
import { ExternalSignIn } from './services/ExternalSignIn';
import { AuthSignInComponent } from './components/auth-sign-in/auth-sign-in.component';
import { AuthSignUpComponent } from './components/auth-sign-up/auth-sign-up.component';
import { AuthResetComponent } from './components/auth-reset/auth-reset.component';

@NgModule({
  declarations: [
    LoginFormComponent,
    RegistrationFormComponent,
    ResetPasswordFormComponent,
    FormLayoutComponent,
    AuthFormComponent,
    AuthFormContentComponent,
    LoginFormContentComponent,
    RegistrationFormContentComponent,
    AuthFormSocialButtonsComponent,
    AuthResetComponent,
    AuthSignUpComponent,
    AuthSignInComponent,
  ],
  providers: [AuthorizedGuard, AnalyticGuard, AuthService, ExternalSignIn],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    UtmModule,
    SharedModule,
    ReactiveFormsModule,
    BasicModule,
    RouterModule,
  ],
  exports: [AuthFormContentComponent, AuthFormSocialButtonsComponent],
})
export class AuthModule {}
