import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { interval, Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-subscription-canceled-dialog',
  templateUrl: './subscription-renewed-dialog.component.html',
})
export class SubscriptionRenewedDialogComponent
  implements AfterViewInit, OnDestroy
{
  interval$: Subscription | undefined;
  interval: Observable<number> = interval(1000);
  intervalValue: number = 5;

  constructor(
    private dialog: MatDialogRef<SubscriptionRenewedDialogComponent>,
  ) {}

  ngAfterViewInit(): void {
    this.interval$ = this.interval.subscribe({
      next: () => {
        if (this.intervalValue === 1) {
          this.interval$?.unsubscribe();

          this.close();

          this.intervalValue = 5;
        } else {
          this.intervalValue = this.intervalValue - 1;
        }
      },
      error: (error: any) => console.error(error),
    });
  }

  ngOnDestroy(): void {
    [this.interval$].forEach(($: Subscription | undefined) => $?.unsubscribe());
  }

  close(): void {
    this.dialog.close();
  }
}
