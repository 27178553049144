import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "firstLetterUpperCase",
})
export class FirstLetterUpperCasePipe implements PipeTransform {
  transform(text: string, otherLowerCase = false): string {
    if (!text) return "";

    text = text.trim();
    let result: string;

    if (text.length == 1) {
      result = text.toUpperCase();
    } else {
      let firstLetter: string = text.substr(0, 1).toUpperCase();
      let otherLetters: string = text.substr(1, text.length - 1);

      if (otherLowerCase) otherLetters = otherLetters.toLowerCase();

      result = firstLetter + otherLetters;
    }

    return result;
  }
}
