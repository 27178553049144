import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SubscriptionsNew } from '../../models/subscriptions-new.model';
import { SubscriptionDialogService } from '../../services/dialog.service';
import { SubscriptionChangeService } from '../../services/subscription-change.service';
import { MeteredUsageInfo } from '../../../../premium-new/models/premium-new.model';

@Component({
  selector: 'app-subscription-cancel-dialog',
  templateUrl: './subscription-cancel-dialog.component.html',
})
export class SubscriptionCancelDialogComponent implements OnInit {
  nextPaymentDate: Date | undefined = undefined;

  leftDaysOfTrial: number = 0;

  nowDate: Date = new Date();

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      mySubscriptions: SubscriptionsNew;
      meteredUsageInfo: MeteredUsageInfo | undefined;
    },
    private dialog: MatDialogRef<SubscriptionCancelDialogComponent>,
    private dialogService: SubscriptionDialogService,
    private subscriptionChangeService: SubscriptionChangeService,
  ) {}

  // prettier-ignore
  ngOnInit(): void {
    this.nextPaymentDate = this.data.mySubscriptions.next_payment ? new Date(this.data.mySubscriptions.next_payment.date) : undefined;

    if (this.nextPaymentDate) {
      this.leftDaysOfTrial = Math.ceil((this.nextPaymentDate.getTime() - new Date().getTime()) / 1000 / 86400);
    }
  }

  close(): void {
    this.dialog.close();
  }

  // prettier-ignore
  onCancel(): void {
    const isFirstCancellation: boolean = !this.data.mySubscriptions.discounted_at;

    if (isFirstCancellation) {
      /** Открываем модалку с опросом */
      this.close();

      this.dialogService.openReasonsSubscription({ mySubscriptions: this.data.mySubscriptions });
    } else {
      this.subscriptionChangeService.currentChangeSubject.next('cancel');
    }
  }
}
