<svg class="like-button" (click)="clicked()" width="102" height="102" viewBox="0 0 102 102" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="51" cy="51" r="51" fill="#62C37D"/>
    <circle cx="51" cy="51" r="51" fill="url(#paint0_radial_407_1241)" fill-opacity="0.14"/>
    <g filter="url(#filter0_d_407_1241)">
    <path d="M30 50.375C30 49.8333 30.1072 49.2969 30.3155 48.7964C30.5239 48.296 30.8292 47.8412 31.2141 47.4582C31.5991 47.0751 32.056 46.7713 32.559 46.564C33.0619 46.3567 33.6009 46.25 34.1453 46.25C34.6897 46.25 35.2287 46.3567 35.7316 46.564C36.2346 46.7713 36.6915 47.0751 37.0765 47.4582C37.4614 47.8412 37.7667 48.296 37.9751 48.7964C38.1834 49.2969 38.2906 49.8333 38.2906 50.375V66.875C38.2906 67.969 37.8539 69.0182 37.0765 69.7918C36.2991 70.5654 35.2447 71 34.1453 71C33.0459 71 31.9915 70.5654 31.2141 69.7918C30.4367 69.0182 30 67.969 30 66.875V50.375ZM41.0541 49.9158V64.8482C41.0537 65.8703 41.3394 66.8723 41.8793 67.7418C42.4191 68.6113 43.1918 69.3139 44.1106 69.7707L44.2488 69.8395C45.7822 70.6022 47.4729 70.9995 49.1872 71H64.1545C65.4327 71.0005 66.6716 70.5601 67.6602 69.7539C68.6488 68.9476 69.326 67.8253 69.5766 66.578L72.8928 50.078C73.0531 49.2801 73.0334 48.4569 72.8353 47.6675C72.6372 46.8781 72.2656 46.1422 71.7472 45.513C71.2288 44.8838 70.5766 44.3769 69.8376 44.0288C69.0986 43.6807 68.2911 43.5001 67.4735 43.5H57.6353V32.5C57.6353 31.0413 57.053 29.6424 56.0165 28.6109C54.98 27.5795 53.5741 27 52.1083 27C51.3753 27 50.6724 27.2897 50.1542 27.8055C49.6359 28.3212 49.3447 29.0207 49.3447 29.75V31.5842C49.3447 33.9643 48.569 36.2802 47.1339 38.1842L43.265 43.3158C41.8299 45.2198 41.0541 47.5357 41.0541 49.9158Z" fill="white"/>
    </g>
    <defs>
    <filter id="filter0_d_407_1241" x="26" y="27" width="51" height="52" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="4"/>
    <feGaussianBlur stdDeviation="2"/>
    <feComposite in2="hardAlpha" operator="out"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_407_1241"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_407_1241" result="shape"/>
    </filter>
    <radialGradient id="paint0_radial_407_1241" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(29.5 19.5) rotate(65.2249) scale(57.2713)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    </defs>
</svg>    