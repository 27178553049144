import { Component, Input } from '@angular/core';
import { RiskRewardHeatmapDialogSectionModel } from '../../../../models/risk-reward-heatmap-dialog.model';

@Component({
  selector: 'app-risk-reward-heatmap-dialog-section',
  templateUrl: './risk-reward-heatmap-dialog-section.component.html',
  styleUrls: ['./risk-reward-heatmap-dialog-section.component.scss']
})
export class RiskRewardHeatmapDialogSectionComponent {

  @Input() model: RiskRewardHeatmapDialogSectionModel;

  constructor() { }

}
