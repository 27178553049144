import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, share } from 'rxjs';
import { MainHttpClient } from 'src/app/services/main-http-client.service';
import { environment } from '../../../../../environments/environment';
import { SubscriptionsPageResponse } from '../../../../interfaces/api/payment/subscriptions-page';
import { PremiumService } from '../../premium/services/premium.service';
import { TariffPriceInterval } from 'src/app/interfaces/api/payment/tariff/types/tariff-price-interval';

@Injectable()
export class SubscriptionsDataService {
  constructor(
    private http: MainHttpClient,
    private premiumService: PremiumService,
  ) {}

  async loadSubscriptionPage(): Promise<SubscriptionsPageResponse> {
    return this.http.post<SubscriptionsPageResponse>('/payment/subscriptions-page', {});
  }

  async unsubscribe(planId: string) {
    return this.premiumService.unsubscribe(planId);
  }

  async subscribe(planId: string, priceId: string, interval: TariffPriceInterval) {
    return this.premiumService.subscribe(planId, priceId, interval);
  }
}
