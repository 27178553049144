import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RegistrationRequest } from 'src/app/interfaces/api/auth/registration';
import { AnalyticService } from 'src/app/services/analytic.service';
import { EventsService, EventType } from 'src/app/services/events.service';
import { UtmService } from '../../shared/modules/utm/services/utm.service';
import { AuthService } from './auth.service';
import { RedirectUrlService } from './redirect-url.service';
import { LinkMinkService } from '../../affiliate/services/LinkMink.service';
import { TuneAffiliateService } from '../../affiliate/services/TuneAffiliateService';

type AffiliatePlatforms = NonNullable<RegistrationRequest['affiliateData']>;

@Injectable({
  providedIn: 'root',
})
export class RegistrationService {
  errorMessage?: string;

  private redirectUrl: string = '';

  constructor(
    private router: Router,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private utmService: UtmService,
    private analyicService: AnalyticService,
    private redirectUrlService: RedirectUrlService,
    private eventService: EventsService,
    private linkMinService: LinkMinkService,
    private tuneAffiliateService: TuneAffiliateService,
  ) {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.redirectUrl = this.redirectUrlService.getRedirectUrlContent(params);
    });
  }

  public async signUp(registrationData: RegistrationRequest) {
    registrationData.email = registrationData.email.toLocaleLowerCase();

    try {
      const utm = this.utmService.getUtmData();
      if (utm) {
        registrationData.utm = utm;
      }

      const referralData =
        this.linkMinService.getReferralDataFromLocalStorage();
      if (referralData) {
        registrationData.referralData = referralData;
      }

      const tuneAffiliateData = this.tuneAffiliateService.retrieveData();
      if (tuneAffiliateData) {
        this.addAffiliateDataToRequest(
          registrationData,
          tuneAffiliateData,
          'tune',
        );
      }

      const { userId } = await this.authService.registration(registrationData);
      this.tuneAffiliateService.clearData();
      this.linkMinService.clearReferralData();
      this.errorMessage = '';
      if (this.redirectUrl?.length > 0) {
        this.router.navigateByUrl(this.redirectUrl);
      }

      this.analyicService.send(
        'subscribe_success',
        new Map([['user_id', userId]]),
      );

      this.eventService.emit(EventType.UserTypeChanged);
    } catch (error: any) {
      this.errorMessage = error.message;
      throw new Error(error.message);
    }
  }

  private addAffiliateDataToRequest<
    AffiliatePlatform extends keyof AffiliatePlatforms,
    AffiliateData extends AffiliatePlatforms[AffiliatePlatform],
  >(
    registrationData: RegistrationRequest,
    affiliateData: AffiliateData,
    affiliatePlatform: AffiliatePlatform,
  ): void {
    if (!registrationData.affiliateData) {
      registrationData.affiliateData = {};
    }
    registrationData.affiliateData[affiliatePlatform] = affiliateData;
  }
}
