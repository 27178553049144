
<app-form-layout [formName]="'Reset Password'">

    <div class="reset-password-description">
      Please enter the email address you used to register, then we will
      send you a link tochoose a new password.
    </div>

    <div class="auth-form-text-field">
      <mat-form-field appearance="outline">
        <mat-label>E-mail</mat-label>
        <input matInput [(ngModel)]="email">
      </mat-form-field>
    </div>

    <div class="auth-form-error-message" *ngIf="errorMessage">
      {{ errorMessage }}
    </div>

    <button (click)="resetPassword()" class="auth-form-button">Send Reset Link</button>

    <div class="reset-password-go-to-sign-in auth-form-link" (click)="goToSignIn.emit()">
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.6667 7C11.6667 6.67783 11.4055 6.41666 11.0833 6.41666H2.91667C2.5945 6.41666 2.33333 6.67783 2.33333 7C2.33333 7.32216 2.5945 7.58333 2.91667 7.58333H11.0833C11.4055 7.58333 11.6667 7.32216 11.6667 7Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.41248 2.50419C7.18467 2.27638 6.81533 2.27638 6.58752 2.50419L2.50419 6.58752C2.27638 6.81533 2.27638 7.18468 2.50419 7.41248L6.58752 11.4958C6.81533 11.7236 7.18467 11.7236 7.41248 11.4958C7.64028 11.268 7.64028 10.8987 7.41248 10.6709L3.74162 7L7.41248 3.32915C7.64028 3.10134 7.64028 2.732 7.41248 2.50419Z" fill="currentColor"/>
      </svg>
      <div>Back to Sign In</div>
    </div>
</app-form-layout>
