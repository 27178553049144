import {
  ANIM_SCALE,
  ANIM_SCALE_Y,
} from './../../shared/modules/basic/animations/scale.animation';
import { HeaderService } from './services/header.service';
import { AfterViewInit, Component, HostBinding, OnInit } from '@angular/core';
import { UserTypeModel } from '../../shared/models/user-type.model';
import { EventsService, EventType } from 'src/app/services/events.service';
import { AuthService } from '../../auth/services/auth.service';
import { INavigationMenu } from '../../shared/modules/basic/structures/app';
import { AnalyticService } from 'src/app/services/analytic.service';
import { OnboardingHintEventService } from '../../shared/modules/basic/components/onboarding-hint/onboarding-hint-event.service';
import { OnboardingService } from '../sevices/onboarding.service';
import { PortfolioService } from '../../widgets/portfolio/services/portfolio.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [ANIM_SCALE, ANIM_SCALE_Y],
})
export class HeaderComponent implements AfterViewInit, OnInit {

  menuItems: Array<INavigationMenu> = [];

  private readonly onboardingService: OnboardingService;

  constructor(public headerService: HeaderService,
              private eventService: EventsService,
              private authService: AuthService,
              private analyticService: AnalyticService,
              private portfolioService: PortfolioService,
              private onboardingEventService: OnboardingHintEventService) {
    this.eventService.subscription().subscribe(async (type) => {
      if (type === EventType.UserTypeChanged) {
        this.userType = await this.authService.getUserType();
      } else if (type === EventType.NumberOfStocksInPortfolioChanged) {
        await this.onboardingService.updateNumberOfEvents(this.menuItems);
      }
    });

    this.menuItems = this.headerService.menus;
    this.onboardingService = new OnboardingService(
      this.authService,
      this.portfolioService,
      this.onboardingEventService,
      this.menuItems);
  }

  ngOnInit(): void {
    this.onboardingService.updateNumberOfEvents(this.menuItems);
  }

  itemClick(item: INavigationMenu) {
    if (!item.route?.includes("money-flow") && this.userType === UserTypeModel.Unregistered) {
      this.analyticService.send('click_sign_up', new Map([
        [ "name", item.text.default ],
      ]));
    }
    this.headerService.itemClick(item);
  }

  async ngAfterViewInit(): Promise<void> {
    this.userType = await this.authService.getUserType();
  }

  get contextMenu() {
    return this.headerService.contextMenu;
  }

  userType: UserTypeModel = UserTypeModel.Unregistered;

  get UserTypeModel(): typeof UserTypeModel {
    return UserTypeModel;
  }

  @HostBinding('class.z-up') get searchFocused() {
    return this.headerService.searchFocused;
  }

  @HostBinding('class.unregistered') get unregistred() {
    return this.userType === this.UserTypeModel.Unregistered;
  }
}
