import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, tap } from 'rxjs';

import { environment } from '@/environments/environment';
import {
  ChangeEmailDialogType,
  EmailAction,
  EmailActionResponse,
  EmailChangedResponse,
  ResetPasswordResponse,
  UserProfile,
  UserProfileResponse,
} from '../pages/settings/settings.model';

@Injectable({ providedIn: 'root' })
export class SettingsService {
  public readonly currentEmailToken$ = new BehaviorSubject<
    EmailAction['token'] | null
  >(null);
  public readonly newEmailToken$ = new BehaviorSubject<
    EmailAction['token'] | null
  >(null);
  public readonly latestEmailChangeDialogType$ =
    new BehaviorSubject<ChangeEmailDialogType>(
      ChangeEmailDialogType.CURRENT_EMAIL_CODE,
    );
  public readonly latestNewEmailValue$ = new BehaviorSubject<
    UserProfile['email']
  >('');

  public readonly userProfile$ = new BehaviorSubject<UserProfile | null>(null);

  constructor(private readonly http: HttpClient) {}

  getUserProfile(): Observable<UserProfileResponse> {
    return this.http
      .get<UserProfileResponse>(`${environment.apiMainUrl}/v2/user/profile`)
      .pipe(tap(({ data }) => this.userProfile$.next(data)));
  }

  changeName(name: UserProfile['name']): Observable<UserProfileResponse> {
    return this.http
      .post<UserProfileResponse>(
        `${environment.apiMainUrl}/v2/user/profile/change-name`,
        { name },
      )
      .pipe(
        tap(
          ({ data }) =>
            this.userProfile$.value &&
            this.userProfile$.next({
              ...this.userProfile$.value,
              name: data.name,
            }),
        ),
      );
  }

  changePassword(passwords: {
    current_password: string;
    new_password: string;
    new_password_confirmation: string;
  }): Observable<UserProfileResponse> {
    return this.http.post<UserProfileResponse>(
      `${environment.apiMainUrl}/v2/user/profile/change-password`,
      passwords,
    );
  }

  resetPassword(toCreate = false): Observable<ResetPasswordResponse> {
    return this.http
      .post<ResetPasswordResponse>(
        `${environment.apiMainUrl}/v2/auth/reset-password`,
        {
          email: this.userProfile$.value?.email ?? '',
        },
      )
      .pipe(
        tap(() => {
          if (toCreate && this.userProfile$.value) {
            this.userProfile$.next({
              ...this.userProfile$.value,
              is_password_specified: true,
            });
          }
        }),
      );
  }

  // Change Email

  sendCurrentConfirmationCode(): Observable<EmailActionResponse> {
    return this.http
      .post<EmailActionResponse>(
        `${environment.apiMainUrl}/v2/user/profile/change-email/send-current-confirmation-code`,
        {},
      )
      .pipe(tap(({ data }) => this.currentEmailToken$.next(data.token)));
  }

  confirmCurrent(code: string): Observable<EmailActionResponse> {
    return this.http.post<EmailActionResponse>(
      `${environment.apiMainUrl}/v2/user/profile/change-email/confirm-current`,
      {
        code,
        token: this.currentEmailToken$.value,
      },
    );
  }

  sendNewConfirmationCode(
    email: UserProfile['email'],
  ): Observable<EmailActionResponse> {
    return this.http
      .post<EmailActionResponse>(
        `${environment.apiMainUrl}/v2/user/profile/change-email/send-new-confirmation-code`,
        {
          email,
          token: this.currentEmailToken$.value,
        },
      )
      .pipe(tap(({ data }) => this.newEmailToken$.next(data.token)));
  }

  confirmNew(code: string): Observable<EmailChangedResponse> {
    return this.http
      .post<EmailChangedResponse>(
        `${environment.apiMainUrl}/v2/user/profile/change-email/confirm-new`,
        { code, token: this.newEmailToken$.value },
      )
      .pipe(
        tap(
          ({ data }) =>
            this.userProfile$.value &&
            this.userProfile$.next({
              ...this.userProfile$.value,
              email: data.user.email,
            }),
        ),
      );
  }
}
