<button
  (click)="changeSort()"
  [class.!text-default-secondary-2]="sortDirection !== 'none'"
  class="flex gap-1 w-full p-2 text-interface-caption-1-semibold text-default-neutral-1"
  [ngClass]="{
    'hover:text-active-neutral-1': !isDisabled,
    'no-hover': isDisabled,
    '!cursor-default': isDisabled
  }"
  type="button"
>
  <ng-content></ng-content>
  <svg
    class="ml-auto shrink-0"
    *ngIf="sortDirection === 'none'"
    fill="currentColor"
    height="20"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Arrows / down arrow long">
      <path
        class="icon-path"
        d="M14.7583 9.40833C14.6809 9.33023 14.5887 9.26823 14.4871 9.22592C14.3856 9.18362 14.2767 9.16183 14.1667 9.16183C14.0566 9.16183 13.9477 9.18362 13.8462 9.22592C13.7446 9.26823 13.6525 9.33023 13.575 9.40833L10.8333 12.1583V5.83333C10.8333 5.61232 10.7455 5.40036 10.5892 5.24408C10.433 5.0878 10.221 5 9.99999 5C9.77898 5 9.56702 5.0878 9.41074 5.24408C9.25446 5.40036 9.16666 5.61232 9.16666 5.83333V12.1583L6.42499 9.40833C6.26807 9.25141 6.05524 9.16326 5.83333 9.16326C5.61141 9.16326 5.39858 9.25141 5.24166 9.40833C5.08474 9.56525 4.99658 9.77808 4.99658 10C4.99658 10.2219 5.08474 10.4347 5.24166 10.5917L9.40833 14.7583C9.48758 14.8342 9.58103 14.8937 9.68333 14.9333C9.78308 14.9774 9.89093 15.0002 9.99999 15.0002C10.1091 15.0002 10.2169 14.9774 10.3167 14.9333C10.419 14.8937 10.5124 14.8342 10.5917 14.7583L14.7583 10.5917C14.8364 10.5142 14.8984 10.422 14.9407 10.3205C14.983 10.2189 15.0048 10.11 15.0048 10C15.0048 9.88999 14.983 9.78107 14.9407 9.67952C14.8984 9.57797 14.8364 9.4858 14.7583 9.40833Z"
        id="Vector"
      />
    </g>
  </svg>

  <svg
    class="ml-auto shrink-0"
    *ngIf="sortDirection !== 'none'"
    [class.-rotate-180]="sortDirection === 'asc'"
    fill="none"
    height="20"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      class="icon-path-active"
      d="M14.7583 9.40833C14.6809 9.33023 14.5887 9.26823 14.4871 9.22592C14.3856 9.18362 14.2767 9.16183 14.1667 9.16183C14.0566 9.16183 13.9477 9.18362 13.8462 9.22592C13.7446 9.26823 13.6525 9.33023 13.575 9.40833L10.8333 12.1583V5.83333C10.8333 5.61232 10.7455 5.40036 10.5892 5.24408C10.433 5.0878 10.221 5 9.99999 5C9.77898 5 9.56702 5.0878 9.41074 5.24408C9.25446 5.40036 9.16666 5.61232 9.16666 5.83333V12.1583L6.42499 9.40833C6.26807 9.25141 6.05524 9.16326 5.83333 9.16326C5.61141 9.16326 5.39858 9.25141 5.24166 9.40833C5.08474 9.56525 4.99658 9.77808 4.99658 10C4.99658 10.2219 5.08474 10.4347 5.24166 10.5917L9.40833 14.7583C9.48758 14.8342 9.58103 14.8937 9.68333 14.9333C9.78308 14.9774 9.89093 15.0002 9.99999 15.0002C10.1091 15.0002 10.2169 14.9774 10.3167 14.9333C10.419 14.8937 10.5124 14.8342 10.5917 14.7583L14.7583 10.5917C14.8364 10.5142 14.8984 10.422 14.9407 10.3205C14.983 10.2189 15.0048 10.11 15.0048 10C15.0048 9.88999 14.983 9.78107 14.9407 9.67952C14.8984 9.57797 14.8364 9.4858 14.7583 9.40833Z"
    />
  </svg>
</button>
