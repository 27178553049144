import { SubscriptionModel } from '../../models/subscription.model';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-subscription-switch',
  templateUrl: './subscription-switch.component.html',
  styleUrls: ['./subscription-switch.component.scss'],
})
export class SubscriptionSwitchComponent {
  @Input() data?: SubscriptionModel.IAnnualPayment;
  @Output() event = new EventEmitter<SubscriptionModel.IEvent>();

  constructor() {}

  payClick() {
    if (this.data)
      this.event.emit({
        type: this.data.active
          ? SubscriptionModel.EventType.Unsubscribe
          : SubscriptionModel.EventType.Subscribe,
        plan: SubscriptionModel.Plan.Annual,
        planData: {
          planId: this.data.planId,
          priceId: this.data.priceId,
          interval: this.data.interval,
          plan: SubscriptionModel.Plan.Annual,
          name: "",
          options: []
        }
      });
  }
}
