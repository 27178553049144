<div class="block" appSnackbar></div>
<div
  (resized)="onResize()"
  class="app-container"
  [ngStyle]="{ 'margin-top': marginTop + 'px' }"
>
  <!--  <app-header></app-header>-->
  <!-- VS-371 -->
  <app-header-new></app-header-new>

  <div class="app-body-content">
    <router-outlet></router-outlet>
    <!-- <app-prototype-manager></app-prototype-manager> -->
  </div>
  <app-footer></app-footer>
</div>
<app-like-button></app-like-button>
<!-- TERN DEVICE -->
<div
  *ngIf="showTurnDevice && !isBlockedShowTurnDevice"
  class="fixed left-1/2 bottom-16 -translate-x-1/2 flex md:hidden justify-center w-screen max-w-[376px] !p-4 z-[1001]"
>
  <div
    class="relative flex items-center !gap-4 w-full h-28 !p-4 rounded bg-[#E4EAF1]"
  >
    <button
      class="absolute right-4 top-4"
      (click)="isBlockedShowTurnDevice = true"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="21"
        viewBox="0 0 20 21"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8.94573 10.7441L5 6.79841L6.05426 5.74414L10 9.68988L13.9457 5.74414L15 6.79841L11.0543 10.7441L15 14.6899L13.9457 15.7441L10 11.7984L6.05426 15.7441L5 14.6899L8.94573 10.7441Z"
          fill="#393939"
        />
      </svg>
    </button>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="81"
      viewBox="0 0 80 81"
      fill="none"
    >
      <path
        d="M23.4639 30.0087C24.7119 21.2223 38.7182 14.7226 50.5713 13.5339C66.0189 11.3956 74.2747 25.5394 75.7145 36.3373C77.5102 49.8039 69.1125 67.1457 43.6048 68.026C14.6368 69.0256 2.28228 57.6554 1.89224 51.4775C1.5022 45.2995 21.9038 40.9916 23.4639 30.0087Z"
        fill="#F8FAFC"
      />
      <rect
        x="66.8281"
        y="25.0078"
        width="32"
        height="59"
        rx="6"
        transform="rotate(90 66.8281 25.0078)"
        fill="white"
      />
      <rect x="10.3828" y="27.9961" width="53.9775" height="4" fill="#F1F5F9" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M63.8281 31.0068V51.0068C63.8281 52.6637 62.485 54.0068 60.8281 54.0068H13.8281C12.1713 54.0068 10.8281 52.6637 10.8281 51.0068V44.7048H7.82836V37.318H10.8281V31.0068C10.8281 29.35 12.1713 28.0068 13.8281 28.0068L60.8281 28.0068C62.485 28.0068 63.8281 29.35 63.8281 31.0068ZM60.8281 25.0068C64.1418 25.0068 66.8281 27.6931 66.8281 31.0068V51.0068C66.8281 54.3205 64.1418 57.0068 60.8281 57.0068H13.8281C10.5144 57.0068 7.82812 54.3205 7.82812 51.0068L7.82813 31.0068C7.82813 27.6931 10.5144 25.0068 13.8281 25.0068H60.8281Z"
        fill="#393939"
      />
      <path
        d="M57.8281 35.0068C59.485 35.0068 60.8281 36.35 60.8281 38.0068L60.8281 44.0068C60.8281 45.6637 59.485 47.0068 57.8281 47.0068C56.1713 47.0068 54.8281 45.6637 54.8281 44.0068L54.8281 38.0068C54.8281 36.35 56.1713 35.0068 57.8281 35.0068Z"
        fill="#45CF95"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M59.3281 44.0068L59.3281 38.0068C59.3281 37.1784 58.6566 36.5068 57.8281 36.5068C56.9997 36.5068 56.3281 37.1784 56.3281 38.0068L56.3281 44.0068C56.3281 44.8353 56.9997 45.5068 57.8281 45.5068C58.6566 45.5068 59.3281 44.8353 59.3281 44.0068ZM60.8281 38.0068C60.8281 36.35 59.485 35.0068 57.8281 35.0068C56.1713 35.0068 54.8281 36.35 54.8281 38.0068L54.8281 44.0068C54.8281 45.6637 56.1713 47.0068 57.8281 47.0068C59.485 47.0068 60.8281 45.6637 60.8281 44.0068L60.8281 38.0068Z"
        fill="#393939"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M51.1095 65.6874L51.1095 67.7294C51.1082 68.0525 51.2357 68.363 51.464 68.5924C51.6923 68.8219 52.0027 68.9516 52.327 68.953C52.6512 68.9543 52.9627 68.8273 53.193 68.5998C53.4233 68.3722 53.5534 68.0628 53.5548 67.7397L53.5781 62.2567C53.5795 61.9335 53.452 61.6231 53.2237 61.3936C52.9954 61.1641 52.6849 61.0344 52.3607 61.033L46.859 61.0098C46.5347 61.0084 46.2232 61.1355 45.9929 61.363C45.7627 61.5905 45.6325 61.8999 45.6312 62.2231C45.6298 62.5462 45.7573 62.8567 45.9856 63.0861C46.2139 63.3156 46.5243 63.4453 46.8486 63.4467L49.1029 63.4562C45.6971 66.4502 41.3163 67.7739 36.7909 67.7739C30.879 67.7739 26.454 65.8317 23.6051 63.1563C23.0012 62.5892 22.052 62.619 21.4849 63.2229C20.9177 63.8268 20.9476 64.7761 21.5514 65.3432C25.0193 68.5999 30.2066 70.7739 36.7909 70.7739C41.9093 70.7739 47.0592 69.2629 51.1095 65.6874Z"
        fill="#393939"
      />
      <path
        d="M23.5467 16.3328V14.2908C23.5481 13.9677 23.4206 13.6572 23.1923 13.4278C22.964 13.1983 22.6535 13.0686 22.3293 13.0672C22.005 13.0659 21.6935 13.1929 21.4632 13.4204C21.233 13.648 21.1029 13.9574 21.1015 14.2805L21.0781 19.7635C21.0768 20.0867 21.2043 20.3971 21.4326 20.6266C21.6609 20.8561 21.9713 20.9858 22.2956 20.9872L27.7973 21.0104C28.1216 21.0118 28.4331 20.8847 28.6633 20.6572C28.8936 20.4297 29.0237 20.1203 29.0251 19.7971C29.0265 19.474 28.899 19.1635 28.6707 18.9341C28.4424 18.7046 28.1319 18.5749 27.8077 18.5735L25.5532 18.564C28.9592 15.5698 33.34 14.2461 37.8656 14.2461C43.7775 14.2461 48.2025 16.1882 51.0513 18.8636C51.6552 19.4307 52.6045 19.4009 53.1716 18.797C53.7387 18.1931 53.7089 17.2438 53.105 16.6767C49.6372 13.4201 44.4499 11.2461 37.8656 11.2461C32.7471 11.2461 27.597 12.7571 23.5467 16.3328Z"
        fill="#393939"
      />
    </svg>
    <p class="text-sm font-semibold text-[#393939] max-w-[180px]">
      Rotate your device horizontally for better viewing
    </p>
  </div>
</div>
<app-agreement-cookie></app-agreement-cookie>
