<div class="flex items-center justify-center" [ngStyle]="{ height }">
  <div class="flex animate-spin">
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_4427_53074)">
        <circle
          cx="12.5"
          cy="2.25"
          r="2.25"
          fill="#393939"
          fill-opacity="0.1"
        />
        <circle
          opacity="0.45"
          cx="12.5"
          cy="21.75"
          r="2.25"
          fill="#393939"
          fill-opacity="0.65"
        />
        <circle cx="2.75" cy="12" r="2.25" fill="#393939" fill-opacity="0.85" />
        <circle
          cx="22.25"
          cy="12"
          r="2.25"
          fill="#393939"
          fill-opacity="0.35"
        />
        <circle cx="20" cy="5.25" r="2.25" fill="#393939" fill-opacity="0.25" />
        <circle
          cx="20"
          cy="18.75"
          r="2.25"
          fill="#393939"
          fill-opacity="0.45"
        />
        <circle cx="5" cy="18.75" r="2.25" fill="#393939" fill-opacity="0.75" />
        <circle cx="5" cy="5.25" r="2.25" fill="#393939" />
      </g>
      <defs>
        <clipPath id="clip0_4427_53074">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  </div>
</div>
