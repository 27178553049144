import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
} from '@angular/core';
import { map, Observable, startWith, takeWhile, tap, timer } from 'rxjs';

import { SettingsService } from '@main/profile/services/settings.service';

@Component({
  selector: 'profile-email-changed-successfully',
  templateUrl: './email-changed-successfully.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmailChangedSuccessfullyComponent {
  @Output() next = new EventEmitter<void>();

  public readonly email$: Observable<string> =
    this.settingsService.userProfile$.pipe(map((user) => user?.email ?? ''));

  public readonly closeTime$: Observable<number> = timer(0, 1000).pipe(
    map((value) => 5 - value), // 5 sec
    takeWhile((value) => value >= 0, false),
    startWith(5),
    tap((time) => {
      if (!time) {
        this.next.emit();
        this.settingsService.latestNewEmailValue$.next('');
      }
    }),
  );

  constructor(private readonly settingsService: SettingsService) {}
}
