import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { UserProfile } from '../settings.model';
import { ChangeEmailDialogComponent } from './dialog/dialog.component';

@Component({
  selector: 'profile-settings-change-email',
  templateUrl: './change-email.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeEmailComponent {
  @Input() loginViaGoogle = false;
  @Input() email: UserProfile['email'] = '';

  constructor(private readonly dialog: MatDialog) {}

  openChangeEmailDialog(): void {
    this.dialog.open(ChangeEmailDialogComponent, {
      data: { email: this.email },
    });
  }
}
