import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  BehaviorSubject,
  catchError,
  debounceTime,
  finalize,
  map,
  Observable,
  of,
  startWith,
  Subject,
  switchMap,
  takeUntil,
  takeWhile,
  tap,
  timer,
} from 'rxjs';

import { HelperService } from '@app/services/helper.service';
import {
  EmailAction,
  UserProfile,
} from '@main/profile/pages/settings/settings.model';
import { SettingsService } from '@main/profile/services/settings.service';
import { CODE_LENGTH } from '../dialog.component';

@Component({
  selector: 'profile-enter-current-email-code',
  templateUrl: './enter-current-email-code.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnterCurrentEmailCodeComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() email: UserProfile['email'] = '';

  @Output() next = new EventEmitter<void>();
  @Output() gotToken = new EventEmitter<EmailAction['token']>();

  private readonly destroy$ = new Subject<void>();

  public readonly codeForm = new FormGroup({
    code: new FormControl('', {
      validators: [
        Validators.required,
        Validators.minLength(CODE_LENGTH),
        Validators.maxLength(CODE_LENGTH),
      ],
    }),
  });

  public token: EmailAction['token'] = '';

  public readonly sendCode$ = new Subject<void>();
  public readonly resendTime$: Observable<number> = this.sendCode$.pipe(
    switchMap(() =>
      timer(0, 1000).pipe(
        map((value) => 60 - value), // 60 sec
        takeWhile((value) => value >= 0),
      ),
    ),
    startWith(60),
  );
  public readonly formIsSubmitting$ = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly helperService: HelperService,
    private readonly settingsService: SettingsService,
  ) {}

  ngOnInit(): void {
    const codeControl = this.codeForm.get('code');

    if (codeControl) {
      codeControl.valueChanges
        .pipe(
          debounceTime(500),
          tap(
            (code: string) =>
              code?.trim()?.length === CODE_LENGTH &&
              !this.formIsSubmitting$.value &&
              this.submit(),
          ),
          takeUntil(this.destroy$),
        )
        .subscribe();
    }
  }

  // TODO move request to template when update angular
  ngAfterViewInit(): void {
    this.sendCode();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  sendCode(): void {
    this.settingsService
      .sendCurrentConfirmationCode()
      .pipe(
        tap(({ data }) => {
          this.token = data.token;
          this.gotToken.emit(this.token);
          this.sendCode$.next();
        }),
        catchError(() => {
          this.sendCode$.next();
          return of(null);
        }),
      )
      .subscribe();
  }

  submit(): void {
    if (this.helperService.getFormValidation(this.codeForm)) {
      this.formIsSubmitting$.next(true);
      this.settingsService
        .confirmCurrent(this.codeForm.get('code')?.value?.trim())
        .pipe(
          tap(() => this.next.emit()),
          catchError(() => of(null)),
          finalize(() => this.formIsSubmitting$.next(false)),
        )
        .subscribe();
    }
  }
}
