import {
  Component,
  Inject,
  Input,
  LOCALE_ID,
  ViewEncapsulation,
} from '@angular/core';
import * as d3 from 'd3-selection';
import * as d3Selection from 'd3-selection';
import { formatNumber } from '@angular/common';
import { EventTimeLineDetailsDataProjection } from '@main/events-timeline/models/event-timeline-details.model';

// prettier-ignore
@Component({
  selector: 'shared-snapshot-setup, [sharedSnapshotSetup]',
  templateUrl: './snapshot-setup.component.html',
  styleUrls: ['./snapshot-setup.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SnapshotSetupComponent {
  @Input()
  set appEventTimeLineDetailsDataProjection(eventTimeLineDetailsDataProjection: EventTimeLineDetailsDataProjection[]) {
    this.eventTimeLineDetailsDataProjection = eventTimeLineDetailsDataProjection;

    this.eventTimeLineDetailsDataProjection.length && this.setSvg();
  }

  eventTimeLineDetailsDataProjection: EventTimeLineDetailsDataProjection[] = [];

  svg: d3.Selection<SVGSVGElement, unknown, HTMLElement, any>;
  svgSelector: string = '#events-timeline-event-details-setup';

  svgWidth: number = 0;
  svgHeight: number = 0;

  heightRow: number = 40;
  heightGap: number = 20;
  heightStartY: number = 100;

  widthCenterSpace: number = 144;
  widthBetsSpace: number = 70;

  yPositionPriceList: number[] = [];

  constructor(
    @Inject(LOCALE_ID)
    private locale: string
  ) {}

  setSvg(): void {
    const height = (): number => {
      const m: number = this.eventTimeLineDetailsDataProjection.length;

      return this.heightStartY + ((this.heightRow + this.heightGap) * m);
    };

    this.svgHeight = height();
    this.svgWidth = 958;

    d3Selection.select(this.svgSelector).select('svg').remove();

    this.svg = d3Selection
      .select(this.svgSelector)
      .append('svg')
      .attr('viewBox', `0 0 ${this.svgWidth} ${this.svgHeight}`);

    /** SETUP */
    this.setYPositionPriceList(); // получаем Y - координаты для рядов

    /** HEADER */
    this.setHeader();

    /** PRICE ROWS */
    this.setRows();

    /** PRICE CENTER TEXT */
    this.setRowsCenter();
  }

  setYPositionPriceList(): void {
    this.yPositionPriceList = [];

    for (let i: number = 0; i < this.eventTimeLineDetailsDataProjection.length; i++) {
      this.yPositionPriceList.push(i === 0 ? this.heightStartY : this.heightStartY + (i * (this.heightRow + this.heightGap)))
    }
  }

  setHeader(): void {
    /** ЗАШТРИХОВАННЫЙ ФОН */
    this.svg
      .append('defs')
      .attr('id', 'defs-strokes')
      .append('pattern')
      .attr('id', 'pattern-strokes')
      .attr('patternContentUnits', 'objectBoundingBox')
      .attr('width', 1)
      .attr('height', 1)
      .append('use')
      .attr('xlink:href', '#image-strokes')
      .attr('transform', 'scale(0.0078125)')

    this.svg
      .select('#defs-strokes')
      .append('image')
      .attr('id', 'image-strokes')
      .attr('width', 128)
      .attr('height', 128)
      .attr('xlink:href', 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIAAAACACAYAAADDPmHLAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAGoSURBVHgB7dGxjQNBDARBZvqbfxIvBSBHzvKELgK0G6iZ+f7+3v//4c/cOf3FPnzjwy/24RsffrEP3/jwi334xodf7MM3PvxiH77x4Uf78ON9+PE+/HgffrwPP96HP3fuqf3V+Jk7p7/Yh298+MU+fOPDL/bhGx9+sQ/f+PCLffjGh1/swzc+/GIfvvHhF/vwjQ+/2IdvfPjFPnzjwy/24RsffrEP3/jwi3347T78eB9+vA8/3ocf78OP9+HPnXtqfzV+5s7pL/bhGx9+sQ/f+PCLffjGh1/swzc+/GIfvvHhF/vwjQ+/2IdvfPjFPnzjwy/24RsffrEP3/jwi334xodf7MM3PvxiH367Dz/ehx/vw4/34cf78ON9+HPnntpfjZ+5c/qLffjGh1/swzc+/GIfvvHhF/vwjQ+/2IdvfPjFPnzjwy/24RsffrEP3/jwi334xodf7MM3PvxiH77x4Rf78I0Pv9iH3+7Dj/fhx/vw43348T78eB/+3Lmn9lfjZ+6c/mIfvvHhF/vwjQ+/2IdvfPjFPnzjwy/24RsffrEP3/jwf73/Aud+dKZmN4lbAAAAAElFTkSuQmCC')

    /** TOP LEFT */
    this.svg
      .append('text')
      .attr('x', this.svgWidth / 2 - this.widthCenterSpace / 2)
      .attr('y', 18)
      .attr('text-anchor', 'end')
      .style('fill', '#333')
      .style('font-family', 'Averta CY')
      .style('font-size', '16px')
      .style('font-weight', 600)
      .style('letter-spacing', '-0.08px')
      .text('Decline Bets, contracts');

    this.svg
      .append('text')
      .attr('x', this.svgWidth / 2 - this.widthCenterSpace / 2)
      .attr('y', 46)
      .attr('text-anchor', 'end')
      .style('fill', '#F16D6A')
      .style('font-family', 'B612 Mono')
      .style('font-size', '12px')
      .style('font-weight', 400)
      .text('5d change');

    const pointDecline: number = this.svgWidth / 2 - this.widthCenterSpace / 2 - 100;

    this.svg
      .append('rect')
      .attr('x', pointDecline)
      .attr('y', 32)
      .attr('width', 20)
      .attr('height', 20)
      .attr('fill', '#F0D2D1');

    this.svg
      .append('rect')
      .attr('x', pointDecline)
      .attr('y', 32)
      .attr('width', 20)
      .attr('height', 20)
      .attr('fill', 'url(#pattern-strokes)')
      .attr('fill-opacity', 0.2);

    this.svg
      .append('path')
      .attr('d', `M${pointDecline} 32 H ${pointDecline + 20} L ${pointDecline} 52 V 32`)
      .attr('fill', '#F16D6A');

    this.svg
      .append('text')
      .attr('x', this.svgWidth / 2 - this.widthCenterSpace / 2 - 120)
      .attr('y', 46)
      .attr('text-anchor', 'end')
      .style('fill', '#3E3E3E')
      .style('font-family', 'B612 Mono')
      .style('font-size', '12px')
      .style('font-weight', 400)
      .text('Total contracts');

    this.svg
      .append('rect')
      .attr('x', this.svgWidth / 2 - this.widthCenterSpace / 2 - 20 - 245)
      .attr('y', 32)
      .attr('width', 20)
      .attr('height', 20)
      .attr('fill', '#F16D6A33');

    /** TOP RIGHT */
    this.svg
      .append('text')
      // 24 - width rect, 12 space
      .attr('x', this.svgWidth / 2 + this.widthCenterSpace / 2)
      .attr('y', 18)
      .style('fill', '#333')
      .style('font-family', 'Averta CY')
      .style('font-size', '16px')
      .style('font-weight', 600)
      .style('letter-spacing', '-0.08px')
      .text('Growth Bets, contracts');

    this.svg
      .append('rect')
      .attr('x', this.svgWidth / 2 + this.widthCenterSpace / 2)
      .attr('y', 32)
      .attr('width', 20)
      .attr('height', 20)
      .attr('fill', '#41B1A633');

    this.svg
      .append('text')
      .attr('x', this.svgWidth / 2 + this.widthCenterSpace / 2 + 32)
      .attr('y', 46)
      .attr('text-anchor', 'start')
      .style('fill', '#3E3E3E')
      .style('font-family', 'B612 Mono')
      .style('font-size', '12px')
      .style('font-weight', 400)
      .text('Total contracts');

    const pointGrowth: number = this.svgWidth / 2 + this.widthCenterSpace / 2 + 170;

    this.svg
      .append('rect')
      .attr('x', pointGrowth)
      .attr('y', 32)
      .attr('width', 20)
      .attr('height', 20)
      .attr('fill', '#CADFDD');

    this.svg
      .append('rect')
      .attr('x', pointGrowth)
      .attr('y', 32)
      .attr('width', 20)
      .attr('height', 20)
      .attr('fill', 'url(#pattern-strokes)')
      .attr('fill-opacity', 0.2);

    this.svg
      .append('path')
      .attr('d', `M${pointGrowth} 32 H ${pointGrowth + 20} L ${pointGrowth} 52 V 32`)
      .attr('fill', '#41B1A6');

    this.svg
      .append('text')
      .attr('x', this.svgWidth / 2 + this.widthCenterSpace / 2 + 202)
      .attr('y', 46)
      .attr('text-anchor', 'start')
      .style('fill', '#41B1A6')
      .style('font-family', 'B612 Mono')
      .style('font-size', '12px')
      .style('font-weight', 400)
      .text('5d change');

    /** TOP PRICE */
    this.svg
      .append('text')
      .attr('x', this.svgWidth / 2)
      .attr('y', 80)
      .attr('text-anchor', 'middle')
      .style('fill', '#717579')
      .style('font-family', 'B612 Mono')
      .style('font-size', '10px')
      .style('font-weight', 700)
      .style('letter-spacing', '1px')
      .text('PRICE');
  }

  setRows(): void {
    /**
     * Вычисляем максимальную длину полоски в пикселях и отнимаем оттуда зарезервированное место для цифр
     */

    const maxWidth: number = ((this.svgWidth - this.widthCenterSpace) / 2) - this.widthBetsSpace;

    /**
     * Вычисляем максимальные цены
     */

    const maxGrowBets: number = Math.max(...this.eventTimeLineDetailsDataProjection.map((eventTimeLineDetailsDataProjection: EventTimeLineDetailsDataProjection) => {
      if (eventTimeLineDetailsDataProjection.growBets5d < 0) {
        return eventTimeLineDetailsDataProjection.growBets + Math.abs(eventTimeLineDetailsDataProjection.growBets5d)
      }

      return eventTimeLineDetailsDataProjection.growBets;
    }));

    const maxDeclineBets: number = Math.max(...this.eventTimeLineDetailsDataProjection.map((eventTimeLineDetailsDataProjection: EventTimeLineDetailsDataProjection) => {
      if (eventTimeLineDetailsDataProjection.declineBets5d < 0) {
        return eventTimeLineDetailsDataProjection.declineBets + Math.abs(eventTimeLineDetailsDataProjection.declineBets5d)
      }

      return eventTimeLineDetailsDataProjection.declineBets;
    }));

    this.yPositionPriceList.forEach((yPosition: number, index: number) => {
      /** LEFT SIDE */

      const percentDeclineBets: number = (this.eventTimeLineDetailsDataProjection[index].declineBets / maxDeclineBets) * 100;
      const percentDeclineBets5d: number = (this.eventTimeLineDetailsDataProjection[index].declineBets5d / maxDeclineBets) * 100;

      let widthDeclineBets: number = isFinite(percentDeclineBets) ? maxWidth * Math.abs((percentDeclineBets / 100)) : 0;
      let widthDeclineBets5d: number = isFinite(percentDeclineBets5d) ? maxWidth * Math.abs((percentDeclineBets5d / 100)) : 0;

      // Set minimum width

      if (widthDeclineBets > 0 && widthDeclineBets < 4) {
        widthDeclineBets = 4;
      }

      if (widthDeclineBets5d > 0 && widthDeclineBets5d < 4) {
        widthDeclineBets5d = 4;
      }

      // pale
      this.svg
        .append('rect')
        .attr('x', this.svgWidth / 2 - this.widthCenterSpace / 2)
        .attr('y', yPosition)
        .attr('width', widthDeclineBets)
        .attr('height', this.heightRow)
        .attr('fill', '#F16D6A33')
        .attr('transform', `translate(-${widthDeclineBets},0)`);

      // bright
      this.svg
        .append('rect')
        .attr('x', this.svgWidth / 2 - this.widthCenterSpace / 2)
        .attr('y', yPosition)
        .attr('width', widthDeclineBets5d)
        .attr('height', this.heightRow)
        .attr('fill', () => percentDeclineBets5d < 0 ? '#F0D2D1' : '#F16D6A')
        .attr('transform', `translate(-${widthDeclineBets5d + (percentDeclineBets5d < 0 ? widthDeclineBets : 0)},0)`)

      /** ШТРИХ */
      if (percentDeclineBets5d < 0) {
        this.svg
          .append('rect')
          .attr('x', this.svgWidth / 2 - this.widthCenterSpace / 2)
          .attr('y', yPosition)
          .attr('width', widthDeclineBets5d)
          .attr('height', this.heightRow)
          .attr('transform', `translate(-${widthDeclineBets5d + (percentDeclineBets5d < 0 ? widthDeclineBets : 0)},0)`)
          .attr('fill', 'url(#pattern-strokes-decline-' + index + ')')
          .attr('fill-opacity', 0.2);

        this.svg
          .append('defs')
          .attr('id', 'defs-strokes-decline-' + index)
          .append('pattern')
          .attr('id', 'pattern-strokes-decline-' + index)
          .attr('patternContentUnits', 'objectBoundingBox')
          .attr('width', 0.64 / (widthDeclineBets5d / 40))
          .attr('height', 0.64)
          .append('use')
          .attr('xlink:href', '#image-strokes')
          .attr('transform', `scale(${0.005 / (widthDeclineBets5d / 40)} 0.005)`);
      }

      // text
      // prettier-ignore
      this.svg
        .append("text")
        .attr("x", () => {
          let row: number = widthDeclineBets < widthDeclineBets5d ? widthDeclineBets5d : widthDeclineBets;

          if (percentDeclineBets5d < 0) {
            row = row + widthDeclineBets5d;
          }

          return this.svgWidth / 2 - this.widthCenterSpace / 2 - row - 8;
        })
        .attr("y", yPosition + this.heightRow - 24)
        .attr("text-anchor", "end")
        .style("fill", "#3E3E3E")
        .style("font-family", "B612 Mono")
        .style("font-size", '12px')
        .style("font-weight", 400)
        .text(() => {
          return formatNumber(this.eventTimeLineDetailsDataProjection[index].declineBets, this.locale, '1.0-0');
        });

      // text
      // prettier-ignore
      this.svg
        .append("text")
        .attr("x", () => {
          let row: number = widthDeclineBets < widthDeclineBets5d ? widthDeclineBets5d : widthDeclineBets;

          if (percentDeclineBets5d < 0) {
            row = row + widthDeclineBets5d;
          }

          return this.svgWidth / 2 - this.widthCenterSpace / 2 - row - 8;
        })
        .attr("y", yPosition + this.heightRow - 8)
        .attr("text-anchor", "end")
        .style("fill", "#F16D6A")
        .style("font-family", "B612 Mono")
        .style("font-size", '12px')
        .style("font-weight", 400)
        .text(() => {
          return formatNumber(this.eventTimeLineDetailsDataProjection[index].declineBets5d, this.locale, '1.0-0');
        });

      /** RIGHT SIDE */

      const percentGrowBets: number = (this.eventTimeLineDetailsDataProjection[index].growBets / maxGrowBets) * 100;
      const percentGrowBets5d: number = (this.eventTimeLineDetailsDataProjection[index].growBets5d / maxGrowBets) * 100;

      let widthGrowBets: number = isFinite(percentDeclineBets) ? maxWidth * Math.abs((percentGrowBets / 100)) : 0;
      let widthGrowBets5d: number = isFinite(percentGrowBets5d) ? maxWidth * Math.abs((percentGrowBets5d / 100)) : 0;

      // Set minimum width

      if (widthGrowBets > 0 && widthGrowBets < 4) {
        widthGrowBets = 4;
      }

      if (widthGrowBets5d > 0 && widthGrowBets5d < 4) {
        widthGrowBets5d = 4;
      }

      // pale
      this.svg
        .append('rect')
        .attr('x', this.svgWidth / 2 + this.widthCenterSpace / 2)
        .attr('y', yPosition)
        .attr('width', widthGrowBets)
        .attr('height', this.heightRow)
        .attr('fill', '#41B1A633');

      // bright
      this.svg
        .append('rect')
        .attr('x', this.svgWidth / 2 + this.widthCenterSpace / 2)
        .attr('y', yPosition)
        .attr('width', widthGrowBets5d)
        .attr('height', this.heightRow)
        .attr('fill', () => percentGrowBets5d < 0 ? '#CADFDD' : '#41B1A6')
        .attr('transform', `translate(${percentGrowBets5d < 0 ? widthGrowBets : 0},0)`);

      /** ШТРИХ */
      if (percentGrowBets5d < 0) {
        this.svg
          .append('rect')
          .attr('x', this.svgWidth / 2 + this.widthCenterSpace / 2)
          .attr('y', yPosition)
          .attr('width', widthGrowBets5d)
          .attr('height', this.heightRow)
          .attr('transform', `translate(${percentGrowBets5d < 0 ? widthGrowBets : 0},0)`)
          .attr('fill', 'url(#pattern-strokes-grow-' + index + ')')
          .attr('fill-opacity', 0.2);

        this.svg
          .append('defs')
          .attr('id', 'defs-strokes-grow-' + index)
          .append('pattern')
          .attr('id', 'pattern-strokes-grow-' + index)
          .attr('patternContentUnits', 'objectBoundingBox')
          .attr('width', 0.64 / (widthGrowBets5d / 40))
          .attr('height', 0.64)
          .append('use')
          .attr('xlink:href', '#image-strokes')
          .attr('transform', `scale(${0.005 / (widthGrowBets5d / 40)} 0.005)`);
      }

      // text
      // prettier-ignore
      this.svg
        .append("text")
        .attr("x", () => {
          let row: number = widthGrowBets < widthGrowBets5d ? widthGrowBets5d : widthGrowBets;

          if (percentGrowBets5d < 0) {
            row = row + widthGrowBets5d;
          }

          return this.svgWidth / 2 + this.widthCenterSpace / 2 + row + 8
        })
        .attr("y", yPosition + this.heightRow - 24)
        .style("fill", "#3E3E3E")
        .style("font-family", "B612 Mono")
        .style("font-size", '12px')
        .style("font-weight", 400)
        .text(() => {
          return formatNumber(this.eventTimeLineDetailsDataProjection[index].growBets, this.locale, '1.0-0');
        });

      // text
      // prettier-ignore
      this.svg
        .append("text")
        .attr("x", () => {
          let row: number = widthGrowBets < widthGrowBets5d ? widthGrowBets5d : widthGrowBets;

          if (percentGrowBets5d < 0) {
            row = row + widthGrowBets5d;
          }

          return this.svgWidth / 2 + this.widthCenterSpace / 2 + row + 8;
        })
        .attr("y", yPosition + this.heightRow - 8)
        .style("fill", "#41B1A6")
        .style("font-family", "B612 Mono")
        .style("font-size", '12px')
        .style("font-weight", 400)
        .text(() => {
          return formatNumber(this.eventTimeLineDetailsDataProjection[index].growBets5d, this.locale, '1.0-0');
        });
    });
  }

  setRowsCenter(): void {
    this.yPositionPriceList.forEach((yPosition: number, index: number) => {
      const [x, y]: number[] = [this.svgWidth / 2, yPosition];

      const labelKeys: string[] = ['isResistance', 'isSupport', 'isCurrent'];
      const labelValues: string[] = ['Main Resistance', 'Main Support', 'Current Price'];

      // @ts-ignore
      const labelList: string[] = labelValues.filter((_: string, i: number) => this.eventTimeLineDetailsDataProjection[index][labelKeys[i]]);

      /**
       * Корректируем Y цены в зависимости от кол-ва лейблов
       */

      this.svg
        .append('text')
        .attr('x', x)
        .attr('y', (y + 24) - (6 * labelList.length))
        .attr('text-anchor', 'middle')
        .style('fill', '#000')
        .style('font-family', 'B612 Mono')
        .style('font-size', '12px')
        .style('font-weight', 400)
        .text(this.eventTimeLineDetailsDataProjection[index].price);

      labelList.forEach((label: string, j: number) => {
        this.svg
          .append("text")
          .attr("x", x)
          .attr("y", (y + 32 + 12 * (j + 1)) - (10 * labelList.length))
          .attr("text-anchor", "middle")
          .style("fill", "#7C7C7C")
          .style("font-family", "B612 Mono")
          .style("font-size", '10px')
          .style("font-weight", 400)
          .text(label);
      });
    });
  }
}
