<div
  class="fixed top-[0px] md:top-1/2 left-1/2 -translate-x-1/2 md:-translate-y-1/2 flex flex-col bg-white h-screen md:h-auto overflow-y-auto scrollbar-y w-screen md:w-[calc(100vw-48px)] md:max-w-[480px] md:rounded-2xl md:shadow-shadow-modal-shadow z-[3]"
>
  <div class="flex flex-col !gap-4 p-3">
    <button
      type="button"
      class="kw-btn kw-btn-sm kw-btn-active kw-btn-on-light !p-0 self-end"
      (click)="close()"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="currentColor"
      >
        <path
          d="M13.408 12.0002L19.708 5.71019C19.8963 5.52188 20.0021 5.26649 20.0021 5.00019C20.0021 4.73388 19.8963 4.47849 19.708 4.29019C19.5197 4.10188 19.2643 3.99609 18.998 3.99609C18.7317 3.99609 18.4763 4.10188 18.288 4.29019L11.998 10.5902L5.70799 4.29019C5.51968 4.10188 5.26429 3.99609 4.99799 3.99609C4.73168 3.99609 4.47629 4.10188 4.28799 4.29019C4.09968 4.47849 3.99389 4.73388 3.99389 5.00019C3.99389 5.26649 4.09968 5.52188 4.28799 5.71019L10.588 12.0002L4.28799 18.2902C4.19426 18.3831 4.11986 18.4937 4.06909 18.6156C4.01833 18.7375 3.99219 18.8682 3.99219 19.0002C3.99219 19.1322 4.01833 19.2629 4.06909 19.3848C4.11986 19.5066 4.19426 19.6172 4.28799 19.7102C4.38095 19.8039 4.49155 19.8783 4.61341 19.9291C4.73527 19.9798 4.86597 20.006 4.99799 20.006C5.13 20.006 5.2607 19.9798 5.38256 19.9291C5.50442 19.8783 5.61502 19.8039 5.70799 19.7102L11.998 13.4102L18.288 19.7102C18.3809 19.8039 18.4915 19.8783 18.6134 19.9291C18.7353 19.9798 18.866 20.006 18.998 20.006C19.13 20.006 19.2607 19.9798 19.3826 19.9291C19.5044 19.8783 19.615 19.8039 19.708 19.7102C19.8017 19.6172 19.8761 19.5066 19.9269 19.3848C19.9776 19.2629 20.0038 19.1322 20.0038 19.0002C20.0038 18.8682 19.9776 18.7375 19.9269 18.6156C19.8761 18.4937 19.8017 18.3831 19.708 18.2902L13.408 12.0002Z"
        />
      </svg>
    </button>

    <div
      class="flex flex-col justify-center items-center text-center gap-4 px-4 pb-4"
    >
      <div class="flex !p-3 bg-default-primary-1 rounded-full">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
        >
          <path
            d="M31.1663 12C30.4997 11.3333 29.4997 11.3333 28.833 12L16.333 24.5L11.1663 19.3333C10.4997 18.6667 9.49967 18.6667 8.83301 19.3333C8.16634 20 8.16634 21 8.83301 21.6667L15.1663 28C15.4997 28.3333 15.833 28.5 16.333 28.5C16.833 28.5 17.1663 28.3333 17.4997 28L31.1663 14.3333C31.833 13.6667 31.833 12.6667 31.1663 12Z"
            fill="#62C37D"
          />
        </svg>
      </div>

      <div class="flex flex-col gap-2">
        <p class="text-interface-title-3-semibold text-default-neutral-1">
          Your password reset successfully
        </p>

        <p class="text-interface-body-1-regular text-medium">
          Check your email. We sent you a letter with your new password to
          <span class="text-default-neutral-1">{{ email$ | async }}</span>
        </p>
      </div>
    </div>
  </div>
</div>
