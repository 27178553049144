import {
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { debounceTime, filter, fromEvent, merge, Subscription } from 'rxjs';
import { DOCUMENT, Location } from '@angular/common';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';

interface ItemMenu {
  title: string;
  href: string;
  params?: { [key: string]: string };
  label?: string;
}

@Component({
  selector: 'app-header-new',
  templateUrl: './header-new.component.html',
})
export class HeaderNewComponent implements OnInit, OnDestroy {
  routeEvents$!: Subscription;

  @ViewChild('burgerMenu') burgerMenu: ElementRef<HTMLDivElement> | undefined;
  // prettier-ignore
  @ViewChild('openBurgerMenu') openBurgerMenu: ElementRef<HTMLButtonElement> | undefined;
  // prettier-ignore
  @ViewChild('closeBurgerMenu') closeBurgerMenu: ElementRef<HTMLButtonElement> | undefined;

  windowAction$!: Subscription;
  windowClick$!: Subscription;

  mainMenuList: ItemMenu[] = [
    // { title: '3D Sentiment', href: '/single-stock' },
    { title: 'Key Events', href: '/events-timeline' },
    { title: 'Winrate Report', href: '/win-rate', label: 'NEW' },
  ];

  subMenuHomeList: ItemMenu[] = [
    { title: 'About', href: 'core-features' },
    { title: 'Expert Use Cases', href: 'expert-use-cases' },
    { title: 'Core Features', href: 'landing-analytic-tools' },
    { title: 'Team', href: 'research-team' },
    { title: 'Research Papers', href: 'research-papers' },
  ];

  subMenuPremiumList: ItemMenu[] = [
    { title: 'Plans & Prices', href: 'tariffs' },
    { title: 'Get profits!', href: 'profits' },
    { title: 'Win Rate Guarantee', href: 'guarantee' },
    { title: 'Compare plans', href: 'compare-plans' },
  ];

  isOpenBurgerMenu: boolean = false;

  isOpenSearch: boolean = false;

  isHomePage: boolean = false;
  isPremiumPage: boolean = false;

  lastScrollY: number = 0;
  directionScroll: 'top' | 'bottom' = 'top';

  constructor(
    @Inject(DOCUMENT)
    private document: Document,
    private router: Router,
    private location: Location,
  ) {}

  // prettier-ignore
  ngOnInit(): void {
    this.routeEvents$ = this.router.events
      // @ts-ignore
      .pipe(filter((routerEvent: RouterEvent): boolean => routerEvent instanceof NavigationEnd))
      .subscribe(() => {
        this.isHomePage = this.location.path().length === 0 || this.location.path()[0] === '?';

        this.isPremiumPage = this.location.path().includes('premium')
      });

    this.windowClick$ = fromEvent(this.document, 'click').subscribe({
      next: (event: any): void => {
        const burgerMenu: boolean = !!this.burgerMenu?.nativeElement.contains(event.target);

        const openBurgerMenu: boolean = !!this.openBurgerMenu?.nativeElement.contains(event.target);

        const closeBurgerMenu: boolean = !!this.closeBurgerMenu?.nativeElement.contains(event.target);

        if (!openBurgerMenu && !closeBurgerMenu) {
          this.isOpenBurgerMenu = burgerMenu;
        }
      },
      error: (error: any): void => console.error(error),
    });

    this.windowAction$ = merge(
      fromEvent(window, 'scroll'),
      fromEvent(window, 'resize'),
    )
      .pipe(debounceTime(20))
      .subscribe((): void => {
        this.directionScroll = window.scrollY > 100 ? this.lastScrollY >= window.scrollY ? 'top' : 'bottom' : 'top';

        this.lastScrollY = window.scrollY;

        this.isOpenBurgerMenu = false;
      });
  }

  ngOnDestroy(): void {
    [this.routeEvents$, this.windowAction$, this.windowClick$].forEach(
      ($: Subscription) => $?.unsubscribe(),
    );
  }

  // prettier-ignore
  onScroll(element: string): void {
    const htmlElement: HTMLElement | null = this.document.getElementById(element);
    const headerOffset: number = 98;

    if (htmlElement) {
      const elementPosition: number = htmlElement.getBoundingClientRect().top;
      const offsetPosition: number = elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({ top: offsetPosition, behavior: 'smooth' });
    }
  }

  onCloseBurger(): void {
    setTimeout(() => (this.isOpenBurgerMenu = false));
  }

  onOpenStockPage() {
    this.router
      .navigate(['single-stock', { ticker: 'SPY' }])
      .then(() => console.debug('Change router'));
  }
}
