import { Injectable } from '@angular/core';
import { MainHttpClient } from 'src/app/services/main-http-client.service';
import { WidgetsHttpClient } from 'src/app/services/widgets-http-client.service';
import {
  FavoriteSectorsAddRequest,
  FavoriteSectorsAddResponse,
} from '../../../../interfaces/api/main/favorite-sectors/add';
import { FavoriteSectorsListIdsRequest, FavoriteSectorsListIdsResponse } from '../../../../interfaces/api/main/favorite-sectors/list-ids';
import { FavoriteSectorsRemoveRequest, FavoriteSectorsRemoveResponse } from '../../../../interfaces/api/main/favorite-sectors/remove';

@Injectable()
export class FavoriteSectorsService {
  constructor(private http: MainHttpClient) {}

  public async add(data: FavoriteSectorsAddRequest): Promise<FavoriteSectorsAddResponse> {
    const response = await this.http
      .post<FavoriteSectorsAddResponse>(`/favorite-sectors/add`, data)

    return response;
  }

  public async remove(data: FavoriteSectorsRemoveRequest): Promise<FavoriteSectorsRemoveResponse> {
    const response = await this.http
      .post<FavoriteSectorsAddResponse>(`/favorite-sectors/remove`, data);

    return response;
  }  
  
  public async listIds(): Promise<FavoriteSectorsListIdsResponse> {
    const response = await this.http
      .post<FavoriteSectorsListIdsResponse>(`/favorite-sectors/list-ids`, {});

    return response;
  }
}
