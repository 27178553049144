<app-dropdown
  (toggled)="(null)"
  [appDisableClick]="true"
  [appToggleEvent]="isOpened"
  [appToggleMode]="false"
>
  <div slot="target">
    <fieldset
      *ngIf="{ value: false } as isError"
      [class.disabled]="control.disabled"
      [disabled]="control.disabled"
      class="kw-form-control-label-scaling kw-form-control-lg kw-form-control-select cursor-pointer"
    >
      <label
        *ngIf="!multiselect"
        [class.active]="control?.value"
        [for]="id"
        class="kw-form-control-floating-label cursor-pointer"
      >
        {{ label }}
      </label>
      <ng-container *ngIf="multiselect">
        <div class="flex flex-wrap gap-1 w-full">
          <div
            (click)="deselectOption(select)"
            *ngFor="let select of control.value"
            class="flex rounded-full py-1 px-1.5 gap-1 bg-surface-6"
          >
            <span class="text-interface-control-4-semibold text-high-on-dark">
              {{ select }}
            </span>
            <div class="flex justify-center items-center ml-auto">
              <svg
                class="flex-shrink-0"
                fill="none"
                height="20"
                viewBox="0 0 20 20"
                width="20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.5827 6C15.2494 5.66667 14.7493 5.66667 14.416 6L8.16602 12.25L5.58268 9.66667C5.24935 9.33333 4.74935 9.33333 4.41602 9.66667C4.08268 10 4.08268 10.5 4.41602 10.8333L7.58268 14C7.74935 14.1667 7.91602 14.25 8.16602 14.25C8.41602 14.25 8.58268 14.1667 8.74935 14L15.5827 7.16667C15.916 6.83333 15.916 6.33333 15.5827 6Z"
                  fill="#303D50"
                />
              </svg>
            </div>
          </div>
        </div>
      </ng-container>
      <input
        *ngIf="!multiselect"
        [formControl]="control"
        [id]="id"
        class="kw-form-control-input cursor-pointer"
        placeholder=" "
        readonly
      />

      <i
        class="kw-form-control-icon"
        [class.kw-form-control-icon-disabled]="control.disabled"
      >
        <svg
          fill="none"
          height="25"
          viewBox="0 0 24 25"
          width="24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Arrows / upper arrow">
            <path
              d="M16.9997 13.5338L12.7097 9.29385C12.6167 9.20012 12.5061 9.12572 12.3843 9.07495C12.2624 9.02419 12.1317 8.99805 11.9997 8.99805C11.8677 8.99805 11.737 9.02419 11.6151 9.07495C11.4933 9.12572 11.3827 9.20012 11.2897 9.29385L7.0497 13.5338C6.95598 13.6268 6.88158 13.7374 6.83081 13.8593C6.78004 13.9811 6.75391 14.1118 6.75391 14.2438C6.75391 14.3759 6.78004 14.5066 6.83081 14.6284C6.88158 14.7503 6.95598 14.8609 7.0497 14.9538C7.23707 15.1401 7.49052 15.2446 7.7547 15.2446C8.01889 15.2446 8.27234 15.1401 8.4597 14.9538L11.9997 11.4138L15.5397 14.9538C15.726 15.1386 15.9774 15.2427 16.2397 15.2438C16.3713 15.2446 16.5018 15.2194 16.6236 15.1696C16.7454 15.1199 16.8563 15.0465 16.9497 14.9538C17.0468 14.8642 17.1251 14.7563 17.1802 14.6362C17.2353 14.5161 17.2661 14.3863 17.2708 14.2543C17.2754 14.1222 17.2539 13.9906 17.2073 13.8669C17.1608 13.7433 17.0902 13.6301 16.9997 13.5338Z"
              fill="#303D50"
              id="Vector"
            />
          </g>
        </svg>
      </i>
    </fieldset>
  </div>
  <div
    class="flex dropdown-shadow max-h-64 overflow-y-auto scrollbar-y p-1.5 rounded w-full bg-[#FFF]"
    slot="content"
  >
    <ul class="w-full">
      <li
        (click)="selectOption(option)"
        *ngFor="let option of options"
        class="flex items-center justify-between group h-[40px] hover:rounded-lg p-2 hover:bg-[#a3b0c233] duration-300 cursor-pointer border-b border-[#DADADA] hover:border-[#a3b0c233]"
      >
        <span
          class="block px-2 py-0.5 text-interface-control-2-regular text-default-neutral-1 truncate"
        >
          {{ option }}
        </span>
        <svg
          *ngIf="
            multiselect
              ? (control?.value | includes: option)
              : option === control?.value
          "
          class="flex-shrink-0"
          fill="none"
          height="20"
          viewBox="0 0 20 20"
          width="20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.5827 6C15.2494 5.66667 14.7493 5.66667 14.416 6L8.16602 12.25L5.58268 9.66667C5.24935 9.33333 4.74935 9.33333 4.41602 9.66667C4.08268 10 4.08268 10.5 4.41602 10.8333L7.58268 14C7.74935 14.1667 7.91602 14.25 8.16602 14.25C8.41602 14.25 8.58268 14.1667 8.74935 14L15.5827 7.16667C15.916 6.83333 15.916 6.33333 15.5827 6Z"
            fill="#303D50"
          />
        </svg>
      </li>
    </ul>
  </div>
</app-dropdown>
