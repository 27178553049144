<div class="feedback-dialog">
    <div class="feedback-dialog-top">
        <svg (click)="close()" width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.3215 12.4999L6.91077 8.08916L8.08928 6.91064L12.5 11.3214L16.9108 6.91064L18.0893 8.08916L13.6785 12.4999L18.0893 16.9106L16.9108 18.0892L12.5 13.6784L8.08928 18.0892L6.91077 16.9106L11.3215 12.4999Z" fill="#333333"/>
        </svg>    
    </div>
    <div class="feedback-dialog-section">
        <svg class="feedback-dialog-section-icon" width="25" height="28" viewBox="0 0 25 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.6732 22.0996V25.6668" stroke="#62C37D" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12.7555 2C15.6762 2 18.3202 3.74004 18.383 6.23742C18.4895 10.4756 19.482 17.1959 12.8249 17.1959C6.16781 17.1959 7.09093 10.4756 7.19743 6.23742C7.26019 3.74004 9.83476 2 12.7555 2Z" fill="#C8E7D4" stroke="#62C37D" stroke-width="3"/>
            <path d="M2.21216 13.9312C2.55969 18.4964 6.35862 22.1001 12.7207 22.1001C18.6902 22.1001 22.6485 18.4863 23.3686 13.9312" stroke="#62C37D" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <div class="feedback-dialog-section-title">
            <a target="_blank" href="https://sayt.app/r/a5678e55-2e2d-45af-9dd8-9c4830292a40">
                Leave a voice reiew
            </a>
        </div>
    </div>
    <div class="feedback-dialog-section review-mark">
        <div class="feedback-dialog-section-container">
            <svg class="feedback-dialog-section-icon" width="28" height="26" viewBox="0 0 28 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.1409 2.09489C13.456 1.32658 14.544 1.32658 14.8591 2.09489L17.5332 8.61523L24.734 9.24642C25.5627 9.31906 25.8851 10.3609 25.2423 10.889L20.332 14.9227C20.0631 15.1436 19.9403 15.4962 20.0138 15.8363L21.5497 22.9475C21.7259 23.7635 20.8198 24.3795 20.1259 23.9154L14 19.8187L7.87414 23.9154C7.18021 24.3795 6.27406 23.7635 6.4503 22.9475L7.98624 15.8363C8.0597 15.4962 7.93687 15.1436 7.66803 14.9227L2.75768 10.889C2.11489 10.3609 2.43733 9.31906 3.26602 9.24642L10.4668 8.61523L13.1409 2.09489Z" fill="#C8E7D4" stroke="#62C37D" stroke-width="3" stroke-linejoin="round"/>
            </svg>            
            <div class="feedback-dialog-section-title">How much do you benefit from the data on this page?</div>
        </div>
        <div class="feedback-dialog-section-marks">
            <div (click)="clickMark(1)" class="feedback-dialog-section-mark">1</div>
            <div (click)="clickMark(2)" class="feedback-dialog-section-mark">2</div>
            <div (click)="clickMark(3)" class="feedback-dialog-section-mark">3</div>
            <div (click)="clickMark(4)" class="feedback-dialog-section-mark">4</div>
            <div (click)="clickMark(5)" class="feedback-dialog-section-mark">5</div>
        </div>
    </div>
    <div class="feedback-dialog-section share">
        <svg class="feedback-dialog-section-icon" width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.57734 18.2763C8.93909 18.2763 10.8537 16.3617 10.8537 14C10.8537 11.6382 8.93909 9.72363 6.57734 9.72363C4.2156 9.72363 2.30103 11.6382 2.30103 14C2.30103 16.3617 4.2156 18.2763 6.57734 18.2763Z" fill="#C8E7D4" stroke="#62C37D" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M21.4226 10.0526C23.7843 10.0526 25.6989 8.13806 25.6989 5.77632C25.6989 3.41457 23.7843 1.5 21.4226 1.5C19.0608 1.5 17.1462 3.41457 17.1462 5.77632C17.1462 8.13806 19.0608 10.0526 21.4226 10.0526Z" fill="#C8E7D4" stroke="#62C37D" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M21.4226 26.4999C23.7843 26.4999 25.6989 24.5853 25.6989 22.2236C25.6989 19.8618 23.7843 17.9473 21.4226 17.9473C19.0608 17.9473 17.1462 19.8618 17.1462 22.2236C17.1462 24.5853 19.0608 26.4999 21.4226 26.4999Z" fill="#C8E7D4" stroke="#62C37D" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M8.56031 10.2567C12.4652 8.0543 12.7022 8.03576 17.1462 6.78223" stroke="#62C37D" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M8.56031 17.7433C12.4652 19.9457 12.7022 19.9642 17.1462 21.2178" stroke="#62C37D" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>            
        <div (click)="share()" class="feedback-dialog-section-title">Please share!</div>
    </div>
</div>
