import { Injectable } from '@angular/core';
import { BehaviorSubject, first, from } from 'rxjs';
import { CashFlowTotalExposureData } from '../models/cash-flow-total-exposure.modal';
import { MainHttpClient } from '@app/services/main-http-client.service';

@Injectable({
  providedIn: 'root',
})
export class ChartService {
  cleanSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  startForecastSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false,
  );

  currentPriceSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  // prettier-ignore
  currentTotalExposureData: BehaviorSubject<{ openInterest: CashFlowTotalExposureData; ticker: string; } | null> = new BehaviorSubject<{ openInterest: CashFlowTotalExposureData; ticker: string; } | null>(null);

  constructor(private http: MainHttpClient) {}

  // prettier-ignore
  getCurrentTotalExposureData(ticker: string): void {
    const value: { openInterest: CashFlowTotalExposureData; ticker: string; } | null = this.currentTotalExposureData.getValue();
    const body: any = { ticker };

    if (value && value.ticker === ticker) {
      this.currentTotalExposureData.next(value);
    } else {
      from(this.http.post<{ openInterest: CashFlowTotalExposureData }>('/stock-page/openInterest', body))
        .pipe(first())
        .subscribe({
          next: (cashFlowTotalExposureData: { openInterest: CashFlowTotalExposureData }) => {
            this.currentTotalExposureData.next({ ...cashFlowTotalExposureData, ticker });
          },
          error: (error: any) => {
            this.currentTotalExposureData.next(null);

            console.debug(error);
          },
        });
    }
  }
}
