import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { Router } from '@angular/router';
import { UnsubscribeReason } from 'src/app/interfaces/api/payment/unsubscribe-reason';
import { AuthService } from 'src/app/modules/auth/services/auth.service';
import { PremiumService } from 'src/app/modules/main/premium/services/premium.service';
import { ToastService } from 'src/app/modules/shared/services/toast.service';

enum PiplineStep {
  Reasons,
  ScheduleCall,
  Discount,
  PayWithDiscount
}

@Component({
  selector: 'app-unsubscribe-pipline-widget',
  templateUrl: './unsubscribe-pipline-widget.component.html',
  styleUrls: ['./unsubscribe-pipline-widget.component.scss'],
  providers: [PremiumService, AuthService]
})
export class UnsubscribePiplineWidgetComponent implements OnInit {

  private DIALOG_PARAMS = "width=600,height=679,scrollbars=1,toolbar=0,location=1";
  
  step: PiplineStep = PiplineStep.Reasons;

  reasons: Array<UnsubscribeReason> = Object.values(UnsubscribeReason)
  private currentUnsubscribeReason: UnsubscribeReason;
  reasonButtonDisabled: boolean = true;

  reasonChange(event: MatRadioChange) {
    this.currentUnsubscribeReason = event.value;
    this.reasonButtonDisabled = false;
  }

  constructor(private dialogRef: MatDialogRef<UnsubscribePiplineWidgetComponent>,
              private premiumService: PremiumService,
              private toastService: ToastService,
              private authService: AuthService)
  { }

  ngOnInit(): void {
  }

  get PiplineStep(): typeof PiplineStep {
    return PiplineStep;
  }

  goToScheduleCall() {
    this.step = PiplineStep.ScheduleCall;
  }

  goToDiscount() {
    this.step = PiplineStep.Discount;
  }

  goToPayWithDiscount() {
    this.step = PiplineStep.PayWithDiscount;
  }

  scheduleCall() {
    this.premiumService.sendUnsubscribeReason({
      reason: this.currentUnsubscribeReason,
      scheduledCallParams: [ true, true ],
      isScheduledCall: true
    });
    this.dialogRef.close(false);

    const payload = this.authService.getPayload();
    const utmContent = {
      isUnsubscribeProcess: true,
      userId: payload?.userId
    };

    const popup = window.open(
      `https://calendly.com/visualsectors/30min?utm_content=${JSON.stringify(utmContent)}`,
      "Calendly",
      this.DIALOG_PARAMS);

    const subscription = setInterval(() => {
      if (popup!.closed) {
        clearInterval(subscription);
        this.toastService.show("Thank you. Application accepted. Our manager will contact you soon");
      }
    }, 1000);
  }

  cancelSubscription() {
    this.dialogRef.close(true);
  }

  async payWithDiscount() {
    await this.premiumService.sendUnsubscribeReason({
      reason: this.currentUnsubscribeReason,
      applyPromo: true
    });
    this.dialogRef.close(false);
    this.toastService.show("We successfully applied you promo code. Next time you will pay with 50% discount")
  }

  close() {
    this.dialogRef.close();
  }

}
