import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MainRoutingModule } from './main-routing.module';
import { ProfileModule } from './profile/profile.module';
import { PremiumNewModule } from './premium-new/premium-new.module';

@NgModule({
  declarations: [],
  imports: [CommonModule, ProfileModule, MainRoutingModule, PremiumNewModule],
})
export class MainModule {}
