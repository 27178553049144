import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  AfterViewInit,
} from '@angular/core';
import { IMenu } from 'src/app/modules/shared/modules/basic/structures/ui';
import { SubscriptionModel } from '../../models/subscription.model';

@Component({
  selector: 'app-subscription-plan',
  templateUrl: './subscription-plan.component.html',
  styleUrls: ['./subscription-plan.component.scss'],
})
export class SubscriptionPlanComponent implements OnInit {
  @Input() plan?: SubscriptionModel.IPlan;
  @Input()
  set appIsDisabledUnsubscribe(isDisabled: boolean) {
    this.isDisabledUnsubscribe = isDisabled;
  }
  isDisabledUnsubscribe: boolean = false;

  @Output() event = new EventEmitter<SubscriptionModel.IEvent>();

  menus: IMenu[] = [];
  activePeriod?: SubscriptionModel.Period;

  constructor() {}

  get activePrice() {
    return this.plan?.prices?.find((item) => item.active);
  }

  get period() {
    return SubscriptionModel.Period;
  }

  ngOnInit(): void {
    this.initMenus();
    this.activePeriod = this.activePrice?.period;
  }

  initMenus() {
    this.menus =
      this.plan?.periods?.map((item) => {
        return {
          text: {
            default: item.toString(),
          },
          id: item,
        };
      }) ?? [];
  }

  initPrice(period: SubscriptionModel.Period) {
    this.activePeriod = period;
    this.plan?.prices?.forEach(
      (item) => (item.active = item.period == this.activePeriod),
    );
  }

  optionClick(option: SubscriptionModel.IOption) {
    this.event.emit({
      type: SubscriptionModel.EventType.ActiveOption,
      plan: SubscriptionModel.Plan.Trial,
      option: option,
    });
  }

  planClick() {
    if (this.plan)
      this.event.emit({
        type: this.plan.active
          ? SubscriptionModel.EventType.Unsubscribe
          : SubscriptionModel.EventType.Subscribe,
        plan: this.plan.plan,
        planData: this.plan,
      });
  }
}
