import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthorizedGuard } from '../../auth/guards/authorized.guard';
import { CalendlyComponent } from './pages/calendly/calendly.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { SorryPageComponent } from './pages/sorry-page/sorry-page.component';
import { SubscriptionsNewComponent } from './subscriptions-new/subscriptions-new.component';

const routes: Routes = [
  {
    path: 'subscriptions',
    pathMatch: 'full',
    component: SubscriptionsNewComponent,
    canActivate: [AuthorizedGuard],
  },
  {
    path: 'calendly',
    component: CalendlyComponent,
    canActivate: [AuthorizedGuard],
  },
  {
    path: 'sorry',
    component: SorryPageComponent,
    canActivate: [AuthorizedGuard],
  },
  {
    path: 'settings',
    component: SettingsComponent,
    canActivate: [AuthorizedGuard],
  },
];

@NgModule({
  declarations: [SubscriptionsNewComponent],
  imports: [RouterModule.forChild(routes), CommonModule],
  exports: [RouterModule],
})
export class ProfileRoutingModule {}
