export class DomUtil {
  static hasClass(element: HTMLElement, className: string): boolean {

    if (element.parentElement) {
      const parentHasClass = DomUtil.hasClass(element.parentElement, className);
      if (parentHasClass) return true;
    }

    return element.classList.contains(className);
  }
}
