<div class="flex flex-col items-start !gap-4 md:!gap-6">
  <p
    class="text-interface-title-5-semibold md:text-interface-title-4-semibold text-default-neutral-1"
  >
    Email:
    <span class="text-medium">{{ email }}</span>
  </p>

  <button
    *ngIf="!loginViaGoogle; else goToGoogle"
    (click)="openChangeEmailDialog()"
    class="kw-btn kw-btn-active kw-btn-lg kw-btn-primary"
    type="button"
  >
    <span>Change E-mail</span>
  </button>

  <ng-template #goToGoogle>
    <p class="text-interface-caption-1-semibold text-default-neutral-1">
      Need to change e-mail? Go to
      <a
        href="https://myaccount.google.com"
        target="_blank"
        class="text-default-secondary-2"
      >
        Google
      </a>
    </p>
  </ng-template>
</div>
