/** @format */

import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import {
  Snackbar,
  SnackbarDuration,
  SnackbarOptions,
} from '../modules/shared/models/snackbar.model';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  snackbar$: ReplaySubject<Snackbar> = new ReplaySubject<Snackbar>();

  getDefaultDuration(duration: number): SnackbarDuration {
    return {
      value: duration,
    };
  }

  success(message: string, duration: number = 4000, options?: SnackbarOptions) {
    this.snackbar$.next({
      message,
      options: {
        classList: 'bg-default-success-2 text-white',
        icon: 'check-circle',
        duration: this.getDefaultDuration(duration),
        ...options,
      },
    });
  }

  error(message: string, duration: number = 5000, options?: SnackbarOptions) {
    this.snackbar$.next({
      message,
      options: {
        classList: 'bg-default-error-2 text-white',
        icon: 'exclamation-circle',
        duration: this.getDefaultDuration(duration),
        ...options,
      },
    });
  }

  neutral(message: string, duration: number = 4000, options?: SnackbarOptions) {
    this.snackbar$.next({
      message,
      options: {
        classList: 'bg-surface-6 text-white',
        icon: 'bolt',
        duration: this.getDefaultDuration(duration),
        ...options,
      },
    });
  }
}
