/** @format */

import { Injectable } from '@angular/core';
import {
  AbstractControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  getUUID(): string {
    // @ts-ignore
    const source: string = [1e7] + -1e3 + -4e3 + -8e3 + -1e11;
    const getUUID = (n: string): string => {
      const m: number = Number(n);

      const uint8Array: Uint8Array = window.crypto.getRandomValues(
        new Uint8Array(1),
      );

      // tslint:disable-next-line:no-bitwise
      return (m ^ (uint8Array[0] & (15 >> (m / 4)))).toString(16);
    };

    return source.replace(/[018]/g, getUUID);
  }

  getClipboard(value: string): Promise<void> {
    return new Promise((resolve, reject): void => {
      window.navigator.clipboard
        .writeText(value)
        .then(() => resolve())
        .catch(() => reject());
    });
  }

  getFormValidation(form: any): boolean {
    if (form.valid) {
      return true;
    }

    // prettier-ignore
    Object.keys(form.controls).forEach((control) => form.get(control).markAsTouched({ onlySelf: true }));

    return false;
  }

  getCustomValidation(customValidation: string, payload?: any): ValidatorFn {
    return (abstractControl: AbstractControl): ValidationErrors | null => {
      switch (customValidation) {
        case 'isDate': {
          // prettier-ignore
          if (!new RegExp('^\\d{2}[.]\\d{2}[.]\\d{4}$').test(abstractControl.value)) {
            return { isDate: false };
          }
          return null;
        }
        default: {
          return null;
        }
      }
    };
  }

  // prettier-ignore
  getFormatDate(date: Date): string {
    const year: number = date.getFullYear();
    const month: number | string = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
    const day: number | string = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();

    return `${year}-${month}-${day}`;
  }
}
