import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ComponentColor } from '../../structures/ui';

@Component({
  selector: 'app-input-text',
  templateUrl: './input-text.component.html',
  styleUrls: ['./input-text.component.scss'],
})
export class InputTextComponent {
  @Input() placeholder!: string;
  @Input() color: ComponentColor = {};
  @Input() classes: {
    padding?: string;
    bg?: string;
    placeholder?: string;
  } = {};
  
  @Output() OnInput = new EventEmitter<string>();

  onInput(event: any) {
    this.OnInput.emit(event.target.value)
  }

  constructor() {}
}
