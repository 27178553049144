<div *ngIf="model" [class.selected]="selected" class="item">
  <div class="item-top">
    <div class="item-top-top">
      <div class="name">{{ model.name }}</div>
      <div class="right">
        <div class="gradient-hover"><svg class="add-map" (click)="onAddMap()" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M7.00621 6.66677L7.00621 0.429036H8.65786L8.65786 6.66677L14.8394 6.66677L14.8394 8.33344L8.65786 8.33344L8.65786 14.5712H7.00621L7.00621 8.33344L0.824667 8.33344L0.824667 6.66677L7.00621 6.66677Z" fill="currentColor"/>
        </svg></div>
        <div class="gradient-hover"><svg class="open-map" (click)="onOpenMap()" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5.08274 9.73877H2.08911C1.53683 9.73877 1.08911 10.1865 1.08911 10.7388V13.9999C1.08911 14.5522 1.53683 14.9999 2.08911 14.9999H5.08274C5.63503 14.9999 6.08274 14.5522 6.08274 13.9999V10.7388C6.08274 10.1865 5.63503 9.73877 5.08274 9.73877Z" stroke="currentColor" stroke-width="1.1"/>
          <path d="M7.59872 8.40122L12.414 3.58594M12.414 3.58594V6.97447M12.414 3.58594H9.11465" stroke="currentColor" stroke-width="1.1"/>
          <path d="M1 8.04459V3C1 1.89543 1.89543 1 3 1H13C14.1046 1 15 1.89543 15 3V12.9108C15 14.0154 14.1046 14.9108 13 14.9108H8.04459" stroke="currentColor" stroke-width="1.1"/>
        </svg></div>
      </div>
    </div>
    <div class="item-top-bottom">
      <div class="item-top-bottom--risk">
        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.60884 7.25C10.1862 6.25 11.6296 6.25 12.2069 7.25L14.805 11.75C15.3823 12.75 14.6607 14 13.506 14H8.3098C7.1551 14 6.43342 12.75 7.01077 11.75L9.60884 7.25Z" [attr.fill]="model.fillColor" [attr.stroke]="model.chart.color"/>
        </svg>
        <span class="text">Risk</span>
        <span [style.color]="model.chart.color">{{ model.risk }}</span>
      </div>
      <div class="item-top-bottom--reward">
        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.60884 7.25C10.1862 6.25 11.6296 6.25 12.2069 7.25L14.805 11.75C15.3823 12.75 14.6607 14 13.506 14H8.3098C7.1551 14 6.43342 12.75 7.01077 11.75L9.60884 7.25Z" [attr.fill]="model.fillColor" [attr.stroke]="model.chart.color"/>
        </svg>
        <span class="text">Reward</span>
        <span [style.color]="model.chart.color">{{ model.reward }}</span>
      </div>
    </div>
  </div>

  <div class="item-bottom">
    <app-risk-reward-heatmap-chart [svgWidth]="model.chart.width" [svgHeight]="model.chart.height" [model]="model.chart"></app-risk-reward-heatmap-chart>
  </div>
</div>
