<div class="flex flex-col bg-white w-screen max-w-[728px] !p-5 rounded-2xl">
  <div class="flex justify-end">
    <button type="button" class="outline-none" (click)="close()">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.7349 12L6 7.26512L7.26512 6L12 10.7349L16.7349 6L18 7.26512L13.2651 12L18 16.7349L16.7349 18L12 13.2651L7.26512 18L6 16.7349L10.7349 12Z"
          fill="#333333"
        />
      </svg>
    </button>
  </div>
  <div class="flex flex-col gap-6 px-7 pb-7">
    <div class="flex flex-col gap-1">
      <div class="text-default-neutral-1 text-interface-title-3-semibold">
        Schedule a call with our investing consultant
      </div>
      <div class="text-default-neutral-1 text-interface-title-5-semibold">
        (your subscription will be on pause)
      </div>
    </div>
    <form
      class="flex flex-col items-start justify-start gap-[24px]"
      [formGroup]="formForm"
      (ngSubmit)="onSubmit()"
    >
      <fieldset class="kw-form-control-checkbox">
        <input
          id="findOut"
          type="checkbox"
          class="kw-form-control-input"
          formControlName="findOut"
        />
        <label class="kw-form-control-checkbox-mark" for="findOut"></label>
        <label
          class="kw-form-control-checkbox-text text-interface-control-2-regular text-high-on-light"
          for="findOut"
        >
          find out a winning use-case for you personally
        </label>
      </fieldset>
      <fieldset class="kw-form-control-checkbox">
        <input
          id="feedback"
          type="checkbox"
          class="kw-form-control-input"
          formControlName="feedback"
        />
        <label class="kw-form-control-checkbox-mark" for="feedback"></label>
        <label
          class="kw-form-control-checkbox-text text-interface-control-2-regular text-high-on-light"
          for="feedback"
        >
          get feedback on you Portfolio stoks
        </label>
      </fieldset>
      <fieldset class="flex gap-4 w-full">
        <button
          class="kw-btn kw-btn-md kw-btn-primary kw-btn-active w-full"
          type="submit"
          [disabled]="formForm.invalid"
        >
          <span class="block"> Yes, please </span>
        </button>
        <button
          class="kw-btn kw-btn-md kw-btn-on-light kw-btn-active w-full"
          type="button"
          (click)="onCancel()"
        >
          <span class="block"> No, just cancel the subscription </span>
        </button>
      </fieldset>
    </form>
  </div>
</div>
