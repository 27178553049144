import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as d3Drag from 'd3-drag';
import * as d3Selection from 'd3-selection';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-range-number-picker, [appRangeNumberPicker]',
  templateUrl: './range-number-picker.component.html',
})
export class RangeNumberPickerComponent implements AfterViewInit {
  @ViewChild('pickerLeft') pickerLeft: ElementRef<HTMLDivElement> | undefined;
  @ViewChild('pickerRight') pickerRight: ElementRef<HTMLDivElement> | undefined;

  @Input()
  set appToFixed(fixed: number) {
    this.fixed = fixed;
  }

  @Input()
  set appValueLeft(valueLeft: number) {
    this.numberForm.get('valueLeft')?.setValue(valueLeft.toFixed(this.fixed));

    this.onSubmit('left');
  }

  @Input()
  set appValueRight(valueRight: number) {
    this.numberForm.get('valueRight')?.setValue(valueRight.toFixed(this.fixed));

    this.onSubmit('right');
  }

  @Input()
  set appLabelLeft(labelLeft: string) {
    this.labelLeft = labelLeft;
  }

  @Input()
  set appLabelRight(labelRight: string) {
    this.labelRight = labelRight;
  }

  @Input()
  set appMin(min: number) {
    this.min = min;
  }

  @Input()
  set appMax(max: number) {
    this.max = max;
  }

  @Input()
  set appDisable(disabled: boolean) {
    this.disabled = disabled;
  }

  // prettier-ignore
  @Output() selected: EventEmitter<[number | null, number | null]> = new EventEmitter();

  numberForm: FormGroup;

  labelLeft: string = '0';
  labelRight: string = '100';

  min: number = 0;
  max: number = 1000;

  disabled: boolean = false;

  fixed: number = 0;

  translateXLeft: number = 0;
  translateXLeftDragStart: number = 0;
  translateXRight: number = 350;
  translateXRightDragStart: number = 350;
  translateXMin: number = 0;
  translateXMax: number = 350;

  constructor(private formBuilder: FormBuilder) {
    // prettier-ignore
    this.numberForm = this.formBuilder.group({
      valueLeft: this.formBuilder.control(0, [Validators.required]),
      valueRight: this.formBuilder.control(1000, [Validators.required]),
    });
  }

  // prettier-ignore
  ngAfterViewInit(): void {
    // LEFT
    const dragLeft: any = d3Drag
      .drag()
      .on('start', () => !this.disabled && this.onDragStart())
      .on('drag', (event: any) => !this.disabled && this.onDragMove(event, 'left'))
      .on('end', () => !this.disabled && this.onDragEnd('left'));

    // @ts-ignore
    d3Selection.select(this.pickerLeft?.nativeElement).call(dragLeft);

    // RIGHT
    const dragRight: any = d3Drag
      .drag()
      .on('start', () => !this.disabled && this.onDragStart())
      .on('drag', (event: any) => !this.disabled && this.onDragMove(event, 'right'))
      .on('end', () => !this.disabled && this.onDragEnd('right'));

    // @ts-ignore
    d3Selection.select(this.pickerRight?.nativeElement).call(dragRight);
  }

  onDragStart(): void {
    this.translateXLeftDragStart = this.translateXLeft;
    this.translateXRightDragStart = this.translateXRight;
  }

  // prettier-ignore
  onDragMove(event: any, picker: 'left' | 'right'): void {
    // где начали subject.x
    // где закончили x
    const direction: number = event.subject.x <= event.x ? 1 : -1;
    const difference: number = Math.abs(event.subject.x - event.x);

    const dragStart: number = picker === 'left' ? this.translateXLeftDragStart : this.translateXRightDragStart;

    const currentPosition: number = dragStart + direction * difference;

    if (picker === 'left') {
      this.translateXLeft = currentPosition > this.translateXMin ? currentPosition < this.translateXRight ? currentPosition : this.translateXRight : this.translateXMin;
    }

    if (picker === 'right') {
      this.translateXRight = currentPosition > this.translateXLeft ? currentPosition < this.translateXMax ? currentPosition : this.translateXMax : this.translateXLeft;
    }

    this.setForm(picker);
  }

  onDragEnd(direction: 'left' | 'right'): void {
    this.setForm(direction);
  }

  // prettier-ignore
  setForm(direction: 'left' | 'right'): void {
    const multiplier: number = this.translateXMax / (this.max - this.min);

    const valueLeft: number = Number(this.numberForm.get('valueLeft')?.value);
    const valueRight: number = Number(this.numberForm.get('valueRight')?.value);

    const newValue: number = direction === 'left' ? (this.translateXLeft + (this.min * multiplier)) / multiplier : (this.translateXRight + (this.min * multiplier)) / multiplier;

    if (direction === 'left') {
      this.numberForm.get('valueLeft')?.setValue((newValue > this.min ? newValue < valueRight ? newValue : valueRight : this.min).toFixed(this.fixed));

      this.selected.emit([Number(valueLeft.toFixed(this.fixed)), null]);
    }

    if (direction === 'right') {
      this.numberForm.get('valueRight')?.setValue((newValue > valueLeft ? newValue < this.max ? newValue : this.max : valueLeft).toFixed(this.fixed));

      this.selected.emit([null, Number(valueRight.toFixed(this.fixed))]);
    }
  }

  // prettier-ignore
  onSubmit(direction: 'left' | 'right'): void {
    let valueLeft: number = Number(this.numberForm.get('valueLeft')?.value);
    let valueRight: number = Number(this.numberForm.get('valueRight')?.value);

    if (direction === 'left') {
      valueLeft = valueLeft > this.min ? valueLeft < valueRight ? valueLeft : valueRight : this.min;

      if (Number(this.numberForm.get('valueLeft')?.value) !== valueLeft) {
        this.numberForm.get('valueLeft')?.setValue(valueLeft.toFixed(this.fixed));
      }
    }

    if (direction === 'right') {
      valueRight = valueRight > valueLeft ? valueRight < this.max ? valueRight : this.max : valueLeft;

      if (Number(this.numberForm.get('valueRight')?.value) !== valueRight) {
        this.numberForm.get('valueRight')?.setValue(valueRight.toFixed(this.fixed));
      }
    }

    const multiplier: number = this.translateXMax / (this.max - this.min);

    const result: number = direction === 'left' ? (valueLeft * multiplier) - (this.min * multiplier) : (valueRight * multiplier) - (this.min * multiplier);

    if (direction === 'left') {
      this.translateXLeft = result > this.translateXMin ? result < this.translateXRight ? result : this.translateXRight : this.translateXMin;

      this.selected.emit([Number(valueLeft.toFixed(this.fixed)), null]);
    }

    if (direction === 'right') {
      this.translateXRight = result > this.translateXLeft ? result < this.translateXMax ? result : this.translateXMax : this.translateXLeft;

      this.selected.emit([null, Number(valueRight.toFixed(this.fixed))]);
    }
  }
}
