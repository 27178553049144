<div [class]="data.classes?.title?.text ?? 'registration-form-have-account'">
  {{ data.texts?.title?.text ?? 'Already have an account?' }}
  <span
    (click)="goToSignIn.emit()"
    [class]="data.classes?.title?.link ?? ''"
    class="auth-form-link"
  >
    {{ data.texts?.title?.link ?? 'Sign In' }}</span
  >
</div>

<app-auth-form-social-buttons
  (loginSuccessful)="loginSuccessful.emit()"
></app-auth-form-social-buttons>

<div class="auth-form-divider">
  <div class="auth-form-divider-line"></div>
  <div class="auth-form-divider-text">Or</div>
</div>

<form [formGroup]="form">
  <div class="auth-form-text-field flex flex-col gap-[16px]">
    <mat-form-field appearance="outline">
      <mat-label>E-mail</mat-label>
      <input
        matInput
        formControlName="email"
        [class]="data.classes?.input ?? ''"
      />
    </mat-form-field>
    <div class="flex gap-[6px] cursor-pointer" (click)="onClickAgreedToTerms()">
      <app-checkbox
        class="cursor-pointer"
        [checked]="form.get('agreedToTerms')?.value"
      ></app-checkbox>
      <div class="text-base leading-5 text-[#333333]">
        I agree to the
        <a
          class="text-[#2F80ED] leading-5 hover:text-[#0a58ca]"
          target="_blank"
          [routerLink]="['pages', 'terms']"
        >
          Terms and Conditions
        </a>
        and confirm my consent to the processing of
        <a
          class="text-[#2F80ED] hover:text-[#0a58ca]"
          target="_blank"
          [routerLink]="['pages', 'privacy-policy']"
        >
          Personal data
        </a>
      </div>
    </div>
  </div>
</form>

<div class="auth-form-error-message" *ngIf="errorMessage">
  {{ errorMessage }}
</div>

<div class="registration-form-hint text-center">
  Your password will be e-mailed to you, you can change it later. If you don’t
  get the e-mail, check your spam folder
</div>

<ng-container
  *ngIf="registerLicense"
  [ngTemplateOutlet]="registerLicense"
></ng-container>

<button
  (click)="signUp()"
  class="auth-form-button transition-opacity duration-200"
  [class.opacity-50]="!signUpEnable || form.invalid"
  [disabled]="!signUpEnable || form.invalid"
>
  {{ data.texts?.signButton ?? 'Sign Up' }}
</button>
