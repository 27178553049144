<div class="section">
  <div class="section-name">{{ model.name }}</div>
  <div class="section-top-items">
    <div *ngFor="let topItem of model.topItems" class="section-top-item">
      <div class="section-top-item-name">{{ topItem.name }}</div>
      <div [class.positive]="topItem.value[0] != '-'" class="section-top-item-value">{{ topItem.value }}</div>
    </div>
  </div>
</div>
<div class="subsections">
  <mat-expansion-panel class="subsection" #panel *ngFor="let subsection of model.subsections" [togglePosition]="'before'">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="subsection-name">{{ subsection.name }}</div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div *ngIf="subsection.fields.length > 0" class="subsection-table">
      <div class="subsection-table-left">
        <div class="subsection-table-header">
          <div class="subsection-table-header-empty cell"></div>
          <div class="subsection-table-header-now cell">
            <div class="subsection-table-header-item">Now</div>
          </div>
          <div class="subsection-table-header-fair cell">
            <div class="subsection-table-header-item">Fair</div>
          </div>
        </div>
        <div *ngFor="let field of subsection.fields" class="subsection-table-field">
          <div class="subsection-table-field-name cell">
            <div class="subsection-table-header-item">
              {{ field.name }}
            </div>
          </div>
          <div class="subsection-table-field-now cell">{{ field.now }}</div>
          <div class="subsection-table-field-fair cell">{{ field.fair }}</div>
        </div>
      </div>
      <div class="subsection-table-right">
        <div class="subsection-table-header cell">

        </div>
        <div [class.positive]="field.percent[0] !== '-'" *ngFor="let field of subsection.fields" class="subsection-table-field-percent cell">
          ({{ field.percent }})
        </div>
      </div>
    </div>
  </mat-expansion-panel>
</div>
