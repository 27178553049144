import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-loader-new',
  templateUrl: 'loader-new.component.html',
})
export class LoaderNewComponent {
  @Input()
  set appHeight(height: string) {
    this.height = height;
  }

  height: string = '100px';

  constructor() {}
}
