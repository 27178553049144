<div *ngIf="model" class="risk-reward-dialog-container">

  <div class="risk-reward-dialog--header">
    <svg (click)="close()" width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M11.3219 12.5004L6.91113 8.08964L8.08964 6.91113L12.5004 11.3219L16.9111 6.91113L18.0896 8.08964L13.6789 12.5004L18.0896 16.9111L16.9111 18.0896L12.5004 13.6789L8.08964 18.0896L6.91113 16.9111L11.3219 12.5004Z" fill="#333333"/>
    </svg>
  </div>

  <div class="risk-reward-dialog">
    <div class="risk-reward-dialog--title">{{ model.title }}</div>
    <app-scrollable [height]="'50vh'">
      <div class="risk-reward-dialog--map">
        <div class="item">
          <div class="item-top">
            <div class="item-top-left">
              <div class="item-top-left--risk">
                <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.60884 7.25C10.1862 6.25 11.6296 6.25 12.2069 7.25L14.805 11.75C15.3823 12.75 14.6607 14 13.506 14H8.3098C7.1551 14 6.43342 12.75 7.01077 11.75L9.60884 7.25Z" [attr.fill]="model.fillColor" [attr.stroke]="model.chart.color"/>
                </svg>
                <span class="text">Risk</span>
                <span [style.color]="model.chart.color">{{ model.risk }}</span>
              </div>
              <div class="item-top-left--reward">
                <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.60884 7.25C10.1862 6.25 11.6296 6.25 12.2069 7.25L14.805 11.75C15.3823 12.75 14.6607 14 13.506 14H8.3098C7.1551 14 6.43342 12.75 7.01077 11.75L9.60884 7.25Z" [attr.fill]="model.fillColor" [attr.stroke]="model.chart.color"/>
                </svg>
                <span class="text">Reward</span>
                <span [style.color]="model.chart.color">{{ model.reward }}</span>
              </div>
            </div>
            <div class="item-top-right">
              <div class="gradient-hover"><svg class="add-map" (click)="onAddMap()" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.00621 6.66677L7.00621 0.429036H8.65786L8.65786 6.66677L14.8394 6.66677L14.8394 8.33344L8.65786 8.33344L8.65786 14.5712H7.00621L7.00621 8.33344L0.824667 8.33344L0.824667 6.66677L7.00621 6.66677Z" fill="currentColor"/>
              </svg></div>
            </div>
          </div>
          <app-risk-reward-heatmap-chart [svgWidth]="500" [svgHeight]="150" [model]="model.chart"></app-risk-reward-heatmap-chart>
        </div>
        <div class="periods">
          <div (click)="onSelectPeriod(period)"
              [class.selected]="period.selected"
                *ngFor="let period of model.periods" class="periods-item">
            {{ period.name }}
          </div>
        </div>
      </div>

      <div class="risk-reward-dialog--sections">
        <app-risk-reward-heatmap-dialog-section [model]="section" *ngFor="let section of model.sections"></app-risk-reward-heatmap-dialog-section>
      </div>
    </app-scrollable>
  </div>
</div>
