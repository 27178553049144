<ul [class]="classes" class="flex-grow">
  <li
    *ngFor="let item of items; let last = last"
    [class]="item.classes ?? 'px-4 py-2 border-b-base-800 text-[17px]'"
    [class.border-b]="!last"
    (click)="itemClick.emit(item)"
    class="gap-2 cursor-pointer flex basic-toggle-container"
  >
    <app-icon *ngIf="item.icon" [icon]="item.icon"></app-icon>
    <div class="item-text">{{ item.text }}</div>
    <mat-slide-toggle 
      (toggleChange)="toggleChange(item)"
      *ngIf="item.toggle !== undefined"
      [checked]="item.toggle"
      class="set-alerts-notifications-alert-sectors"></mat-slide-toggle>
  </li>
</ul>
