<ng-container>
  <!-- BLUR -->
  <div
    *ngIf="cashFlowNetFlowsFakeData.width"
    class="absolute bottom-0 h-[86px]"
    [style]="{
      left: cashFlowNetFlowsFakeData.minXFake + svgTranslateX + 'px',
      width: cashFlowNetFlowsFakeData.width + 'px'
    }"
  >
    <div class="w-full h-full backdrop-filter backdrop-blur-[5px]">
      <div class="w-full h-full bg-white opacity-50"></div>
    </div>
  </div>
  <!-- prettier-ignore -->
  <div
    *ngIf="cashFlowNetFlowsFakeData.width"
    class="absolute flex items-center justify-end bottom-0 h-[86px] pointer-events-auto"
    [style]="{
      left:
        334 < cashFlowNetFlowsFakeData.minXFake + svgTranslateX + 40
          ? cashFlowNetFlowsFakeData.minXFake + 40 + svgTranslateX + 'px'
          : 334 > cashFlowNetFlowsFakeData.maxXFake + svgTranslateX - 154 - 40
          ? cashFlowNetFlowsFakeData.maxXFake - 154 - 40 + svgTranslateX + 'px'
          : '334px'
    }"
  >
    <div class="flex flex-col gap-2 w-[154px]">
      <button
        class="block bg-[#62C37D] text-[#FFF] text-sm leading-[140%] font-semibold !rounded py-1.5 px-2"
        type="button"
        (click)="onShowIndicator()"
      >
        {{ userType === 0 ? 'Show 1 more month' : 'Show full chart' }}
      </button>
      <span
        class="block text-center text-sm leading-[16px] font-semibold tracking-[-0.08px] px-1"
      >
        {{ userType === 0 ? 'Free Sign Up' : userType === 1 ? 'Get Free Trial' : 'Upgrade' }}
      </span>
    </div>
  </div>
  <!-- CONTROL -->
  <div
    class="absolute bottom-[92px] flex pointer-events-auto z-[2]"
    [style]="{
      left:
        334 < cashFlowNetFlowsFakeData.minX + svgTranslateX + 40
          ? cashFlowNetFlowsFakeData.minX + 40 + svgTranslateX + 'px'
          : 334 > cashFlowNetFlowsFakeData.maxX + svgTranslateX - 154 - 40
          ? cashFlowNetFlowsFakeData.maxX - 154 - 40 + svgTranslateX + 'px'
          : '334px'
    }"
  >
    <div class="flex items-start !gap-4">
      <div
        class="flex flex-shrink-0 !p-1 rounded-lg bg-[#F1F5F9] badge-shadow opacity-90"
      >
        <span
          class="block font-B612 text-[10px] font-normal leading-[12px] !px-0.5"
        >
          Netflows
        </span>
      </div>
      <app-dropdown
        [appUseScale]="true"
        *ngIf="{ value: false } as toggle"
        (toggled)="toggle.value = $event"
        class="flex flex-shrink-0"
      >
        <button
          class="flex rounded-lg bg-[#F4F4F4] hover:bg-[#EAEAEA] text-[#393939] font-normal text-[10px] leading-[12px] !p-1 opacity-90 duration-300"
          type="button"
          slot="target"
        >
          <span class="block px-1">
            {{ cashFlowNetFlowsActivePeriod }} Change
          </span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            class="duration-300"
            [class.-rotate-180]="toggle.value"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M2.1342 4.15914C2.32245 3.95711 2.63884 3.94594 2.84086 4.1342L6.23018 7.29242L9.13329 4.16012C9.321 3.95759 9.63735 3.94558 9.83988 4.13329C10.0424 4.321 10.0544 4.63735 9.86672 4.83989L6.28202 8.70758L2.15914 4.86581C1.95711 4.67755 1.94594 4.36117 2.1342 4.15914Z"
              fill="#393939"
            />
          </svg>
        </button>
        <div
          class="flex dropdown-shadow p-1 rounded-lg mt-2 w-[140px] h-[80px] bg-[#FFF]"
          slot="content"
        >
          <ul class="w-full">
            <li
              *ngFor="let item of cashFlowNetFlowsPeriodList"
              (click)="onSetPeriod(item)"
              [id]="'netflows-period-' + item"
              [attr.data-text]="item + ' Change'"
              class="flex items-center justify-between gap-1 h-6 hover:rounded !p-1 !pl-2 hover:bg-[#a3b0c233] duration-300 cursor-pointer border-b border-[#DADADA] hover:border-[#a3b0c233]"
            >
              <span class="block py-1 text-[10px] text-[#393939] truncate">
                {{ item }} Change
              </span>
              <svg
                *ngIf="item === cashFlowNetFlowsActivePeriod"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                class="flex-shrink-0"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M12.412 4.18569C12.1464 3.95805 11.7466 3.98881 11.5189 4.25438L6.28551 10.36L4.48066 8.25438C4.25303 7.98881 3.85321 7.95805 3.58763 8.18569C3.32206 8.41332 3.2913 8.81314 3.51894 9.07872L5.80465 11.7454C5.92497 11.8858 6.10063 11.9665 6.28551 11.9665C6.4704 11.9665 6.64605 11.8858 6.76638 11.7454L12.4807 5.07872C12.7083 4.81314 12.6775 4.41332 12.412 4.18569Z"
                  fill="#696969"
                />
              </svg>
            </li>
          </ul>
        </div>
      </app-dropdown>
    </div>
  </div>
</ng-container>
