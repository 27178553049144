import { AfterViewInit, Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { AuthService } from 'src/app/modules/auth/services/auth.service';
import { UserTypeModel } from 'src/app/modules/shared/models/user-type.model';
import { AnalyticService } from 'src/app/services/analytic.service';
import { EventsService, EventType } from 'src/app/services/events.service';
import jwt_decode from 'jwt-decode';
import { HeaderService } from '../../services/header.service';
import { ANIM_SCALE_Y } from 'src/app/modules/shared/modules/basic/animations/scale.animation';
import { AuthFormService } from 'src/app/modules/auth/services/auth-form.service';
import { ONBOARDING_PASSED_NAME } from 'src/app/constants/onboarding-constants';
import { ContextMenuItemToggleEvent } from 'src/app/modules/shared/modules/basic/components/context-menu/context-menu.component';
import { OnboardingHintEventService, OnboardingHintEventType } from 'src/app/modules/shared/modules/basic/components/onboarding-hint/onboarding-hint-event.service';

declare var $: any;

export enum AccountMenu {
  Email = 'email',
  Subscriptions = 'subscription',
  Logout = 'logout',
  Hints = 'hints'
}

@Component({
  selector: 'app-header-account',
  templateUrl: './header-account.component.html',
  styleUrls: ['./header-account.component.scss'],
  animations: [ANIM_SCALE_Y]
})
export class HeaderAccountComponent implements AfterViewInit, OnInit {
  email: string;

  userType: UserTypeModel = UserTypeModel.Unregistered;

  get contextMenu() {
    return this.headerService.contextMenu;
  }

  constructor(
    private authService: AuthService,
    private authFormService: AuthFormService,
    public headerService: HeaderService,
    private analyticService: AnalyticService,
    private eventService: EventsService,
    private onboardingEventService: OnboardingHintEventService
  ) {}

  @HostListener('document:click', ['$event']) globalClick(event: any) {
    const contextMenu = $("app-context-menu");
    if (contextMenu.length === 0) {
      return;
    }
    const inside = contextMenu.find(event.target).length > 0;
    if(!inside) {
      this.contextMenu.visible = false;
    }
  }

  ngOnInit(): void {
    this.eventService.subscription().subscribe(async (type) => {
      if (type === EventType.UserTypeChanged) {
        this.userType = await this.authService.getUserType();
        this.setEmail();
      }
    });

    this.setEmail();
  }


  setEmail() {
    const payload = this.authService.getPayload();
    if (payload !== null) {
      this.email = payload.userEmail;
    }
    this.headerService.initContextMenu(this.email);
  }

  async ngAfterViewInit(): Promise<void> {
    this.userType = await this.authService.getUserType();
  }

  get UserTypeModel(): typeof UserTypeModel {
    return UserTypeModel;
  }

  logout() {
    this.authService.logout();
  }

  signUp() {
    this.analyticService.send('click_sign_up', new Map([
      ["name", "В шапке https://skr.sh/sJQhqCMN5mn"],
    ]));
    this.authFormService.openSignUp();
  }

  signIn() {
    this.authFormService.openSignIn();
  }

  showContextMenu(event: Event) {

    if (!this.contextMenu.visible) {
      setTimeout(() => {
        this.contextMenu.visible = true;
      });
    }
  }

  contextMenuItemToggle(event: ContextMenuItemToggleEvent) {
    if (event.id === AccountMenu.Hints) {
      const passed = localStorage.getItem(ONBOARDING_PASSED_NAME);
      if (event.value) {
        if (passed !== null) {
          localStorage.removeItem(ONBOARDING_PASSED_NAME);
        }
        this.onboardingEventService.emit(OnboardingHintEventType.Enable);
      } else {
        localStorage.setItem(ONBOARDING_PASSED_NAME, "TRUE");
        this.onboardingEventService.emit(OnboardingHintEventType.Disable);
      }
    }
  }
}
