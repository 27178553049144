import { Component, Input, TemplateRef } from '@angular/core';
import { ITableData, ITableColumn } from '../../structures/ui';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent {
  @Input() columns: ITableColumn[] = [];
  @Input() data: ITableData[] = [];
  @Input() templates: { [templateName: string]: TemplateRef<any> } = {};
  @Input() classes: {
    table?: string;
    th?: {
      host?: string;
      specific?: { [column: string]: string };
    };
    td?: {
      host?: string;
      specific?: { [column: string]: string };
    };
  } = {};
  @Input() config: {
    pagination?: {
      count?: number;
      moreButton?: boolean;
      displayAll?: boolean;
    };
  } = {
    pagination: {
      count: 3,
      moreButton: true,
    },
  };

  constructor() {}
}
