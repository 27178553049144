import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  ConsultationDialogComponent,
  DiscountDialogComponent,
  PayCancelDialogComponent,
  PaySuccessDialogComponent,
  ReasonDialogComponent,
} from '../';
import { GetDiscountDialogComponent } from '../get-discount-dialog/get-discount-dialog.component';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionsDialogService {
  priceSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  constructor(private dialog: MatDialog) {}

  openConsultationDialog(): void {
    this.dialog.open(ConsultationDialogComponent);
  }

  openDiscountDialog(): void {
    this.dialog.open(DiscountDialogComponent);
  }

  openGetDiscountDialog(): void {
    this.dialog.open(GetDiscountDialogComponent);
  }

  openReasonDialog(): void {
    this.dialog.open(ReasonDialogComponent);
  }

  openPayCancelDialog(): void {
    this.dialog.open(PayCancelDialogComponent);
  }

  openPaySuccessDialog(): void {
    this.dialog.open(PaySuccessDialogComponent);
  }
}
