import { Injectable } from '@angular/core';
import { MOBILE_NAVIGATION_MENUS, NAVIGATION_MENUS } from '../modules/layout/header/data/menu.data';
import { INavigationMenu } from '../modules/shared/modules/basic/structures/app';
import { IFooterItem } from '../modules/shared/modules/basic/structures/ui';
import { FOOTER_DATA } from '../modules/shared/modules/basic/utils/data';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  navigationMenus: INavigationMenu[] = NAVIGATION_MENUS;
  mobileMenus: INavigationMenu[] = MOBILE_NAVIGATION_MENUS;
  footerItems: IFooterItem[] = FOOTER_DATA;

  constructor() {}
}
