import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { filter, fromEvent, merge, Subscription } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { ScaleService } from '../../services/scale.service';

@Component({
  selector: 'app-dropdown, [appDropdown]',
  templateUrl: './dropdown.component.html',
})
export class DropdownComponent implements OnInit, OnDestroy {
  @ViewChild('dropdownTarget') dropdownTarget: ElementRef | undefined;
  @ViewChild('dropdownContent') dropdownContent: ElementRef | undefined;

  @Output() toggled: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input()
  set appDisableClick(disableClick: boolean) {
    this.disableClick = disableClick;
  }

  @Input()
  set appSetWidth(settingWidth: boolean) {
    this.setWidth = settingWidth;
  }

  @Input()
  set appUseScale(useScale: boolean) {
    this.useScale = useScale;
  }

  @Input()
  set appPositionX(directionX: 'left' | 'right') {
    this.directionX = directionX;
  }

  @Input()
  set appOffsetX(offsetX: number) {
    this.offsetX = offsetX;
  }

  @Input()
  set appToggleEvent(toggleEvent: EventEmitter<boolean>) {
    this.toggleEvent = toggleEvent;

    if (this.toggleEvent$ === undefined) {
      this.toggleEvent$ = this.toggleEvent.subscribe((toggled: boolean) => {
        this.setStateStyle(toggled);
      });
    }
  }

  @Input()
  set appToggleMode(toggleMode: boolean) {
    this.toggleMode = toggleMode;
  }

  windowClick$: Subscription | undefined;
  windowAction$: Subscription | undefined;

  dropdownState: boolean = false;
  dropdownStateIgnore: boolean = false;
  dropdownStateStyle: any = {
    display: 'none',
  };

  disableClick: boolean = false;
  toggleMode: boolean = true;

  toggleEvent: EventEmitter<boolean> | undefined = undefined;
  toggleEvent$: Subscription | undefined;

  offsetX: number = 0;
  directionX: 'left' | 'right' = 'left';
  setWidth: boolean = true;
  useScale: boolean = false;

  constructor(
    @Inject(DOCUMENT)
    private document: Document,
    private elementRef: ElementRef,
    private scaleService: ScaleService,
  ) {}

  ngOnInit(): void {
    this.windowClick$ = fromEvent(this.document, 'click')
      .pipe(
        filter(() => {
          // prettier-ignore
          const inputElement: HTMLInputElement | null = this.elementRef.nativeElement.querySelector('input');

          if (!!inputElement) {
            return !inputElement.disabled && !this.dropdownStateIgnore;
          }

          return !this.dropdownStateIgnore;
        }),
      )
      .subscribe({
        next: (event: any) => {
          // prettier-ignore
          const target: boolean = this.dropdownTarget?.nativeElement.contains(event.target);

          // prettier-ignore
          const content: boolean = this.dropdownContent?.nativeElement.contains(event.target);

          // prettier-ignore
          const checkParent = (element: HTMLElement | null): boolean => {
            if (element && element.parentElement !== this.dropdownContent?.nativeElement) {
              if (element.getAttribute('data-disable-click')) {
                return true;
              }

              return checkParent(element.parentElement);
            }

            return false;
          };

          const ignore: boolean = checkParent(event.target);

          /**
           * If click on target = show/hide toggle
           * If opened - close on click content(if disableClick is false) or outside
           */

          if (target) {
            if (this.toggleMode) {
              this.setStateStyle(!this.dropdownState);
            } else {
              this.setStateStyle(true);
            }
          } else if (this.dropdownState) {
            if ((content && !this.disableClick) || (content && ignore)) {
              this.setStateStyle(!ignore);
            } else if (!target && !content) {
              this.setStateStyle(false);
            }
          }
        },
        error: (error: any) => console.error(error),
      });

    this.windowAction$ = merge(
      fromEvent(window, 'scroll'),
      fromEvent(window, 'resize'),
    )
      .pipe(filter(() => this.dropdownState))
      .subscribe({
        next: () => this.setStateStyle(false),
        error: (error: any) => console.error(error),
      });
  }

  ngOnDestroy(): void {
    [this.windowClick$, this.windowAction$, this.toggleEvent$].forEach(($) =>
      $?.unsubscribe(),
    );
  }

  setStateStyle(state: boolean): void {
    this.dropdownState = state;

    /** Add scrollbar or no to content.select-list */

    this.setScrollbarSelectList(this.dropdownState);

    // prettier-ignore
    if (this.dropdownState) {
      setTimeout(() => {
        const elementRefStyle: any = this.elementRef.nativeElement.getBoundingClientRect();

        if (this.setWidth) {
          this.dropdownStateStyle = {
            'width.px': elementRefStyle.width,
          };
        }

        if (this.useScale && this.scaleService.currentScaleSubject.value < 1) {
          this.elementRef.nativeElement.style.position = 'relative';

          this.dropdownStateStyle = {
            ...this.dropdownStateStyle,
            display: 'block',
            position: 'absolute',
            'top.%': 100,
            [`${this.directionX}.px`]: 0,
            'z-index': 3,
          };
        } else {
          this.elementRef.nativeElement.style.position = '';

          this.dropdownStateStyle = {
            ...this.dropdownStateStyle,
            display: 'block',
            position: 'fixed',
            'top.px': elementRefStyle.top + elementRefStyle.height,
            'left.px': elementRefStyle[this.directionX] + this.offsetX,
            'z-index': 11,
          };
        }
      }, 300);
    } else {
      this.dropdownStateStyle = {
        display: 'none'
      };
    }

    this.toggled.emit(this.dropdownState);
  }

  setScrollbarSelectList(state: boolean): void {
    // prettier-ignore
    const scrollbarTimeout = setTimeout(() => {
      const selectList: HTMLUListElement = this.dropdownContent?.nativeElement.querySelector('ul.select-list');

      if (!!selectList) {

        /** Has scrollbar */
        /** Add & Remove for dynamic changes */

        if (state) {
          if (selectList.scrollHeight > selectList.clientHeight) {
            selectList.classList.add('pr-2');
            selectList.parentElement?.classList.add('pr-2');
          }
        } else {
          selectList.classList.remove('pr-2');
          selectList.parentElement?.classList.remove('pr-2');
        }

        clearTimeout(scrollbarTimeout);
      }
    }, 0);
  }
}
