import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UnsubscriptionReason } from '../../../pages/subscriptions-page/models/subscription.model';
import { FormService } from '../../../../../../services/form.service';
import { SubscriptionDialogService } from '../../services/dialog.service';
import { SubscriptionsNew } from '../../models/subscriptions-new.model';

@Component({
  selector: 'app-subscription-cancel-dialog',
  templateUrl: './subscription-reasons-dialog.component.html',
})
export class SubscriptionReasonsDialogComponent {
  formForm: FormGroup;
  formList: Array<{ label: string; value: UnsubscriptionReason }> = [
    {
      label: 'I didn’t figure how to make money',
      value: 'reason-1',
    },
    {
      label: 'It’s too complicated',
      value: 'reason-2',
    },
    {
      label: 'I’m a day trader, it doesn’t work for me',
      value: 'reason-3',
    },
    {
      label: 'I am not reallytrading\\investing right now',
      value: 'reason-4',
    },
    {
      label: 'I think you charge too much',
      value: 'reason-5',
    },
    {
      label: 'I think the data is low quality',
      value: 'reason-6',
    },
    {
      label: 'Other',
      value: 'reason-7',
    },
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { mySubscriptions: SubscriptionsNew },
    private dialog: MatDialogRef<SubscriptionReasonsDialogComponent>,
    private formBuilder: FormBuilder,
    private formService: FormService,
    private dialogService: SubscriptionDialogService,
  ) {
    this.formForm = this.formBuilder.group({
      reason: this.formBuilder.control('', [Validators.required]),
    });
  }

  close(): void {
    this.dialog.close();
  }

  onResetForm(event: Event): void {
    event.preventDefault();
    event.stopPropagation();

    this.formForm.reset();
  }

  onSubmit(): void {
    this.formForm = this.formService.onFormSubmit(this.formForm);

    if (this.formForm.valid) {
      const reason: UnsubscriptionReason = this.formForm.get('reason')?.value;

      this.formForm.reset();
      this.close();

      if (this.data.mySubscriptions.next_payment?.has_discount) {
        this.dialogService.openCanTalkSubscription(this.data);
      } else {
        switch (reason) {
          case 'reason-1':
          case 'reason-2':
          case 'reason-5':
          case 'reason-6':
            this.dialogService.openCanTalkSubscription(this.data);
            break;
          case 'reason-3':
          case 'reason-4':
          case 'reason-7':
            this.dialogService.openGetDiscountSubscription();
            break;
          default:
            console.debug('default switch reason');
            break;
        }
      }
    }
  }
}
