import { Injectable } from "@angular/core";
import { TrialConditions } from "src/app/interfaces/api/payment/domain/trial-conditions";
import { MainHttpClient } from "src/app/services/main-http-client.service";
import { AuthService } from "../../auth/services/auth.service";

@Injectable({
  providedIn: 'root'
})
export class TrialDataService {
  constructor(private http: MainHttpClient,
              private authService: AuthService) {

  }

  async getTrialConditions(): Promise<TrialConditions | null> {
    if (!await this.authService.isAuthenticated()) {
      return null;
    }
    return this.http.post<TrialConditions>('/payment/eligible-for-free-trial');
  }

  async isTrialActive(): Promise<boolean> {
    const permissions = await this.authService.getPermissions();
    const trial = permissions.find(x => x === "trial");
    return trial !== undefined;
  }
}