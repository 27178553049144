import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { filter, first, from, Subscription } from 'rxjs';
import { MainHttpClient } from '../../../../../../../services/main-http-client.service';
import { SnackbarService } from '../../../../../../../services/snackbar.service';
import { SubscriptionsDialogService } from '../services/dialog.service';

@Component({
  selector: 'app-get-discount-dialog',
  templateUrl: './get-discount-dialog.component.html',
})
export class GetDiscountDialogComponent implements OnInit, OnDestroy {
  dialogService$!: Subscription;

  isDisabled: boolean = false;
  currentPrice: number = 0;

  constructor(
    private dialog: MatDialogRef<GetDiscountDialogComponent>,
    private httpClient: MainHttpClient,
    private snackbarService: SnackbarService,
    private dialogService: SubscriptionsDialogService,
  ) {}

  ngOnInit(): void {
    this.dialogService$ = this.dialogService.priceSubject.subscribe(
      (price: number) => (this.currentPrice = price),
    );
  }
  ngOnDestroy(): void {
    [this.dialogService$].forEach(($) => $.unsubscribe());
  }

  close(success: boolean = false): void {
    this.dialog.close(success);
  }

  onGetDiscount(): void {
    this.isDisabled = true;

    // одноразовый метод для получения скидки 50%
    from(this.httpClient.get('/v2/discount'))
      .pipe(first())
      .subscribe({
        next: () => {
          this.close();
          this.isDisabled = false;

          // prettier-ignore
          this.snackbarService.success('You have received a discount for your subscription');
        },
        error: () => {
          this.snackbarService.error('Server Error');

          this.close();
          this.isDisabled = false;
        },
      });
  }
}
