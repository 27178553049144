import { Component, HostBinding } from '@angular/core';
import { WidgetType } from '../models/widget-type.model';
import { TipsModel } from './tips.model';
import { WidgetsService } from '../sevices/widgets.service';

@Component({
  selector: 'app-tips',
  templateUrl: './tips.component.html',
  styleUrls: ['./tips.component.scss']
})
export class TipsComponent {

  tips: Array<TipsModel> = [];

  activeTip = 0;

  displayTips: boolean = false;

  @HostBinding('class.disabled') get display() {
    return !this.displayTips;
  }

  constructor(private widgetsService: WidgetsService) {
    this.widgetsService.subscribe((type: WidgetType) => {
      this.displayTips = true;
      if (type === WidgetType.SectorTrendsEconomic) {
        this.tips = [
          {
            title: "Did you know…",
            content:  `Every Sector correlates with several macroeconomic indicators. It is easier to have
            a projection of how the Price Index will behave and choose relevant sectors, than trying to figure
            out the next movement by looking at stocks. Just click the Sector below the Cycle chart and see what
            macro indicators are relevant. Correlation refers to the long term trend. Winrate - day of macro
            indicator report movement.`
          },
          {
            title: "Did you know…",
            content:  `The cycle stage is calculated by looking at 4 main indicators - GDP, bond yield curves,
            Prices index and Federal reserve rate. We apply the stage of cycle assessment so that you could
            clearly see the cycle development.`
          },
        ];
      } else if (type === WidgetType.SectorTrendsMonetary) {
        this.tips = [
          {
            title: "Did you know…",
            content:  `Monetary conditions are the most influential factor for the market. When money is
            available and is cheap, every institution and retail investor buy  and leverage as much as they
            can. Sectors have a different beta for money flows, technology being the first beneficiary of
            easing conditions.`
          },
          {
            title: "Did you know…",
            content:  `Monetary conditions are the only factor tradeable on facts, not expectations. Investors
            can’t leverage if the money is not there or expensive. But they will, when it’s cheap and excessive`
          },
        ];
      } else if (type === WidgetType.RiskRewardHeatmap) {
        this.tips = [
          {
            title: "Did you know…",
            content:  `Investing is about taking risks lower than potential rewards. An ideal ratio is over 1:3.
            We calculated all the relevant ratios and indicators for each sector and indexes. Than we placed
            them into a risk or reward section based on the numbers compared to benchmarks. So a low PE is a
            potential upside, whereas a negative EPS growth is a potential downside. So now you know exactly what
            your risk-reward ratio is and can act accordingly.`
          },
          {
            title: "Did you know…",
            content:  `You can click the + button to save the sector as your preferred one. The system will then
            offer you the best picks for this sector and the target allocation in your portfolio`
          },
        ];
      } else if (type === WidgetType.MoneyFlow) {
        this.tips = [
          {
            title: "Did you know…",
            content:  `Options data provides us with much more information than volume indicators. You can clearly
            see other traders’ targets, levels of support and the expected timing of the move. The calculation is
            relatively simple. We use open interest and implied volatility change to identify when the deal is
            a short sell or a buy, find the largest positions and most popular trades. Now you can see the
            Support/Resistance/Target levels and have a clear understanding of the crowd expectations.`
          },
          {
            title: "Did you know…",
            content:  `Option bets start to grow several days before the stocks start moving. You can use the
            growth/decline bets change to time your market actions precisely.`
          },
        ];
      } else if (type === WidgetType.BuildingPortfolio) {
        this.tips = [
          {
            title: "Did you know…",
            content: `The most successful investors are not diversifying too much. They make a well calculated
            bet and get the most out of it. The perfect diversification is not earning you money, because the
            falling assets eat profits off the growing ones. It’s only good to preserve the capital, not grow
            it. `
          },
          {
            title: "Did you know…",
            content: `The best bet is having 2 main sectors of 3-5 stocks each and 2-3 secondary sectors of 1-2
            stocks each. It’s easier to pick the right sector and then find the champion stock in it. Remember,
            it is always about competition. The Investor relations managers have to compete to get to your
            portfolio. You choose the best of the best, then you win. Imagine how they would reason you
            should invest in their company and only choose the most promising deals, don’t grab all you can.`
          },
        ]
      } else if (type === WidgetType.PortfolioRiskHeatmap) {
        this.tips = [
          {
            title: "Did you know…",
            content: `One of the best ways to rebalance a portfolio is to reduce risk. Look at the biggest risk
             - the weight, the heat. Sell the worst stocks and improve the overall portfolio. Iteration after
            iteration, your portfolio gets better. `
          },
        ]
      } else if (type === WidgetType.Portfolio) {
        this.tips = [
          {
            title: "Did you know…",
            content: `You can have an easy look at your portfolio's actual state by paying attention to sector
            color and trend arrow, and sources of risk for each stock. Fundamentally good stocks show a green
            F.A. source, Technically good stocks show a green T.A. source`
          },
          {
            title: "Did you know…",
            content: `Analysts target prices are definitely important, but they are a lagging indicator rather
            than a leading one. Analysts raise targets after the market has reacted to news/earnings reports.
            Be careful with those, they might go down and the stock might not come back`
          }
        ]
      } else if (type === WidgetType.SingleStock) {
        this.tips = [
          {
            title: "Did you know…",
            content: `The best way to deal with a vast number of indicators is to group them. We offer you
            several ways to visualize the data - the radar chart to compare the overall assessment against
            the sector, the tables to handpick the indicators you like, and the key risks and opportunities
            picked by our AI.`
          }
        ]
      }
    });
  }

  back() {
    if (this.activeTip != 0) {
      this.activeTip--;
    }
  }

  forward() {
    if (this.activeTip < this.tips.length) {
      this.activeTip++;
    }
  }

}
