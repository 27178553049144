import {
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
} from '@angular/core';
import { IAuthRegistrContentData } from '../../../models/auth-ui.models';
import { RegistrationService } from '../../../services/registration.service';
import { SocialAuthService } from '../../../services/social-auth.service';
import { IAuthSubscriptionPrice } from '../../../../main/dialogs/auth-subscirption-dialog/models/auth-subscription-dialog.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-registration-form-content',
  templateUrl: './registration-form-content.component.html',
  styleUrls: ['./registration-form-content.component.scss'],
})
export class RegistrationFormContentComponent {
  @Output() goToSignIn: EventEmitter<string> = new EventEmitter<string>();
  @Output() loginSuccessful: EventEmitter<void> = new EventEmitter<void>();
  @Input() data: IAuthRegistrContentData = {};
  @Input() signUpSubscription?: IAuthSubscriptionPrice;
  @Input() signUpEnable?: boolean;
  @Input() registerLicense?: TemplateRef<any>;
  form: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    agreedToTerms: new FormControl(false, [Validators.requiredTrue]),
  });

  errorMessage?: string;

  constructor(
    public registrationService: RegistrationService,
    public socialAuthService: SocialAuthService,
  ) {}

  async signUp() {
    if (this.signUpSubscription) {
      //sign up with subscription
      //console.log('sign up with subscription', this.signUpSubscription);
      return;
    }

    if (this.signUpEnable ?? true) {
      await this.registrationService.signUp(this.form.value);

      this.loginSuccessful.emit();
    }
  }

  onClickAgreedToTerms(): void {
    const isAgree: boolean = this.form.get('agreedToTerms')?.value;

    this.form.get('agreedToTerms')?.setValue(!isAgree);
  }

  async googleSignIn() {
    await this.socialAuthService.googleAuth();
    this.loginSuccessful.emit();
  }

  async facebookSignIn() {
    await this.socialAuthService.facebookAuth();
    this.loginSuccessful.emit();
  }
}
