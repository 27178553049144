<ul
  class="flex flex-grow md:flex-grow-0 overflow-hidden divide-x divide-y-0 divide-base-300 border-x border-base-300"
>
  <li
    *ngFor="let menu of items; let first = first"
    [class.active-menu]="
      menu.route === '/events-timeline'
        ? url.includes($any(menu.route))
        : menu.route == url
    "
    [class.reverse-color]="menu.route == '/sectors-and-industries'"
    [class.no-fill]="menu.route == '/profile/subscriptions'"
    [class.blink]="menu.onboardingHint !== undefined"
    class="menu-item flex flex-col flex-center px-[0.5rem] nav-item !py-1.5 cursor-pointer flex-grow md:flex-grow-0 !rounded-none"
  >
    <div class="menu-item-container">
      <a
        *ngIf="menu.route?.includes('?')"
        class="flex flex-col flex-grow flex-center [&_path]:hover:stroke-[#8E92A4] [&>span]:hover:text-[#8E92A4]"
        [href]="menu.route"
      >
        <app-icon *ngIf="menu.icon" [icon]="menu.icon"></app-icon>
        <span class="text-[12px] text-base-500">{{ menu.text.default }}</span>
      </a>
      <a
        *ngIf="!menu.route?.includes('?')"
        class="flex flex-col flex-grow flex-center [&_path]:hover:stroke-[#8E92A4] [&>span]:hover:text-[#8E92A4]"
        (click)="itemClick.emit(menu)"
        [routerLink]="menu.route"
      >
        <app-icon *ngIf="menu.icon" [icon]="menu.icon"></app-icon>
        <span class="text-[12px] text-base-500">{{ menu.text.default }}</span>
      </a>
      <div
        *ngIf="menu.numberOfEvents !== undefined"
        class="menu-item-number-of-events"
      >
        +{{ menu.numberOfEvents }}
      </div>
    </div>
    <app-onboarding-hint
      (closed)="closedOnboardingHint(menu)"
      *ngIf="menu.onboardingHint !== undefined"
    >
      {{ menu.onboardingHint }}
    </app-onboarding-hint>
  </li>
</ul>
