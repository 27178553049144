import { Injectable } from "@angular/core";
import { Params } from "@angular/router";

@Injectable({
    providedIn: "root"
})
export class RedirectUrlService {
    private readonly redirectUrlPattern = "redirectUrl";

    getRedirectUrl(params: Params): string {
        let redirectUrl = params[this.redirectUrlPattern] as string;
        if (redirectUrl?.length > 0) {
            redirectUrl = `${this.redirectUrlPattern}=${redirectUrl}`;
        }

        return redirectUrl;
    }

    getRedirectUrlContent(params: Params): string {
        let redirectUrl = params[this.redirectUrlPattern] as string;
        if (redirectUrl === undefined) {
            return "";
        }
        return decodeURIComponent(redirectUrl);
    }
}