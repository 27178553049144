import { AfterViewChecked, AfterViewInit, Component, ElementRef, Input } from '@angular/core';

import * as d3 from 'd3-selection';
import * as d3Scale from 'd3-scale';
import * as d3Shape from 'd3-shape';
import * as d3Array from 'd3-array';
import { IdService } from 'src/app/services/id.services';
import { Coordinate } from 'src/app/models/coordinate.model';
import { RiskRewardChartModel } from '../../../models/risk-reward-heatmap-item.model';

@Component({
  selector: 'app-risk-reward-heatmap-chart',
  templateUrl: './risk-reward-heatmap-chart.component.html',
  styleUrls: ['./risk-reward-heatmap-chart.component.scss']
})
export class RiskRewardHeatmapChartComponent implements AfterViewChecked {

  @Input() model: RiskRewardChartModel;
  @Input() svgWidth: number;
  @Input() svgHeight: number;

  private margin = {top: 10, right: 0, bottom: 0, left: 0};
  private width: number =  0;
  private height: number = 0;
  private x: any;
  private y: any;
  private svg: any;
  private line: d3Shape.Line<[number, number]>;
  public heatMapId: string;
  private gradiendId: string;
  private rendered = false;

  constructor(public idService: IdService,
              private elementRef: ElementRef)
  {
    this.heatMapId = this.idService.getId("risk-reward-heatmap-chart");
    this.gradiendId = `${this.heatMapId}-gradient`;
  }

  ngAfterViewChecked(): void {
    if (this.elementRef.nativeElement.offsetParent !== null && !this.rendered) {
      this.rendered = true;
      this.width = this.svgWidth - this.margin.left - this.margin.right;
      this.height = this.svgHeight - this.margin.top - this.margin.bottom;
      this.initMap();
    }
  }

  private initMap() {
    this.initSvg();
    this.initAxis();
    this.drawGrid();
    this.drawLine();
    this.drawGradient();
  }

  private initSvg() {
    this.svg = d3.select(`#${this.heatMapId}`)
                 .append("svg")
                 .attr("viewBox", `0 0 ${this.svgWidth} ${this.svgHeight}`);

    this.svg = this.svg.append('g')
            .attr('transform', 'translate(' + this.margin.left + ',' + this.margin.top + ')');
  }

  private initAxis() {
      this.x = d3Scale.scaleLinear().domain([0, 1]).range([0, this.width]);
      this.y = d3Scale.scaleLinear().domain([0, 1]).range([this.height, 0]);
      this.x.domain(this.model.coordinates.map((d) => d.text));
      this.x.domain(d3Array.extent(this.model.coordinates, (d) => d.location.x ));
      this.y.domain(d3Array.extent(this.model.coordinates, (d) => d.location.y ));
  }

  private getX(value: number): number {
    const xStep = this.width / (this.model.coordinates.length - 1);
    return value * xStep;
  }

  private getY(value: number): number {
    const yStep = this.height / this.model.coordinates.length;
    return this.height - (value * yStep);
  }

  private drawLine() {
      this.line = d3Shape.line()
          .x( (d: any) => this.getX(d.location.x) )
          .y( (d: any) => this.getY(d.location.y) );

      this.svg.append('path')
          .datum(this.model.coordinates)
          .attr('d', this.line)
          .attr('transform', 'translate(0, -30)')
          .attr('style', `stroke: ${this.model.color}; fill: none; stroke-width: 2px;`);

      for(let i = 1; i < this.model.coordinates.length - 1; i++) {
        const coordinate = this.model.coordinates[i];
        this.drawText(coordinate.location, coordinate.text);
      }
  }

  private drawGradient() {
    const gradientRect = d3Shape.line()
          .x( (d: any) => this.getX(d.x) )
          .y( (d: any) => this.getY(d.y) );

    let lg = this.svg.append("defs").append("linearGradient")
          .attr("id", this.gradiendId) //id of the gradient
          .attr("x1", "0%")
          .attr("x2", "0%")
          .attr("y1", "0%")
          .attr("y2", "100%"); //since its a vertical linear gradient

          lg.append("stop")
          .attr("offset", "0%")
          .style("stop-color", `${this.model.color}`) //end in red
          .style("stop-opacity", .2);

          lg.append("stop")
          .attr("offset", "100%")
          .style("stop-color", "#fff") //start in blue
          .style("stop-opacity", .1);

    const gradientCoordinates: Array<Coordinate> = [
      { x: 0, y: -1 },
      ...this.model.coordinates.map(c => c.location),
      { x: this.model.coordinates[this.model.coordinates.length - 1].location.x, y: -1 }
    ]

    this.svg.append('path')
          .datum(gradientCoordinates)
          .attr('d', gradientRect)
          .attr('transform', 'translate(0, -30)')
          .attr('style', `stroke: none; fill: url(#${this.gradiendId}); stroke-width: 2px;`);
  }

  private drawText(location: Coordinate, text: string) {
      this.svg.append("text")
      .attr('x', this.getX(location.x))
      .attr('y', this.height - 10)
      .attr('class', 'risk-reward-heatmap-x-axis-item')
      .attr('style', `fill: #333333A8; font-size: 12px; font-weight: 300; transform: translate(-10px, 0px);`)
      .text(text);
  }

  private drawGrid() {
    const numberOfLines = 4;
    for(let i = 0; i <= numberOfLines; i++) {
      let gridLine = d3Shape.line()
        .x( (d: any) => d.x )
        .y( (d: any) => d.y );

      let lineY = this.height / numberOfLines * i;
      this.svg.append("g").append('path')
        .datum([{ x: 0, y: lineY }, { x: this.width, y: lineY }])
        .attr('d', gridLine)
        .attr('stroke-dasharray', "4 4")
        .attr('style', `stroke: #A9AEC333; stroke-width: 1px; fill: none;`);
    }
  }

  private drawPoints() {

    this.model.points.forEach(point => {
      this.svg.append('circle')
              .attr('cx', this.x(point.x))
              .attr('cy', this.y(point.y))
              .attr('r', 7)
              .attr('stroke', '#fff')
              .attr('stroke-width', '4px')
              .attr('fill', this.model.color);
    });
  }

}
