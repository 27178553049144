import { INavigationMenu } from 'src/app/modules/shared/modules/basic/structures/app';
import { Icon } from 'src/app/modules/shared/modules/basic/structures/ui';
import { TextUtil } from 'src/app/modules/shared/modules/basic/utils/text';

const menus = ['Key Events', 'Money Flows', 'My Portfolio', 'Set Alerts'];
export const NAV_ROUTE_MAP = new Map([
  ['key-events', 'events-timeline'],
  ['money-flows', 'money-flow?type=markets'],
  ['sector-data', 'sectors-and-industries'],
  ['my-portfolio', 'portfolio'],
  ['my-subscriptions', 'profile/subscriptions'],
  ['portfolio', 'portfolio'],
  ['myportfolio', 'portfolio'],
  ['set-alerts', 'alerts'],
  ['premium', 'premium'],
  ['visuals', 'sector-visuals'],
  ['privacy-policy', 'pages/privacy-policy'],
  ['markets', 'money-flow?type=markets'],
  ['sectors', 'money-flow?type=sectors'],
  ['portfolioflowsheatmap', 'portfolio-money-flow'],
]);

export const NAVIGATION_MENUS: INavigationMenu[] = menus.map((item) => {
  const converted = TextUtil.camelCaseToSnakeCase(item);
  return {
    text: { default: item },
    route: '/' + NAV_ROUTE_MAP.get(converted),
    icon: converted,
  };
});

export const MOBILE_NAVIGATION_MENUS: INavigationMenu[] = [
  {
    text: {
      default: 'KeyEvents',
    },
    route: '/events-timeline',
    icon: Icon.KeyEvents,
  },
  {
    text: {
      default: 'MoneyFlows',
    },
    route: '/money-flow?type=markets',
    icon: Icon.MoneyFlows,
  },
  {
    text: {
      default: 'Portfolio',
    },
    route: '/portfolio',
    icon: Icon.MyPortfolio,
  },
  {
    text: {
      default: 'Alerts',
    },
    route: '/alerts',
    icon: Icon.SetAlets,
  },
  {
    text: {
      default: 'Premium',
    },
    route: '/premium',
    icon: Icon.Crown,
  },
  {
    text: {
      default: 'Profile',
    },
    route: '/profile/subscriptions',
    icon: Icon.Profile,
  },
];
