<ng-container *ngIf="formType === AuthFormType.SignIn">
    <app-login-form (goToSignUp)="formType = AuthFormType.SignUp"
                    (goResetPassword)="formType = AuthFormType.ResetPassword"
                    (loginSuccessful)="loginSuccessful()"></app-login-form>
</ng-container>

<ng-container *ngIf="formType === AuthFormType.SignUp">
    <app-registration-form (goToSignIn)="formType = AuthFormType.SignIn"
                           [signUpEnable]="true"
                           (loginSuccessful)="loginSuccessful()"></app-registration-form>
</ng-container>

<ng-container *ngIf="formType === AuthFormType.ResetPassword">
    <app-reset-password-form (goToSignIn)="formType = AuthFormType.SignIn"></app-reset-password-form>
</ng-container>
