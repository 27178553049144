import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { POPULAR_STOCKS } from '../search-result/data/search-result.data';
import { Ticker } from '../../../../interfaces/api/domain/ticker';
import { debounceTime, filter, Subscription, tap } from 'rxjs';
import { AnalyticService } from '../../../../services/analytic.service';
import { Router } from '@angular/router';
import { UserTypeModel } from '../../models/user-type.model';
import { PortfolioService } from '../../services/portfolio.service';
import { Portfolio } from '../../../main/portfolio-new/models/portfolio.model';

class Stock {
  ticker: Ticker;
  added: boolean;
}

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
})
export class SearchComponent implements OnInit, OnDestroy {
  @ViewChild('searchEl') searchEl: ElementRef | undefined;

  @Input()
  set appBorder(border: boolean) {
    this.border = border;
  }

  @Input()
  set appAnalyticGoal(analyticGoal: string) {
    this.analyticFocusGoal = analyticGoal;
  }

  @Output() isOpened: EventEmitter<boolean> = new EventEmitter();

  changePortfolioService$!: Subscription | undefined;

  searchForm$!: Subscription | undefined;
  searchForm: FormGroup;
  isFocus: boolean = false;

  searchResultsTitle: string = 'Popular';
  popularStocks: Stock[] = POPULAR_STOCKS;
  searchResults: Stock[] = [];
  addedStocks: Array<Stock> = [];

  border: boolean = false;

  isShowLinkToPortfolio: boolean = false;

  analyticFocusGoal: string | null = null;

  // @ts-ignore
  focusTimeout: NodeJS.Timeout | undefined = undefined;

  userType: UserTypeModel = UserTypeModel.Unregistered;
  portfolioTickerList: Portfolio[] = [];

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private portfolioService: PortfolioService,
    private analyticService: AnalyticService,
  ) {
    // prettier-ignore
    this.searchForm = this.formBuilder.group({search: this.formBuilder.control('', [Validators.required])});
  }

  // prettier-ignore
  ngOnInit(): void {
    this.searchForm$ = this.searchForm.get('search')
      ?.valueChanges.pipe(
        debounceTime(1000),
        tap((value: string) => this.setSearchResultsTitle(value?.trim() || '')),
        filter((value: string) => !!value?.trim()),
      )
      .subscribe((value: string) => this.loadSearchResults(value).then());

    this.setSearchResults(this.popularStocks);
    this.loadPopularStocks().then();

    this.changePortfolioService$ = this.portfolioService.currentPortfolioSubject
      .subscribe((portfolio: Portfolio[]) => {
        this.portfolioTickerList = portfolio;

        !this.searchForm.get('search')?.value && this.setSearchResults(this.popularStocks);
      });
  }

  ngOnDestroy(): void {
    [this.searchForm$, this.changePortfolioService$].forEach(($) =>
      $?.unsubscribe(),
    );
  }

  private setSearchResultsTitle(value: string) {
    if (value.length > 0) {
      this.searchResultsTitle = `Search by \"${value.substring(0, 10)}\"`;
    } else {
      this.searchResultsTitle = 'Popular';

      this.setSearchResults(this.popularStocks);
    }
  }

  private async loadPopularStocks(): Promise<void> {
    for (let s of this.popularStocks) {
      this.portfolioService
        .searchTicker({
          searchText: s.ticker.ticker,
          searchByWords: true,
          itemsPerPage: 1,
        })
        .then((x) => {
          const foundTicker: Ticker | null = x.tickers[0] || null;
          if (foundTicker?.ticker !== s.ticker.ticker) {
            console.error(
              `Failed to find stock with ticker [${s.ticker.ticker}]`,
            );
          } else {
            s.ticker.id = foundTicker.id;
            s.ticker.name = foundTicker.name;
          }
        });
    }
  }

  async loadSearchResults(searchText: string) {
    const tickers = await this.portfolioService.searchTicker({
      searchText: searchText,
      searchByWords: true,
      itemsPerPage: 20,
    });

    const mapTicker = (ticker: Ticker): Stock => {
      return {
        // @ts-ignore
        ticker: {
          id: ticker.id,
          ticker: ticker.ticker,
          name: ticker.name,
        },
        added: false,
      };
    };
    // @ts-ignore
    this.setSearchResults(tickers.tickers.sort().map(mapTicker));

    this.isShowLinkToPortfolio = false;
  }

  // prettier-ignore
  setSearchResults(stockList: Stock[]): void {
    this.searchResults = stockList.map((stock) => {
      const isContains: Portfolio | undefined = this.portfolioTickerList.find((ticker) => {
        return stock.ticker.ticker === ticker.ticker;
      });

      return { ...stock, added: !!isContains };
    });
  }

  // prettier-ignore
  addStock(stock: Stock, event: Event): void {
    event.preventDefault();
    event.stopPropagation();

    this.portfolioService
      .addTicker({ tickerId: [stock.ticker.id], price: 0, quantity: 0 })
      .then((): void => {
        stock.added = true;
        this.addedStocks.push(stock);

        const portfolioList: Portfolio[] = this.portfolioService.currentPortfolioSubject.getValue();
        const name: string = stock.ticker.ticker;

        this.analyticService.send('add_ticker', new Map([['name', name], ['count_tickers', `${portfolioList.length + 1}`]]));

        this.isShowLinkToPortfolio = true;

        this.portfolioService.changePortfolioSubject.next(true);
      });
  }

  removeStock(stock: Stock, event: Event): void {
    event.preventDefault();
    event.stopPropagation();

    stock.added = false;
    const index: number = this.addedStocks.findIndex(
      (s: Stock): boolean => s.ticker === stock.ticker,
    );
    this.addedStocks.splice(index, 1);

    this.portfolioService
      .removeTicker({
        tickerId: stock.ticker.id,
      })
      .then(() => {
        this.portfolioService.changePortfolioSubject.next(true);
      });
  }

  onFocus(setFocus: boolean): void {
    if (!this.focusTimeout) {
      this.focusTimeout = setTimeout(() => {
        if (setFocus) {
          this.searchEl?.nativeElement.focus();

          /** отправка метрик */
          if (this.analyticFocusGoal) {
            this.analyticService.send(this.analyticFocusGoal);
          }
        } else {
          this.searchEl?.nativeElement.blur();
        }

        this.isFocus = setFocus;

        this.isOpened.emit(this.isFocus);

        this.focusTimeout = undefined;
      }, 100);
    }
  }

  onClear(): void {
    this.searchForm.reset();

    this.isShowLinkToPortfolio = false;

    this.onFocus(false);
  }

  onOpenStockPage(stock: Stock) {
    this.router
      .navigate(['single-stock', { ticker: stock.ticker.ticker }])
      .then(() => {
        this.onClear();

        console.debug('Change router');
      });
  }
}
