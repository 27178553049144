<div
  class="flex flex-col items-center justify-center"
  [class.!gap-12]="size === 'lg'"
  [class.!gap-8]="size === 'md'"
  [class.!gap-6]="size === 'sm'"
  [class.!gap-4]="size === 'xs'"
  [ngStyle]="{ height }"
>
  <div class="flex animate-spin">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 72 72"
      fill="none"
      [style]="{ width: widthSvg }"
    >
      <g clip-path="url(#clip0_4427_53050)">
        <circle cx="36" cy="6.75" r="6.75" fill="#303D50" fill-opacity="0.1" />
        <circle
          opacity="0.45"
          cx="36"
          cy="65.25"
          r="6.75"
          fill="#303D50"
          fill-opacity="0.65"
        />
        <circle cx="6.75" cy="36" r="6.75" fill="#303D50" fill-opacity="0.85" />
        <circle
          cx="65.25"
          cy="36"
          r="6.75"
          fill="#303D50"
          fill-opacity="0.35"
        />
        <circle
          cx="58.5"
          cy="15.75"
          r="6.75"
          fill="#303D50"
          fill-opacity="0.25"
        />
        <circle
          cx="58.5"
          cy="56.25"
          r="6.75"
          fill="#303D50"
          fill-opacity="0.45"
        />
        <circle
          cx="13.5"
          cy="56.25"
          r="6.75"
          fill="#303D50"
          fill-opacity="0.75"
        />
        <circle cx="13.5" cy="15.75" r="6.75" fill="#303D50" />
      </g>
      <defs>
        <clipPath id="clip0_4427_53050">
          <rect width="72" height="72" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </div>
  <span
    class="text-default-neutral-3"
    [class.text-interface-title-3-regular]="size === 'lg'"
    [class.text-interface-title-4-regular]="size === 'md'"
    [class.text-interface-title-5-regular]="size === 'sm'"
    [class.text-interface-title-6-regular]="size === 'xs'"
  >
    Loading...
  </span>
</div>
