import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  LoginRequest,
  LoginRPCAnswer,
} from '../../../../interfaces/api/auth/login';
import { UtmService } from '../../../shared/modules/utm/services/utm.service';
import { AuthService } from '../../services/auth.service';
import { AnalyticService } from '../../../../services/analytic.service';
import { AuthFormService } from '../../services/auth-form.service';
import { EventsService, EventType } from '../../../../services/events.service';
import {
  AuthFormParametersModel,
  AuthFormParametersPlan,
} from '../../models/auth-form-parameters.model';
import { TrialDataService } from '../../../main/services/trial-data.service';
import { PremiumService } from '../../../main/premium/services/premium.service';
import { TrialConditions } from '../../../../interfaces/api/payment/domain/trial-conditions';
import { SubscribeResponse } from '../../../../interfaces/api/payment/subscribe';
import { ToastService } from '../../../shared/services';
import { SocialAuthService } from '../../services/social-auth.service';
import { HelperService } from '../../../../services/helper.service';

@Component({
  selector: 'app-auth-sign-in',
  templateUrl: './auth-sign-in.component.html',
})
export class AuthSignInComponent {
  signInForm: FormGroup;
  signInFormIsSubmitting: boolean = false;

  passwordFieldType: 'text' | 'password' = 'password';

  parameters: AuthFormParametersModel | undefined;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialogRef<AuthSignInComponent>,
    private formBuilder: FormBuilder,
    private utmService: UtmService,
    private authFormService: AuthFormService,
    private authService: AuthService,
    private analyicService: AnalyticService,
    private eventService: EventsService,
    private trialService: TrialDataService,
    private premiumService: PremiumService,
    private toastService: ToastService,
    public socialAuthService: SocialAuthService,
    private helperService: HelperService,
  ) {
    this.signInForm = this.formBuilder.group({
      email: this.formBuilder.control('', [
        Validators.required,
        Validators.email,
      ]),
      password: this.formBuilder.control('', [
        Validators.required,
        Validators.minLength(6),
      ]),
    });

    this.parameters = data.parameters;
  }

  onClose(): void {
    this.dialog.close();
  }

  async submitSignIn(): Promise<void> {
    if (this.helperService.getFormValidation(this.signInForm)) {
      try {
        this.signInFormIsSubmitting = true;

        const loginData: LoginRequest = {
          email: this.signInForm.controls['email'].value.toLocaleLowerCase(),
          password: this.signInForm.controls['password'].value,
        };

        const utm = this.utmService.getUtmData();
        if (utm) {
          loginData.utm = utm;
        }

        const loginRPCAnswer: LoginRPCAnswer = await this.authService.login(
          loginData,
        );

        const userId = loginRPCAnswer.userId;

        this.utmService.clearUtmData();

        this.loginSuccessful();

        // prettier-ignore
        this.analyicService.send('login_success', new Map([['user_id', userId]]));

        this.signInFormIsSubmitting = false;
      } catch (error: any) {
        this.signInFormIsSubmitting = false;
      }
    }
  }

  googleSignIn(): void {
    this.socialAuthService.googleAuth().then(() => {
      this.loginSuccessful();
    });
  }

  loginSuccessful() {
    if (this.parameters?.redirectUrl != null) {
      if (this.parameters.redirectUrl.includes('/profile/subscriptions')) {
        this.trialService
          .getTrialConditions()
          .then((conditions: TrialConditions | null) => {
            if (!conditions?.trialAvailable) {
              this.goToStripe(this.parameters?.plan!);
            } else {
              if (this.parameters?.redirectUrl) {
                document.location.href = this.parameters.redirectUrl;
              }
            }
          });
      } else {
        document.location.href = this.parameters.redirectUrl;
      }

      this.eventService.emit(EventType.UserTypeChanged);

      this.onClose();
    } else {
      location.reload();
    }
  }

  private goToStripe(plan: AuthFormParametersPlan) {
    try {
      this.premiumService
        .subscribe(plan.planId, plan.priceId, plan.interval)
        .then((response: SubscribeResponse) => {
          if (response.needToPay) {
            window.location.href = response.paymentUrl!;
          }
        });
    } catch (e) {
      this.toastService.show('Failed to upgrade plan. Please try again');
    }
  }

  togglePasswordFieldEye() {
    if (this.passwordFieldType === 'password') {
      this.passwordFieldType = 'text';
    } else {
      this.passwordFieldType = 'password';
    }
  }

  onGoToSignUp(): void {
    this.onClose();

    this.authFormService.openSignUp();
  }

  onGoToReset(): void {
    this.onClose();

    this.authFormService.openResetPassword();
  }
}
