import { AbstractControl } from '@angular/forms';

export interface UserProfile {
  id: string;
  name: string;
  email: string;
  oauth_driver: null | OAuthDriver;
  is_password_specified: boolean;
  created_at: string;
  updated_at: string;
}

export interface UserProfileResponse {
  status: string;
  data: UserProfile;
}

export interface EmailAction {
  message: string;
  token: string;
  email: UserProfile['email'];
}

export interface EmailActionResponse {
  status: string;
  data: EmailAction;
}

export interface EmailChangedResponse {
  status: string;
  data: {
    user: UserProfile;
    message: string;
  };
}

export enum OAuthDriver {
  GOOGLE = 'google',
}

export enum ChangeEmailDialogType {
  CURRENT_EMAIL_CODE = 'enterCurrentEmailCode',
  ENTER_NEW_EMAIL = 'enterNewEmail',
  NEW_EMAIL_CODE = 'enterNewEmailCode',
  CHANGED_SUCCESSFULLY = 'emailChangedSuccessfully',
}

export interface ResetPasswordResponse {
  status: string;
  data: {
    message: string;
  };
}

export const checkPasswords = (group: AbstractControl): null => {
  const newPasswordControl = group.get('newPassword');
  const confirmPasswordControl = group.get('confirmNewPassword');
  const password = newPasswordControl?.value;
  const confirmPassword = confirmPasswordControl?.value;

  if (password !== confirmPassword) {
    newPasswordControl?.setErrors({
      ...(newPasswordControl?.errors || {}),
      notMatch: true,
    });
    confirmPasswordControl?.setErrors({
      ...(confirmPasswordControl?.errors || {}),
      notMatch: true,
    });
  } else {
    if (newPasswordControl?.errors) {
      const { notMatch, ...otherErrors } = newPasswordControl.errors;
      newPasswordControl.setErrors(
        Object.keys(otherErrors).length ? otherErrors : null,
      );
    }
    if (confirmPasswordControl?.errors) {
      const { notMatch, ...otherErrors } = confirmPasswordControl.errors;
      confirmPasswordControl.setErrors(
        Object.keys(otherErrors).length ? otherErrors : null,
      );
    }
  }

  return null;
};
