import { Injectable } from "@angular/core";
import { arc } from "d3";
import { Coordinate } from "src/app/models/coordinate.model";
import { SectorNamePositionAlign, SectorNamePositionModel } from "../models/diagram-position.model";

declare var $:any;

@Injectable()
export class DiagramPositionService {

  private readonly diagramCenterSize: number = 20;

  getPositions(diagramSelector: string, arcs: Array<number>, gap: number = 0, margin: number =  1.15): Array<SectorNamePositionModel> {

    const res: Array<SectorNamePositionModel> = [];

    const svg = $(diagramSelector);
    const diagramRadius = (svg.width() / 2);
    const diagramCenter = {
      x: svg.offset().left + diagramRadius,
      y: svg.offset().top + diagramRadius,
    };
    for(let i = 0; i < arcs.length - 1; i++) {
      const pos = this.getPosition(arcs[i], arcs[i + 1] - gap, diagramCenter, diagramRadius, margin);
      res.push(pos);
    }

    const lastPosition = this.getPosition(arcs[arcs.length - 1], arcs[0] - gap, diagramCenter, diagramRadius, margin);
    res.push(lastPosition);
    return res;
  }

  getPosition(startAngle: number,
              endAngle: number,
              diagramCenter: Coordinate,
              diagramRadius: number,
              bigRadiusCoef: number): SectorNamePositionModel {

    const sectionCenterPoint = this.getSectionCenter(diagramCenter, diagramRadius, startAngle, endAngle, bigRadiusCoef);
    const align = this.getAlign(startAngle, endAngle);
    return {
      left: `${sectionCenterPoint.x}px`,
      top: `${sectionCenterPoint.y}px`,
      align: align
    }
  }

  getAlign(start: number, end: number): SectorNamePositionAlign {
    let align: SectorNamePositionAlign = {
      flex: "center",
      text: "center"
    };
    const distanceOfEndAngleToCenter = end - 180;
    const distanceOfStartAngleToCenter = start - 180;

    if (Math.abs(distanceOfEndAngleToCenter) > this.diagramCenterSize || // if section not in center
        Math.abs(distanceOfStartAngleToCenter) > this.diagramCenterSize) {

        if (distanceOfEndAngleToCenter < 0 && distanceOfStartAngleToCenter < 0) { // right side
          align.flex = "flex-start";
          align.text = "start";
        } else if (distanceOfEndAngleToCenter > 0 && distanceOfStartAngleToCenter > 0) { // left side
          align.flex = "flex-end";
          align.text = "end";
        } else if (Math.abs(distanceOfEndAngleToCenter) < Math.abs(distanceOfStartAngleToCenter)) {
          // the biggest part in right side
          align.flex = "flex-start";
          align.text = "start";
        } else {
          // the biggest part in left side
          align.flex = "flex-end"
          align.text = "end"
        }
    }

    return align;
  }

  getSectionCenter(diagramCenter: Coordinate,
                   diagramRadius: number,
                   startAngle: number,
                   endAngle: number,
                   bigRadiusCoef: number): Coordinate {
    if (startAngle > endAngle) {
      endAngle += 360;
    }
    let sectionCenterAngle = (startAngle + endAngle) / 2;
    const sectionCenterPoint  = this.getPointOnCircle(diagramCenter.x,
                                                      diagramCenter.y,
                                                      diagramRadius,
                                                      sectionCenterAngle,
                                                      bigRadiusCoef);
    return sectionCenterPoint;
  }

  getPointOnCircle(x0: number, y0: number, radius: number, angle: number, bigRadiusCoef: number): Coordinate {
    const angleInRadian = (angle * Math.PI) / 180;
    const bigRadius =  radius * bigRadiusCoef;
    const x = bigRadius * Math.sin(angleInRadian) + x0;
    const y = -(bigRadius * Math.cos(angleInRadian)) + y0;
    return {
      x: x,
      y: y
    }
  }
}
