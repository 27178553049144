<ng-container *ngIf="plan; else loader">
  <div class="title-block">
    <span class="title">{{ plan.name }}</span>
    <span *ngIf="plan.active" class="active">active</span>
  </div>

  <div *ngIf="plan.prices" class="flex mt-[0.75rem] mb-[11px]">
    <ng-container *ngFor="let price of plan.prices">
      <div *ngIf="price.active" class="flex min-w-[65px]">
        <div class="text-lg flex-shrink-0 font-semibold">
          <span appCountUp [count]="price.amount" [duration]="1000">{{
            price.amount
          }}</span
          ><span>{{ price.currency }}</span>
        </div>
      </div>
    </ng-container>

    <app-menu-list
      *ngIf="plan.periods"
      [menus]="menus"
      [activeId]="activePeriod"
      (menuClick)="initPrice($event.id)"
    ></app-menu-list>
  </div>

  <div *ngIf="plan.payment" class="payment-block">
    <div class="payment">
      <span class="text">Payment</span>
      <span class="price">{{
        plan.payment.price.amount +
          plan.payment.price.currency +
          '/' +
          plan.payment.price.period.substr(0, 2)
      }}</span>
    </div>
    <div *ngIf="plan.payment.nextPaymentDate" class="date-block">
      <span class="text">Next payment date</span>
      <span class="date">{{ plan.payment.nextPaymentDate }}</span>
    </div>
  </div>

  <div *ngIf="plan.options" class="options-block">
    <div *ngFor="let item of plan.options" class="item">
      <div class="content">
        <app-checkbox [checked]="item.active"></app-checkbox>
        <span class="text">{{ item.text }}</span>
      </div>
      <span *ngIf="!item.active" class="link" (click)="optionClick(item)"
        >Start using Now!</span
      >
    </div>
  </div>

  <app-button
    [text]="plan.active ? 'Unsubscribe' : 'Upgrade'"
    [color]="{
      bg: plan.active ? 'bg-danger-500' : 'bg-primary-700',
      text: 'text-primary-content'
    }"
    (click)="planClick()"
    class="mt-9 button self-center"
    [classes]="
      plan.active && isDisabledUnsubscribe
        ? 'cursor-not-allowed pointer-events-none py-[1rem] px-8 min-w-[200px]'
        : 'py-[1rem] px-8 min-w-[200px]'
    "
  ></app-button>
</ng-container>

<ng-template #loader>
  <div class="flex flex-col gap-[0.5rem]">
    <div
      *ngFor="let item of ['h-4 w-1/2', 'h-6 w-4/5', 'h-40']"
      [class]="item"
      class="rounded-md bg-base-200"
    ></div>
  </div>
</ng-template>
