import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CashFlowExposureDataChangeTypeKey } from '../models/cash-flow-exposure.model';

@Injectable({
  providedIn: 'root',
})
export class ExposureChangeTypeService {
  // prettier-ignore
  currentExposureChangeTypeSubject: BehaviorSubject<CashFlowExposureDataChangeTypeKey> = new BehaviorSubject<CashFlowExposureDataChangeTypeKey>('1d');
}
