<ng-container>
  <!-- LOCKED -->
  <ul class="flex pointer-events-auto">
    <li
      class="flex flex-col min-w-[238px] items-center justify-center bg-[#ffffff26] !p-6 rounded-lg !gap-4 locked-shadow backdrop-filter backdrop-blur-sm absolute z-[1]"
      [style]="{
        top: (locked.top < 0 ? 0 : locked.top) + 'px',
        left: locked.left + svgTranslateX + 'px'
      }"
      *ngFor="let locked of cashFlowExposureLockedList"
      (click)="onShowIndicator()"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.99935 13.3333V9.33333C7.99935 7.2116 8.8422 5.17676 10.3425 3.67647C11.8428 2.17618 13.8776 1.33333 15.9993 1.33333C18.1211 1.33333 20.1559 2.17618 21.6562 3.67647C23.1565 5.17676 23.9993 7.2116 23.9993 9.33333V13.3333H25.3327C27.5418 13.3333 29.3327 15.1242 29.3327 17.3333V26.6667C29.3327 28.8758 27.5418 30.6667 25.3327 30.6667H6.66602C4.45688 30.6667 2.66602 28.8758 2.66602 26.6667V17.3333C2.66602 15.1242 4.45688 13.3333 6.66602 13.3333H7.99935ZM12.2281 5.56209C13.2283 4.5619 14.5849 3.99999 15.9993 3.99999C17.4138 3.99999 18.7704 4.5619 19.7706 5.56209C20.7708 6.56229 21.3327 7.91884 21.3327 9.33333V13.3333H10.666V9.33333C10.666 7.91884 11.2279 6.56229 12.2281 5.56209ZM6.66602 16C5.92964 16 5.33268 16.5969 5.33268 17.3333V26.6667C5.33268 27.403 5.92964 28 6.66602 28H25.3327C26.0691 28 26.666 27.403 26.666 26.6667V17.3333C26.666 16.5969 26.0691 16 25.3327 16H6.66602Z"
          fill="#393939"
        />
      </svg>
      <button
        type="button"
        class="block bg-[#62C37D] text-[#FFF] text-lg leading-[24px] font-semibold !rounded py-2 px-3"
      >
        Show indicators
      </button>
      <!-- prettier-ignore -->
      <span
        class="block text-base leading-[20px] font-semibold tracking-[-0.08px]"
      >
        {{ userType === 0 ? 'Free Sign Up' : userType === 1 ? 'Get Free Trial' : 'Upgrade' }}
      </span>
    </li>
  </ul>
</ng-container>
