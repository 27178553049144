import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class IdService {
  private trendCount = 0;
  private readonly zeroCharcode: number = "0".charCodeAt(0);
  private readonly nineCharcode: number = "9".charCodeAt(0);
  private readonly aCharcode: number = "a".charCodeAt(0);
  private readonly zCharcode: number = "z".charCodeAt(0);

  getId(seed: string): string {
    const pref = this.replaceExtraChracters(seed.toLocaleLowerCase());
    let id = `${pref}-trend-${this.trendCount}`;
    this.trendCount++;
    return id;
  }

  replaceExtraChracters(str: string): string {
    let res = "";
    for(let c of str) {
      const code = c.charCodeAt(0);
      if ((code >= this.zeroCharcode && code <= this.nineCharcode) ||
          (code >= this.aCharcode && code <= this.zCharcode)) {
            res += c;
      } else {
        res += '-';
      }
    }

    return res;
  }
}
