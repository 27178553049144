import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { ContextMenuItem, Icon, Point } from '../../structures/ui';

@Component({
  selector: 'app-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss'],
})
export class ContextMenuComponent implements AfterViewInit {
  @Input() items: ContextMenuItem[] = [];
  @Input() position?: Point;
  @Input() checkParent = true;
  @Input() classes = "";

  @Output() itemClick = new EventEmitter();
  @Output() itemToggle = new EventEmitter<ContextMenuItemToggleEvent>();

  get getIcon() {
    return Icon;
  }

  constructor(private host: ElementRef) {}

  ngAfterViewInit(): void {
    if (this.host) {
    }
  }

  toggleChange(item: ContextMenuItem) {
    item.toggle = !item.toggle;
    this.itemToggle.emit({ id: item.id, value: item.toggle });
  }
}

export interface ContextMenuItemToggleEvent {
  id: string;
  value: boolean;
}
