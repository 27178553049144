import {
  SubscriptionModel,
} from '../models/subscription.model';

export const PRO: SubscriptionModel.IPlan = {
  plan: SubscriptionModel.Plan.Pro,
  name: 'Pro Plan',
  active: true,
  payment: {
    price: {
      amount: 24.99,
      currency: '$',
      period: SubscriptionModel.Period.Monthly,
    },
    nextPaymentDate: '23 January 2023',
  },
  options: [
    {
      text: 'Full data access',
      active: true,
    },
    {
      text: 'Unlimited Portfolio stocks',
      active: true,
    },
    {
      id: 'your-id',
      text: 'Unlimited alerts',
    },
  ],
  planId: ""
};

export const ULTRA_PREMIUM: SubscriptionModel.IPlan = {
  plan: SubscriptionModel.Plan.Pro,
  name: 'Ultra Premium',
  prices: [
    {
      amount: 54.95,
      currency: '$',
      period: SubscriptionModel.Period.Monthly,
      active: true,
    },
    {
      amount: 648,
      currency: '$',
      period: SubscriptionModel.Period.Annually,
    },
  ],
  options: [
    {
      text: 'Your Portfolio in depth stocks breakdown',
      active: true,
    },
  ],
  periods: [SubscriptionModel.Period.Monthly, SubscriptionModel.Period.Annually],
  planId: ""
};

export const ANNUAL_PAYMENT: SubscriptionModel.IAnnualPayment = {
  discount: '30%',
  amount: '194,95$',
  planId: ""
};
