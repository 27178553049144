import { Component, OnDestroy, OnInit } from '@angular/core';
import { SubscriptionDialogService } from './services/dialog.service';
import { MainHttpClient } from '../../../../services/main-http-client.service';
import { debounceTime, first, from, Subscription } from 'rxjs';
import {
  SubscriptionChange,
  SubscriptionChangeTo,
  SubscriptionFree,
  SubscriptionsNew,
} from './models/subscriptions-new.model';
import { SubscriptionChangeService } from './services/subscription-change.service';
import {
  MeteredUsageInfo,
  Tariff,
  TariffPrice,
} from '../../premium-new/models/premium-new.model';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { EventsService, EventType } from '../../../../services/events.service';
import { AuthService } from '../../../auth/services/auth.service';
import { UserTypeModel } from '../../../shared/models/user-type.model';

@Component({
  selector: 'app-subscriptions-new',
  templateUrl: './subscriptions-new.component.html',
})
export class SubscriptionsNewComponent implements OnInit, OnDestroy {
  routeQueryParams$!: Subscription;
  subscriptionChangeService$!: Subscription;

  mySubscriptionsNew: SubscriptionsNew | undefined | null = undefined;
  meteredUsageInfo: MeteredUsageInfo | undefined = undefined;
  nextPaymentDate: Date | undefined = undefined;
  leftDaysOfTrial: number = 0;

  tariffList: Tariff[] = [];
  priceMonth: TariffPrice | undefined;
  priceYear: TariffPrice | undefined;
  pricePayIfYouWinMonth: TariffPrice | undefined;

  billingUrl: string = '';
  paymentUrl: string | null = null;

  amountOfMoneySaved: number = 0;

  claimStatus: 'pending' | 'approved' | 'denied' | null = null;

  userType: UserTypeModel = UserTypeModel.Unregistered;

  constructor(
    private http: MainHttpClient,
    private dialogService: SubscriptionDialogService,
    private subscriptionChangeService: SubscriptionChangeService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private cookieService: CookieService,
    private authService: AuthService,
    private eventService: EventsService,
  ) {}

  // prettier-ignore
  ngOnInit(): void {
    this.routeQueryParams$ = this.activatedRoute.queryParams
      .pipe(debounceTime(100))
      .subscribe({
        next: (queryParams: Params) => {
          if ('pay-status' in queryParams) {
            switch (queryParams['pay-status']) {
              case 'cancel':
                this.dialogService.openPayCancelDialog();
                break;
              case 'success':
                this.openSuccessModal();
                break;
              default:
                break;
            }
          }
        },
      });

    this.subscriptionChangeService$ =
      this.subscriptionChangeService.currentChangeSubject.subscribe(
        (subscriptionChange: SubscriptionChange) => {
          switch (subscriptionChange) {
            case 'info':
            case 'change':
              this.getMySubscription();
              break;
            case 'renew':
              this.getRenewSubscription();
              break;
            case 'cancel':
              this.getCancelSubscription();
              break;
            case 'discount':
              this.getDiscountSubscription();
              break;
            default:
              console.debug('default switch subscription change', subscriptionChange);
              break;
          }
        },
      );

    this.getTariffList();

    this.eventService.subscription().subscribe(async (type) => {
      if (type === EventType.UserTypeChanged) {
        this.getUserType();
      }
    });

    this.getUserType();
  }

  ngOnDestroy(): void {
    [this.subscriptionChangeService$, this.routeQueryParams$].forEach(
      ($: Subscription) => $.unsubscribe(),
    );
  }

  getUserType(): void {
    this.authService.getUserType().then((userType: UserTypeModel): void => {
      this.userType = userType;
    });
  }

  // prettier-ignore
  getMySubscription(): void {
    this.mySubscriptionsNew = undefined;

    from(this.http.get<{ data: SubscriptionsNew | SubscriptionFree }>('/v2/subscription/info'))
      .pipe(first())
      .subscribe((data: { data: SubscriptionsNew | SubscriptionFree }) => {
        /** если пустой массив, то нет подписки */
        if ('is_trial_available' in data.data) {
          this.mySubscriptionsNew = null;
        } else {
          this.mySubscriptionsNew = data.data;

          this.activatedRoute.queryParams.pipe(first()).subscribe({
            next: (queryParams: Params) => {
              if ('pay-status' in queryParams) {
                switch (queryParams['pay-status']) {
                  case 'success':
                    this.openSuccessModal();
                    break;
                  default:
                    break;
                }
              }
            },
          });

          this.nextPaymentDate = this.mySubscriptionsNew.next_payment ? new Date(this.mySubscriptionsNew.next_payment.date) : undefined;

          /** Если нет триала или триал закончился и тип тарифа metered, то забираем доп. инфу для отмены */
          if (
            (!this.mySubscriptionsNew.trial_ends_at || this.mySubscriptionsNew.trial_ends_at && this.mySubscriptionsNew.is_trial_expired)
            && this.mySubscriptionsNew.price.usage_type === 'metered'
          ) {
            this.getMeteredUsageInfo();
          }

          this.getLeftDaysOfTrial();

          this.billingUrl.length === 0 && this.getBillingUrl();
          !this.paymentUrl && this.getPaymentUrl();

          this.setAmountOfMoneySaved();

          this.getClaimStatus();
        }
      });
  }

  // prettier-ignore
  getClaimStatus(): void {
    from(this.http.get<{ data: { status: 'pending' | 'approved' | 'denied'; } }>('/v2/getClaimStatus'))
      .pipe(first())
      .subscribe({
        next: (data: {data: { status: 'pending' | 'approved' | 'denied'; }}) => {
          this.claimStatus = data.data.status;
        },
      });
  }

  // prettier-ignore
  setAmountOfMoneySaved(): void {
    if (this.mySubscriptionsNew && !this.mySubscriptionsNew.ends_at && this.mySubscriptionsNew.price.interval !== 'year' && this.mySubscriptionsNew.stripe_status !== 'past_due' && this.priceYear) {
      if (this.mySubscriptionsNew.price.usage_type === 'metered' && this.pricePayIfYouWinMonth) {
        const amountOfYear: number = this.pricePayIfYouWinMonth.amount * 365;

        const amountSpent: number = this.pricePayIfYouWinMonth.amount * new Date().getDate();

        this.amountOfMoneySaved = amountOfYear - (Number(this.priceYear.amount) - amountSpent);
      }

      if (this.mySubscriptionsNew.price.usage_type === 'licensed' && this.priceMonth) {
        this.amountOfMoneySaved = this.priceMonth.amount * 12 - this.priceYear.amount;
      }
    }
  }

  // prettier-ignore
  getMeteredUsageInfo(): void {
    from(this.http.get<{ data: MeteredUsageInfo }>('/v2/subscription/metered/usage-info'))
      .pipe(first())
      .subscribe({
        next: (data: { data: MeteredUsageInfo }) => {
          this.meteredUsageInfo = data.data;
        },
      });
  }

  getTariffList(): void {
    from(this.http.get<Tariff[]>('/v2/tariff/list'))
      .pipe(first())
      .subscribe({
        next: (tariffList: Tariff[]) => {
          this.tariffList = tariffList;

          const priceList: TariffPrice[] = [];
          this.tariffList.forEach((tariff: Tariff) => {
            priceList.push(...tariff.prices);
          });

          let tariffName: string = '';
          // prettier-ignore
          switch (true) {
            case this.mySubscriptionsNew?.price.lookup_key.includes('pro_'):
              tariffName = 'pro_';
              break;
            case this.mySubscriptionsNew?.price.lookup_key.includes('premium_'):
              tariffName = 'premium_';
              break;
            case this.mySubscriptionsNew?.price.lookup_key.includes('ultimate_'):
              tariffName = 'ultimate_';
              break;
            default:
              tariffName = 'pro_';
              break;
          }

          priceList.forEach((priceTariff: TariffPrice) => {
            if (priceTariff.lookup_key.includes(tariffName)) {
              if (priceTariff.usage_type === 'licensed') {
                if (priceTariff.interval === 'month') {
                  this.priceMonth = priceTariff;
                }

                if (priceTariff.interval !== 'month') {
                  this.priceYear = priceTariff;
                }
              }

              if (priceTariff.usage_type === 'metered') {
                this.pricePayIfYouWinMonth = priceTariff;
              }
            }
          });
        },
      });
  }

  // prettier-ignore
  getLeftDaysOfTrial(): void {
    this.leftDaysOfTrial = 0;

    if(this.mySubscriptionsNew && !this.mySubscriptionsNew?.is_trial_expired) {
      const nowDate: Date = new Date();
      const trialEndsDate: Date = new Date(this.mySubscriptionsNew.trial_ends_at)

      this.leftDaysOfTrial = Math.ceil((trialEndsDate.getTime() - nowDate.getTime()) / 1000 / 86400);
    }
  }

  getBillingUrl(): void {
    from(this.http.get<{ data: { url: string } }>('/v2/billingPortal'))
      .pipe(first())
      .subscribe((data: { data: { url: string } }) => {
        this.billingUrl = data.data.url;
      });
  }

  getPaymentUrl(): void {
    from(this.http.get<{ data: { paymentUrl: string } }>('/v2/pay'))
      .pipe(first())
      .subscribe((data: { data: { paymentUrl: string } }) => {
        this.paymentUrl = data.data.paymentUrl;
      });
  }

  getCancelSubscription(): void {
    from(this.http.get<{ data: [] }>('/v2/cancel'))
      .pipe(first())
      .subscribe({
        next: () => {
          this.subscriptionChangeService.currentChangeSubject.next('info');
          this.dialogService.openCanceledSubscription();
        },
        error: () => {
          this.subscriptionChangeService.currentChangeSubject.next('info');
        },
      });
  }

  getDiscountSubscription(): void {
    from(this.http.get<{ data: [] }>('/v2/discount'))
      .pipe(first())
      .subscribe({
        next: () => {
          this.subscriptionChangeService.currentChangeSubject.next('info');
          this.dialogService.openThankYouSubscription();
        },
        error: () => {
          this.subscriptionChangeService.currentChangeSubject.next('info');
        },
      });
  }

  getRenewSubscription(): void {
    from(this.http.get<{ data: [] }>('/v2/renew'))
      .pipe(first())
      .subscribe({
        next: () => {
          this.subscriptionChangeService.currentChangeSubject.next('info');
          this.dialogService.openRenewedSubscription();
        },
        error: () => {
          this.subscriptionChangeService.currentChangeSubject.next('info');
        },
      });
  }

  openSuccessModal(): void {
    if (this.mySubscriptionsNew?.price.lookup_key) {
      let tariffName: string = '';
      // prettier-ignore
      switch (true) {
        case this.mySubscriptionsNew?.price.lookup_key.includes('pro_'):
          tariffName = 'Pro';
          break;
        case this.mySubscriptionsNew?.price.lookup_key.includes('premium_'):
          tariffName = 'Premium';
          break;
        case this.mySubscriptionsNew?.price.lookup_key.includes('ultimate_'):
          tariffName = 'Ultimate';
          break;
        default:
          tariffName = 'Pro';
          break;
      }

      /** Переводим на страницу на которой был до оплаты тарифа */
      const url: string = this.cookieService.get('page-before-payment');

      if (url.length) {
        this.router.navigateByUrl(url).then(() => {
          this.dialogService.openPaySuccessDialog({ tariffName });
        });
      } else {
        this.dialogService.openPaySuccessDialog({ tariffName });
      }
    }
  }

  // prettier-ignore
  onCancel(): void {
    this.mySubscriptionsNew && this.dialogService.openCancelSubscription({ mySubscriptions: this.mySubscriptionsNew, meteredUsageInfo: this.meteredUsageInfo });
  }

  // prettier-ignore
  onRenew(): void {
    this.mySubscriptionsNew && this.dialogService.openRenewSubscription({ mySubscriptions: this.mySubscriptionsNew });
  }

  // prettier-ignore
  onChangePlan(toPlan: 'Year' | 'PayIfYouWin' ): void {
    let changeTo: SubscriptionChangeTo | null = null;

    let tariffNameFrom: string = '';
    let tariffNameTo: string = '';

    switch (true) {
      case this.mySubscriptionsNew?.price.lookup_key.includes('pro_'):
        tariffNameFrom = tariffNameTo = 'Pro';
        break;
      case this.mySubscriptionsNew?.price.lookup_key.includes('premium_'):
        tariffNameFrom = tariffNameTo = 'Premium';
        break;
      case this.mySubscriptionsNew?.price.lookup_key.includes('ultimate_'):
        tariffNameFrom = tariffNameTo = 'Ultimate';
        break;
      default:
        tariffNameFrom = tariffNameTo = 'Pro';
        break;
    }

    if(this.mySubscriptionsNew) {
      /** Если меняем на Year */
      if (toPlan === 'Year') {
        if (this.mySubscriptionsNew.price.usage_type === 'licensed') {
          /** Если меняем c Month */
          changeTo = 'Month->Year';
        } else {
          /** Если меняем c PayIfYouWin */
          changeTo = 'PayIfYouWin->Year';
        }
      }

      /** Если меняем на PayIfYouWin */
      if (toPlan === 'PayIfYouWin') {
        if (this.mySubscriptionsNew.price.interval === 'month') {
          /** Если меняем c Month */
          changeTo = 'Month->PayIfYouWin';
        } else {
          /** Если меняем c Year */
          changeTo = 'Year->PayIfYouWin';
        }
      }

      this.mySubscriptionsNew && changeTo && this.dialogService.openChangeSubscription({
        mySubscriptions: this.mySubscriptionsNew,
        meteredUsageInfo: this.meteredUsageInfo,
        tariffList: this.tariffList,
        changeTo,
        tariffNameFrom,
        tariffNameTo
      });
    }
  }

  readonly UserTypeModel = UserTypeModel;
}
