<div class="overview-diagram-components">
  <div class="item">
    <div [style]="getStyle('background-color', model.firstComponentSections.color)" class="item-circle"></div>
    <div class="item-text">{{ model.firstComponentName }}</div>
  </div>
  <div class="item">
    <div [style]="getStyle('background-color', model.secondComponentSections.color)" class="item-circle"></div>
    <div class="item-text">{{ model.secondComponentName }}</div>
  </div>
</div>
<div class="overview-diagram-container" [id]="diagramId">
  <div [style]="getPosition(sector.location)" class="diagram-section" *ngFor="let sector of sectorNames">
    <div class="diagram-section-text">{{ sector.text }}</div>
  </div>
</div>
