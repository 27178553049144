<ng-container>
  <div
    class="flex pointer-events-auto"
    *ngIf="cashFlowTotalExposureData?.close"
  >
    <div class="flex items-start !gap-4 absolute top-0 right-20 z-[2]">
      <div
        class="flex flex-shrink-0 !p-1 rounded-lg bg-[#F1F5F9] badge-shadow opacity-90"
      >
        <span
          class="block font-B612 text-[10px] font-normal leading-[12px] !px-0.5"
        >
          Total Exposure
        </span>
      </div>
      <app-dropdown
        [appUseScale]="true"
        *ngIf="{ value: false } as toggle"
        (toggled)="toggle.value = $event"
        class="flex flex-shrink-0"
        [class.opacity-0]="
          userType < 2 && cashFlowTotalExposureDataTicker !== 'SPY'
        "
      >
        <button
          class="flex rounded-lg bg-[#F4F4F4] hover:bg-[#EAEAEA] text-[#393939] font-normal text-[10px] leading-[12px] !p-1 opacity-90 duration-300"
          type="button"
          slot="target"
        >
          <span class="block px-1">
            {{ cashFlowTotalExposureActivePeriod }} Change
          </span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            class="duration-300"
            [class.-rotate-180]="toggle.value"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M2.1342 4.15914C2.32245 3.95711 2.63884 3.94594 2.84086 4.1342L6.23018 7.29242L9.13329 4.16012C9.321 3.95759 9.63735 3.94558 9.83988 4.13329C10.0424 4.321 10.0544 4.63735 9.86672 4.83989L6.28202 8.70758L2.15914 4.86581C1.95711 4.67755 1.94594 4.36117 2.1342 4.15914Z"
              fill="#393939"
            />
          </svg>
        </button>
        <div
          class="flex dropdown-shadow p-1 rounded-lg mt-2 w-[140px] h-[80px] bg-[#FFF]"
          slot="content"
        >
          <ul class="w-full">
            <li
              *ngFor="let item of cashFlowTotalExposurePeriodList"
              (click)="cashFlowTotalExposureActivePeriod = item"
              [id]="'total-exposure-period-' + item"
              [attr.data-text]="item + ' Change'"
              class="flex items-center justify-between gap-1 h-6 hover:rounded !p-1 !pl-2 hover:bg-[#a3b0c233] duration-300 cursor-pointer border-b border-[#DADADA] hover:border-[#a3b0c233]"
            >
              <span class="block py-1 text-[10px] text-[#393939] truncate">
                {{ item }} Change
              </span>
              <svg
                *ngIf="item === cashFlowTotalExposureActivePeriod"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                class="flex-shrink-0"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M12.412 4.18569C12.1464 3.95805 11.7466 3.98881 11.5189 4.25438L6.28551 10.36L4.48066 8.25438C4.25303 7.98881 3.85321 7.95805 3.58763 8.18569C3.32206 8.41332 3.2913 8.81314 3.51894 9.07872L5.80465 11.7454C5.92497 11.8858 6.10063 11.9665 6.28551 11.9665C6.4704 11.9665 6.64605 11.8858 6.76638 11.7454L12.4807 5.07872C12.7083 4.81314 12.6775 4.41332 12.412 4.18569Z"
                  fill="#696969"
                />
              </svg>
            </li>
          </ul>
        </div>
      </app-dropdown>
    </div>
    <!-- LEVELS -->
    <div
      *ngFor="let level of levels"
      class="flex items-center justify-center !gap-0.5 absolute font-B612 text-xs leading-4 text-white rounded overflow-hidden cursor-pointer whitespace-nowrap z-[2]"
      [style]="{
        background: level.background,
        top: level.top,
        right: level.right,
        width: level.width,
        transition: 'width 600ms ease-in-out'
      }"
      [class.hidden]="userType < 2 && cashFlowTotalExposureDataTicker !== 'SPY'"
      (mouseover)="onMouseover(level.name)"
      (mouseout)="onMouseout()"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        class="flex-shrink-0"
      >
        <path
          d="M9.815 1.82503C9.75691 1.77804 9.68903 1.74467 9.61635 1.72736C9.54367 1.71006 9.46803 1.70926 9.395 1.72503C8.86072 1.837 8.30917 1.83843 7.77432 1.72922C7.23947 1.62002 6.73263 1.40249 6.285 1.09003C6.2013 1.03197 6.10187 1.00085 6 1.00085C5.89814 1.00085 5.7987 1.03197 5.715 1.09003C5.26738 1.40249 4.76054 1.62002 4.22569 1.72922C3.69083 1.83843 3.13928 1.837 2.605 1.72503C2.53197 1.70926 2.45634 1.71006 2.38365 1.72736C2.31097 1.74467 2.24309 1.77804 2.185 1.82503C2.12699 1.87209 2.08026 1.93155 2.04825 1.99904C2.01623 2.06653 1.99975 2.14033 2 2.21503V5.94003C1.99956 6.65691 2.17039 7.36351 2.49827 8.00101C2.82615 8.63851 3.30159 9.18845 3.885 9.60503L5.71 10.905C5.79468 10.9653 5.89605 10.9977 6 10.9977C6.10395 10.9977 6.20532 10.9653 6.29 10.905L8.115 9.60503C8.69841 9.18845 9.17385 8.63851 9.50174 8.00101C9.82962 7.36351 10.0004 6.65691 10 5.94003V2.21503C10.0003 2.14033 9.98377 2.06653 9.95176 1.99904C9.91974 1.93155 9.87301 1.87209 9.815 1.82503ZM9 5.94003C9.00039 6.49741 8.86765 7.04682 8.61283 7.54254C8.35801 8.03826 7.98848 8.46596 7.535 8.79003L6 9.88503L4.465 8.79003C4.01152 8.46596 3.642 8.03826 3.38718 7.54254C3.13236 7.04682 2.99962 6.49741 3 5.94003V2.79003C4.04822 2.87975 5.09802 2.63654 6 2.09503C6.90198 2.63654 7.95179 2.87975 9 2.79003V5.94003ZM6.77 4.79503L5.425 6.14503L4.98 5.69503C4.88585 5.60088 4.75815 5.54799 4.625 5.54799C4.49185 5.54799 4.36415 5.60088 4.27 5.69503C4.17585 5.78918 4.12296 5.91688 4.12296 6.05003C4.12296 6.18318 4.17585 6.31088 4.27 6.40503L5.07 7.20503C5.11648 7.2519 5.17179 7.28909 5.23271 7.31448C5.29364 7.33986 5.359 7.35293 5.425 7.35293C5.49101 7.35293 5.55636 7.33986 5.61729 7.31448C5.67822 7.28909 5.73352 7.2519 5.78 7.20503L7.5 5.50003C7.59415 5.40588 7.64705 5.27818 7.64705 5.14503C7.64705 5.01188 7.59415 4.88418 7.5 4.79003C7.40585 4.69588 7.27815 4.64299 7.145 4.64299C7.01185 4.64299 6.88415 4.69588 6.79 4.79003L6.77 4.79503Z"
          fill="white"
        />
      </svg>
      <span class="text-chart-control-1-regular text-white">
        {{ level.text }}
      </span>
    </div>
    <div
      class="absolute top-7 right-[72px] flex flex-col items-center justify-center !gap-4 w-[203px] h-[536px] rounded-lg bg-[#ffffff26] locked-shadow backdrop-filter backdrop-blur-sm z-[3]"
      *ngIf="
        (userType < 2 || userType === 7) &&
        cashFlowTotalExposureDataTicker !== 'SPY'
      "
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.99935 13.3333V9.33333C7.99935 7.2116 8.8422 5.17676 10.3425 3.67647C11.8428 2.17618 13.8776 1.33333 15.9993 1.33333C18.1211 1.33333 20.1559 2.17618 21.6562 3.67647C23.1565 5.17676 23.9993 7.2116 23.9993 9.33333V13.3333H25.3327C27.5418 13.3333 29.3327 15.1242 29.3327 17.3333V26.6667C29.3327 28.8758 27.5418 30.6667 25.3327 30.6667H6.66602C4.45688 30.6667 2.66602 28.8758 2.66602 26.6667V17.3333C2.66602 15.1242 4.45688 13.3333 6.66602 13.3333H7.99935ZM12.2281 5.56209C13.2283 4.5619 14.5849 3.99999 15.9993 3.99999C17.4138 3.99999 18.7704 4.5619 19.7706 5.56209C20.7708 6.56229 21.3327 7.91884 21.3327 9.33333V13.3333H10.666V9.33333C10.666 7.91884 11.2279 6.56229 12.2281 5.56209ZM6.66602 16C5.92964 16 5.33268 16.5969 5.33268 17.3333V26.6667C5.33268 27.403 5.92964 28 6.66602 28H25.3327C26.0691 28 26.666 27.403 26.666 26.6667V17.3333C26.666 16.5969 26.0691 16 25.3327 16H6.66602Z"
          fill="#393939"
        />
      </svg>
      <button
        type="button"
        class="block bg-[#62C37D] text-[#FFF] text-lg leading-[24px] font-semibold !rounded py-2 px-3"
        (click)="onShowIndicator()"
      >
        Show Chart
      </button>
      <!-- prettier-ignore -->
      <span
        class="block text-base leading-[20px] font-semibold tracking-[-0.08px]"
      >
        {{ userType === 0 ? 'Free Sign Up' : userType === 1 ? 'Get Free Trial' : 'Upgrade' }}
      </span>
    </div>
  </div>
</ng-container>
