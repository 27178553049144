<div *ngIf="papersList.length" class="flex flex-col !gap-6 xl:!gap-12 relative">
  <div class="flex md:!items-center justify-between">
    <span
      class="block text-interface-title-2-semibold xl:!text-interface-title-1-semibold text-high-on-light"
    >
      Read our exclusive
      <span class="text-default-primary-2">research papers</span>
    </span>
    <button
      class="hidden items-center justify-start !bg-default-secondary-1 hover:!bg-active-secondary-1 text-interface-control-2-semibold text-default-secondary-2 rounded-[6px] gap-[4px] p-[10px] transition-colors duration-300"
      type="button"
    >
      <span class="block px-[4px]">See all</span>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.91205 3.57709C6.58661 3.90252 6.58661 4.43016 6.91205 4.7556L12.1561 9.99967L6.91205 15.2438C6.58661 15.5692 6.58661 16.0968 6.91205 16.4223C7.23748 16.7477 7.76512 16.7477 8.09056 16.4223L13.9239 10.5889C14.2493 10.2635 14.2493 9.73586 13.9239 9.41042L8.09056 3.57709C7.76512 3.25165 7.23748 3.25165 6.91205 3.57709Z"
        />
      </svg>
    </button>
  </div>
  <ul
    class="grid !gap-4 overflow-hidden min-w-[]"
    [ngClass]="
      disableAdaptive
        ? papersList.length === 1
          ? '!grid-cols-2'
          : '!grid-cols-3'
        : papersList.length === 1
        ? 'grid-cols-1 md:!grid-cols-2'
        : 'grid-cols-1 md:!grid-cols-3'
    "
  >
    <li class="col-span-1" *ngFor="let paper of papersList">
      <div
        class="flex flex-col items-start justify-start bg-surface-3 rounded-lg overflow-hidden"
      >
        <div
          class="flex justify-center w-full h-[240px] bg-default-neutral-2 overflow-hidden"
        >
          <div class="relative w-[280px]">
            <div
              class="absolute top-8 right-0 w-[244px] h-[345px] bg-[#F7F9FB] shadow-shadow-landing-papers-cover-bg rounded z-[1]"
            ></div>
            <div
              class="absolute top-[44px] right-[12px] w-[244px] h-[345px] bg-[#F7F9FB] shadow-shadow-landing-papers-cover-bg rounded z-[1]"
            ></div>
            <div
              class="absolute top-[56px] right-[24px] w-[244px] h-[345px] bg-[#F7F9FB] shadow-shadow-landing-papers-cover-bg rounded z-[1]"
            ></div>
            <img
              [src]="paper.cover"
              [alt]="paper.title"
              class="absolute top-[68px] right-[36px] w-[244px] h-[345px] shadow-shadow-landing-papers-cover object-cover rounded z-[1]"
            />
          </div>
        </div>
        <div class="flex flex-col items-start justify-start !gap-8 !p-6">
          <div class="flex flex-col !gap-2">
            <p
              class="text-interface-title-4-semibold text-default-neutral-1 line-clamp-3"
            >
              {{ paper.title }}
            </p>
            <span
              class="text-interface-body-2-regular text-default-neutral-3 line-clamp-2"
            >
              {{ paper.subtitle }}
            </span>
          </div>
          <div class="flex items-center justify-start !gap-2">
            <img
              [src]="paper.author.avatar"
              [alt]="paper.author.name"
              class="block w-[52px] h-[52px] object-cover rounded-full"
            />
            <div class="flex flex-col items-start justify-start max-w-[200px]">
              <span class="text-interface-title-6-semibold text-high-on-light">
                {{ paper.author.name }}
              </span>
              <span
                class="text-interface-caption-2-regular text-default-neutral-3 line-clamp-2"
              >
                {{ paper.author.description }}
              </span>
            </div>
          </div>
          <button
            class="kw-btn kw-btn-md kw-btn-primary kw-btn-active"
            type="button"
            (click)="onClickReadArticle(paper)"
            [id]="'get-paper-' + paper.id"
          >
            <span class="block">Read the Article</span>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.91205 3.57709C6.58661 3.90252 6.58661 4.43016 6.91205 4.7556L12.1561 9.99967L6.91205 15.2438C6.58661 15.5692 6.58661 16.0968 6.91205 16.4223C7.23748 16.7477 7.76512 16.7477 8.09056 16.4223L13.9239 10.5889C14.2493 10.2635 14.2493 9.73586 13.9239 9.41042L8.09056 3.57709C7.76512 3.25165 7.23748 3.25165 6.91205 3.57709Z"
                fill="white"
              />
            </svg>
          </button>
        </div>
      </div>
    </li>
    <!-- EMPTY -->
    <li class="col-span-1" *ngIf="papersList.length < 3">
      <div
        class="flex flex-col gap-8 items-center justify-center w-full h-full min-h-[304px] bg-default-neutral-2 rounded-2xl"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="80"
          height="80"
          viewBox="0 0 80 80"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9 10C7.34315 10 6 11.3431 6 13V65C6 66.6569 7.34315 68 9 68H49C50.6569 68 52 66.6569 52 65V13C52 11.3431 50.6569 10 49 10H9Z"
            fill="#62C37D"
          />
          <path
            d="M6 13C6 11.3431 7.34315 10 9 10H49C50.3538 10 51.4981 10.8967 51.8715 12.1285C51.5957 12.045 51.3031 12 51 12H11C9.34315 12 8 13.3431 8 15V67C8 67.3031 8.04495 67.5957 8.12854 67.8715C6.89669 67.4981 6 66.3538 6 65V13Z"
            fill="#4E9C64"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M4 13C4 10.2386 6.23858 8 9 8H49C51.7614 8 54 10.2386 54 13V65C54 67.7614 51.7614 70 49 70H9C6.23857 70 4 67.7614 4 65V13ZM9 10C7.34315 10 6 11.3431 6 13V65C6 66.6569 7.34315 68 9 68H49C50.6569 68 52 66.6569 52 65V13C52 11.3431 50.6569 10 49 10H9Z"
            fill="#303D50"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M56 16H27V24C27 27.866 23.866 31 20 31H12V71C12 72.6569 13.3431 74 15 74H56C57.6569 74 59 72.6569 59 71V19C59 17.3431 57.6569 16 56 16Z"
            fill="#DAF2C2"
          />
          <path
            d="M27 16H56C57.3538 16 58.4981 16.8967 58.8715 18.1285C58.5957 18.045 58.3031 18 58 18H29V26C29 29.866 25.866 33 22 33H14V73C14 73.3031 14.045 73.5957 14.1285 73.8715C12.8967 73.4981 12 72.3538 12 71V31H20C23.866 31 27 27.866 27 24V16Z"
            fill="#BDE595"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M24.9998 17.4141L13.4141 28.9998H19.9998C22.7613 28.9998 24.9998 26.7613 24.9998 23.9998V17.4141Z"
            fill="#DAF2C2"
          />
          <path
            d="M13.4141 28.9998L24.9998 17.4141V21.4141L17.4141 28.9998H13.4141Z"
            fill="#BDE595"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M26 14C25.7348 14 25.4804 14.1054 25.2929 14.2929L10.2929 29.2929C10.1054 29.4804 10 29.7348 10 30V71C10 73.7614 12.2386 76 15 76H55C57.7614 76 60 73.7614 60 71V54C60 53.4477 59.5523 53 59 53C58.4477 53 58 53.4477 58 54V71C58 72.6569 56.6569 74 55 74H15C13.3431 74 12 72.6569 12 71V31H20C23.866 31 27 27.866 27 24V16H55C56.6569 16 58 17.3431 58 19V20C58 20.5523 58.4477 21 59 21C59.5523 21 60 20.5523 60 20V19C60 16.2386 57.7614 14 55 14H26ZM20 29H13.4142L25 17.4142V24C25 26.7614 22.7614 29 20 29Z"
            fill="#303D50"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M19 40C19 39.4477 19.4477 39 20 39H40C40.5523 39 41 39.4477 41 40C41 40.5523 40.5523 41 40 41H20C19.4477 41 19 40.5523 19 40Z"
            fill="#303D50"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M19 48C19 47.4477 19.4477 47 20 47H40C40.5523 47 41 47.4477 41 48C41 48.5523 40.5523 49 40 49H20C19.4477 49 19 48.5523 19 48Z"
            fill="#303D50"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M19 56C19 55.4477 19.4477 55 20 55H50C50.5523 55 51 55.4477 51 56C51 56.5523 50.5523 57 50 57H20C19.4477 57 19 56.5523 19 56Z"
            fill="#303D50"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M19 65C19 64.4477 19.4477 64 20 64H50C50.5523 64 51 64.4477 51 65C51 65.5523 50.5523 66 50 66H20C19.4477 66 19 65.5523 19 65Z"
            fill="#303D50"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M50 24C48.3431 24 47 25.3431 47 27V31H73V27C73 25.3431 71.6569 24 70 24H50Z"
            fill="#B2D2FF"
          />
          <path
            d="M47 27C47 25.3431 48.3431 24 50 24H70C71.3538 24 72.4981 24.8967 72.8715 26.1285C72.5957 26.045 72.3031 26 72 26H52C50.3431 26 49 27.3431 49 29V31H47V27Z"
            fill="#73AEFF"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M47 33V46C47 47.6569 48.3431 49 50 49H70C71.6569 49 73 47.6569 73 46V33H47Z"
            fill="#B2D2FF"
          />
          <path
            d="M47 46V33H73V35H50C49.4477 35 49 35.4477 49 36V48C49 48.3031 49.045 48.5957 49.1285 48.8715C47.8967 48.4981 47 47.3538 47 46Z"
            fill="#73AEFF"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M53 21C53.5523 21 54 21.4477 54 22V26C54 26.5523 53.5523 27 53 27C52.4477 27 52 26.5523 52 26V25H50C48.3431 25 47 26.3431 47 28V31H73V28C73 26.3431 71.6569 25 70 25C69.4477 25 69 24.5523 69 24C69 23.4477 69.4477 23 70 23C72.7614 23 75 25.2386 75 28V46C75 48.7614 72.7614 51 70 51H50C47.2386 51 45 48.7614 45 46V28C45 25.2386 47.2386 23 50 23H52V22C52 21.4477 52.4477 21 53 21ZM73 33H47V46C47 47.6569 48.3431 49 50 49H70C71.6569 49 73 47.6569 73 46V33ZM67 21C67.5523 21 68 21.4477 68 22V26C68 26.5523 67.5523 27 67 27C66.4477 27 66 26.5523 66 26V25H56C55.4477 25 55 24.5523 55 24C55 23.4477 55.4477 23 56 23H66V22C66 21.4477 66.4477 21 67 21Z"
            fill="#303D50"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M53 39C53 37.3431 54.3431 36 56 36C57.6569 36 59 37.3431 59 39V43C59 44.6569 57.6569 46 56 46C54.3431 46 53 44.6569 53 43V39ZM56 38C55.4477 38 55 38.4477 55 39V43C55 43.5523 55.4477 44 56 44C56.5523 44 57 43.5523 57 43V39C57 38.4477 56.5523 38 56 38Z"
            fill="#303D50"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M65.3163 36.0514C65.7246 36.1875 66.0001 36.5696 66.0001 37.0001V44.0001H67.0001C67.5523 44.0001 68.0001 44.4478 68.0001 45.0001C68.0001 45.5524 67.5523 46.0001 67.0001 46.0001H62.0001C61.4478 46.0001 61.0001 45.5524 61.0001 45.0001C61.0001 44.4478 61.4478 44.0001 62.0001 44.0001H64.0001V40.0001L62.8001 41.6001C62.4687 42.0419 61.8419 42.1314 61.4001 41.8001C60.9582 41.4687 60.8687 40.8419 61.2001 40.4001L64.2001 36.4001C64.4583 36.0557 64.9079 35.9153 65.3163 36.0514Z"
            fill="#303D50"
          />
        </svg>
        <span
          class="text-interface-title-4-regular text-default-neutral-1 text-center"
        >
          More research papers coming soon!
        </span>
      </div>
    </li>
  </ul>
</div>
