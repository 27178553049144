import { RouterModule, Routes } from '@angular/router';
import { AnalyticGuard } from '../../auth/guards/analytic.guard';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PremiumNewComponent } from './premium-new.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';

const routes: Routes = [
  {
    path: 'premium',
    component: PremiumNewComponent,
    pathMatch: 'full',
    canActivate: [AnalyticGuard],
  },
];

@NgModule({
  declarations: [PremiumNewComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    ReactiveFormsModule,
    SharedModule,
  ],
})
export class PremiumNewModule {}
