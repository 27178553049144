import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-shimmer',
  templateUrl: './shimmer.component.html',
  styleUrls: ['./shimmer.component.scss'],
})
export class ShimmerComponent {
  @Input() count = 3;
  @Input() color = 'bg-base-500';
  @Input() itemsSize: {
    [indxe: string]: {
      w?: string;
      h?: string;
    };
  } = {};

  get items() {
    return Array(this.count)
      .fill(0)
      .map((_, index) => {
        const size = this.itemsSize[index.toString()];

        return (size?.w ?? 'w-full') + ' ' + (size?.h ?? 'h-4');
      });
  }

  constructor() {}
}
