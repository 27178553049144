export interface UnsubscribeReasonRequest {
  reason: UnsubscribeReason;
  isScheduledCall?: boolean;
  scheduledCallParams?: boolean[];
  applyPromo?: boolean;
}

export type UnsubscribeReasonResponse = {};

export enum UnsubscribeReason {
  DidntFigureOutHowToMakeMoneyWithData = "I didn't figure how to make money with the data",
  TooComplicated = "It's too complicated",
  IamDayTraderDidntWorkForMe = "I am a day trader, it doesn't work for me",
  NotReallyTradingInvestingRightNow = 'I am not really trading/investing right now',
  ChargeTooMuch = 'I think you charge too much',
  DataIsLowQuality = 'I think the data is low quality',
}
