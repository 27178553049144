<div class="app-header-container !px-4">
  <div class="flex gap-8 flex-grow flex-col md:flex-row max-md:gap-2">
    <div
      class="flex flex-shrink-0 cursor-pointer max-md:py-3"
      [class]="
        userType != UserTypeModel.Logged
          ? 'max-md:self-center'
          : 'justify-between'
      "
    >
      <a class="app-header-logo" href="/">
        <app-icon icon="app-name" classes="w-[12.25rem]"></app-icon>
      </a>
      <app-header-account-premium
        *ngIf="userType === UserTypeModel.Logged"
        class="flex md:hidden"
      >
      </app-header-account-premium>
    </div>
    <div class="flex flex-col justify-center flex-grow gap-2">
      <app-header-search></app-header-search>

      <app-context-menu
        *ngIf="contextMenu.visible"
        @scaleY
        [items]="contextMenu.items"
        (itemClick)="headerService.contextMenuClick($event)"
        class="max-md:flex hidden no-shadow no-padding origin-top"
      ></app-context-menu>
    </div>
  </div>

  <div class="hidden md:flex justify-between items-center gap-8 flex-shrink-0">
    <app-nav-menu
      [items]="menuItems"
      (itemClick)="itemClick($event)"
      class="md:hidden lg:flex"
    ></app-nav-menu>
    <app-header-account class="flex"></app-header-account>
  </div>
</div>
