export enum UserTypeModel {
  Unregistered,
  Logged,
  WithPayedSubscription,
  WithTrial,
  WithPro,
  WithPremium,
  WithUltimate,
  WithTrialExpired,
}
