import {
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  OnInit,
} from '@angular/core';
import { ITabItem } from '../../../structures/ui';

@Component({
  selector: 'app-tab-item',
  templateUrl: './tab-item.component.html',
  styleUrls: ['./tab-item.component.scss'],
})
export class TabItemComponent {
  @Input() tab: ITabItem;

  @HostBinding('class.active') isActive = false;

  constructor(private _element: ElementRef) {}

  get element(): HTMLElement | null {
    return this._element?.nativeElement as HTMLElement;
  }
}
