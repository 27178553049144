import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  AuthFormParametersModel,
  AuthFormParametersPlan,
} from '../../models/auth-form-parameters.model';
import { AuthFormTypeModel } from '../../models/auth-form-type.model';
import { MoneyFlowDataService } from 'src/app/modules/widgets/money-flow/services/money-flow-data.service';
import { FavoriteSectorsService } from 'src/app/modules/widgets/money-flow/services/favorite-sectors.service';
import { EventsService, EventType } from 'src/app/services/events.service';
import { TrialDataService } from 'src/app/modules/main/services/trial-data.service';
import { PremiumService } from 'src/app/modules/main/premium/services/premium.service';
import { ToastService } from 'src/app/modules/shared/services/toast.service';
import { AnalyticService } from 'src/app/services/analytic.service';

@Component({
  selector: 'app-auth-form',
  templateUrl: './auth-form.component.html',
  styleUrls: ['./auth-form.component.scss'],
  providers: [MoneyFlowDataService, FavoriteSectorsService],
})
export class AuthFormComponent {
  formType: AuthFormTypeModel;
  parameters: AuthFormParametersModel | undefined;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private dialogRef: MatDialogRef<AuthFormComponent>,
    private eventService: EventsService,
    private trialService: TrialDataService,
    private premiumService: PremiumService,
    private toastService: ToastService,
    private analyticService: AnalyticService,
  ) {
    this.formType = data.type;
    if (this.formType === AuthFormTypeModel.SignUp) {
      this.analyticService.send('subscribe_show');
    }
    this.parameters = data.parameters;
  }

  get AuthFormType(): typeof AuthFormTypeModel {
    return AuthFormTypeModel;
  }

  async loginSuccessful() {
    if (this.parameters?.redirectUrl != null) {
      if (this.parameters.redirectUrl.includes('/profile/subscriptions')) {
        const conditions = await this.trialService.getTrialConditions();
        if (!conditions?.trialAvailable) {
          this.goToStripe(this.parameters.plan!);
        } else {
          document.location.href = this.parameters.redirectUrl;
        }
      } else {
        document.location.href = this.parameters.redirectUrl;
      }
      this.eventService.emit(EventType.UserTypeChanged);
      this.dialogRef.close();
    } else {
      location.reload();
    }
  }

  private async goToStripe(plan: AuthFormParametersPlan) {
    try {
      const response = await this.premiumService.subscribe(
        plan.planId,
        plan.priceId,
        plan.interval,
      );
      if (response.needToPay) {
        window.location.href = response.paymentUrl!;
      }
    } catch (e) {
      this.toastService.show('Failed to upgrade plan. Please try again');
    }
  }
}
