import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appOnlyNumber]',
})
export class OnlyNumberDirective {
  regexStr = '^[0-9]*$';

  constructor() {}

  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    if (
      [46, 8, 9, 27, 13, 110, 190].indexOf(event.keyCode) !== -1 ||
      (event.keyCode == 65 && event.ctrlKey) ||
      (event.keyCode == 67 && event.ctrlKey) ||
      (event.keyCode == 86 && event.ctrlKey) ||
      (event.keyCode == 88 && event.ctrlKey) ||
      // Allow: home, end, left, right
      (event.keyCode >= 35 && event.keyCode <= 39)
    ) {
      // let it happen, don't do anything
      return;
    }

    let ch = String.fromCharCode(event.keyCode);
    let regEx = new RegExp(this.regexStr);

    if (regEx.test(ch)) {
      return;
    } else {
      event.preventDefault();
    }
  }
}
