import { ExpansionPanelComponent } from './components/expansion-panel/expansion-panel.component';
import { TabComponent } from './components/tab/tab.component';
import { ContextMenuComponent } from './components/context-menu/context-menu.component';
import { NavMenuComponent } from './components/nav-menu/nav-menu.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from './components/button/button.component';
import { IconComponent } from './components/icon/icon.component';
import { TranslationPipe } from './pipes/translation.pipe';
import { InputTextComponent } from './components/input-text/input-text.component';
import { MenuComponent } from './components/menu/menu.component';
import { FirstLetterUpperCasePipe } from './pipes/first-letter-upper-case.pipe';
import { CountUpDirective } from './directives/count-up.directive';
import { SelectComponent } from './components/select/select.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { TabsComponent } from './components/tabs/tabs.component';
import { TabItemComponent } from './components/tabs/tab-item/tab-item.component';
import { TableComponent } from './components/table/table.component';
import { IsObjectPipe } from './pipes/is-object.pipe';
import { TableGridComponent } from './components/table-grid/table-grid.component';
import { TableGridHorizontalComponent } from './components/table-grid/table-grid-horizontal/table-grid-horizontal.component';
import { ImageThumbDirective } from './directives/image-thumb.directive';
import { ShimmerComponent } from './components/shimmer/shimmer.component';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { OnboardingHintComponent } from './components/onboarding-hint/onboarding-hint.component';
import { TransformPipe } from './pipes/transform.pipe';

const items = [
  ButtonComponent,
  SelectComponent,
  IconComponent,
  TabComponent,
  ExpansionPanelComponent,
  MenuComponent,
  InputTextComponent,
  ContextMenuComponent,
  TabsComponent,
  TabItemComponent,
  CheckboxComponent,
  TableComponent,
  TableGridComponent,
  TableGridHorizontalComponent,
  NavMenuComponent,
  ImageThumbDirective,
  CountUpDirective,
  TranslationPipe,
  TransformPipe,
  FirstLetterUpperCasePipe,
  ShimmerComponent,
  IsObjectPipe,
  OnboardingHintComponent,
];

@NgModule({
  declarations: items,
  exports: items,
  imports: [CommonModule, RouterModule, FormsModule, MatSlideToggleModule],
})
export class BasicModule {}
