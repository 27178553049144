import { Component, Input, OnInit } from '@angular/core';
import { BannerPage, SidebarPage, SidebarSection } from '../../models/sidebar.model';

@Component({
  selector: 'app-mobile-sidebar',
  templateUrl: './mobile-sidebar.component.html',
  styleUrls: ['./mobile-sidebar.component.scss']
})
export class MobileSidebarComponent implements OnInit {

  @Input() banner: BannerPage | null;

  @Input() page: SidebarPage;

  section: SidebarSection;

  disabledLeft: boolean = true;
  disabledRight: boolean = true;

  currentSectioniIndex: number = 0;

  constructor() { }

  ngOnInit(): void {
    if (this.page && this.page.sections.length > 0) {
      this.currentSectioniIndex = this.page?.sections.findIndex(x => x.selected);
      this.section = this.currentSectioniIndex != -1 ? this.page.sections[this.currentSectioniIndex] : this.page.sections[0];
      this.setControlsState(this.currentSectioniIndex);
    }
  }

  setControlsState(activeIndex: number) {
    this.disabledLeft = true;
    this.disabledRight = true;

    if (activeIndex < 0) {
      return;
    }

    if (activeIndex > 0) {
      this.disabledLeft = false;
    }

    if (activeIndex < this.page.sections.length - 1) {
      this.disabledRight = false;
    }
  }

  right() {
    this.currentSectioniIndex++;
    this.section = this.page.sections[this.currentSectioniIndex];
    this.setControlsState(this.currentSectioniIndex);
  }

  left() {
    this.currentSectioniIndex--;
    this.section = this.page.sections[this.currentSectioniIndex];
    this.setControlsState(this.currentSectioniIndex);
  }

}
