<div *ngIf="step === PiplineStep.Reasons" class="unsubscribe-pipline-widget-step-reasons">
    <mat-radio-group (change)="reasonChange($event)">
        <mat-radio-button *ngFor="let reason of reasons" [value]="reason">
            {{ reason }}
        </mat-radio-button>
    </mat-radio-group>
    <button mat-button [disabled]="reasonButtonDisabled" class="unsubscribe-pipline-widget-button" (click)="goToScheduleCall()">Cancel the subscription</button>
</div>

<div *ngIf="step === PiplineStep.ScheduleCall" class="unsubscribe-pipline-widget-step-schedule-call">
    <div class="unsubscribe-pipline-widget-top">
        <svg (click)="close()" width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.3215 12.5L6.91077 8.08928L8.08928 6.91077L12.5 11.3215L16.9108 6.91077L18.0893 8.08928L13.6785 12.5L18.0893 16.9108L16.9108 18.0893L12.5 13.6785L8.08928 18.0893L6.91077 16.9108L11.3215 12.5Z" fill="#333333"/>
        </svg>            
    </div>
    <div class="unsubscribe-pipline-widget-title">
        Schedule a call with our investing consultant
    </div>
    <div class="unsubscribe-pipline-widget-step-schedule-call-subtitle">
        (your subscription will be on pause)
    </div>

    <div class="unsubscribe-pipline-widget-step-schedule-call-advantages">
        <mat-checkbox [disabled]="true" [checked]="true">find out a winning use-case for you personally</mat-checkbox>
        <mat-checkbox [disabled]="true" [checked]="true">get feedback on your Portfolio stocks</mat-checkbox>
    </div>

    <div class="unsubscribe-pipline-widget-step-schedule-call-controls">
        <div class="unsubscribe-pipline-widget-button" (click)="scheduleCall()">Yes, please</div>
        <div class="unsubscribe-pipline-widget-cancel-subscription" (click)="goToDiscount()">No, just cancel the subscription</div>
    </div>
</div>

<div *ngIf="step === PiplineStep.Discount" class="unsubscribe-pipline-widget-step-discount">
    <div class="unsubscribe-pipline-widget-top">
        <svg (click)="close()" width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.3215 12.5L6.91077 8.08928L8.08928 6.91077L12.5 11.3215L16.9108 6.91077L18.0893 8.08928L13.6785 12.5L18.0893 16.9108L16.9108 18.0893L12.5 13.6785L8.08928 18.0893L6.91077 16.9108L11.3215 12.5Z" fill="#333333"/>
        </svg>            
    </div>
    <div class="unsubscribe-pipline-widget-title">
        Would you like a 50% discount to try another month?
    </div>
    
    <div class="unsubscribe-pipline-widget-step-discount-controls">
        <div class="unsubscribe-pipline-widget-button" (click)="goToPayWithDiscount()">Yes, please</div>
        <div class="unsubscribe-pipline-widget-cancel-subscription" (click)="cancelSubscription()">No, just cancel the subscription</div>
    </div>
</div>

<div *ngIf="step === PiplineStep.PayWithDiscount" class="unsubscribe-pipline-widget-step-pay-with-discount">
    <div class="unsubscribe-pipline-widget-top">
        <svg (click)="close()" width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.3215 12.5L6.91077 8.08928L8.08928 6.91077L12.5 11.3215L16.9108 6.91077L18.0893 8.08928L13.6785 12.5L18.0893 16.9108L16.9108 18.0893L12.5 13.6785L8.08928 18.0893L6.91077 16.9108L11.3215 12.5Z" fill="#333333"/>
        </svg>            
    </div>
    <div class="unsubscribe-pipline-widget-title">
        Pay with 50% discount
    </div>
    
    <div class="unsubscribe-pipline-widget-button" (click)="payWithDiscount()">Only 7,49$</div>
</div>