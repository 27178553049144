import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/modules/auth/services/auth.service';
import { INavigationMenu } from 'src/app/modules/shared/modules/basic/structures/app';
import { ContextMenuItem } from 'src/app/modules/shared/modules/basic/structures/ui';
import { AccountMenu } from '../components/header-account/header-account.component';
import { MOBILE_NAVIGATION_MENUS, NAVIGATION_MENUS } from '../data/menu.data';
import { AnalyticService } from 'src/app/services/analytic.service';

@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  menus: INavigationMenu[] = NAVIGATION_MENUS;
  mobileMenus: INavigationMenu[] = MOBILE_NAVIGATION_MENUS;
  searchFocused = false;
  mobileSearchClearVisible = false;
  mobileAccountVisible = true;
  contextMenu: {
    items: ContextMenuItem[];
    visible?: boolean;
  } = { items: [] };

  constructor(
     private router: Router,
     private authService: AuthService) {}

  itemClick(item: INavigationMenu) {
    item.route && this.gotoRoute(item.route);
  }

  initContextMenu(email?: string) {
    this.contextMenu.items = [];

    if (email)
      this.contextMenu.items.push({ id: AccountMenu.Email, text: email });

    this.contextMenu.items.push({
      id: AccountMenu.Subscriptions,
      text: 'My Subscriptions',
    });

    this.contextMenu.items.push({
      id: AccountMenu.Hints,
      text: 'Hints',
      toggle: true
    });

    this.contextMenu.items.push({
      id: AccountMenu.Logout,
      text: 'Log out',
    });
  }

  contextMenuClick(item: ContextMenuItem) {
    switch (item.id) {
      case AccountMenu.Subscriptions:
        this.router.navigate(['/profile/subscriptions']);
        break;

      case AccountMenu.Logout:
        this.authService.logout();
        break;
    }
  }

  gotoRoute(route: string) {
    if (route.includes('?')) {
      const pathes = route.split('?');
      const path = pathes[0];
      const params = pathes[1];
      const paramsMap = Object.fromEntries(
        new Map(
          params.split(';').map((item) => {
            return item.split('=');
          }) as any,
        ),
      );
      this.router.navigate([path, paramsMap]);
    } else {
      this.router.navigate([route]);
    }
  }
}
