import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import * as d3Drag from 'd3-drag';
import * as d3Selection from 'd3-selection';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-number-picker, [appNumberPicker]',
  templateUrl: './number-picker.component.html',
})
export class NumberPickerComponent implements AfterViewInit {
  @ViewChild('picker') picker: ElementRef<HTMLDivElement> | undefined;

  @Input()
  set appToFixed(fixed: number) {
    this.fixed = fixed;
  }

  @Input()
  set appValue(value: number) {
    this.numberForm.get('value')?.setValue(value.toFixed(this.fixed));

    this.onSubmit();
  }

  @Input()
  set appLabelLeft(labelLeft: string) {
    this.labelLeft = labelLeft;
  }

  @Input()
  set appLabelRight(labelRight: string) {
    this.labelRight = labelRight;
  }

  @Input()
  set appMin(min: number) {
    this.min = min;
  }

  @Input()
  set appMax(max: number) {
    this.max = max;
  }

  @Input()
  set appDisable(disabled: boolean) {
    this.disabled = disabled;
  }

  @Output() selected: EventEmitter<number> = new EventEmitter();

  numberForm: FormGroup;

  labelLeft: string = '0';
  labelRight: string = '100';

  min: number = 0;
  max: number = 100;

  disabled: boolean = false;

  fixed: number = 0;

  translateX: number = 0;
  translateXDragStart: number = 0;
  translateXMin: number = 0;
  translateXMax: number = 350;

  constructor(private formBuilder: FormBuilder) {
    // prettier-ignore
    this.numberForm = this.formBuilder.group({
      value: this.formBuilder.control(0, [Validators.required]),
    });
  }

  ngAfterViewInit(): void {
    const drag: any = d3Drag
      .drag()
      .on('start', () => !this.disabled && this.onDragStart())
      .on('drag', (event: any) => !this.disabled && this.onDragMove(event))
      .on('end', () => !this.disabled && this.onDragEnd());

    // @ts-ignore
    d3Selection.select(this.picker?.nativeElement).call(drag);
  }

  onDragStart(): void {
    this.translateXDragStart = this.translateX;
  }

  // prettier-ignore
  onDragMove(event: any): void {
    // где начали subject.x
    // где закончили x
    const direction: number = event.subject.x <= event.x ? 1 : -1;
    const difference: number = Math.abs(event.subject.x - event.x);

    const currentPosition: number =
      this.translateXDragStart + direction * difference;

    this.translateX = currentPosition > this.translateXMin ? currentPosition < this.translateXMax ? currentPosition : this.translateXMax : this.translateXMin;

    this.setForm();
  }

  onDragEnd(): void {
    this.setForm();
  }

  // prettier-ignore
  setForm(): void {
    const multiplier: number = this.translateXMax / this.max;

    const result: number = (this.translateX + (this.min * multiplier)) / multiplier;

    this.numberForm.get('value')?.setValue((result > this.min ? result < this.max ? result : this.max : this.min).toFixed(this.fixed));

    this.selected.emit(Number(this.numberForm.get('value')?.value));
  }

  // prettier-ignore
  onSubmit(): void {
    let value: number = this.numberForm.get('value')?.value;
    value = value > this.min ? value < this.max ? value : this.max : this.min;

    if(this.numberForm.get('value')?.value !== value) {
      this.numberForm.get('value')?.setValue(value);
    }

    const multiplier: number = this.translateXMax / this.max;

    const result: number = (value * multiplier) - (this.min * multiplier);

    this.translateX = result > this.translateXMin ? result < this.translateXMax ? result : this.translateXMax : this.translateXMin;

    this.selected.emit(Number(value));
  }
}
