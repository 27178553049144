<div
  *ngIf="!config.horizontalMode?.always"
  [ngClass]="[classes.grid ?? '']"
  [class.mobile-hidden]="config.horizontalMode?.mobile"
  class="grid-box"
>
  <div
    class="header"
    [class]="classes.header?.host ?? ''"
    [ngStyle]="{
      'grid-template-columns':
        classes.columnCount ?? 'repeat(' + columns.length + ', 1fr'
    }"
  >
    <div
      *ngFor="let column of columns"
      [ngClass]="[classes.header?.item?.host ?? '', classes.header?.item?.specific?.[column.id] ?? '']"
      class="item"
    >
      {{ column.text }}
    </div>
  </div>
  <div class="content" [class]="classes.content?.host ?? ''">
    <div
      *ngFor="
        let row of data
          | slice
            : 0
            : (config.pagination?.displayAll
                ? data.length
                : config.pagination?.count ?? data.length);
        let even = even;
        let odd = odd
      "
      [class.highlight]="
        (even && config.highlight?.even) || (odd && config.highlight?.odd)
      "
      [class]="classes.content?.row?.host ?? ''"
      [ngStyle]="{
        'grid-template-columns':
          classes.columnCount ?? 'repeat(' + columns.length + ', 1fr'
      }"
      class="grid-row"
    >
      <div
        *ngFor="let column of columns"
        [ngClass]="[classes.header?.item?.host ?? 'text-xs text-accent', classes.header?.item?.specific?.[column.id] ?? '']"
        class="item"
      >
      <span class="table-grid-column-value" [innerHtml]="row[column.id]"></span>
      </div>
    </div>
  </div>
</div>

<app-table-grid-horizontal
  *ngIf="config.horizontalMode?.always || config.horizontalMode?.mobile"
  [columns]="columns"
  [data]="data"
  [templates]="templates"
  [config]="config"
  [classes]="classes.horizontal ?? {}"
  [class.md-hidden]="!config.horizontalMode?.always"
></app-table-grid-horizontal>

<app-button
  *ngIf="
    config.pagination &&
    config.pagination.moreButton &&
    data.length > (config.pagination?.count ?? 3)
  "
  [text]="
    config.pagination.displayAll
      ? 'Show less'
      : 'Show ' + (data.length - (config.pagination.count ?? 3)) + ' more'
  "
  (click)="config.pagination.displayAll = !config.pagination.displayAll"
  classes="px-[2rem] py-[0.5rem]"
  class="md:ml-9 md:self-start"
></app-button>
