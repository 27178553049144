import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AnalyticGuard } from '../auth/guards/analytic.guard';
import { AuthorizedGuard } from '../auth/guards/authorized.guard';

const routes: Routes = [
  {
    path: 'profile',
    loadChildren: () =>
      import('./profile/profile.module').then(
        (imported) => imported.ProfileModule,
      ),
    canActivate: [AnalyticGuard],
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
    canActivate: [AnalyticGuard],
  },
  {
    path: 'alerts',
    loadChildren: () =>
      import('./alerts/alerts.module').then((alert) => alert.AlertsModule),
    canActivate: [AuthorizedGuard],
  },
  {
    path: 'welcome',
    loadChildren: () =>
      import('./welcome/welcome.module').then((m) => m.WelcomeModule),
    canActivate: [AnalyticGuard],
  },
  {
    path: 'trades',
    loadChildren: () =>
      import('./trades/trades.module').then((m) => m.TradesModule),
  },
  {
    path: 'portfolio',
    loadChildren: () =>
      import('../main/portfolio-new/portfolio-new.module').then(
        (m) => m.PortfolioNewModule,
      ),
    canActivate: [AuthorizedGuard, AnalyticGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MainRoutingModule {}
