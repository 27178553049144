<ng-container>
  <div class="relative w-full h-full" id="cash-flow-chart">
    <!-- prettier-ignore -->
    <div
      class="absolute overflow-hidden pointer-events-none"
      [style]="{
        width: svgSetting.width - svgSetting.marginRight - (userType < 2 ? 196 : 246) + 'px',
        height: svgSetting.height - 54 + 'px'
      }"
    >
      <app-cash-flow-exposure
        *ngIf="tickerName"
        [appCashFlowExposureSettingsSvg]="{
          selector: svgSelectorDraggableShort,
          tickerName
        }"
        [appCashFlowExposureScaleY]="svgScaleY"
        [appCashFlowExposureSvgSetting]="svgSetting"
        [appCashFlowExposureTranslateX]="svgTranslateX"
        [appCashFlowExposureDataUpdate]="!cashFlowChartDataInUpdate"
        [appCashFlowExposureScaleYData]="svgScaleYData"
        (cashFlowExposureTooltip)="exposureTooltipList = $event"
      ></app-cash-flow-exposure>
      <!-- NET FLOWS -->
      <app-cash-flow-net-flows
        *ngIf="tickerName"
        [appCashFlowNetFlowsSettingsSvg]="{
          selector: svgSelectorDraggableShort,
          tickerName
        }"
        [appCashFlowNetFlowsSvgSetting]="svgSetting"
        [appCashFlowNetFlowsDataUpdate]="!cashFlowChartDataInUpdate"
        [appCashFlowNetFlowsTranslateX]="svgTranslateX"
        (cashFlowNetFlowsTooltip)="netflowTooltip = $event"
      ></app-cash-flow-net-flows>
      <app-cash-flow-forecast
        *ngIf="tickerName"
        [appCashFlowForecastSettings]="tickerName"
        [appCashFlowForecastSvgSetting]="svgSetting"
        [appCashFlowForecastTranslateX]="svgTranslateX"
      >
      </app-cash-flow-forecast>
    </div>
    <!-- TOTAL EXPOSURE -->
    <app-cash-flow-total-exposure
      *ngIf="tickerName"
      [appCashFlowTotalExposureSettingsSvg]="{
        selector: '#cash-flow-chart > svg',
        tickerName
      }"
      [appCashFlowTotalExposureScaleY]="svgScaleY"
      [appCashFlowTotalExposureSvgSetting]="svgSetting"
      [appCashFlowTotalExposureScaleYData]="svgScaleYData"
      (cashFlowTotalExposureTooltip)="exposureTooltipList = $event"
    ></app-cash-flow-total-exposure>
    <!-- TOOLTIP EXPOSURE -->
    <!-- prettier-ignore -->
    <div
      [id]="svgSelectorExposureTooltip.substring(1)"
      class="absolute z-[4] flex-col w-[252px] bg-[#fff] !hidden !p-4 rounded-lg border border-[#F2EBE0]"
    >
      <div class="arrow"></div>
      <div class="context flex flex-col gap-4">
        <ng-container *ngIf="exposureTooltipList.length">
          <div class="flex gap-2 items-center justify-content-between">
            <div *ngIf="exposureTooltipList[0].ivLevel" class="flex flex-col gap-0.5">
              <span
                class="text-sm font-semibold leading-[20px] tracking-[-0.08px] text-[#393939]"
              >
                {{ exposureTooltipList[0].ivLevel }}
              </span>
              <span class="text-[10px] leading-3 text-[#696969]">
                ${{ exposureTooltipList[0].strike | number: '1.2-2' }}
              </span>
            </div>
            <div
              *ngIf="!exposureTooltipList[0].ivLevel"
              class="text-lg font-bold leading-[20px] tracking-[-0.08px] text-[#393939]"
            >
              ${{ exposureTooltipList[0].strike | number: '1.2-2' }}
            </div>
            <div class="!p-1 rounded-full border border-[#A3B0C2] bg-[#a3b0c21a]">
              <p class="!p-1 text-[11px] leading-[100%] text-[#A3B0C2]">
                Win Rate:
                <span class="font-semibold">
                  {{ exposureTooltipList[0].winRate }}%
                </span>
              </p>
            </div>
          </div>
          <div class="flex flex-col gap-2">
            <div
              class="flex items-center justify-content-between gap-2 text-[10px] leading-[20px] text-[#696969]"
            >
              <span>Open Interest</span>
              <span>{{ exposureTooltipList[0].period | uppercase }} Change</span>
            </div>
            <div class="flex flex-col w-full h-12">
              <div
                class="flex items-center justify-content-between relative w-full h-6 text-[10px] leading-[100%] font-B612"
              >
                <div
                  class="absolute bg-[#41B1A6] h-6"
                  [ngStyle]="{ width: exposureTooltipList[0].growSumWidth + 'px' }"
                ></div>
                <div
                  class="absolute bg-[#41B1A6] h-6 opacity-30"
                  *ngIf="!!exposureTooltipList[0].growPercent"
                  [ngClass]="{ dash: exposureTooltipList[0].growPercent <= 0 }"
                  [ngStyle]="{
                    width: exposureTooltipList[0].growPercentWidth + 'px',
                    left: exposureTooltipList[0].growSumWidth + 'px'
                  }"
                >
                </div>
                <span
                  class="absolute block text-[#393939]"
                  [ngStyle]="{
                    top: exposureTooltipList[0].growPercent !== null ? '2px' : '7px',
                    left: (8 + exposureTooltipList[0].growSumWidth + (!!exposureTooltipList[0].growPercent ? exposureTooltipList[0].growPercentWidth : 0)) + 'px'
                  }"
                >
                  {{ exposureTooltipList[0].growSum | number: '1.0-0' }}
                </span>
                <span
                  class="absolute block text-[#41B1A6]"
                  [ngStyle]="{
                    top: '12px',
                    left: (8 + exposureTooltipList[0].growSumWidth + (!!exposureTooltipList[0].growPercent ? exposureTooltipList[0].growPercentWidth : 0)) + 'px'
                  }"
                  *ngIf="exposureTooltipList[0].growPercent !== null"
                >
                  {{ exposureTooltipList[0].growPercent | number: '1.2-2' }}%
                </span>
              </div>
              <div
                class="flex items-center justify-content-between relative w-full h-6 text-[10px] leading-[100%] font-B612"
              >
                <div
                  class="absolute bg-[#F16D6A] h-6"
                  [ngStyle]="{ width: exposureTooltipList[0].declineSumWidth + 'px' }"
                ></div>
                <div
                  class="absolute bg-[#F16D6A] h-6 opacity-30"
                  *ngIf="!!exposureTooltipList[0].declinePercent"
                  [ngClass]="{ dash: exposureTooltipList[0].declinePercent <= 0 }"
                  [ngStyle]="{
                    width: exposureTooltipList[0].declinePercentWidth + 'px',
                    left: exposureTooltipList[0].declineSumWidth + 'px'
                  }"
                ></div>
                <span
                  class="absolute block text-[#393939]"
                  [ngStyle]="{
                    top: exposureTooltipList[0].declinePercent !== null ? '2px' : '7px',
                    left: (8 + exposureTooltipList[0].declineSumWidth + (!!exposureTooltipList[0].declinePercent ? exposureTooltipList[0].declinePercentWidth : 0)) + 'px'
                  }"
                >
                  {{ exposureTooltipList[0].declineSum | number: '1.0-0' }}
                </span>
                <span
                  class="absolute block text-[#F16D6A]"
                  [ngStyle]="{
                    top: '12px',
                    left: (8 + exposureTooltipList[0].declineSumWidth + (!!exposureTooltipList[0].declinePercent ? exposureTooltipList[0].declinePercentWidth : 0)) + 'px'
                  }"
                  *ngIf="exposureTooltipList[0].declinePercent !== null"
                >
                  {{ exposureTooltipList[0].declinePercent | number: '1.2-2' }}%
                </span>
              </div>
            </div>
          </div>
          <div
            class="flex flex-col gap-0.5"
            *ngFor="let item of exposureTooltipList.slice(1);"
          >
            <div
              class="text-sm font-semibold leading-[20px] tracking-[-0.08px] text-[#393939]"
            >
              ${{ item.strike | number: '1.2-2' }}
            </div>
            <div class="flex flex-col w-full h-12">
              <div
                class="flex items-center justify-content-between relative w-full h-6 text-[10px] leading-[100%] font-B612"
              >
                <div
                  class="absolute bg-[#41B1A6] h-6"
                  [ngStyle]="{ width: item.growSumWidth + 'px' }"
                ></div>
                <div
                  class="absolute bg-[#41B1A6] h-6 opacity-30"
                  *ngIf="!!item.growPercent"
                  [ngClass]="{ dash: item.growPercent <= 0 }"
                  [ngStyle]="{
                    width: item.growPercentWidth + 'px',
                    left: item.growSumWidth + 'px'
                  }"
                ></div>
                <span
                  class="absolute block text-[#393939]"
                  [ngStyle]="{
                    top: item.growPercent !== null ? '2px' : '7px',
                    left: (8 + item.growSumWidth + (!!item.growPercent ? item.growPercentWidth : 0)) + 'px'
                  }"
                >
                  {{ item.growSum | number: '1.0-0' }}
                </span>
                <span
                  class="absolute block text-[#41B1A6]"
                  [ngStyle]="{ top: '12px', left: (8 + item.growSumWidth + (!!item.growPercent ? item.growPercentWidth : 0)) + 'px'}"
                  *ngIf="item.growPercent !== null"
                >
                  {{ item.growPercent | number: '1.2-2' }}%
                </span>
              </div>
              <div
                class="flex items-center justify-content-between relative w-full h-6 text-[10px] leading-[100%] font-B612"
              >
                <div
                  class="absolute bg-[#F16D6A] h-6"
                  [ngStyle]="{ width: item.declineSumWidth + 'px' }"
                ></div>
                <div
                  class="absolute bg-[#F16D6A] h-6 opacity-30"
                  *ngIf="!!item.declinePercent"
                  [ngClass]="{ dash: item.declinePercent <= 0 }"
                  [ngStyle]="{
                    width: item.declinePercentWidth + 'px',
                    left: item.declineSumWidth + 'px'
                  }"
                ></div>
                <span
                  class="absolute block text-[#393939]"
                  [ngStyle]="{
                    top: item.declinePercent !== null ? '2px' : '7px',
                    left: (8 + item.declineSumWidth + (!!item.declinePercent ? item.declinePercentWidth : 0)) + 'px'
                  }"
                >
                  {{ item.declineSum | number: '1.0-0' }}
                </span>
                <span
                  class="absolute block text-[#F16D6A]"
                  [ngStyle]="{ top: '12px', left: (8 + item.declineSumWidth + (!!item.declinePercent ? item.declinePercentWidth : 0)) + 'px'}"
                  *ngIf="item.declinePercent !== null"
                >
                  {{ item.declinePercent | number: '1.2-2' }}%
                </span>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <!-- TOOLTIP NETFLOW -->
    <!-- prettier-ignore -->
    <div
      [id]="svgSelectorNetflowTooltip.substring(1)"
      class="absolute z-[3] flex-col w-[200px] bg-[#fff] !hidden !p-4 rounded-lg border border-[#F2EBE0]"
    >
      <div class="arrow"></div>
      <div class="context flex flex-col">
        <div class="text-sm font-semibold leading-[20px] tracking-[-0.08px]" [style]="{ color: netflowTooltip.color }">
          {{ netflowTooltip.net_flow | number: '1.0-0' }} {{ netflowTooltip.type }} contracts
        </div>
        <div class="text-[10px] leading-[20px]">
          Netflow {{ netflowTooltip.period }} Moving sum
        </div>
      </div>
    </div>
  </div>
</ng-container>
