import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AppToastComponent } from 'src/app/modules/shared/components/app-toast/app-toast.component';
import { EventsService, EventType } from 'src/app/services/events.service';
import { MessageService } from '../../../../shared/services';
import { PremiumService } from '../../../premium/services/premium.service';
import { PricingPlanService } from '../../../services/pricing-plans.service';
import { SubscriptionsDataTransformService } from '../../services/subscriptions-data-transform.service';
import { SubscriptionsDataService } from '../../services/subscriptions-data.service';
import {
  SubscriptionModel,
  SubscriptionsPageModel,
} from './models/subscription.model';
import { AnalyticService } from 'src/app/services/analytic.service';
import { AuthService } from 'src/app/modules/auth/services/auth.service';
import { UnsubscribePiplineWidgetComponent } from './components/unsubscribe-pipline-widget/unsubscribe-pipline-widget.component';
import { SubscriptionsDialogService } from './dialogs/services/dialog.service';
import { debounceTime, first, from, Subscription } from 'rxjs';
import { TariffsResponse } from '../../../../../interfaces/api/payment/tariff/tariffs';
import { MainHttpClient } from '../../../../../services/main-http-client.service';
import { SnackbarService } from '../../../../../services/snackbar.service';

interface SubscriptionInfo {
  id: number;
  user_id: string;
  name: string;
  stripe_id: string;
  stripe_status: string;
  stripe_price: string;
  quantit: string | null;
  trial_ends_at: string | null;
  ends_at: string | null;
  created_at: string;
  updated_at: string;
  items: Array<{
    id: number;
    subscription_id: number;
    stripe_id: string;
    stripe_product: string;
    stripe_price: string;
    quantity: string | null;
    created_at: string;
    updated_at: string;
  }>;
}

@Component({
  selector: 'app-subscriptions-page',
  templateUrl: './subscriptions-page.component.html',
  styleUrls: ['./subscriptions-page.component.scss'],
  providers: [SubscriptionsDataService, SubscriptionsDataTransformService],
})
export class SubscriptionsPageComponent implements OnInit, OnDestroy {
  routeQueryParams$!: Subscription;

  message?: string;
  messageStatus: 'success' | 'error' = 'success';

  isDisabledUnsubscribeBtn: boolean = false;

  model?: SubscriptionsPageModel | null;

  constructor(
    private dataTransformService: SubscriptionsDataTransformService,
    private pricingPlansDataService: PricingPlanService,
    private messageService: MessageService,
    private router: Router,
    private premiumService: PremiumService,
    private dialog: MatDialog,
    private eventService: EventsService,
    private analyticService: AnalyticService,
    private authService: AuthService,
    private dialogService: SubscriptionsDialogService,
    private activatedRoute: ActivatedRoute,
    private httpClient: MainHttpClient,
    private snackbarService: SnackbarService,
  ) {}

  ngOnInit(): void {
    this.loadPageData();

    this.checkSubscription();

    this.routeQueryParams$ = this.activatedRoute.queryParams
      .pipe(debounceTime(100))
      .subscribe({
        next: (queryParams: Params) => {
          if ('pay-status' in queryParams) {
            switch (queryParams['pay-status']) {
              case 'cancel':
                this.dialogService.openPayCancelDialog();
                break;
              case 'success':
                this.dialogService.openPaySuccessDialog();
                break;
              default:
                break;
            }
          }
        },
      });
  }

  ngOnDestroy(): void {
    [this.routeQueryParams$].forEach(($) => $.unsubscribe());
  }

  checkSubscription(): void {
    // prettier-ignore
    from(this.httpClient.get<SubscriptionInfo | Array<any>>('/v2/subscription/info'))
      .pipe(first())
      .subscribe({
        next: (subscriptionInfo: SubscriptionInfo | Array<any>) => {
          if ('ends_at' in subscriptionInfo) {
            this.isDisabledUnsubscribeBtn = typeof subscriptionInfo.ends_at === 'string';
          } else {
            this.isDisabledUnsubscribeBtn = false;
          }
        },
        error: (error: { message: string }) => {
          this.snackbarService.error(error.message);
        },
      });
  }

  onPlanUpgradeRequest(event: SubscriptionModel.IEvent): void {
    const subscription = event.planData as SubscriptionModel.ISubscription;
    if (event.type === SubscriptionModel.EventType.Subscribe) {
      this.subscribe(subscription);
    } else if (event.type === SubscriptionModel.EventType.Unsubscribe) {
      // this.startUnsubscribePipline(subscription);
      this.dialogService.openReasonDialog();
    } else if (
      event.type === SubscriptionModel.EventType.Active &&
      event.plan === SubscriptionModel.Plan.Trial
    ) {
      this.subscribe(subscription, true);
    }
  }

  // prettier-ignore
  async loadPageData(): Promise<void> {
    try {
      const pricingPlans: TariffsResponse = await this.pricingPlansDataService.getPlans();

      // закидываем актуальны прайс для модалки со скидкой.
      this.dialogService.priceSubject.next(pricingPlans[0].prices[0].amount);

      const res = this.dataTransformService.convertSubscriptionPageResponse(pricingPlans);
      this.model = res;
    } catch (error: any) {
      console.error(error);
      this.message =
        error.message || 'Loading page error. Please try to refresh page.';
      this.messageStatus = 'error';
    }
  }

  private startUnsubscribePipline(
    subscription: SubscriptionModel.ISubscription,
  ) {
    this.dialog
      .open(UnsubscribePiplineWidgetComponent)
      .afterClosed()
      .subscribe((success: boolean) => {
        if (success) {
          this.unsubscribe(subscription);
        }
      });
  }

  private async unsubscribe(subscription: SubscriptionModel.ISubscription) {
    this.message = '';
    try {
      const confirmed = await this.messageService.confirm(
        'Your subscription will be deactivated after the end date. The fee will no longer be charged. Continue?',
      );
      if (!confirmed) {
        return;
      }

      await this.premiumService.unsubscribe(subscription.planId);
      subscription.active = false;

      this.message = 'You unsubscribed.';
      this.messageStatus = 'success';
      this.showSubscriptionPopup('You have been unsubscribed.');
    } catch (error: any) {
      this.message = error.message || 'Unsubscription error. Please try again.';
      this.messageStatus = 'error';
      console.error(error);
      this.showSubscriptionPopup(
        'Failed to diactivate subscription. Please try again',
      );
    }
  }

  private async subscribe(
    subscription: SubscriptionModel.ISubscription,
    trial: boolean = false,
  ) {
    this.message = '';
    try {
      const response = await this.premiumService.subscribe(
        subscription.planId,
        subscription.priceId!,
        subscription.interval!,
        !trial,
      );
      if (response.needToPay) {
        const payload = this.authService.getPayload();
        if (payload !== null) {
          this.analyticService.send(
            'click_payment',
            new Map([['user_id', payload.userId]]),
          );
        }
        window.location.href = response.paymentUrl!;
      } else {
        subscription.active = true;
        this.message = 'You subscribed!';
        this.messageStatus = 'success';
        if (trial) {
          this.showSubscriptionPopup(
            'Congratulations! Your trial has been activated',
          );
          this.eventService.emit(EventType.UserTypeChanged);
        } else {
          this.showSubscriptionPopup(
            'Congratulations! Your subscription has been activated',
          );
        }
      }
    } catch (error: any) {
      this.message = error.message || 'Subscription error. Please try again.';
      this.messageStatus = 'error';
      console.error(error);
      if (trial) {
        this.showSubscriptionPopup(
          'Failed to activate trial. Please try again.',
        );
      } else {
        this.showSubscriptionPopup(
          'Failed to activate subscription. Please try again.',
        );
      }
    }
  }

  private showSubscriptionPopup(text: string) {
    this.dialog.open(AppToastComponent, {
      data: {
        text: text,
      },
    });
  }

  getPremium() {
    this.router.navigateByUrl('/premium');
  }
}
