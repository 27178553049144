import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'shared-select',
  templateUrl: './select.component.html',
})
export class SelectComponent {
  @Input() id: string = 'select-' + Math.floor(Math.random() * 10000);
  @Input() multiselect = false;
  // TODO add required: true when update Angular
  @Input() label = 'Label';
  @Input() control: any = new FormControl();
  @Input() options: string[] = ['option 1', 'option 2', 'option 3'];

  @Output() isOpened = new EventEmitter<boolean>();

  selectOption(value: string): void {
    this.multiselect
      ? this.control?.setValue(
          this.control?.value?.includes(value)
            ? this.control?.value?.filter((i: string) => i !== value)
            : [...this.control?.value, value],
        )
      : this.control?.setValue(this.control?.value === value ? null : value);
    this.isOpened.emit(false);
  }

  deselectOption = (title: string): void =>
    this.control.setValue(
      this.control.value.filter((i: string) => i !== title),
    );
}
