import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ScaleService } from '../../../../shared/services/scale.service';
import { debounceTime, fromEvent, startWith, Subscription } from 'rxjs';
import { TurnDeviceService } from '../../../../../services/turn-device.service';

@Component({
  selector: 'app-landing-video-dialog',
  templateUrl: './landing-chart-dialog.component.html',
})
export class LandingChartDialogComponent implements AfterViewInit, OnDestroy {
  @ViewChild('chartParent') chartParent: ElementRef<HTMLDivElement> | undefined;
  @ViewChild('chartMain') chartMain: ElementRef<HTMLDivElement> | undefined;

  scale$!: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { urlVideo: string; title: string },
    private dialog: MatDialogRef<LandingChartDialogComponent>,
    private scaleService: ScaleService,
    private turnDeviceService: TurnDeviceService,
  ) {}

  ngAfterViewInit(): void {
    this.scale$ = fromEvent(window, 'resize')
      .pipe(startWith(1), debounceTime(100))
      .subscribe({
        next: () => {
          this.setScale();

          /** FOR TURN DEVICE */
          // prettier-ignore
          this.turnDeviceService.turnSubject.next(window.screen.width < window.screen.height);
        },
      });
  }

  ngOnDestroy(): void {
    [this.scale$].forEach(($: Subscription) => $?.unsubscribe());

    this.turnDeviceService.turnSubject.next(false);
  }

  setScale(): void {
    if (this.chartParent?.nativeElement) {
      const widthParent: number = this.chartParent?.nativeElement.offsetWidth;

      // prettier-ignore
      const paddingParent: CSSStyleDeclaration = window.getComputedStyle(this.chartParent?.nativeElement);
      const paddingLeftParent: number = parseInt(paddingParent.paddingLeft);
      const paddingBottomParent: number = parseInt(paddingParent.paddingBottom);

      const widthChart: number = 1570;
      const heightChart: number = 856;

      let scale: number = (widthParent - paddingLeftParent * 2) / widthChart;

      this.scaleService.currentScaleSubject.next(scale);

      // prettier-ignore
      let translateX: number = (widthChart - (widthParent - paddingLeftParent * 2)) / 2 * scale;
      // prettier-ignore
      let translateY: number = (heightChart - (heightChart * scale + (paddingBottomParent))) / 2;

      if (this.chartMain?.nativeElement) {
        // prettier-ignore
        this.chartParent.nativeElement.style.height = heightChart * scale + (paddingBottomParent * 2) + 'px';

        // prettier-ignore
        this.chartMain.nativeElement.style.transform = `translate(-${translateX}px, -${translateY}px) scale(${scale})`;
      }
    }
  }

  close(success: boolean = false) {
    this.dialog.close(success);
  }
}
