<ul *ngIf="page" ngbNav #nav="ngbNav" class="sidebar nav-tabs">
  <li [class.selected]="section.selected" *ngFor="let section of page?.sections" class="nav-item sidebar-section">
    <div class="sidebar-section-header">
      <img [src]="section.imgUrl">
      <a *ngIf="section.url.length !== 0" [href]="section.url" class="sidebar-section-header-text">{{ section.title }}</a>
      <div *ngIf="section.url.length == 0" class="sidebar-section-header-text">{{ section.title }}</div>
    </div>
    <a [class.selected]="field.selected"
       *ngFor="let field of section.fields"
       [href]="field.url" class="sidebar-section-item">
       <svg *ngIf="field.selected" width="5" height="9" viewBox="0 0 5 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.79076 4.00367L1.11195 0.199179C1.05153 0.136066 0.979645 0.0859719 0.900441 0.0517862C0.821237 0.0176006 0.736283 0 0.65048 0C0.564677 0 0.479723 0.0176006 0.400519 0.0517862C0.321314 0.0859719 0.249428 0.136066 0.189005 0.199179C0.0679483 0.325341 0 0.496006 0 0.673898C0 0.85179 0.0679483 1.02245 0.189005 1.14862L3.40633 4.51542L0.189005 7.84855C0.0679483 7.97472 0 8.14538 0 8.32327C0 8.50117 0.0679483 8.67183 0.189005 8.79799C0.249201 8.86162 0.320986 8.91223 0.400201 8.94691C0.479416 8.98159 0.564485 8.99963 0.65048 9C0.736475 8.99963 0.821544 8.98159 0.900759 8.94691C0.979973 8.91223 1.05176 8.86162 1.11195 8.79799L4.79076 4.99351C4.85673 4.93045 4.90938 4.85392 4.9454 4.76874C4.98141 4.68356 5 4.59158 5 4.49859C5 4.40559 4.98141 4.31361 4.9454 4.22843C4.90938 4.14325 4.85673 4.06672 4.79076 4.00367Z" fill="#5250F9"/>
        <path d="M4.79076 4.00367L1.11195 0.199179C1.05153 0.136066 0.979645 0.0859719 0.900441 0.0517862C0.821237 0.0176006 0.736283 0 0.65048 0C0.564677 0 0.479723 0.0176006 0.400519 0.0517862C0.321314 0.0859719 0.249428 0.136066 0.189005 0.199179C0.0679483 0.325341 0 0.496006 0 0.673898C0 0.85179 0.0679483 1.02245 0.189005 1.14862L3.40633 4.51542L0.189005 7.84855C0.0679483 7.97472 0 8.14538 0 8.32327C0 8.50117 0.0679483 8.67183 0.189005 8.79799C0.249201 8.86162 0.320986 8.91223 0.400201 8.94691C0.479416 8.98159 0.564485 8.99963 0.65048 9C0.736475 8.99963 0.821544 8.98159 0.900759 8.94691C0.979973 8.91223 1.05176 8.86162 1.11195 8.79799L4.79076 4.99351C4.85673 4.93045 4.90938 4.85392 4.9454 4.76874C4.98141 4.68356 5 4.59158 5 4.49859C5 4.40559 4.98141 4.31361 4.9454 4.22843C4.90938 4.14325 4.85673 4.06672 4.79076 4.00367Z" fill="#62C37D"/>
       </svg>

       {{ field.name }}
    </a>
  </li>
  <!-- <li class="nav-item sidebar-bottom">
    <div class="sidebar-bottom-area">
      <div class="sidebar-bottom-area-content">
        <a *ngIf="banner !== null" [href]="banner?.bannerUrl"><img [src]="banner?.bannerImage"></a>
        <span *ngIf="banner === null">250x200</span>
      </div>
    </div>
  </li> -->
</ul>
