import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SubscriptionDialogService } from '../../services/dialog.service';
import { SubscriptionsNew } from '../../models/subscriptions-new.model';
import { SubscriptionChangeService } from '../../services/subscription-change.service';

@Component({
  selector: 'app-subscription-cancel-dialog',
  templateUrl: './subscription-can-talk-dialog.component.html',
})
export class SubscriptionCanTalkDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { mySubscriptions: SubscriptionsNew },
    private dialog: MatDialogRef<SubscriptionCanTalkDialogComponent>,
    private dialogService: SubscriptionDialogService,
    private subscriptionChangeService: SubscriptionChangeService,
  ) {}

  close(): void {
    this.dialog.close();
  }

  onCancel(): void {
    this.close();

    if (this.data.mySubscriptions.next_payment?.has_discount) {
      this.subscriptionChangeService.currentChangeSubject.next('cancel');
    } else {
      this.dialogService.openGetDiscountSubscription();
    }
  }

  onGetCalendly(): void {
    this.close();

    this.dialogService.openThankYouSubscription();
  }
}
