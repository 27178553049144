<div class="add-ticker-search">
  <svg
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.08464 11.7503C7.34191 11.7501 8.56296 11.3292 9.55334 10.5547L12.6672 13.6685L13.6688 12.6669L10.5549 9.55307C11.3298 8.56259 11.751 7.34126 11.7513 6.08366C11.7513 2.9592 9.20909 0.416992 6.08464 0.416992C2.96018 0.416992 0.417969 2.9592 0.417969 6.08366C0.417969 9.20812 2.96018 11.7503 6.08464 11.7503ZM6.08464 1.83366C8.42851 1.83366 10.3346 3.73978 10.3346 6.08366C10.3346 8.42753 8.42851 10.3337 6.08464 10.3337C3.74076 10.3337 1.83464 8.42753 1.83464 6.08366C1.83464 3.73978 3.74076 1.83366 6.08464 1.83366Z"
      fill="#8E92A3"
    />
  </svg>
  <input
    [ngModel]="searchText"
    (ngModelChange)="onTextChanged($event)"
    placeholder="Search..."
    class="add-ticker-search-input"
    matInput
  />
</div>
