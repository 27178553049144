import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UrlParamsService } from 'src/app/services/url-params.service';
import { UserTypeModel } from '../../models/user-type.model';

@Component({
  selector: 'app-prototype-manager',
  templateUrl: './prototype-manager.component.html',
  styleUrls: ['./prototype-manager.component.scss']
})
export class PrototypeManagerComponent {

  userType: UserTypeModel | undefined;

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute) {
    
  }

  async selectPrototype(type: UserTypeModel) {
    await this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        prototype: UserTypeModel[type].toLocaleLowerCase()
      },
      queryParamsHandling: 'merge',
    }).then(x => {
      window.location.reload()
    });
  }

  get UserTypeModel(): typeof UserTypeModel {
    return UserTypeModel;
  }

}
