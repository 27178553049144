import { Component, Input } from '@angular/core';

type SizeLoader = 'xs' | 'sm' | 'md' | 'lg';

@Component({
  selector: 'app-loader-new-2',
  templateUrl: 'loader-new-2.component.html',
})
export class LoaderNew2Component {
  @Input()
  set appSize(size: SizeLoader) {
    this.size = size;

    switch (this.size) {
      case 'lg':
        this.widthSvg = 72;
        break;
      case 'md':
        this.widthSvg = 48;
        break;
      case 'sm':
        this.widthSvg = 32;
        break;
      case 'xs':
        this.widthSvg = 24;
        break;
      default:
        this.widthSvg = 24;
        break;
    }
  }

  @Input()
  set appHeight(height: string) {
    this.height = height;
  }

  size: SizeLoader = 'xs';
  height: string = '60px';
  widthSvg: number = 24;

  constructor() {}
}
