import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-registration-form',
  templateUrl: './registration-form.component.html',
  styleUrls: ['./registration-form.component.scss'],
})
export class RegistrationFormComponent {
  @Output() goToSignIn = new EventEmitter<string>();
  @Output() loginSuccessful = new EventEmitter<void>();
  @Input() signUpEnable?: boolean;
}
