export class TextUtil {
  static camelCaseToSnakeCase(text: string, firstToLower = true) {
    text = TextUtil.cleanSpaces(text);
    if (firstToLower && text.length) {
      text = text.substring(0, 1).toLowerCase() + text.substring(1);
    }
    return text.replace(/[A-Z]/g, (letter) => `-${letter.toLowerCase()}`);
  }

  static toCamelCase(text: string) {
    return text
      .toLowerCase()
      .replace(/([-_][a-z])/g, (group) =>
        group.toUpperCase().replace('-', '').replace('_', ''),
      );
  }

  static cleanSpaces(text: string) {
    return text.replace(/\s/g, '');
  }
}
