<span
  class="md:hidden bg-base-600 text-center text-sm py-3 font-bold not-italic"
>
  My Subscriptions
</span>
<div class="content">
  <div *ngIf="message" class="user-message" [ngClass]="messageStatus">
    {{ message }}
  </div>

  <app-subscription-header
    class="flex-grow"
    *ngIf="model && model.monthlyPlan"
    [plan]="model.monthlyPlan"
    (event)="onPlanUpgradeRequest($event)"
  >
  </app-subscription-header>
  <div class="plans">
    <div
      *ngIf="!model || model?.monthlyPlan || model?.ultraPlan"
      class="left flex gap-[12px]"
    >
      <app-subscription-plan
        *ngIf="!model || model?.monthlyPlan"
        [plan]="model?.monthlyPlan"
        [appIsDisabledUnsubscribe]="isDisabledUnsubscribeBtn"
        (event)="onPlanUpgradeRequest($event)"
        class="self-start"
      ></app-subscription-plan>

      <!-- ultra-premium-for-tablet -->
      <app-subscription-plan
        *ngIf="!model || model?.ultraPlan"
        [plan]="model?.ultraPlan"
        [appIsDisabledUnsubscribe]="isDisabledUnsubscribeBtn"
        (event)="onPlanUpgradeRequest($event)"
        class="md-lg-visible"
      ></app-subscription-plan>
    </div>

    <div class="right">
      <app-subscription-plan
        *ngIf="!model || model?.ultraPlan"
        [plan]="model?.ultraPlan"
        [appIsDisabledUnsubscribe]="isDisabledUnsubscribeBtn"
        (event)="onPlanUpgradeRequest($event)"
        class="md-lg-hidden"
      ></app-subscription-plan>

      <app-subscription-switch
        *ngIf="!model || model?.annualPlan"
        [data]="model?.annualPlan"
        (event)="onPlanUpgradeRequest($event)"
      ></app-subscription-switch>
    </div>
  </div>
</div>
