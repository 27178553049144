import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-onboarding-hint',
  templateUrl: './onboarding-hint.component.html',
  styleUrls: ['./onboarding-hint.component.scss']
})
export class OnboardingHintComponent implements OnInit {

  @Output() closed = new EventEmitter<void>()

  hidden: boolean = false;

  ngOnInit(): void {
  }

  close() {
    this.hidden = true;
    this.closed.emit()
  }

}
