import { SubscriptionModel } from '../models/subscription.model';

export const SUBSCRIPTION_TRIAL_OPTIONS: SubscriptionModel.IOption[] = [
  {
    type: SubscriptionModel.OptionType.AddStocks,
    text: 'Add 5 stocks to your Portfolio',
    link: {
      text: 'Add 5 stocks for a free 7 days trial',
      url: '/portfolio',
    },
  },
  {
    type: SubscriptionModel.OptionType.SetAlerts,
    text: 'Set Alerts',
    link: {
      text: 'Set alerts for a 20% discountl',
      url: '/alerts',
    },
  },
];
