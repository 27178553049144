import { TariffPriceInterval } from 'src/app/interfaces/api/payment/tariff/types/tariff-price-interval';

export namespace SubscriptionModel {
  export enum Period {
    Monthly = 'monthly',
    Annually = 'annually',
  }

  export enum EventType {
    Subscribe,
    Unsubscribe,
    Active,
    ActiveOption,
  }

  export interface IPrice {
    amount: number;
    currency: string;
    period: Period;
    discount?: string;
    active?: boolean;
  }

  export enum OptionType {
    AddStocks,
    SetAlerts,
    FullDataAccess,
    UnlimitedPortfolioStocks,
    UnlimitedAlerts,
    PortfolioDepthStocks,
  }

  export interface IOption {
    type?: OptionType;
    id?: string;
    text: string;
    active?: boolean;
    link?: {
      text: string;
      url?: string;
    };
  }

  export interface IPayment {
    price: IPrice;
    nextPaymentDate?: string;
  }

  export interface ISubscription {
    active?: boolean;
    planId: string;
    priceId?: string;
    interval?: TariffPriceInterval;
  }

  export interface IPlan extends ISubscription {
    plan: Plan;
    name: string;
    options: IOption[];
    prices?: IPrice[];
    periods?: Period[];
    payment?: IPayment;
  }

  export enum Plan {
    Trial,
    Pro,
    UltraPremium,
    Annual,
  }

  export interface IEvent {
    plan: Plan;
    type: EventType;
    option?: IOption;
    planData?: IPlan;
  }

  export interface IAnnualPayment extends ISubscription {
    amount: string;
    discount?: string;
  }
}

export interface SubscriptionsPageModel {
  ultraPlan?: SubscriptionModel.IPlan;
  annualPlan: SubscriptionModel.IAnnualPayment;
  monthlyPlan: SubscriptionModel.IPlan;
  meteredMonthlyPlan?: SubscriptionModel.IPlan;
}

export type UnsubscriptionReason =
  | 'reason-1'
  | 'reason-2'
  | 'reason-3'
  | 'reason-4'
  | 'reason-5'
  | 'reason-6'
  | 'reason-7';
