import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, catchError, finalize, of, tap } from 'rxjs';

import { HelperService } from '@app/services/helper.service';
import { SnackbarService } from '@app/services/snackbar.service';
import { SettingsService } from '@main/profile/services/settings.service';

import { checkPasswords } from '../settings.model';
import { PasswordCreatedSuccessfullyComponent } from './password-created-successfully/password-created-successfully.component';
import { PasswordResetSuccessfullyComponent } from './password-reset-successfully/password-reset-successfully.component';

@Component({
  selector: 'profile-settings-change-password',
  templateUrl: './change-password.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangePasswordComponent implements OnChanges {
  @Input() loginViaGoogle = false;
  @Input() isPasswordSpecified = false;

  public showPasswordsFields = false;

  public readonly passwordInputsTypes: Record<string, string> = {
    currentPassword: 'password',
    newPassword: 'password',
    confirmNewPassword: 'password',
  };

  // TODO add formGroup types when update angular
  public readonly passwordsForm = new FormGroup({
    currentPassword: new FormControl('', {
      validators: [Validators.required, Validators.minLength(8)],
    }),
    newPassword: new FormControl('', {
      validators: [Validators.required, Validators.minLength(8)],
    }),
    confirmNewPassword: new FormControl('', {
      validators: [Validators.required, Validators.minLength(8)],
    }),
  });

  public readonly formIsSubmitting$ = new BehaviorSubject<boolean>(false);
  public readonly isResetLoading$ = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly helperService: HelperService,
    private readonly snackBarService: SnackbarService,
    private readonly settingsService: SettingsService,
    private readonly dialog: MatDialog,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['loginViaGoogle'] || changes['isPasswordSpecified']) {
      this.showPasswordsFields =
        this.isPasswordSpecified ||
        (this.loginViaGoogle && this.isPasswordSpecified);
    }
  }

  togglePasswordFieldEye(field: string): void {
    const currentValue = this.passwordInputsTypes[field];
    this.passwordInputsTypes[field] =
      currentValue === 'password' ? 'text' : 'password';
  }

  submit(): void {
    checkPasswords(this.passwordsForm);

    if (this.helperService.getFormValidation(this.passwordsForm)) {
      this.formIsSubmitting$.next(true);

      Object.entries(this.passwordInputsTypes).forEach(([key, value]) => {
        if (value === 'text') {
          this.passwordInputsTypes[key] = 'password';
        }
      });

      const rawData = this.passwordsForm.getRawValue();
      this.settingsService
        .changePassword({
          current_password: rawData.currentPassword,
          new_password: rawData.newPassword,
          new_password_confirmation: rawData.confirmNewPassword,
        })
        .pipe(
          tap(() => {
            this.passwordsForm.reset();
            this.snackBarService.success('Changes Saved');
          }),
          catchError(() => of(null)),
          finalize(() => this.formIsSubmitting$.next(false)),
        )
        .subscribe();
    }
  }

  resetPassword(toCreate = false): void {
    if (!this.isResetLoading$.value) {
      this.isResetLoading$.next(true);

      this.settingsService
        .resetPassword(toCreate)
        .pipe(
          tap(() => {
            if (toCreate) {
              this.openCreatedPasswordDialog();
              return;
            }
            this.openResetPasswordDialog();
          }),
          catchError(() => of(null)),
          finalize(() => this.isResetLoading$.next(false)),
        )
        .subscribe();
    }
  }

  private openResetPasswordDialog(): void {
    this.dialog.open(PasswordResetSuccessfullyComponent);
  }

  private openCreatedPasswordDialog(): void {
    this.dialog.open(PasswordCreatedSuccessfullyComponent);
  }
}
