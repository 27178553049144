import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'shared-pagination-control',
  templateUrl: './pagination-control.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginationControlComponent {
  // TODO add required: true when update Angular
  @Input() currentPageNumber = 1;
  @Input() showItemsCount = 20;
  @Input() totalPagesCount = 0;
  @Input() showItemsCountValues: number[] | readonly number[] = [];

  @Output() itemsCountChanged = new EventEmitter<number>();

  changeItemsCount(count: number): void {
    if (this.showItemsCount !== count) {
      this.itemsCountChanged.emit(count);
    }
  }
}
