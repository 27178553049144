import { Injectable } from '@angular/core';
import { SubscribeResponse } from 'src/app/interfaces/api/payment/subscribe';
import { TariffPriceInterval } from 'src/app/interfaces/api/payment/tariff/types/tariff-price-interval';
import { UnsubscribeResponse } from 'src/app/interfaces/api/payment/unsubscribe';
import { UnsubscribeReasonRequest } from 'src/app/interfaces/api/payment/unsubscribe-reason';
import { AuthService } from 'src/app/modules/auth/services/auth.service';
import { UtmService } from 'src/app/modules/shared/modules/utm/services/utm.service';
import { MainHttpClient } from 'src/app/services/main-http-client.service';

@Injectable({
  providedIn: 'root',
})
export class PremiumService {
  constructor(
    private http: MainHttpClient,
    private utmService: UtmService,
    private authService: AuthService,
  ) {}

  async subscribe(
    planId: string,
    priceId: string,
    interval: TariffPriceInterval,
    ignoreTrial: boolean = true,
  ): Promise<SubscribeResponse> {
    const utm = this.utmService.getUtmData();
    const response = this.http.post<SubscribeResponse>(`/v2/subscribe`, {
      plan_id: planId,
      price_id: priceId,
      interval: interval,
      utm: utm,
      ignoreTrial: ignoreTrial,
    });
    return response;
  }

  async unsubscribe(planId: string): Promise<void> {
    await this.http.post<UnsubscribeResponse>(`/payment/unsubscribe`, {
      planId,
    });
    await this.authService.refreshLocalPermissions();
  }

  async sendUnsubscribeReason(
    request: UnsubscribeReasonRequest,
  ): Promise<void> {
    await this.http.post<UnsubscribeResponse>(
      `/payment/unsubscribe-reason`,
      request,
    );
  }
}
