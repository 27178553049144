import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Selection } from 'd3';
import {
  CashFlowNetflowTooltipObj,
  ExpTypeKey,
  SvgSetting,
} from '../../models/cash-flow-chart.model';
import * as d3Selection from 'd3-selection';
import { filter, first, from, Subscription } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { MainHttpClient } from '@app/services/main-http-client.service';
import { Router } from '@angular/router';
import { ExpirationTypeService } from '../../services/expiration-type.service';
import { CashFlowNetFlowsData } from '../../models/cash-flow-net-flows.model';
import { arrow, computePosition, flip, offset } from '@floating-ui/dom';
import { ChartService } from '../../services/chart.service';
import { UserTypeModel } from '../../../../shared/models/user-type.model';
import { AnalyticService } from '@app/services/analytic.service';
import { AuthFormService } from '../../../../auth/services/auth-form.service';
import { AuthService } from '../../../../auth/services/auth.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-cash-flow-net-flows',
  templateUrl: './cash-flow-net-flows.component.html',
})
export class CashFlowNetFlowsComponent implements OnInit, OnDestroy {
  cleanChartService$!: Subscription;
  expirationTypeService$!: Subscription;

  // prettier-ignore
  @Input()
  set appCashFlowNetFlowsSettingsSvg(settings: { selector: string; tickerName: string }) {
    this.svgSelector = settings.selector;
    this.cashFlowNetFlowsDataTicker = settings.tickerName;

    this.svg = d3Selection.select(this.svgSelector + ' > ' + this.svgNetFlowsSelector);

    if (this.svg.empty()) {
      this.svg = d3Selection.select(this.svgSelector).append('g').attr('id', this.svgNetFlowsSelector.substring(1));
    }
  }

  @Input()
  set appCashFlowNetFlowsSvgSetting(svgSetting: SvgSetting) {
    this.svgSetting = svgSetting;
  }

  @Input()
  set appCashFlowNetFlowsDataUpdate(cashFlowChartDataUpdate: boolean) {
    if (cashFlowChartDataUpdate && this.cashFlowNetFlowsDataTicker) {
      this.checkCashFlowNetFlowsData();
    }
  }

  @Input()
  set appCashFlowNetFlowsTranslateX(svgTranslateX: number) {
    this.svgTranslateX = svgTranslateX;
  }

  // prettier-ignore
  @Output() cashFlowNetFlowsTooltip: EventEmitter<CashFlowNetflowTooltipObj> = new EventEmitter();

  svg: Selection<any, any, any, any>;
  svgSetting: SvgSetting;
  svgGridStep: number = 18; // 64 - week, 18 - month, 12 - quarter
  svgTranslateX: number = 0;

  cashFlowNetFlowsData: CashFlowNetFlowsData[] = [];
  // prettier-ignore
  cashFlowNetFlowsFakeData: { minX: number; maxX: number; minXFake: number; maxXFake: number; width: number } = {
    minX: 0,
    maxX: 0,
    minXFake: 0,
    maxXFake: 0,
    width: 0,
  };

  cashFlowNetFlowsDataInUpdate: boolean = false;
  cashFlowNetFlowsDataDateUpdateLastDirection: 'left' | 'right' = 'left';
  cashFlowNetFlowsDataDateUpdateLeftIsBlocked: boolean = false;
  cashFlowNetFlowsDataDateUpdateRightIsBlocked: boolean = false;

  cashFlowNetFlowsDataTicker!: string;
  cashFlowNetFlowsDataActiveExpType: ExpTypeKey = 'month';
  cashFlowNetFlowsActivePeriod: string = '1d'; // 1d | 5d | 30d
  cashFlowNetFlowsPeriodList: string[] = ['1d', '5d', '30d'];

  svgSelector: string;
  svgNetFlowsSelector: string = '#cash-flow-chart-draggable-net-flows';
  // prettier-ignore
  svgNetFlowsSelectorXTickerDays: string = '#cash-flow-chart-draggable-x-ticker > g[data-id="days"]';
  // prettier-ignore
  svgSelectorExposureTooltip: string = '#cash-flow-chart-netflow-tooltip';
  svgSelectorExposureTooltipPopper: any;

  userType: UserTypeModel = UserTypeModel.Unregistered;

  // @ts-ignore
  timeoutIdBars: NodeJS.Timeout | undefined = undefined;

  constructor(
    @Inject(DOCUMENT)
    private document: Document,
    private http: MainHttpClient,
    private router: Router,
    private chartService: ChartService,
    private expirationTypeService: ExpirationTypeService,
    private authService: AuthService,
    private authFormService: AuthFormService,
    private analyticService: AnalyticService,
    private cookieService: CookieService,
  ) {}

  // prettier-ignore
  ngOnInit(): void {
    this.authService.getUserType().then((userTypeModel: UserTypeModel) => {
      this.userType = userTypeModel;
    });

    /** переключение тикера */
    this.cleanChartService$ = this.chartService.cleanSubject
      .pipe(filter((clean: boolean) => clean))
      .subscribe(() => {
        /** чистим для отрисовки заново */
        this.cleanSvg();
      });

    /** переключение зума */
    this.expirationTypeService$ = this.expirationTypeService.currentExpTypeSubject
      .pipe(filter((expTypeKey: ExpTypeKey) => this.cashFlowNetFlowsDataActiveExpType !== expTypeKey))
      .subscribe((expTypeKey: ExpTypeKey) => {
        this.cashFlowNetFlowsDataActiveExpType = expTypeKey;

        // 64 - week, 18 - month, 12 - quarter
        if (this.cashFlowNetFlowsDataActiveExpType === 'week') {
          this.svgGridStep = 64;
        }

        if (this.cashFlowNetFlowsDataActiveExpType === 'month') {
          this.svgGridStep = 18;
        }

        if (this.cashFlowNetFlowsDataActiveExpType === 'quarter') {
          this.svgGridStep = 12;
        }

        /** чистим для отрисовки заново */
        this.cleanSvg();
      });
  }

  ngOnDestroy(): void {
    [this.cleanChartService$, this.expirationTypeService$].forEach(
      ($: Subscription) => $?.unsubscribe(),
    );
  }

  /** удаляем ненужное для отрисовки заново */
  // prettier-ignore
  cleanSvg(): void {
    /** удаляем бары */
    d3Selection.selectAll(this.svgNetFlowsSelector + '> *').remove();

    this.cashFlowNetFlowsDataDateUpdateLeftIsBlocked = false;
    this.cashFlowNetFlowsDataDateUpdateRightIsBlocked = false;

    this.cashFlowNetFlowsData = [];

    this.cashFlowNetFlowsFakeData = { width: 0, minX: 0, maxX: 0, minXFake: 0, maxXFake: 0 };
  }

  // prettier-ignore
  setSvgBars(netFlowsData: CashFlowNetFlowsData[]): void {
    const maxHeightBar: number = 86;
    // @ts-ignore
    const maxBar: number = Math.max(...this.cashFlowNetFlowsData.map((netFlows: CashFlowNetFlowsData) => netFlows['net_flow_' + this.cashFlowNetFlowsActivePeriod]));
    const multiplier: number = maxHeightBar / maxBar;

    let netFlowsParent = () => d3Selection.select(this.svgNetFlowsSelector);

    netFlowsData.forEach((netFlows: CashFlowNetFlowsData, index: number) => {
      const netFlowsBar = () => netFlowsParent().select('g[data-date="' + netFlows.date + '"]');

      if (netFlowsBar().empty()) {
        netFlowsParent().append('g').attr('data-date', netFlows.date).attr('id', new Date(netFlows.date).getTime());
      }

      const dayNode = d3Selection.select(this.svgNetFlowsSelectorXTickerDays).select('text[data-date="' + netFlows.date + '"]').node();

      if (dayNode) {
        const positionX: number = Number(d3Selection.select(dayNode).attr('x'));
        // @ts-ignore
        const heightBar: number = multiplier * netFlows['net_flow_' + this.cashFlowNetFlowsActivePeriod] > 4 ? multiplier * netFlows['net_flow_' + this.cashFlowNetFlowsActivePeriod] : 4;

        const isCreate: boolean = !netFlowsBar().select('rect').empty();

        if (!isCreate) {
          /** create */
          netFlowsBar()
            .append('rect')
            .data([netFlows])
            // @ts-ignore
            .attr('data-value', netFlows['net_flow_' + this.cashFlowNetFlowsActivePeriod])
            .attr('data-is_fake', netFlows.is_faked)
            // @ts-ignore
            .attr('fill', netFlows['color_' + this.cashFlowNetFlowsActivePeriod] === 'red' ? '#F16D6A' : '#41B1A6')
            .attr('opacity', 0.5)
            .attr('x', positionX - 1.5)
            .attr('y', 675)
            .attr('width', 4)
            .attr('height', 0)
            .style('cursor', 'pointer')
            .transition()
            .duration(500)
            .delay(50 * index)
            .attr('y', 675 - heightBar)
            .attr('height', heightBar)
        } else {
          /** update */
          netFlowsBar()
            .select('rect')
            .transition()
            .duration(500)
            // @ts-ignore
            .attr('fill', netFlows['color_' + this.cashFlowNetFlowsActivePeriod] === 'red' ? '#F16D6A' : '#41B1A6')
            .attr('x', positionX - 1.5)
            .attr('y', 675 - heightBar)
            .attr('width', 4)
            .attr('height', heightBar)
        }
      }
    });

    /** blur */
    let minMaxList: number[] = [];
    let minMaxFakeList: number[] = [];

    netFlowsParent().selectAll('g[data-date]').nodes().forEach((netFlowsNode) => {
      const netFlowsRect = d3Selection.select(netFlowsNode).select('rect');
      const netFlows: CashFlowNetFlowsData = netFlowsRect.data()[0] as CashFlowNetFlowsData;

      if (netFlowsRect.node()) {
        if (netFlows.is_faked) {
          minMaxFakeList.push(Number(netFlowsRect.attr('x')) - (this.svgGridStep / 2));
        }

        minMaxList.push(Number(netFlowsRect.attr('x')) - (this.svgGridStep / 2));
      }
    });

    this.cashFlowNetFlowsFakeData = {
      minX: Math.min(...minMaxList),
      maxX: Math.max(...minMaxList),
      minXFake: Math.min(...minMaxFakeList),
      maxXFake: Math.max(...minMaxFakeList),
      width: Math.min(...minMaxFakeList) < 0
        ? Math.abs(Math.min(...minMaxFakeList)) + Math.abs(Math.max(...minMaxFakeList)) + this.svgGridStep
        : Math.abs(Math.max(...minMaxFakeList)) - Math.abs(Math.min(...minMaxFakeList)) + this.svgGridStep
    }

    this.setSvgTooltipEvents();
  }

  /** запрос к бэку за данными, старт отрисовки и запуск проверки на догрузку */
  // prettier-ignore
  getCashFlowNetFlowsData(dateFrom?: Date): void {
    this.cashFlowNetFlowsDataInUpdate = true;

    const body: any = {
      ticker: this.cashFlowNetFlowsDataTicker,
    };

    const nowMilliseconds: number = new Date().getTime();
    const monthMilliseconds: number = 24 * 60 * 60 * 1000 * 30; // month in milliseconds;

    if (!dateFrom) {
      dateFrom = new Date(nowMilliseconds - monthMilliseconds);
    }

    body.dateFrom = dateFrom.toJSON().slice(0, 10);

    from(this.http.post<{ movingAverage: CashFlowNetFlowsData[] }>('/stock-page/movingAverage', body))
      .pipe(first())
      .subscribe({
        next: (cashFlowNetFlowsData: { movingAverage: CashFlowNetFlowsData[] }) => {
          this.cashFlowNetFlowsDataInUpdate = false;

          if (this.cashFlowNetFlowsData && dateFrom) {
            /** Обновляемся */
            this.updateCashFlowExposureData(cashFlowNetFlowsData.movingAverage);
          } else {
            this.cashFlowNetFlowsData = cashFlowNetFlowsData.movingAverage;
          }

          // если получили данные, то рисуем и проверяем на догрузку
          if (cashFlowNetFlowsData.movingAverage.length > 0) {
            /** отрисовка данных */
            this.setSvgBars(cashFlowNetFlowsData.movingAverage);

            /** проверка на догрузку */
            this.checkCashFlowNetFlowsData();
          } else {
            /** Можно грузить прогнозы */
            this.chartService.startForecastSubject.next(true);
          }
        },
        complete: () => this.cashFlowNetFlowsDataInUpdate = false,
        error: (error: any) => console.error(error),
      });
  }

  /** Обновление массивов данных(добавляем без дублей) */
  // prettier-ignore
  updateCashFlowExposureData(cashFlowNetFlowsData: CashFlowNetFlowsData[]): void {
    const uniqueDate: number[] = this.cashFlowNetFlowsData ? [...new Set([...this.cashFlowNetFlowsData.map((netFlows: CashFlowNetFlowsData) => new Date(netFlows.date).getTime())])].map((date: number) => date) : [];

    const oldNetFlows: CashFlowNetFlowsData[] = this.cashFlowNetFlowsData ? this.cashFlowNetFlowsData : [];

    const newNetFlows: CashFlowNetFlowsData[] = cashFlowNetFlowsData.filter((netFlows: CashFlowNetFlowsData) => !uniqueDate.includes(new Date(netFlows.date).getTime()));

    /** блокируем загрузку, если не получаем ни чего нового */
    if (!newNetFlows.length) {
      if (this.cashFlowNetFlowsDataDateUpdateLastDirection === 'left') this.cashFlowNetFlowsDataDateUpdateLeftIsBlocked = true;
      if (this.cashFlowNetFlowsDataDateUpdateLastDirection === 'right') this.cashFlowNetFlowsDataDateUpdateRightIsBlocked = true;
    }

    this.cashFlowNetFlowsData = [...oldNetFlows, ...newNetFlows];
  }

  // prettier-ignore
  checkCashFlowNetFlowsData(): void {
    if (!this.cashFlowNetFlowsDataInUpdate) {
      if (this.cashFlowNetFlowsData.length) {
        /** доступные даты на графике */
        const dateList: d3Selection.BaseType[] = d3Selection.selectAll(this.svgNetFlowsSelectorXTickerDays + ' > text').nodes();

        /** крайняя левая дата на графике */
        // @ts-ignore
        const dateCandleLeft: number = new Date(d3Selection.select(dateList[0]).data()[0].date).getTime();

        /** крайняя правая дата на графике */
        // @ts-ignore
        const dateCandleRight: number = new Date(d3Selection.select(dateList.slice().reverse()[0]).data()[0].date).getTime();

        const dayMilliseconds: number = 24 * 60 * 60 * 1000; // необходимо сдвигать даты чтоб не перескакивало на следующие месяцы

        /** крайняя левая дата индикатора */
        const dateIndicatorLeft: number = Math.min(...this.cashFlowNetFlowsData.map((netFlows: CashFlowNetFlowsData) => new Date(netFlows.date).getTime())) + dayMilliseconds;

        /** крайняя правая дата индикатора */
        const dateIndicatorRight: number = Math.max(...this.cashFlowNetFlowsData.map((netFlows: CashFlowNetFlowsData) => new Date(netFlows.date).getTime())) - (dayMilliseconds * 5);

        /** если слева есть место для индикатора */
        if (!this.cashFlowNetFlowsDataInUpdate && !this.cashFlowNetFlowsDataDateUpdateLeftIsBlocked && isFinite(dateIndicatorLeft) && dateCandleLeft < dateIndicatorLeft) {
          this.cashFlowNetFlowsDataDateUpdateLastDirection = 'left';

          const date: Date = new Date(new Date(dateIndicatorLeft).setMonth(new Date(dateIndicatorLeft).getMonth() - 1));

          this.getCashFlowNetFlowsData(date);
        }

        /** если справа есть место для индикатора */
        if (!this.cashFlowNetFlowsDataInUpdate && !this.cashFlowNetFlowsDataDateUpdateRightIsBlocked && isFinite(dateIndicatorRight) && dateCandleRight > dateIndicatorRight) {
          this.cashFlowNetFlowsDataDateUpdateLastDirection = 'right';

          const date: Date = new Date(new Date(dateIndicatorRight).setMonth(new Date(dateIndicatorRight).getMonth() + 1));

          this.getCashFlowNetFlowsData(date);
        }

        /** если не пошли на загрузку, то пробуем отрисовать что есть */
        if (!this.cashFlowNetFlowsDataInUpdate) {
          this.setSvgBars(this.cashFlowNetFlowsData);
        }
      } else {
        this.getCashFlowNetFlowsData();
      }
    }
  }

  /** переключение периода индикатора и запуск отрисовки */
  onSetPeriod(period: string): void {
    this.cashFlowNetFlowsActivePeriod = period;

    this.setSvgBars(this.cashFlowNetFlowsData);
  }

  /** клик по заблокированному индикатору */
  onShowIndicator(): void {
    /** запоминаем страницу с которой пошли на покупку */
    this.cookieService.delete('page-before-payment', '/');
    this.cookieService.set('page-before-payment', this.router.url, {
      path: '/',
    });

    /** Не авторизован */
    if (this.userType === 0) {
      this.analyticService.send(
        'click_sign_up',
        new Map([['name', 'В индикаторе "netflow"']]),
      );
      this.authFormService.openSignUp();
    }

    /** Авторизован */
    if (this.userType > 0) {
      // prettier-ignore
      this.router.navigate(['/premium'], { fragment: 'tariffs' }).then(() => console.debug('Route changed'));
    }
  }

  // prettier-ignore
  setSvgTooltipEvents(): void {
    const tooltip: HTMLElement | null = this.document.querySelector(this.svgSelectorExposureTooltip);
    const tooltipArrow: HTMLElement | null = this.document.querySelector(this.svgSelectorExposureTooltip + ' > .arrow');
    const tooltipContext: HTMLElement | null = this.document.querySelector(this.svgSelectorExposureTooltip + ' > .context');

    /** Сначала чистим слушателей, потом добавляем заново */
    d3Selection
      .select(this.svgNetFlowsSelector)
      .selectAll('g[data-date] > rect')
      .on('mouseover', null)
      .on('mouseout', null)
      .on('mouseover', (event: any) => {
        const netFlows: any = d3Selection.select(event.target);

        const netFlowsData: CashFlowNetFlowsData = netFlows.data()[0];

        if (tooltip && tooltipArrow && tooltipContext) {
          this.svgSelectorExposureTooltipPopper = computePosition(
            event.target,
            tooltip,
            {
              middleware: [
                flip(),
                offset(18.5),
                arrow({
                  element: tooltipArrow,
                }),
              ],
              placement: 'right',
            },
          ).then(({ x, y, middlewareData }) => {
            if (middlewareData.arrow) {
              const { x, y } = middlewareData.arrow;

              Object.assign(tooltipArrow.style, {
                left: x != null ? `${x}px` : '',
                top: y != null ? `${y}px` : '',
              });
            }

            Object.assign(tooltip.style, {
              left: `${x}px`,
              top: `${y}px`,
            });
          });

          /** устанавливаем значения для тултипа и передаем наверх */
          this.cashFlowNetFlowsTooltip.emit({
            // @ts-ignore
            net_flow: netFlowsData['net_flow_' + this.cashFlowNetFlowsActivePeriod],
            // @ts-ignore
            color: netFlowsData['color_' + this.cashFlowNetFlowsActivePeriod] === 'red' ? '#F16D6A' : '#41B1A6',
            period: this.cashFlowNetFlowsActivePeriod.toUpperCase(),
            // @ts-ignore
            type: netFlowsData['color_' + this.cashFlowNetFlowsActivePeriod] === 'red' ? 'bearish' : 'bullish'
          });

          tooltip.classList.remove('!hidden');

          netFlows.attr('opacity', 1);
        }

        /** Отправка метрики, если смотришь на тултип дольше 3-х сек. */
        this.timeoutIdBars = setTimeout(() => {
          this.analyticService.send('stock_netflow_bars_view');
        }, 3000);
      })
      .on('mouseout', (event: any) => {
        const netFlows: any = d3Selection.select(event.target);

        if (tooltip && tooltipContext) {
          tooltip.classList.add('!hidden');

          netFlows.attr('opacity', 0.5);
        }

        /** Прерывание отправки метрик */
        if (this.timeoutIdBars) {
          clearTimeout(this.timeoutIdBars);
          this.timeoutIdBars = undefined;
        }
      });
  }
}
