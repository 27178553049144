import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { IdService } from 'src/app/services/id.services';
import * as d3 from 'd3-selection';
import * as d3Shape from 'd3-shape';
import { OverviewDiagram, OverviewDiagramSection } from '../../models/overview-diagram.model';
import { SectorNamePositionModel } from '../../models/diagram-position.model';
import { DiagramPositionService } from '../../services/diagram-position.service';
import { EventsService, EventType } from 'src/app/services/events.service';

class SectorName {
  text: string;
  location: SectorNamePositionModel;
}

@Component({
  selector: 'app-overview-diagram',
  templateUrl: './overview-diagram.component.html',
  styleUrls: ['./overview-diagram.component.scss']
})
export class OverviewDiagramComponent implements OnInit, AfterViewInit {

  @Input() model: OverviewDiagram;

  @Input() width: number = 340;

  @Input() height: number = 340;

  private diagramStep: number;
  private maxSectionSize: number;

  private svg: any;
  private arc: any;
  private pie: any;
  public diagramId: any;

  public sectorNames: Array<SectorName> = [];

  constructor(private idService: IdService,
              private positionService: DiagramPositionService,
              private eventService: EventsService,
              private cdr: ChangeDetectorRef) {

      this.eventService.subscription().subscribe(type => {
        if (type === EventType.PageResized) {
          this.setSectorNames();
        }
      })
  }

  ngOnInit(): void {
    this.diagramId = this.idService.getId(`ss-overview-diagram`);
  }

  ngAfterViewInit(): void {
    this.initSvg();
    this.drawGrid();
    this.setSectorNames();
  }

  private initSvg() {
    this.svg = d3.select(`#${this.diagramId}`)
                 .append("svg")
                 .attr("viewBox", `0 0 ${this.width} ${this.height}`);

    let allSectionSizes = this.model.firstComponentSections.sections;
    allSectionSizes = allSectionSizes.concat(this.model.secondComponentSections.sections);
    this.maxSectionSize = Math.max(...allSectionSizes) + 1;
    this.diagramStep = this.width / (2 * this.maxSectionSize);

    this.arc = d3Shape.arc()
                      .innerRadius(5)
                      .outerRadius((d: any) => d.data.size * this.diagramStep );

    this.pie = d3Shape.pie()
      .sort(null)
      .value((d: any) => 1 /* all sections have the same size */);

    const bottomLayer: Array<OverviewDiagramSection> = [];
    const topLayer: Array<OverviewDiagramSection> = [];

    for(let i = 0; i < this.model.sectionNames.length; i++) {
      const first = this.model.firstComponentSections.sections[i];
      const second = this.model.secondComponentSections.sections[i];
      let bottomSection: OverviewDiagramSection;
      let topSection: OverviewDiagramSection;

      if (first > second) {
        bottomSection = { size: first, color: this.model.firstComponentSections.color };
        topSection = { size: second, color: this.model.secondComponentSections.color };
      } else {
        bottomSection = { size: second, color: this.model.secondComponentSections.color };
        topSection = { size: first, color: this.model.firstComponentSections.color };
      }

      bottomLayer.push(bottomSection);
      topLayer.push(topSection);
    }

    this.drawLayer(bottomLayer, "bottom-arc");
    this.drawLayer(topLayer, "top-arc");

    const additionalCoeff: Array<number> = [];
    bottomLayer.forEach(section => {
      const coeff = 1 / section.size * 9;
      additionalCoeff.push(coeff);
    });
  }

  private setSectorNames() {
    let sectionsAngles = [ 0 ];
    this.sectorNames = [];
    const sectionSize = 360 / this.model.sectionNames.length;
    for(let i = 0; i < this.model.sectionNames.length - 1; i++) {
      sectionsAngles.push(sectionsAngles[i] + sectionSize);
    }
    const positions = this.positionService.getPositions(`#${this.diagramId} svg`, sectionsAngles);
    for(let i = 0; i < this.model.sectionNames.length; i++) {
      this.sectorNames.push({
        text: this.model.sectionNames[i],
        location: positions[i]
      });
    }
    this.cdr.detectChanges();
  }

  private drawLayer(layer: Array<OverviewDiagramSection>, className: string) {

    let g = this.svg.selectAll(`.${className}`)
        .data(this.pie(layer))
        .enter().append('g')
        .attr('transform',`translate(${this.width / 2}, ${this.height / 2})`)
        .attr('class', className);

    g.append('path').attr('d', this.arc)
        .style('fill', (d: any) => d.data.color)
        .style('stroke', (d: any) => "#DEDEDE");
  }

  private drawGrid() {
    for(let i = 1; i < this.maxSectionSize; i+= 0.5) {
      this.svg.append('circle')
              .attr('cx', this.width / 2)
              .attr('cy', this.height / 2)
              .attr('r', i * this.diagramStep)
              .attr('stroke', '#A9AEC314')
              .attr('fill', 'none')
              .attr('stroke-width', '1px');
    }

    this.svg.append('circle')
              .attr('cx', this.width / 2)
              .attr('cy', this.height / 2)
              .attr('r', this.maxSectionSize * this.diagramStep)
              .attr('stroke', '#A9AEC3')
              .attr('fill', 'none')
              .attr('stroke-width', '2px');
  }

  getStyle(style: string, value: string): string {
    return `${style}: ${value}`;
  }

  public getPosition(location: SectorNamePositionModel) {
    return `top: ${location.top};
            left: ${location.left};
            justify-content: ${location.align.flex};
            text-align: ${location.align.text}`;
  }

}
