import { Component, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-calendly',
  templateUrl: './calendly.component.html',
})
export class CalendlyComponent implements OnInit {
  constructor(
    @Inject(DOCUMENT)
    private document: Document,
  ) {}

  ngOnInit(): void {
    const scriptElement: HTMLScriptElement =
      this.document.createElement('script');

    scriptElement.id = 'calendly';
    scriptElement.src = 'https://assets.calendly.com/assets/external/widget.js';
    scriptElement.type = 'text/javascript';
    scriptElement.async = true;

    this.document.body.appendChild(scriptElement);
  }
}
