/** @format */

import { inject, Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Injectable({
	providedIn: 'root'
})
export class FormService {
	private readonly formBuilder: FormBuilder = inject(FormBuilder);

	onFormSubmit(formGroup: FormGroup): FormGroup {
		switch (formGroup.status) {
			case 'INVALID': {
				/** Пробегаем по всем контролам */

				this.getFormUpdateValueAndValidity(formGroup);

				/** Меняем updateOn пересозданием формы, иного метода пока не предусмотрено */

				return this.setFormUpdateOn(formGroup, 'change');
			}
			case 'VALID': {
				return formGroup;
			}
			default: {
				return formGroup;
			}
		}
	}

	getFormUpdateValueAndValidity(formGroup: FormGroup): void {
		Object.keys(formGroup.controls).forEach((control: string) => {
			formGroup.get(control)?.updateValueAndValidity();
			formGroup.get(control)?.markAsTouched();
		});
	}

	setFormUpdateOn(formGroup: FormGroup, updateOn: 'change' | 'blur' | 'submit'): FormGroup {
		return this.formBuilder.group(
			{
				...formGroup.controls
			},
			{
				updateOn
			}
		);
	}
}
