import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';
import { ANIM_SCALE_Y } from 'src/app/modules/shared/modules/basic/animations/scale.animation';
import {
  Component,
  OnInit,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { Ticker } from 'src/app/interfaces/api/domain/ticker';
import { POPULAR_STOCKS } from './data/search-result.data';
import { EventsService } from 'src/app/services/events.service';
import { AnalyticService } from 'src/app/services/analytic.service';
import { PortfolioService } from 'src/app/modules/widgets/portfolio/services/portfolio.service';

class Stock {
  ticker: Ticker;
  added: boolean;
}

@Component({
  selector: 'app-search-result',
  templateUrl: './search-result.component.html',
  styleUrls: ['./search-result.component.scss'],
  animations: [ANIM_SCALE_Y],
})
export class SearchResultComponent implements OnInit, OnChanges, OnDestroy {
  searchResultsTitle: string = 'Popular';
  searchResults: Array<Stock> = [];
  popularStocks: Array<Stock> = POPULAR_STOCKS;
  addedStocks: Array<Stock> = [];
  @Input() visible = false;
  @Input() searchText = '';

  searchLimiter: Subject<string>;

  constructor(
    private router: Router,
    private portfolioService: PortfolioService,
    private analyticService: AnalyticService
  ) {}

  ngOnDestroy(): void {
    this.searchLimiter.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const searchText = changes['searchText'];
    this.setSearchResultsTitle(this.searchText.trim());
    if (searchText && !searchText.firstChange) {
      this.searchLimiter.next(this.searchText.trim());
    }
  }

  ngOnInit(): void {
    this.searchLimiter = new Subject<string>();

    this.searchLimiter
      .pipe(debounceTime(200), distinctUntilChanged())
      .subscribe((text) => {
        this.loadSearchResults(text);
      });

    this.searchResults = this.popularStocks;
    this.loadPopularStocks();
  }

  private async loadPopularStocks(): Promise<void> {
    for (let s of this.popularStocks) {
      this.portfolioService
        .searchTicker({
          searchText: s.ticker.ticker,
          searchByWords: true,
          itemsPerPage: 1,
        })
        .then((x) => {
          const foundTicker = x.tickers[0];
          if (foundTicker.ticker !== s.ticker.ticker) {
            console.error(
              `Failed to find stock with ticker [${s.ticker.ticker}]`,
            );
          } else {
            s.ticker.id = foundTicker.id;
            s.ticker.name = foundTicker.name;
          }
        });
    }
  }

  async addStock(stock: Stock): Promise<void> {
    stock.added = true;
    this.addedStocks.push(stock);

    const tickersInPortfolio = await this.portfolioService.loadTickers();
    const name = stock.ticker.ticker;
    this.analyticService.send('add_ticker', new Map([
      ["name", name],
      ["count_tickers", `${tickersInPortfolio.tickers.length + 1}`]
    ]));

    await this.portfolioService.addTicker({
      tickerId: this.addedStocks.map((x) => x.ticker.id),
      price: 0,
      quantity: 0,
    });
  }

  async removeStock(stock: Stock): Promise<void> {
    stock.added = false;
    const index = this.addedStocks.findIndex((s) => s.ticker === stock.ticker);
    this.addedStocks.splice(index, 1);

    await this.portfolioService.removeTicker({
      tickerId: stock.ticker.id,
    });
  }

  openStockPage(stock: Stock) {
    this.router.navigate([
      'single-stock',
      {
        ticker: stock.ticker.ticker,
      },
    ]);
  }

  private setSearchResultsTitle(value: string) {
    if (value.length > 0) {
      this.searchResultsTitle = `Search by \"${value.substring(0, 10)}\"`;
    } else {
      this.searchResultsTitle = 'Popular';
      this.searchResults = this.popularStocks;
    }
  }

  async loadSearchResults(searchText: string) {
    const tickers = await this.portfolioService.searchTicker({
      searchText: searchText,
      searchByWords: true,
      itemsPerPage: 20,
    });

    const mapTicker = (ticker: Ticker): Stock => {
      return {
        ticker: {
          id: ticker.id,
          ticker: ticker.ticker,
          name: ticker.name,
        },
        added: false,
      };
    };
    this.searchResults = tickers.tickers.sort().map(mapTicker);
  }

  openList() {
    this.router.navigate(['portfolio']);
    this.addedStocks = [];
    this.searchResults = [];
  }
}
