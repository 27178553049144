import { Component } from '@angular/core';
import { COMPANY, TOOLS } from './footer.component.type';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  // menuItems: Array<INavigationMenu> = [];
  // private readonly onboardingService: OnboardingService;

  // constructor(
  //   public appService: AppService,
  //   public headerService: HeaderService,
  //   private authService: AuthService,
  //   private portfolioService: PortfolioService,
  //   private onboardingEventService: OnboardingHintEventService,
  //   private eventService: EventsService,
  //   public sanitizer: DomSanitizer,
  // ) {
  //   this.eventService.subscription().subscribe(async (type) => {
  //     if (type === EventType.NumberOfStocksInPortfolioChanged) {
  //       await this.onboardingService.updateNumberOfEvents(this.menuItems);
  //     }
  //   });

  //   this.menuItems = this.appService.mobileMenus;
  //   this.onboardingService = new OnboardingService(
  //     this.authService,
  //     this.portfolioService,
  //     this.onboardingEventService,
  //     this.menuItems,
  //   );
  // }

  // ngOnInit(): void {
  //   this.onboardingService.updateNumberOfEvents(this.menuItems);
  // }

  // get items() {
  //   return this.appService.footerItems;
  // }

  // itemClick(route?: string) {
  //   if (route) {
  //     if (route.startsWith('http')) {
  //       window.open(route);
  //     } else {
  //       this.headerService.gotoRoute(route);
  //     }
  //   }
  // }

  readonly TOOLS = TOOLS;
  readonly COMPANY = COMPANY;
  readonly currentYear = new Date().getFullYear();
}
