import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from '@angular/router';
import { AnalyticService } from 'src/app/services/analytic.service';

@Injectable()
export class AnalyticGuard implements CanActivate {
  constructor(private analyticService: AnalyticService) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    this.analyticService.sendFullUserData();
    return true;
  }
}
