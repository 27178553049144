<div
  class="flex flex-col bg-white w-screen md:max-w-[480px] h-screen md:h-auto !p-[16px] md:rounded-2xl"
>
  <div class="flex justify-end">
    <button type="button" (click)="close()">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M13.4099 12.0002L19.7099 5.71019C19.8982 5.52188 20.004 5.26649 20.004 5.00019C20.004 4.73388 19.8982 4.47849 19.7099 4.29019C19.5216 4.10188 19.2662 3.99609 18.9999 3.99609C18.7336 3.99609 18.4782 4.10188 18.2899 4.29019L11.9999 10.5902L5.70994 4.29019C5.52164 4.10188 5.26624 3.99609 4.99994 3.99609C4.73364 3.99609 4.47824 4.10188 4.28994 4.29019C4.10164 4.47849 3.99585 4.73388 3.99585 5.00019C3.99585 5.26649 4.10164 5.52188 4.28994 5.71019L10.5899 12.0002L4.28994 18.2902C4.19621 18.3831 4.12182 18.4937 4.07105 18.6156C4.02028 18.7375 3.99414 18.8682 3.99414 19.0002C3.99414 19.1322 4.02028 19.2629 4.07105 19.3848C4.12182 19.5066 4.19621 19.6172 4.28994 19.7102C4.3829 19.8039 4.4935 19.8783 4.61536 19.9291C4.73722 19.9798 4.86793 20.006 4.99994 20.006C5.13195 20.006 5.26266 19.9798 5.38452 19.9291C5.50638 19.8783 5.61698 19.8039 5.70994 19.7102L11.9999 13.4102L18.2899 19.7102C18.3829 19.8039 18.4935 19.8783 18.6154 19.9291C18.7372 19.9798 18.8679 20.006 18.9999 20.006C19.132 20.006 19.2627 19.9798 19.3845 19.9291C19.5064 19.8783 19.617 19.8039 19.7099 19.7102C19.8037 19.6172 19.8781 19.5066 19.9288 19.3848C19.9796 19.2629 20.0057 19.1322 20.0057 19.0002C20.0057 18.8682 19.9796 18.7375 19.9288 18.6156C19.8781 18.4937 19.8037 18.3831 19.7099 18.2902L13.4099 12.0002Z"
          fill="#303D50"
        />
      </svg>
    </button>
  </div>
  <div class="!p-[16px]">
    <div class="flex flex-col items-center justify-start gap-[24px]">
      <svg
        class="block"
        width="64"
        height="64"
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 32C0 14.3269 14.3269 0 32 0C49.6731 0 64 14.3269 64 32C64 49.6731 49.6731 64 32 64C14.3269 64 0 49.6731 0 32Z"
          fill="#E8F6EC"
        />
        <path
          d="M43.1654 24C42.4987 23.3333 41.4987 23.3333 40.832 24L28.332 36.5L23.1654 31.3333C22.4987 30.6667 21.4987 30.6667 20.832 31.3333C20.1654 32 20.1654 33 20.832 33.6667L27.1654 40C27.4987 40.3333 27.832 40.5 28.332 40.5C28.832 40.5 29.1654 40.3333 29.4987 40L43.1654 26.3333C43.832 25.6667 43.832 24.6667 43.1654 24Z"
          fill="#62C37D"
        />
      </svg>
      <div class="flex flex-col gap-[8px] text-center">
        <span class="text-interface-title-3-semibold text-high-on-light">
          Thank you!
        </span>
        <p class="text-interface-body-2-regular text-default-neutral-3">
          The requested article will be sent to the
          <span class="text-default-neutral-1"> {{ data.email }} </span>
          .
          {{
            data.signUp
              ? 'Your password will also be e - mailed to you, you can change it later.'
              : ''
          }}
        </p>
      </div>
      <p class="text-interface-caption-1-semibold text-default-neutral-1">
        If you don’t get the e-mail, check your spam folder
      </p>
      <span class="text-interface-caption-1-regular text-medium">
        This window will automatically close in
        <span class="text-default-secondary-2">0{{ formIntervalValue }}</span>
        sec
      </span>
    </div>
  </div>
</div>
