import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { RouterModule } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { SimplebarAngularModule } from 'simplebar-angular';
import {
  AgreementCookieComponent,
  AppToastComponent,
  DatepickerComponent,
  DropdownComponent,
  ErrorTooltipComponent,
  LoaderComponent,
  LoaderNew2Component,
  LoaderNewComponent,
  NumberPickerComponent,
  OverviewDiagramComponent,
  PaginationComponent,
  PrototypeManagerComponent,
  RangeDatepickerComponent,
  RangeNumberPickerComponent,
  ResearchPapersComponent,
  RiskRewardHeatmapChartComponent,
  RiskRewardHeatmapDialogComponent,
  RiskRewardHeatmapDialogSectionComponent,
  RiskRewardHeatmapItemComponent,
  ScrollableComponent,
  SearchComponent,
  SearchResultComponent,
  SignalIconComponent,
  SnackbarComponent,
  SnapshotMarketReactionComponent,
  SnapshotSetupComponent,
  TickerSearchFieldComponent,
  TrialBannerComponent,
} from './components';
import { PaginationControlComponent } from './components/pagination-control/pagination-control.component';
import { TableSortComponent } from './components/table-sort/table-sort.component';
import { TrianglesBackgroundComponent } from './components/triangles-background/triangles-background.component';
import {
  OnlyNumberDirective,
  ResizedDirective,
  VisibilityDirective,
} from './directives';
import { BasicModule } from './modules/basic/basic.module';
import {
  DiagramPositionService,
  MessageService,
  TextFormattingService,
} from './services';
import { SelectComponent } from './components/select/select.component';
import { IncludesPipe } from './pipes/includes.pipe';

@NgModule({
  declarations: [
    AgreementCookieComponent,
    AppToastComponent,
    DatepickerComponent,
    DropdownComponent,
    ErrorTooltipComponent,
    LoaderComponent,
    LoaderNewComponent,
    LoaderNew2Component,
    NumberPickerComponent,
    OverviewDiagramComponent,
    PaginationComponent,
    PrototypeManagerComponent,
    RangeDatepickerComponent,
    RangeNumberPickerComponent,
    RiskRewardHeatmapChartComponent,
    RiskRewardHeatmapDialogComponent,
    RiskRewardHeatmapDialogSectionComponent,
    RiskRewardHeatmapItemComponent,
    ScrollableComponent,
    SearchComponent,
    SearchResultComponent,
    TickerSearchFieldComponent,
    TrialBannerComponent,
    SnackbarComponent,
    SnapshotMarketReactionComponent,
    SnapshotSetupComponent,
    OnlyNumberDirective,
    ResizedDirective,
    VisibilityDirective,
    TrianglesBackgroundComponent,
    ResearchPapersComponent,
    SignalIconComponent,
    PaginationControlComponent,
    TableSortComponent,
    SelectComponent,
    IncludesPipe,
  ],
  imports: [
    CommonModule,
    MatExpansionModule,
    BasicModule,
    SimplebarAngularModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
  ],
  providers: [
    DiagramPositionService,
    MessageService,
    TextFormattingService,
    CookieService,
  ],
  exports: [
    OverviewDiagramComponent,
    RiskRewardHeatmapItemComponent,
    ScrollableComponent,
    SearchResultComponent,
    OnlyNumberDirective,
    ResizedDirective,
    VisibilityDirective,
    TickerSearchFieldComponent,
    LoaderComponent,
    NumberPickerComponent,
    LoaderNewComponent,
    LoaderNew2Component,
    TrialBannerComponent,
    SnapshotMarketReactionComponent,
    SnapshotSetupComponent,
    SnackbarComponent,
    PrototypeManagerComponent,
    DropdownComponent,
    PaginationComponent,
    ErrorTooltipComponent,
    SearchComponent,
    DatepickerComponent,
    AgreementCookieComponent,
    RangeDatepickerComponent,
    RangeNumberPickerComponent,
    TrianglesBackgroundComponent,
    ResearchPapersComponent,
    SignalIconComponent,
    PaginationControlComponent,
    TableSortComponent,
    SelectComponent,
    IncludesPipe,
  ],
})
export class SharedModule {}
