<ng-container>
  <ul class="flex pointer-events-auto">
    <!-- prettier-ignore -->
    <ng-container *ngFor="let forecast of forecastPrepareList">
      <li
        class="absolute bottom-0 h-[86px]"
        [ngClass]="forecast.gradient"
        [style]="{
          left: forecast.positionX + svgTranslateX + 'px',
          width: forecast.width + 'px'
        }"
        [attr.data-date]="forecast.date"
        *ngIf="!forecast.is_faked"
      >
        <div
          class="relative flex !gap-4 items-center justify-center w-full h-full"
        >
          <div [innerHTML]="forecast.icon"></div>
          <!-- prettier-ignore -->
          <span class="text-interface-title-6-semibold">
            {{ forecast.expectation_type ? forecast.expectation_type : 'Mixed' }}
          </span>
        </div>
      </li>
    </ng-container>
  </ul>
  <!-- BLUR -->
  <div
    *ngIf="fakeData.width"
    class="absolute bottom-0 h-[86px]"
    [style]="{
      left: fakeData.minXFake + svgTranslateX + 'px',
      width: fakeData.width + 'px'
    }"
  >
    <div class="w-full h-full backdrop-filter backdrop-blur-[5px]">
      <div class="w-full h-full bg-white opacity-50"></div>
    </div>
  </div>
  <!-- prettier-ignore -->
  <div
    *ngIf="fakeData.width"
    class="absolute flex items-center justify-end bottom-0 h-[86px] pointer-events-auto"
    [style]="{
      left:
        334 < fakeData.minXFake + svgTranslateX + 40
          ? fakeData.minXFake + 40 + svgTranslateX + 'px'
          : 334 > fakeData.maxXFake + svgTranslateX - 154 - 40
          ? fakeData.maxXFake - 154 - 40 + svgTranslateX + 'px'
          : '334px'
    }"
  >
    <div class="flex flex-col gap-2 w-[154px]">
      <button
        class="block bg-[#62C37D] text-[#FFF] text-sm leading-[140%] font-semibold !rounded py-1.5 px-2"
        type="button"
        (click)="onShowIndicator()"
      >
        {{ userType === 0 ? 'Show 1 more month' : 'Show full chart' }}
      </button>
      <span
        class="block text-center text-sm leading-[16px] font-semibold tracking-[-0.08px] px-1"
      >
        {{ userType === 0 ? 'Free Sign Up' : userType === 1 ? 'Get Free Trial' : 'Upgrade' }}
      </span>
    </div>
  </div>
</ng-container>
