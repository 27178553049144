import { Injectable } from '@angular/core';
import {
  SubscriptionModel,
  SubscriptionsPageModel,
} from '../pages/subscriptions-page/models/subscription.model';
import {
  PRO,
  ANNUAL_PAYMENT,
} from '../pages/subscriptions-page/data/subscription-plan.data';
import { TariffsResponse } from 'src/app/interfaces/api/payment/tariff/tariffs';
import { TariffType } from 'src/app/interfaces/api/payment/tariff/types/tariff-type';
import { TariffPriceInterval } from 'src/app/interfaces/api/payment/tariff/types/tariff-price-interval';

@Injectable()
export class SubscriptionsDataTransformService {
  constructor() {}

  convertSubscriptionPageResponse(
    tariffResponse: TariffsResponse,
  ): SubscriptionsPageModel | null {
    const model: Partial<SubscriptionsPageModel> = {};
    for (const tariff of tariffResponse) {
      if (tariff.type === TariffType.pro || tariff.type === undefined) {
        const monthProPlan = tariff?.prices.find(
          (p) => p.interval === TariffPriceInterval.month && p.usage_type === 'licensed',
        );
        const yearProPlan = tariff?.prices.find(
          (p) => p.interval === TariffPriceInterval.year && p.usage_type === 'licensed',
        );
        const meteredMonthProPlan = tariff?.prices.find(
          (p) => p.interval === TariffPriceInterval.month && p.usage_type === 'metered',
        );

        model.monthlyPlan = {
          name: 'Pro',
          active: tariff.isActive,
          planId: `${tariff.id}`,
          priceId: monthProPlan?.id,
          interval: TariffPriceInterval.month,
          payment: {
            nextPaymentDate: '',
            price: {
              amount: monthProPlan?.amount!,
              period: SubscriptionModel.Period.Monthly,
              currency: '$',
            },
          },
          options: PRO.options,
          plan: SubscriptionModel.Plan.Pro,
        };

        model.annualPlan = {
          active: tariff.isActive,
          planId: `${tariff.id}`,
          priceId: yearProPlan?.id,
          interval: TariffPriceInterval.year,
          amount: `${yearProPlan?.amount!}`,
        };

        if (meteredMonthProPlan) {
          model.meteredMonthlyPlan = {
            name: 'Pay If You Win',
            active: tariff.isActive,
            planId: `${tariff.id}`,
            priceId: meteredMonthProPlan.id,
            interval: TariffPriceInterval.month,
            payment: {
              nextPaymentDate: '',
              price: {
                amount: meteredMonthProPlan.amount,
                period: SubscriptionModel.Period.Monthly,
                currency: '$',
              },
            },
            options: PRO.options,
            plan: SubscriptionModel.Plan.Pro,
          };
        }
      }
    }
    return model as SubscriptionsPageModel;
  }
}
