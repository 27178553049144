import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { MatTabsModule } from '@angular/material/tabs';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { LayoutModule } from './modules/layout/layout.module';
import { RouterModule } from '@angular/router';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { AuthModule } from './modules/auth/auth.module';
import { ServerErrorInterceptor } from './interceptors/server-errors.interceptor';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { MainModule } from './modules/main/main.module';
import { SharedModule } from './modules/shared/shared.module';
import { WidgetsHttpClient } from './services/widgets-http-client.service';
import { environment } from 'src/environments/environment';
import { MainHttpClient } from './services/main-http-client.service';
import { UtmModule } from './modules/shared/modules/utm/utm.module';
import { FooterModule } from './modules/layout/footer/footer.module';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { StoreModule } from '@ngrx/store';
import { vsReducer } from './store/vs.reducer';
import { AffiliateModule } from './modules/affiliate/affiliate.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    MatTabsModule,
    MatInputModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatNativeDateModule,
    MatSelectModule,
    MatDatepickerModule,
    BrowserAnimationsModule,
    MatDialogModule,
    MatExpansionModule,
    HttpClientModule,
    RouterModule.forRoot([
      // {
      //   path: '',
      //   loadChildren: () =>
      //     import('./modules/main/home/home.module').then((m) => m.HomeModule),
      //   pathMatch: 'full',
      // },
      {
        path: '',
        loadChildren: () =>
          import('./modules/main/landing/landing.module').then(
            (m) => m.LandingModule,
          ),
        pathMatch: 'full',
      },
      {
        path: '',
        loadChildren: () =>
          import('./modules/widgets/widgets.module').then(
            (m) => m.WidgetsModule,
          ),
      },
      {
        path: 'pages',
        loadChildren: () =>
          import('./modules/pages/pages.module').then((m) => m.PagesModule),
      },
      {
        path: 'alerts',
        loadChildren: () =>
          import('./modules/main/alerts/alerts.module').then(
            (m) => m.AlertsModule,
          ),
      },
      {
        path: 'events-timeline',
        loadChildren: () =>
          import('./modules/main/events-timeline/events-timeline.module').then(
            (m) => m.EventsTimelineModule,
          ),
      },
      {
        path: 'win-rate',
        loadChildren: () =>
          import('./modules/main/win-rate/win-rate.module').then(
            (m) => m.WinRateModule,
          ),
      },
      /** VS-433 */
      {
        path: 'single-stock',
        loadChildren: () =>
          import('./modules/main/cash-flow/cash-flow.module').then(
            (m) => m.CashFlowModule,
          ),
      },
    ]),
    StoreModule.forRoot({ vsStore: vsReducer }),
    LayoutModule,
    SharedModule,
    AuthModule,
    MainModule,
    FooterModule,
    UtmModule,
    AffiliateModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ServerErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: WidgetsHttpClient,
      useFactory: (http: HttpClient) =>
        new WidgetsHttpClient(http, environment.apiWidgetsUrl),
      deps: [HttpClient],
    },
    {
      provide: MainHttpClient,
      useFactory: (http: HttpClient) =>
        new MainHttpClient(http, environment.apiMainUrl),
      deps: [HttpClient],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
