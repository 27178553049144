import { Injectable } from '@angular/core';
import {
  MoneyFlowPageRequest,
  MoneyFlowPageResponse,
} from 'src/app/interfaces/api/widgets/money-flow/money-flow-page';
import { WidgetsHttpClient } from 'src/app/services/widgets-http-client.service';
import {
  SectorRequest,
  SectorResponse,
} from '../../../../interfaces/api/widgets/money-flow/sector';
import {
  SectorMonthDataRequest,
  SectorMonthDataResponse,
} from '../../../../interfaces/api/widgets/money-flow/sector-month-data';
import {
  SectorSupportResistanceChartRequest,
  SectorSupportResistanceChartResponse,
} from '../../../../interfaces/api/widgets/money-flow/sector-support-resistance-chart';
import {
  SectorTopStocksRequest,
  SectorTopStocksResponse,
} from '../../../../interfaces/api/widgets/money-flow/sector-top-stocks';

@Injectable()
export class MoneyFlowDataService {
  constructor(private http: WidgetsHttpClient) {}

  public getPage(body: MoneyFlowPageRequest): Promise<MoneyFlowPageResponse> {
    return this.http.post('/money-flow/page', body);
  }

  public getSector(body: SectorRequest): Promise<SectorResponse> {
    return this.http.post('/money-flow/sector', body);
  }

  public getSectorMonthData(
    body: SectorMonthDataRequest,
  ): Promise<SectorMonthDataResponse> {
    return this.http.post('/money-flow/sector-month-data', body);
  }

  public getSectorTopStocks(
    body: SectorTopStocksRequest,
  ): Promise<SectorTopStocksResponse> {
    return this.http.post('/money-flow/sector-top-stocks', body);
  }

  public async getTargetSupportChart(
    body: SectorSupportResistanceChartRequest,
  ): Promise<SectorSupportResistanceChartResponse> {
    return this.http.post('/money-flow/sector-support-resistance-chart', body);
  }
}
