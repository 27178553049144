import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Subject, takeUntil, tap } from 'rxjs';

import { SnackbarService } from '@app/services/snackbar.service';
import {
  ChangeEmailDialogType,
  UserProfile,
} from '@main/profile/pages/settings/settings.model';
import { SettingsService } from '@main/profile/services/settings.service';

interface DialogData {
  email: UserProfile['email'];
}

export const CODE_LENGTH = 8;

@Component({
  templateUrl: './dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeEmailDialogComponent implements OnInit, OnDestroy {
  public readonly DialogType = ChangeEmailDialogType;
  public newEmail: UserProfile['email'] = '';

  public readonly dialogType$ = new BehaviorSubject<ChangeEmailDialogType>(
    this.settingsService.latestEmailChangeDialogType$.value,
  );

  // TODO create destroyService / use takeUntilDestroyed
  private readonly destroy$ = new Subject<void>();

  constructor(
    // TODO use protected when update angular
    @Inject(MAT_DIALOG_DATA) public readonly data: DialogData,
    private readonly dialog: MatDialogRef<ChangeEmailDialogComponent>,
    private readonly snackBarService: SnackbarService,
    private readonly settingsService: SettingsService,
  ) {}

  ngOnInit(): void {
    this.dialogType$
      .pipe(
        tap((dialogType) => {
          this.settingsService.latestEmailChangeDialogType$.next(
            dialogType === ChangeEmailDialogType.CHANGED_SUCCESSFULLY
              ? ChangeEmailDialogType.CURRENT_EMAIL_CODE
              : dialogType,
          );
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  close(): void {
    this.dialog.close();
  }

  showToast(): void {
    this.snackBarService.success('Changes Saved');
  }
}
