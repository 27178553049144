import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  BehaviorSubject,
  catchError,
  debounceTime,
  finalize,
  of,
  tap,
} from 'rxjs';

import { HelperService } from '@app/services/helper.service';
import {
  EmailAction,
  UserProfile,
} from '@main/profile/pages/settings/settings.model';
import { SettingsService } from '@main/profile/services/settings.service';

@Component({
  selector: 'profile-enter-new-email',
  templateUrl: './enter-new-email.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnterNewEmailComponent implements OnInit {
  @Input() token: EmailAction['token'] = '';

  @Output() next = new EventEmitter<UserProfile['email']>();

  public readonly emailForm = new FormGroup({
    email: new FormControl(this.settingsService.latestNewEmailValue$.value, {
      validators: [Validators.required, Validators.email],
    }),
  });

  public readonly formIsSubmitting$ = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly helperService: HelperService,
    private readonly settingsService: SettingsService,
  ) {}

  ngOnInit(): void {
    const emailControl = this.emailForm.get('email');

    if (emailControl) {
      emailControl.valueChanges
        .pipe(
          debounceTime(0),
          tap((email) => this.settingsService.latestNewEmailValue$.next(email)),
        )
        .subscribe();
    }
  }

  submit(): void {
    if (this.helperService.getFormValidation(this.emailForm)) {
      this.formIsSubmitting$.next(true);
      const email = this.emailForm.get('email')?.value?.trim();
      this.settingsService
        .sendNewConfirmationCode(email)
        .pipe(
          tap(() => this.next.emit(email)),
          finalize(() => this.formIsSubmitting$.next(false)),
          catchError(() => of(null)),
        )
        .subscribe();
    }
  }
}
