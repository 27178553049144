import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { SocialAuthService } from '../../services/social-auth.service';

@Component({
  selector: 'app-auth-form-social-buttons',
  templateUrl: './auth-form-social-buttons.component.html',
  styleUrls: ['./auth-form-social-buttons.component.scss']
})
export class AuthFormSocialButtonsComponent implements OnInit {

  @Output() loginSuccessful = new EventEmitter<void>();

  constructor(public socialAuthService: SocialAuthService) { }

  ngOnInit(): void {
  }

  async googleSignIn() {
    await this.socialAuthService.googleAuth();
    this.loginSuccessful.emit();
  }

  async facebookSignIn() {
    await this.socialAuthService.facebookAuth();
    this.loginSuccessful.emit();
  }

}
