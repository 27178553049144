import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { SubscriptionChangeService } from '../../services/subscription-change.service';

@Component({
  selector: 'app-subscription-cancel-dialog',
  templateUrl: './subscription-get-discount-dialog.component.html',
})
export class SubscriptionGetDiscountDialogComponent {
  constructor(
    private dialog: MatDialogRef<SubscriptionGetDiscountDialogComponent>,
    private subscriptionChangeService: SubscriptionChangeService,
  ) {}

  close(): void {
    this.dialog.close();
  }

  onCancel(): void {
    this.close();

    this.subscriptionChangeService.currentChangeSubject.next('cancel');
  }

  onGetDiscount(): void {
    this.close();

    this.subscriptionChangeService.currentChangeSubject.next('discount');
  }
}
