import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-landing-already-signed-in-dialog',
  templateUrl: './landing-already-signed-in-dialog.component.html',
})
export class LandingAlreadySignedInDialogComponent {
  constructor(
    private router: Router,
    private dialog: MatDialogRef<LandingAlreadySignedInDialogComponent>,
  ) {}

  close(success: boolean = false): void {
    this.dialog.close(success);
  }

  // prettier-ignore
  openTickerPage(): void {
    this.close();

    this.router.navigate(['single-stock', { ticker: 'SPY' }]).then(() => console.debug('router changed'));
  }
}
