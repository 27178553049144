/** 
 * @todo Refactoring. 
 * Use ususal HTML and remove this file  
 */

import { Icon, IFooterItem } from './../structures/ui';

export const FOOTER_DATA: IFooterItem[] = [
  {
    title: {
      default: 'Main',
    },
    items: [
      { text: { default: 'Set alerts' } , route: 'alerts' },
      { text: { default: 'Premium' } , route: 'premium' },
      { text: { default: 'My Subscriptions' } , route: 'subscriptions' },
      { text: { default: 'Terms and conditions' } , route: 'pages/terms' },
      { text: { default: 'Privacy policy' } , route: 'pages/privacy-policy' },
    ]
  },
  {
    title: {
      default: 'Portfolio',
    },
    items: [
      { text: { default: 'My portfolio' } , route: 'portfolio' },
      { text: { default: 'Portfolio flows heatmap' } , route: 'portfolio-money-flow' },
      //{ text: { default: 'Risk heatmap' } , route: 'risk-reward-heatmap' },
      //{ text: { default: 'Upside calculation' } , route: 'portfolio-upside-calculator' },
      //{ text: { default: 'Trades' } , route: 'trades' },
    ]
  },
  {
    title: {
      default: 'Money flows',
    },
    items: [
      { text: { default: 'Markets' } , route: 'money-flow?type=markets' },
      { text: { default: 'Sectors' } , route: 'money-flow?type=sectors' },
    ],
  },
  {
    title: {
      default: 'Social media',
    },
    items: [
      {
        icon: Icon.Youtube,
        route: 'https://www.youtube.com/@visualsectors/featured',
      },
      {
        icon: Icon.Instagram,
        route: 'https://www.instagram.com/visualsectors/reels/',
      },
      { icon: Icon.Tiktok, route: 'https://www.tiktok.com/@visualsectors' },
      { icon: Icon.Facebook, route: 'https://www.facebook.com/visualsectors' },
      { icon: Icon.Twitter, route: 'https://twitter.com/visualsectors' },
      { icon: Icon.Reddit, route: 'https://www.reddit.com/r/visualsectors/' },
      { html: `
        <div class="flex flex-col">
          <div style="color: #333333">Contact Us</div>
          <div>support@visualsectors.com</div>
        </div>

        <div class="flex">
          <div>
            <div class="office-label">US office:</div>
            Visual Sectors, Inc.<br />
            Suite 305, 131 Continental Dr., Newark, DE 19713
          </div>
          <div>
           <div class="office-label">UK office:</div>
            Visual Sectors, Ltd<br />
            20-22 Wenlock rd, London, UK, N1 7GU
          </div>
        </div>
        `}
    ],
    classes: 'flex-row footer-social-media',
    itemsClasses: 'px-[0.32rem]',
  },
];
