import { AppEnvironment } from './interfaces/environment';

export const environment: AppEnvironment = {
  production: false,
  apiWidgetsUrl: 'https://kw-vs.ru/widgets/api',
  apiMainUrl: 'https://kw-vs.ru/api',
  apiAdminUrl: 'https://kw-vs.ru/admin/api/v1',
  facebookAppId: '1223629191596356',
  googleClientId:
    '401120992888-p4kquf5vdupavjv3s5a3m2he6ko5if4g.apps.googleusercontent.com',
};
