import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';

@Injectable()
export class ExternalSignIn {
public constructor(
  private authService: AuthService,
) { }

  public requested(): boolean {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.has('externalSignIn') && searchParams.has('token');
  }

  public handle(): void {
    const token = this.getTokenFromRequest();
    if (token) {
      this.authService.setTokens(token, '');
      window.location.replace(window.location.toString().split("?")[0]);
    } else {
      console.error('Cannot sign in with empty token.');
    }
  }

  private getTokenFromRequest(): string | null {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get('token');
  }
}