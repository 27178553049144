import { Component, Input } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';
import { RiskRewardHeatmapItemModel } from '../../models/risk-reward-heatmap-item.model';
import { RiskRewardHeatmapDialogComponent } from './risk-reward-heatmap-dialog/risk-reward-heatmap-dialog.component';

@Component({
  selector: 'app-risk-reward-heatmap-item',
  templateUrl: './risk-reward-heatmap-item.component.html',
  styleUrls: ['./risk-reward-heatmap-item.component.scss']
})
export class RiskRewardHeatmapItemComponent {

  @Input() model: RiskRewardHeatmapItemModel;
  public selected: boolean;

  constructor(public dialog: MatDialog)
  { }

  onAddMap() {
    this.selected = !this.selected;
  }

  onOpenMap() {
    this.dialog.open(RiskRewardHeatmapDialogComponent, {
       data: {
        model: this.model
      }
    });
  }

}
