<input
  [placeholder]="placeholder | translation | firstLetterUpperCase"
  [class]="[
    classes.padding ?? 'px-2',
    classes.bg ?? 'bg-transparent',
    classes.placeholder ?? 'placeholder:text-accent'
  ]"
  type="text"
  class="flex-grow"
  (input)="onInput($event)"
/>
