import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class EventsService {
  private subject = new Subject<EventType>();

  emit(e: EventType) {
    this.subject.next(e);
  }

  subscription(): Observable<EventType> {
    return this.subject.asObservable();
  };
}

export enum EventType {
  PageResized,
  SingleStockFilterUpdated,
  RequestFailed,
  UserTypeChanged,
  NumberOfStocksInPortfolioChanged
}
