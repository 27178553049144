import { Component, Output, EventEmitter, Input, OnInit } from '@angular/core';
import { TrialDataService } from 'src/app/modules/main/services/trial-data.service';
import { EventsService, EventType } from 'src/app/services/events.service';
import { SUBSCRIPTION_TRIAL_OPTIONS } from '../../data/subscription-trial.data';
import { SubscriptionModel } from '../../models/subscription.model';
import { TariffPriceInterval } from 'src/app/interfaces/api/payment/tariff/types/tariff-price-interval';

@Component({
  selector: 'app-subscription-header',
  templateUrl: './subscription-header.component.html',
  styleUrls: ['./subscription-header.component.scss'],
})
export class SubscriptionHeaderComponent implements OnInit {
  @Output() event = new EventEmitter<SubscriptionModel.IEvent>();

  trialOptions: SubscriptionModel.IOption[] = [];

  trialButtonActive: boolean = false;
  public trialAvailable = false;

  @Input() plan?: SubscriptionModel.ISubscription;

  private readonly planData: SubscriptionModel.IPlan = {
    planId: "",
    interval: TariffPriceInterval.month,
    name: "",
    options: [],
    plan: SubscriptionModel.Plan.Trial
  }
  
  constructor(private trialService: TrialDataService,
              private eventService: EventsService) {

    this.eventService.subscription().subscribe(type => {
      if (type === EventType.UserTypeChanged) {
        this.loadTrialOptions();
      }
    });
    this.trialOptions = SUBSCRIPTION_TRIAL_OPTIONS;
    this.loadTrialOptions();
  }

  ngOnInit(): void {
    this.planData.priceId = this.plan!.priceId;
    this.planData.planId = this.plan!.planId;
  }

  async loadTrialOptions() {
    const conditions = await this.trialService.getTrialConditions();
    if (conditions !== null) {
      this.trialButtonActive = conditions.canActivateTrial;
      this.trialAvailable = conditions.trialAvailable;
      for(let option of this.trialOptions) {
        if (option.type === SubscriptionModel.OptionType.AddStocks) {
          option.active = conditions.hasRequiredNumberOfTickers;
        } else if (option.type === SubscriptionModel.OptionType.SetAlerts) {
          option.active = conditions.alertsAreSetUp;
        }
      }
    }
  }

  optionClick(option: SubscriptionModel.IOption) {
    this.event.emit({
      type: SubscriptionModel.EventType.ActiveOption,
      plan: SubscriptionModel.Plan.Trial,
      option: option,
      planData: this.planData
    });
  }

  activate() {
    if (this.trialButtonActive) {
      this.event.emit({
        type: SubscriptionModel.EventType.Active,
        plan: SubscriptionModel.Plan.Trial,
        planData: this.planData
      });
    }
  }
}
