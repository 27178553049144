export enum SignalName {
  ACCUMULATION_SUM_LONG = 'Accumulation Sum Long Positioning',
  ACCUMULATION_SUM_SHORT = 'Accumulation Sum Short Positioning',
  ACCUMULATION_CHANGE_LONG = 'Accumulation Change Long Positioning',
  ACCUMULATION_CHANGE_SHORT = 'Accumulation Change Short Positioning',
  CONTRARIAN_MONEYNESS_LEVEL_LONG = 'Contrarian Moneyness Level Long Positioning',
  CONTRARIAN_MONEYNESS_LEVEL_SHORT = 'Contrarian Moneyness Level Short Positioning',
  CONTRARIAN_MONEYNESS_CHANGE_LONG = 'Contrarian Moneyness Change Long Positioning',
  CONTRARIAN_MONEYNESS_CHANGE_SHORT = 'Contrarian Moneyness Change Short Positioning',
  REWARD_RISK_LONG = 'Reward-Risk Long Positioning',
  REWARD_RISK_SHORT = 'Reward-Risk Short Positioning',
  REWARD_RISK_MOVING_AVERAGE_LONG = 'Reward-Risk Moving Average Long Positioning',
  REWARD_RISK_MOVING_AVERAGE_SHORT = 'Reward-Risk Moving Average Short Positioning',
}
