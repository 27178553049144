import { Injectable } from '@angular/core';
import { TariffsResponse } from 'src/app/interfaces/api/payment/tariff/tariffs';
import { MainHttpClient } from 'src/app/services/main-http-client.service';

@Injectable({
  providedIn: 'root',
})
export class PricingPlanService {
  constructor(private http: MainHttpClient) {}

  getPlans(): Promise<TariffsResponse> {
    return this.http.get<TariffsResponse>('/v2/tariff/list');
  }
}
