import {
  AfterViewInit,
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  CashFlowChartData,
  CashFlowChartDataCandle,
  ExpTypeKey,
} from '../../models/cash-flow-chart.model';
import { ExpirationTypeService } from '../../services/expiration-type.service';
import { CashFlowExposureDataChangeTypeKey } from '../../models/cash-flow-exposure.model';
import { ExposureChangeTypeService } from '../../services/exposure-change-type.service';
import { from, Subscription } from 'rxjs';
import { MainHttpClient } from '@app/services/main-http-client.service';
import { ChartService } from '../../services/chart.service';
import { createPopper, Instance } from '@popperjs/core';
import { DOCUMENT } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-cash-flow-main',
  templateUrl: './cash-flow-main.component.html',
})
export class CashFlowMainComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input()
  set appCashFlowTickerName(tickerName: string) {
    this.tickerName = tickerName;

    this.isChooseZoom = true;

    this.chartService.cleanSubject.next(true);

    this.getCashFlowChartData();
  }

  expirationTypeService$!: Subscription;
  exposureChangeTypeService$!: Subscription;
  cashFlowChartData$!: Subscription;

  cashFlowChartData!: CashFlowChartData;

  tickerName!: string;

  activeExpType: ExpTypeKey = 'month';
  expTypeList: Array<{ title: string; value: ExpTypeKey }> = [
    { title: 'Weekly', value: 'week' },
    { title: 'Monthly', value: 'month' },
    { title: 'Quarterly', value: 'quarter' },
  ];
  expTypeObj: { [key in ExpTypeKey]: string } = {
    week: 'Weekly',
    month: 'Monthly',
    quarter: 'Quarterly',
  };

  activeExposureChangeType: CashFlowExposureDataChangeTypeKey = '1d';
  // prettier-ignore
  exposureChangeTypeList: Array<{ title: string; value: CashFlowExposureDataChangeTypeKey; }> = [
    { title: '1d Change', value: '1d' },
    { title: '5d Change', value: '5d' },
    { title: '30d Change', value: '30d' },
  ];
  // prettier-ignore
  exposureChangeTypeObj: { [key in CashFlowExposureDataChangeTypeKey]: string; } = {
    '1d': '1d Change',
    '5d': '5d Change',
    '30d': '30d Change',
  };

  legendStateForm$: Subscription;
  legendStateForm: FormGroup;
  legendState: 'on' | 'off' = 'on';
  legendToggle: boolean = true;
  legendClasses: string = '';

  isChooseZoom: boolean = false;

  constructor(
    @Inject(DOCUMENT)
    private document: Document,
    private formBuilder: FormBuilder,
    private http: MainHttpClient,
    private chartService: ChartService,
    private expirationTypeService: ExpirationTypeService,
    private exposureChangeTypeService: ExposureChangeTypeService,
  ) {
    // prettier-ignore
    this.legendStateForm = this.formBuilder.group({
      state: this.formBuilder.control('on', [Validators.required]),
    });
  }

  // prettier-ignore
  ngOnInit(): void {
    /** переключение зума */
    this.expirationTypeService$ = this.expirationTypeService.currentExpTypeSubject.pipe().subscribe((expTypeKey: ExpTypeKey): void => {
      if (this.activeExpType !== expTypeKey) {
        this.activeExpType = expTypeKey;

        this.isChooseZoom = true;

        this.getCashFlowChartData();
      }
    });

    /** переключение периода индикатора exposure */
    this.exposureChangeTypeService$ = this.exposureChangeTypeService.currentExposureChangeTypeSubject.subscribe(
      (changeTypeKey: CashFlowExposureDataChangeTypeKey) => {
        this.activeExposureChangeType = changeTypeKey;
      });

    this.legendStateForm$ = this.legendStateForm.valueChanges.subscribe((value: { state: 'on' | 'off' }) => {
      this.legendState = value.state;

      this.onSetLegendToggle(this.legendState === 'on');
    });
  }

  ngOnDestroy(): void {
    [
      this.expirationTypeService$,
      this.cashFlowChartData$,
      this.exposureChangeTypeService$,
      this.legendStateForm$,
    ].forEach(($: Subscription) => $?.unsubscribe());
  }

  ngAfterViewInit(): void {
    const elementNameList: string[] = ['exposure', 'net-flows', 'levels'];

    const showEventList: string[] = ['mouseenter', 'focus'];
    const hideEventList: string[] = ['mouseleave', 'blur'];

    // prettier-ignore
    elementNameList.forEach((name: string) => {
      const target: HTMLElement | null = this.document.querySelector(`#${name}-target`);
      const tooltip: HTMLElement | null = this.document.querySelector(`#${name}-tooltip`);

      if (target && tooltip) {
        const popperInstance: Instance = createPopper(target, tooltip, {
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 8],
              },
            },
          ],
          placement: 'right-start',
        });

        /** set events */
        showEventList.forEach((event: string) => {
          target.addEventListener(event, () => {
            tooltip.classList.remove('hidden');

            popperInstance.update().then((r) => console.debug('update popper'));
          });
        });

        hideEventList.forEach((event: string) => {
          target.addEventListener(event, () => tooltip.classList.add('hidden'));
        });
      }
    });
  }

  // prettier-ignore
  getCashFlowChartData(dateFrom?: Date): void {
    const body: any = {
      ticker: this.tickerName,
      expType: this.activeExpType
    };

    const nowMilliseconds: number = new Date().getTime();
    const monthMilliseconds: number = 24 * 60 * 60 * 1000 * 30; // month in milliseconds;

    if (!dateFrom) {
      dateFrom = new Date(nowMilliseconds - monthMilliseconds);

      body.withMinMax = true;
    }

    body.dateFrom = dateFrom.toJSON().slice(0, 10);

    this.cashFlowChartData$ = from(this.http.post<CashFlowChartData>('/stock-page/candlesticks', body)).subscribe({
      next: (cashFlowChartData: CashFlowChartData) => {
        if (cashFlowChartData.candlesticks.length && cashFlowChartData.candlesticks[0].ticker === this.tickerName) {
          if (!!this.cashFlowChartData && dateFrom) {
            /** Проверяем надо ли тянуть новые данные */
            const cashFlowChartDataDisabledUpdate = (candleListUpdated: CashFlowChartDataCandle[]): boolean => {
              const candlesBefore: CashFlowChartDataCandle[] = this.cashFlowChartData?.candlesticks as CashFlowChartDataCandle[];
              const candlesAfter: CashFlowChartDataCandle[] = candleListUpdated;

              const totalCandlesBefore: number = candlesBefore.length;
              const totalCandlesAfter: number = candlesAfter.length;

              if (totalCandlesBefore > 0 && totalCandlesBefore === totalCandlesAfter) {
                const firstDateBefore: Date = new Date(candlesBefore[0].date);
                const firstDateAfter: Date = new Date(candlesAfter[0].date);
                const firstDateEqual: boolean = firstDateBefore.getTime() === firstDateAfter.getTime();

                const lastDateBefore: Date = new Date(candlesBefore[candlesBefore.length - 1].date);
                const lastDateAfter: Date = new Date(candlesAfter[candlesAfter.length - 1].date);
                const lastDateEqual: boolean = lastDateBefore.getTime() === lastDateAfter.getTime();

                return firstDateEqual || lastDateEqual;
              } else {
                return true;
              }
            };

            const isNext: boolean = !!this.cashFlowChartData.candlesticks.slice().reverse()[0] && dateFrom.getTime() >= new Date(this.cashFlowChartData.candlesticks.slice().reverse()[0].date).getTime();

            const candlesticksListOld: CashFlowChartDataCandle[] = [...this.cashFlowChartData.candlesticks];
            const candlesticksListNew: CashFlowChartDataCandle[] = this.cashFlowChartData.candlesticks.length
              ? cashFlowChartData.candlesticks.filter((candle: CashFlowChartDataCandle) => {
                if (isNext) {
                  // @ts-ignore
                  return new Date(candle.date).getTime() > dateFrom.getTime() + 10;
                } else {
                  // @ts-ignore
                  return new Date(candle.date).getTime() < dateFrom.getTime();
                }
              })
              : cashFlowChartData.candlesticks;

            const candleListUpdated = (): CashFlowChartDataCandle[] => {
              let candlesTemp: CashFlowChartDataCandle[] = [];

              if (isNext) {
                candlesTemp = [...candlesticksListOld, ...candlesticksListNew];
              } else {
                candlesTemp = [...candlesticksListNew, ...candlesticksListOld];
              }

              return candlesTemp.reduce((accumulator: CashFlowChartDataCandle[], current: CashFlowChartDataCandle, currentIndex: number) => {
                const candleExist: CashFlowChartDataCandle | undefined = accumulator.find((item: CashFlowChartDataCandle) => {
                  const isSameDate: boolean = new Date(item.date).getTime() === new Date(current.date).getTime();
                  const isSameHigh: boolean = item.high === current.high;
                  const isSameLow: boolean = item.low === current.low;
                  const isSameOpen: boolean = item.open === current.open;
                  const isSameClose: boolean = item.close === current.close;

                  return isSameDate && isSameHigh && isSameLow && isSameOpen && isSameClose;
                });

                if (!candleExist) {
                  accumulator.push(current);
                }

                const millisecondsOfDay: number = 86400000;

                /** отдаем пустую свечку с датой */
                const getEmptyCandle = (currentDate: number): CashFlowChartDataCandle => {
                  const year: number = new Date(currentDate).getFullYear();
                  const month: number | string = new Date(currentDate).getMonth() + 1 < 10 ? '0' + (new Date(currentDate).getMonth() + 1) : new Date(currentDate).getMonth() + 1;
                  const day: number | string = new Date(currentDate).getDate() < 10 ? '0' + new Date(currentDate).getDate() : new Date(currentDate).getDate();

                  return {
                    ticker: current.ticker,
                    date: `${year}-${month}-${day}`,
                    high: 0,
                    low: 0,
                    open: 0,
                    close: 0,
                    color: 'red',
                  };
                }

                /** добавляем дату */
                const addEmptyDate = (currentDate: number, nextDate: number) => {
                  if (nextDate - currentDate > millisecondsOfDay) {
                    accumulator.push(getEmptyCandle(currentDate + millisecondsOfDay))
                  }

                  if (nextDate - (currentDate + millisecondsOfDay) > millisecondsOfDay) {
                    addEmptyDate(currentDate + millisecondsOfDay, nextDate);
                  }
                }

                const currentDate: number = new Date(current.date).getTime();
                const nextDate: number = candlesTemp[currentIndex + 1]?.date ? new Date(candlesTemp[currentIndex + 1].date).getTime() : 0;

                /** только если недельный зум */
                this.activeExpType === 'week' && addEmptyDate(currentDate, nextDate);

                return accumulator;
              }, []);
            };

            /** Обновляемся */
            if (cashFlowChartDataDisabledUpdate(candleListUpdated())) {
              this.cashFlowChartData = {
                ...cashFlowChartData,
                candlesticks: candleListUpdated(),
              };
            }
          } else {
            this.cashFlowChartData = cashFlowChartData;
          }

          this.isChooseZoom = false;
          this.chartService.cleanSubject.next(false);
        }
      },
      error: (error: any) => console.error(error),
    });
  }

  onSetExpiration(expTypeKey: ExpTypeKey): void {
    this.expirationTypeService.currentExpTypeSubject.next(expTypeKey);
  }

  // prettier-ignore
  onSetExposureChangeType(exposureChangeTypeKey: CashFlowExposureDataChangeTypeKey): void {
    this.exposureChangeTypeService.currentExposureChangeTypeSubject.next(exposureChangeTypeKey);
  }

  onSetLegendToggle(toggle: boolean): void {
    this.legendToggle = toggle;

    if (toggle) {
      setTimeout(() => {
        this.legendClasses = '';
      }, 500);
    } else {
      this.legendClasses = 'overflow-hidden';
    }
  }
}
