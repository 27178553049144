<div class="flex flex-col bg-white w-screen max-w-[728px] !p-5 rounded-2xl">
  <div class="flex justify-end">
    <button type="button" class="outline-none" (click)="close()">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.7349 12L6 7.26512L7.26512 6L12 10.7349L16.7349 6L18 7.26512L13.2651 12L18 16.7349L16.7349 18L12 13.2651L7.26512 18L6 16.7349L10.7349 12Z"
          fill="#333333"
        />
      </svg>
    </button>
  </div>
  <div class="flex flex-col gap-6 px-7 pb-7">
    <div class="flex flex-col gap-1">
      <div class="text-default-neutral-1 text-interface-title-3-semibold">
        Pay with 50% discount.
      </div>
    </div>
    <button
      class="kw-btn kw-btn-md kw-btn-primary kw-btn-active w-full"
      type="button"
      [disabled]="isDisabled"
      (click)="onGetDiscount()"
    >
      <span class="block">
        Only {{ currentPrice / 2 | number: '1.2-2' }}$
      </span>
    </button>
  </div>
</div>
