<app-icon
  icon="crown"
  [color]="{
    fill: { base: 'fill-primary-dark' },
    stroke: 'stroke-2 stroke-primary-700'
  }"
  class="rounded-full p-[0.75rem] self-center"
></app-icon>

<span routerLink="/premium" class="text-primary-700">{{ text }}</span>
