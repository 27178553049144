import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
})
export class PaginationComponent {
  @Input()
  set appLimit(limit: number) {
    this.itemsLimit = limit;

    this.calculate();
  }

  @Input()
  set appPage(page: number) {
    this.pageCurrent = page;

    this.calculate();
  }

  @Input()
  set appTotal(total: number) {
    this.itemsTotal = total;

    this.calculate();
  }

  @Input()
  set appDisableBtn(disableBtn: boolean) {
    this.disableBtn = disableBtn;

    this.calculate();
  }

  @Output() selectedPage = new EventEmitter<number>();

  itemsLimit: number = 10;
  itemsTotal: number = 0;

  pageCurrent: number = 1;
  pageList: number[] = [];
  pagesCount: number = 0;

  maxButtonCount: number = 6;

  disableBtn: boolean = false;

  constructor() {}

  calculate(): void {
    this.pagesCount = Math.ceil(this.itemsTotal / this.itemsLimit);
    this.pageList = [];

    if (this.pagesCount > this.maxButtonCount) {
      const maxPage = this.pageCurrent + (this.maxButtonCount - 1);

      if (maxPage < this.pagesCount) {
        for (let i = this.pageCurrent; i <= maxPage; i++) {
          this.pageList.push(i);
        }
      } else {
        // prettier-ignore
        for (let i = this.pagesCount - (this.maxButtonCount - 1); i <= this.pagesCount; i++) {
          this.pageList.push(i);
        }
      }
    } else {
      for (let i = 1; i <= this.pagesCount; i++) {
        this.pageList.push(i);
      }
    }
  }
}
