import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthFormParametersModel } from '../models/auth-form-parameters.model';
import { AuthFormTypeModel } from '../models/auth-form-type.model';
import { AuthSignInComponent } from '../components/auth-sign-in/auth-sign-in.component';
import { AuthSignUpComponent } from '../components/auth-sign-up/auth-sign-up.component';
import { AuthResetComponent } from '../components/auth-reset/auth-reset.component';

@Injectable({
  providedIn: 'root',
})
export class AuthFormService {
  private opened: boolean = false;

  private readonly panelClass: string = 'auth-form-dialog';

  public get IsFormOpenned(): boolean {
    return this.opened;
  }

  constructor(private dialog: MatDialog) {}

  openSignIn(params?: AuthFormParametersModel) {
    this.opened = true;
    this.dialog
      .open(AuthSignInComponent, {
        data: {
          type: AuthFormTypeModel.SignIn,
          parameters: params,
        },
        panelClass: this.panelClass,
      })
      .afterClosed()
      .subscribe((x) => (this.opened = false));
  }

  openSignUp(params?: AuthFormParametersModel) {
    this.opened = true;
    this.dialog
      .open(AuthSignUpComponent, {
        data: {
          type: AuthFormTypeModel.SignUp,
          parameters: params,
        },
        panelClass: this.panelClass,
      })
      .afterClosed()
      .subscribe((x) => (this.opened = false));
  }

  openResetPassword(params?: AuthFormParametersModel) {
    this.opened = true;
    this.dialog
      .open(AuthResetComponent, {
        data: {
          type: AuthFormTypeModel.ResetPassword,
          parameters: params,
        },
        panelClass: this.panelClass,
      })
      .afterClosed()
      .subscribe((x) => (this.opened = false));
  }
}
