import { NgModule } from '@angular/core';
import { SubscriptionsPageComponent } from './pages/subscriptions-page/subscriptions-page.component';
import { ProfileRoutingModule } from './profile-routing.module';
import { ProfileLayoutComponent } from './layout/profile-layout.component';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { PremiumService } from '../premium/services/premium.service';
import { SubscriptionSwitchComponent } from './pages/subscriptions-page/components/subscription-switch/subscription-switch.component';
import { SubscriptionHeaderComponent } from './pages/subscriptions-page/components/subscription-header/subscription-header.component';
import { BasicModule } from '../../shared/modules/basic/basic.module';
import { SubscriptionPlanComponent } from './pages/subscriptions-page/components/subscription-plan/subscription-plan.component';
import { UnsubscribePiplineWidgetComponent } from './pages/subscriptions-page/components/unsubscribe-pipline-widget/unsubscribe-pipline-widget.component';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import {
  ConsultationDialogComponent,
  DiscountDialogComponent,
  GetDiscountDialogComponent,
  ReasonDialogComponent,
} from './pages/subscriptions-page/dialogs';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  SubscriptionCancelDialogComponent,
  SubscriptionCanceledDialogComponent,
  SubscriptionCanTalkDialogComponent,
  SubscriptionChangedDialogComponent,
  SubscriptionChangeDialogComponent,
  SubscriptionGetDiscountDialogComponent,
  SubscriptionPayCancelDialogComponent,
  SubscriptionPaySuccessDialogComponent,
  SubscriptionReasonsDialogComponent,
  SubscriptionRenewDialogComponent,
  SubscriptionRenewedDialogComponent,
  SubscriptionThankYouDialogComponent,
} from './subscriptions-new/dialogs';
import { SettingsComponent } from './pages/settings/settings.component';
import { ChangeNameComponent } from './pages/settings/change-name/change-name.component';
import { ChangePasswordComponent } from './pages/settings/change-password/change-password.component';
import { ChangeEmailComponent } from './pages/settings/change-email/change-email.component';
import { ChangeEmailDialogComponent } from './pages/settings/change-email/dialog/dialog.component';
import { EnterCurrentEmailCodeComponent } from './pages/settings/change-email/dialog/enter-current-email-code/enter-current-email-code.component';
import { EnterNewEmailComponent } from './pages/settings/change-email/dialog/enter-new-email/enter-new-email.component';
import { EnterNewEmailCodeComponent } from './pages/settings/change-email/dialog/enter-new-email-code/enter-new-email-code.component';
import { EmailChangedSuccessfullyComponent } from './pages/settings/change-email/dialog/email-changed-successfully/email-changed-successfully.component';
import { PasswordResetSuccessfullyComponent } from './pages/settings/change-password/password-reset-successfully/password-reset-successfully.component';
import { PasswordCreatedSuccessfullyComponent } from './pages/settings/change-password/password-created-successfully/password-created-successfully.component'

@NgModule({
  declarations: [
    SubscriptionsPageComponent,
    ProfileLayoutComponent,
    SubscriptionSwitchComponent,
    SubscriptionHeaderComponent,
    SubscriptionPlanComponent,
    UnsubscribePiplineWidgetComponent,
    GetDiscountDialogComponent,
    ConsultationDialogComponent,
    DiscountDialogComponent,
    ReasonDialogComponent,
    SubscriptionCancelDialogComponent,
    SubscriptionCanceledDialogComponent,
    SubscriptionChangeDialogComponent,
    SubscriptionChangedDialogComponent,
    SubscriptionCanTalkDialogComponent,
    SubscriptionGetDiscountDialogComponent,
    SubscriptionPayCancelDialogComponent,
    SubscriptionPaySuccessDialogComponent,
    SubscriptionReasonsDialogComponent,
    SubscriptionRenewDialogComponent,
    SubscriptionRenewedDialogComponent,
    SubscriptionThankYouDialogComponent,
    SettingsComponent,
    ChangeNameComponent,
    ChangePasswordComponent,
    ChangeEmailComponent,
    ChangeEmailDialogComponent,
    EnterCurrentEmailCodeComponent,
    EnterNewEmailComponent,
    EnterNewEmailCodeComponent,
    EmailChangedSuccessfullyComponent,
    PasswordResetSuccessfullyComponent,
    PasswordCreatedSuccessfullyComponent,
  ],
  imports: [
    CommonModule,
    ProfileRoutingModule,
    BasicModule,
    SharedModule,
    MatRadioModule,
    MatCheckboxModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [PremiumService],
})
export class ProfileModule {}
