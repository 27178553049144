import { AfterViewInit, Component, Input, TemplateRef } from '@angular/core';
import { TABLE_DEFAULT_CONFIG } from '../../utils/table.data';
import {
  ITableData,
  ITableColumn,
  ITableGridClasses,
  ITableConfig,
  IHorizontalTableGridClasses,
} from '../../structures/ui';

@Component({
  selector: 'app-table-grid',
  templateUrl: './table-grid.component.html',
  styleUrls: ['./table-grid.component.scss'],
})
export class TableGridComponent implements AfterViewInit {
  @Input() columns: ITableColumn[] = [];
  @Input() data: ITableData[] = [];
  @Input() templates: { [templateName: string]: TemplateRef<any> } = {};
  @Input() classes: ITableGridClasses = {};
  @Input() config: ITableConfig = TABLE_DEFAULT_CONFIG;

  ngAfterViewInit(): void {
    console.log(this);
  }
}
