import { Injectable } from '@angular/core';
import { AnalyticService } from '../../../../../services/analytic.service';
import { UtmData } from '../interfaces/utm-data';

@Injectable()
export class UtmService {
  private utmDataStorageKey = 'utmData';

  public constructor(
    private analitycsService: AnalyticService,
  ) {}

  public async createUtm(): Promise<UtmData> {
    let utm: UtmData;

    const utmDataInRoute = this.getUtmDataFromRoute();
    if (utmDataInRoute) {
      utm = utmDataInRoute;
    } else {
      utm = this.getUtmData();
    }

    utm.googleClientId = this.analitycsService.googleClientId();
    utm.yandexClientId = await this.analitycsService.yandexClientId();

    return utm;
  }

  public setUtmData(data: UtmData) {
    localStorage.setItem(this.utmDataStorageKey, JSON.stringify(data));
  }

  public getUtmData(): UtmData {
    let utmData: UtmData = {};

    const localData = localStorage.getItem(this.utmDataStorageKey);
    if (localData) {
      utmData = JSON.parse(localData);
    }

    return utmData;
  }

  public clearUtmData(): void {
    localStorage.removeItem(this.utmDataStorageKey);
  }

  private getUtmDataFromRoute(): UtmData | null {
    const searchParams = new URLSearchParams(window.location.search);

    let utm: UtmData | null = {};

    if (searchParams.has('utm_source')) {
      utm.source = searchParams.get('utm_source')!;
    }
    if (searchParams.has('utm_medium')) {
      utm.medium = searchParams.get('utm_medium')!;
    }
    if (searchParams.has('utm_campaign')) {
      utm.campaign = searchParams.get('utm_campaign')!;
    }
    if (searchParams.has('utm_content')) {
      utm.content = searchParams.get('utm_content')!;
    }
    if (searchParams.has('utm_term')) {
      utm.term = searchParams.get('utm_term')!;
    }

    const utmIsEmpty = Object.keys(utm).length === 0;
    if (utmIsEmpty) {
      utm = null;
    }

    return utm;
  }
}
