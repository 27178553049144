import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ITabItem } from '../../structures/ui';

@Component({
  selector: 'app-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss']
})
export class TabComponent {
  @Input() items: ITabItem[] = [];
  @Input() activeId: string;

  @Output() clickItem = new EventEmitter<ITabItem>();

  constructor() { }

}
