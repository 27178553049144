<div class="flex justify-center w-full">
  <form class="w-full" [formGroup]="searchForm">
    <app-dropdown
      [appDisableClick]="true"
      [appToggleMode]="false"
      [appToggleEvent]="isOpened"
      (toggled)="onFocus($event)"
    >
      <div
        class="relative"
        [ngClass]="border ? 'bg-[#d7f0de66] p-2 rounded-full' : ''"
        slot="target"
      >
        <div
          class="absolute top-0 left-0 w-full h-full bg-[#d7f0de66] rounded-full z-[1]"
          [ngClass]="isFocus ? 'animate-opacity' : ''"
        ></div>
        <div class="relative z-[2] w-full bg-white rounded-full">
          <fieldset
            class="flex items-center !p-1 outline duration-300 rounded-full"
            [ngClass]="isFocus ? 'outline-[#62C37D]' : 'outline-transparent'"
          >
            <input
              #searchEl
              type="text"
              class="w-full !mx-4 md:!mx-6 !my-2 text-base md:text-lg leading-4 md:leading-6 text-[#1C2236] placeholder-[#AFAFAF] outline-none truncate"
              placeholder="Search any Stock for a free analysis"
              formControlName="search"
              (focus)="onFocus(true)"
            />
            <button
              *ngIf="
                (!isFocus && !this.searchForm.get('search')?.value) ||
                !this.searchForm.get('search')?.value
              "
              (click)="onFocus(true)"
              class="flex bg-[#62C37D] p-2.5 rounded-full"
              type="button"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M14.0354 15.0267C12.7524 16.053 11.1248 16.6667 9.354 16.6667C5.21187 16.6667 1.854 13.3089 1.854 9.16675C1.854 5.02461 5.21187 1.66675 9.354 1.66675C13.4961 1.66675 16.854 5.02461 16.854 9.16675C16.854 10.9376 16.2403 12.5651 15.2139 13.8482L18.2765 16.9107C18.6019 17.2362 18.6019 17.7638 18.2765 18.0893C17.9511 18.4147 17.4234 18.4147 17.098 18.0893L14.0354 15.0267ZM3.52067 9.16675C3.52067 5.94509 6.13234 3.33341 9.354 3.33341C12.5757 3.33341 15.1873 5.94509 15.1873 9.16675C15.1873 10.7381 14.566 12.1643 13.5557 13.2132C13.5269 13.2352 13.4993 13.2594 13.473 13.2857C13.4467 13.3121 13.4225 13.3397 13.4004 13.3684C12.3516 14.3788 10.9253 15.0001 9.354 15.0001C6.13234 15.0001 3.52067 12.3884 3.52067 9.16675Z"
                  fill="white"
                />
              </svg>
            </button>
            <button
              *ngIf="
                (isFocus && this.searchForm.get('search')?.value) ||
                this.searchForm.get('search')?.value
              "
              (click)="onClear()"
              class="flex bg-[#62C37D] p-2.5 rounded-full"
              type="button"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M11.3626 9.99991L16.6126 4.75824C16.7695 4.60132 16.8577 4.3885 16.8577 4.16658C16.8577 3.94466 16.7695 3.73183 16.6126 3.57491C16.4557 3.41799 16.2429 3.32983 16.0209 3.32983C15.799 3.32983 15.5862 3.41799 15.4293 3.57491L10.1876 8.82491L4.94595 3.57491C4.78903 3.41799 4.5762 3.32983 4.35428 3.32983C4.13236 3.32983 3.91953 3.41799 3.76261 3.57491C3.60569 3.73183 3.51754 3.94466 3.51754 4.16658C3.51754 4.3885 3.60569 4.60132 3.76261 4.75824L9.01261 9.99991L3.76261 15.2416C3.6845 15.319 3.62251 15.4112 3.5802 15.5128C3.5379 15.6143 3.51611 15.7232 3.51611 15.8332C3.51611 15.9433 3.5379 16.0522 3.5802 16.1537C3.62251 16.2553 3.6845 16.3474 3.76261 16.4249C3.84008 16.503 3.93225 16.565 4.0338 16.6073C4.13535 16.6496 4.24427 16.6714 4.35428 16.6714C4.46429 16.6714 4.57321 16.6496 4.67476 16.6073C4.77631 16.565 4.86848 16.503 4.94595 16.4249L10.1876 11.1749L15.4293 16.4249C15.5067 16.503 15.5989 16.565 15.7005 16.6073C15.802 16.6496 15.9109 16.6714 16.0209 16.6714C16.131 16.6714 16.2399 16.6496 16.3414 16.6073C16.443 16.565 16.5351 16.503 16.6126 16.4249C16.6907 16.3474 16.7527 16.2553 16.795 16.1537C16.8373 16.0522 16.8591 15.9433 16.8591 15.8332C16.8591 15.7232 16.8373 15.6143 16.795 15.5128C16.7527 15.4112 16.6907 15.319 16.6126 15.2416L11.3626 9.99991Z"
                  fill="white"
                />
              </svg>
            </button>
          </fieldset>
        </div>
      </div>
      <div
        class="flex flex-col !px-4 !py-2 rounded-2xl bg-white shadow-shadow-popup-lg-shadow z-[5]"
        [ngClass]="border ? '!ml-2 w-[calc(100%-16px)]' : 'w-full mt-2'"
        slot="content"
      >
        <div class="flex items-center justify-between gap-4 w-full !py-2">
          <span
            class="text-interface-title-5-semibold text-default-primary-2 truncate"
          >
            {{ searchResultsTitle }}
          </span>
          <a
            *ngIf="isShowLinkToPortfolio"
            routerLink="/portfolio"
            class="kw-btn kw-btn-sm kw-btn-active kw-btn-on-secondary !p-0"
          >
            <span class="block"> Go to Portfolio </span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                d="M14.9333 9.68333C14.8937 9.58103 14.8342 9.48758 14.7583 9.40833L10.5917 5.24166C10.514 5.16396 10.4217 5.10233 10.3202 5.06028C10.2187 5.01823 10.1099 4.99658 10 4.99658C9.77808 4.99658 9.56525 5.08474 9.40833 5.24166C9.33063 5.31936 9.269 5.4116 9.22695 5.51312C9.1849 5.61464 9.16326 5.72344 9.16326 5.83333C9.16326 6.05524 9.25141 6.26807 9.40833 6.42499L12.1583 9.16666H5.83333C5.61232 9.16666 5.40036 9.25446 5.24408 9.41074C5.0878 9.56702 5 9.77898 5 9.99999C5 10.221 5.0878 10.433 5.24408 10.5892C5.40036 10.7455 5.61232 10.8333 5.83333 10.8333H12.1583L9.40833 13.575C9.33023 13.6525 9.26823 13.7446 9.22592 13.8462C9.18362 13.9477 9.16183 14.0566 9.16183 14.1667C9.16183 14.2767 9.18362 14.3856 9.22592 14.4871C9.26823 14.5887 9.33023 14.6809 9.40833 14.7583C9.4858 14.8364 9.57797 14.8984 9.67952 14.9407C9.78107 14.983 9.88999 15.0048 10 15.0048C10.11 15.0048 10.2189 14.983 10.3205 14.9407C10.422 14.8984 10.5142 14.8364 10.5917 14.7583L14.7583 10.5917C14.8342 10.5124 14.8937 10.419 14.9333 10.3167C15.0167 10.1138 15.0167 9.88621 14.9333 9.68333Z"
              />
            </svg>
          </a>
        </div>
        <ul
          class="flex flex-col gap-2 max-h-[312px] overflow-y-auto scrollbar-y"
          [ngClass]="searchResults.length > 6 ? '!pr-4' : '!pr-0'"
        >
          <li
            *ngFor="let result of searchResults"
            class="flex items-center justify-between gap-4 !pb-2 border-b border-[#E4EAF1]"
          >
            <button
              class="flex gap-4 w-full overflow-hidden"
              type="button"
              (click)="onOpenStockPage(result)"
            >
              <div
                class="flex-shrink-0 w-[68px] text-interface-title-5-semibold text-default-neutral-1 truncate"
              >
                {{ result.ticker.ticker }}
              </div>
              <div
                class="w-full text-interface-title-5-regular text-default-neutral-1 text-left truncate"
              >
                {{ result.ticker.name }}
              </div>
            </button>
            <button
              *ngIf="!result.added"
              class="flex items-center justify-center bg-[#62C37D] hover:bg-[#4E9C64] p-2.5 rounded duration-300"
              type="button"
              (click)="addStock(result, $event)"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M12.6665 7.33317L8.6665 7.33317V3.33317C8.6665 3.15636 8.59627 2.98679 8.47124 2.86177C8.34622 2.73674 8.17665 2.6665 7.99984 2.6665C7.82303 2.6665 7.65346 2.73674 7.52843 2.86177C7.40341 2.98679 7.33317 3.15636 7.33317 3.33317L7.33317 7.33317L3.33317 7.33317C3.15636 7.33317 2.98679 7.40341 2.86177 7.52843C2.73674 7.65346 2.6665 7.82303 2.6665 7.99984C2.6665 8.17665 2.73674 8.34622 2.86177 8.47124C2.98679 8.59627 3.15636 8.6665 3.33317 8.6665H7.33317L7.33317 12.6665C7.33317 12.8433 7.40341 13.0129 7.52843 13.1379C7.65346 13.2629 7.82303 13.3332 7.99984 13.3332C8.17665 13.3332 8.34622 13.2629 8.47124 13.1379C8.59627 13.0129 8.6665 12.8433 8.6665 12.6665L8.6665 8.6665L12.6665 8.6665C12.8433 8.6665 13.0129 8.59627 13.1379 8.47124C13.2629 8.34622 13.3332 8.17665 13.3332 7.99984C13.3332 7.82303 13.2629 7.65346 13.1379 7.52843C13.0129 7.40341 12.8433 7.33317 12.6665 7.33317Z"
                  fill="white"
                />
              </svg>
            </button>
            <div *ngIf="result.added" class="flex items-center gap-2">
              <span class="text-base leading-5 text-[#62C37D] font-semibold">
                Added
              </span>
              <button
                class="flex items-center justify-center p-2.5 group"
                (click)="removeStock(result, $event)"
                type="button"
              >
                <svg
                  class="fill-[#A3B0C2] group-hover:fill-[#7E8CA0] duration-300"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    d="M8.94574 10L5 6.05426L6.05426 5L10 8.94574L13.9457 5L15 6.05426L11.0543 10L15 13.9457L13.9457 15L10 11.0543L6.05426 15L5 13.9457L8.94574 10Z"
                  />
                </svg>
              </button>
            </div>
          </li>
          <li
            *ngIf="searchResults.length === 0"
            class="flex items-center justify-center bg-default-neutral-2 rounded-lg !p-3"
          >
            <span class="text-interface-body-2-regular text-default-neutral-1">
              Nothing found
            </span>
          </li>
        </ul>
      </div>
    </app-dropdown>
  </form>
</div>
