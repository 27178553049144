<div *ngIf="visible" @scaleY class="header-search-results origin-top search-result">
  <div class="header-search-results-container">
    <div class="header-search-results-title">{{ searchResultsTitle }}</div>
    <div class="header-search-results-content">
      <app-scrollable
        classes="h-[10rem] max-md:max-h-[15rem] max-lg:h-[auto] max-lg:max-h-[50vh]"
      >
        <div
          [class.added]="result.added"
          *ngFor="let result of searchResults"
          class="header-search-result"
        >
          <div class="header-search-result-ticker">
            {{ result.ticker.ticker }}
          </div>
          <div class="header-search-result-name">{{ result.ticker.name }}</div>
          <div class="header-search-result-controls">
            <div
              [class.hidden]="result.added"
              (click)="openStockPage(result)"
              class="header-search-result-control-page"
            >
              Page
            </div>
            <app-icon
              *ngIf="!result.added"
              [color]="{ fill: {}, stroke: 'stroke-2 stroke-primary-content' }"
              (click)="addStock(result)"
              icon="plus"
              class="bg-primary-700 p-[3px] self-center rounded-md cursor-pointer search-result"
              classes="w-6 h-6"
            >
            </app-icon>
            <div
              [class.hidden]="!result.added"
              (click)="removeStock(result)"
              class="header-search-result-control-added"
            >
              Added
            </div>
          </div>
        </div>
      </app-scrollable>
    </div>
  </div>

  <div
    *ngIf="addedStocks.length"
    @scaleY
    class="header-search-results-added-stocks-container origin-top"
  >
    <div class="header-search-results-added-stocks">
      <div class="header-search-results-added-stocks-title">Added to List</div>
      <div class="header-search-results-added-stocks-list">
        <div
          *ngFor="let item of addedStocks"
          @scale
          class="header-search-results-added-stocks-list-item"
        >
          <div class="ticker">{{ item.ticker.ticker }}</div>
          <img
            (click)="removeStock(item)"
            class="header-search-results-added-stocks-list-item-delete search-result"
            src="/assets/images/header/close.svg"
          />
        </div>
      </div>

      <div
        (click)="openList()"
        class="header-search-results-added-stocks-open-list"
      >
        Go to My Portfolio
        <app-icon
          icon="arrow-down"
          classes="w-3 h-3"
          [color]="{ fill: { content: 'fill-primary-content' } }"
          class="-rotate-90"
        ></app-icon>
      </div>
    </div>
  </div>
</div>
