<div [class.active]="userType === UserTypeModel.Unregistered"
     (click)="selectPrototype(UserTypeModel.Unregistered)"
      class="prototype-option">U</div>

<div [class.active]="userType === UserTypeModel.Logged"
     (click)="selectPrototype(UserTypeModel.Logged)" 
      class="prototype-option">L</div>

<div [class.active]="userType === UserTypeModel.WithPayedSubscription"
     (click)="selectPrototype(UserTypeModel.WithPayedSubscription)" 
      class="prototype-option">P</div>