import { Injectable } from '@angular/core';
import { BehaviorSubject, filter, first, from } from 'rxjs';
import {
  PortfolioAddTickerRequest,
  PortfolioAddTickerResponse,
} from '@app/interfaces/api/main/portfolio/portfolio-add-ticker';
import { PortfolioPageResponse } from '@app/interfaces/api/main/portfolio/portfolio-page';
import {
  PortfolioRemoveTickerRequest,
  PortfolioRemoveTickerResponse,
} from '@app/interfaces/api/main/portfolio/portfolio-remove-ticker';
import {
  PortfolioSearchTickersRequest,
  PortfolioSearchTickersResponse,
} from '@app/interfaces/api/main/portfolio/portfolio-search-tickers';
import { MainHttpClient } from '@app/services/main-http-client.service';
import { ShareHttpResultService } from '@app/services/share-http-result.service';
import { Portfolio } from '@main/portfolio-new/models/portfolio.model';

@Injectable({
  providedIn: 'root',
})
export class PortfolioService {
  // prettier-ignore
  changePortfolioSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  // prettier-ignore
  currentPortfolioSubject: BehaviorSubject<Portfolio[]> = new BehaviorSubject<Portfolio[]>([]);

  constructor(
    private http: MainHttpClient,
    // private eventService: EventsService,
    private shareHttpResultService: ShareHttpResultService,
  ) {
    this.changePortfolioSubject
      .pipe(filter((change: boolean) => change))
      .subscribe(() => {
        this.getPortfolio();
      });
  }

  // prettier-ignore
  async searchTicker(data: PortfolioSearchTickersRequest): Promise<PortfolioSearchTickersResponse> {
    return this.http.post<PortfolioSearchTickersResponse>(`/portfolio/search-tickers`, data);
  }

  // prettier-ignore
  public async addTicker(data: PortfolioAddTickerRequest): Promise<PortfolioAddTickerResponse> {
    return this.http.post<PortfolioAddTickerResponse>('/portfolio/add-ticker', data);
  }

  // prettier-ignore
  async removeTicker(data: PortfolioRemoveTickerRequest): Promise<PortfolioRemoveTickerResponse> {
    const res = this.http.post<PortfolioRemoveTickerResponse>(`/portfolio/remove-ticker`, data);
    // this.eventService.emit(EventType.NumberOfStocksInPortfolioChanged);
    return res;
  }

  // prettier-ignore
  async loadTickers(shareResult: boolean = false): Promise<PortfolioPageResponse> {
    if (shareResult) {
      const key = 'portfolio-service:load-tickers';
      return this.shareHttpResultService.invoke(key, () =>
        this.http.get<PortfolioPageResponse>(`/portfolio`),
      );
    }
    const response = this.http.get<PortfolioPageResponse>(`/portfolio`);
    return response;
  }

  getPortfolio(): void {
    from(this.http.get<{ tickers: Portfolio[] }>('/portfolio'))
      .pipe(first())
      .subscribe({
        next: (data: { tickers: Portfolio[] }) => {
          this.currentPortfolioSubject.next(data.tickers);
        },
        error: (error: any) => {
          this.currentPortfolioSubject.next([]);

          console.debug(error);
        },
      });
  }
}
