import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isObject',
  pure: false,
})
export class IsObjectPipe implements PipeTransform {
  transform(data: any) {
    return typeof data == 'object';
  }
}
