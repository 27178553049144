import {
  Component,
  QueryList,
  ContentChildren,
  Input,
  Output,
  EventEmitter,
  DoCheck,
  OnInit,
} from '@angular/core';
import { TabItemComponent } from './tab-item/tab-item.component';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent implements DoCheck {
  @Input() activeId?: string;
  @Output() activeTabChanged = new EventEmitter<string>();
  @ContentChildren(TabItemComponent) tabs: QueryList<TabItemComponent>;

  constructor() {}

  get items() {
    return this.tabs.map((item) => item);
  }

  ngDoCheck(): void {
    if (this.tabs) {
      if (!this.activeId) {
        this.activeId = this.tabs.get(0)?.tab.id;
      }

      const activeIndex = this.items.findIndex(
        (item) => item.tab.id == this.activeId,
      );

      this.tabs.forEach((item, index) => {
        item.isActive = item.tab.id == this.activeId;
        if (item.element) {
          item.element.style.transform = `translateX(${
            (index - activeIndex) * 100
          }%)`;
        }
      });
    }
  }

  changeActiveTab(id: string) {
    this.activeId = id;
    this.activeTabChanged.emit(id);
  }
}
