import { AfterViewInit, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { interval, Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-landing-research-papers-success-dialog',
  templateUrl: './landing-research-papers-success-dialog.component.html',
})
export class LandingResearchPapersSuccessDialogComponent
  implements AfterViewInit
{
  formInterval$: Subscription | undefined;
  formInterval: Observable<number> = interval(1000);
  formIntervalValue: number = 5;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { email: string; signUp: boolean },
    private dialog: MatDialogRef<LandingResearchPapersSuccessDialogComponent>,
  ) {}

  ngAfterViewInit(): void {
    this.formInterval$?.unsubscribe();
    this.formInterval$ = this.formInterval.subscribe({
      next: () => {
        if (this.formIntervalValue === 1) {
          this.formInterval$?.unsubscribe();

          this.close();

          this.formIntervalValue = 5;
        } else {
          this.formIntervalValue = this.formIntervalValue - 1;
        }
      },
      error: (error: any) => console.error(error),
    });
  }

  close(): void {
    this.dialog.close();
  }
}
