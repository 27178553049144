<div class="tabs">
  <span
    *ngFor="let item of items"
    [class.active]="activeId == item.tab.id"
    (click)="changeActiveTab(item.tab.id)"
    class="tab"
  >
    {{ item.tab.text }}
  </span>
</div>
<div class="content">
  <ng-content></ng-content>
</div>
