<table [class]="classes.table ?? ''">
  <thead>
    <tr>
      <th
        *ngFor="let column of columns"
        [ngClass]="[classes.th?.host ?? '', classes.th?.specific?.[column.id] ?? '']"
      >
        {{ column.text }}
      </th>
    </tr>
  </thead>
  <tbody>
    <tr
      *ngFor="
        let row of data
          | slice
            : 0
            : (config.pagination?.displayAll
                ? data.length
                : config.pagination?.count ?? data.length);
        let even = even
      "
      [class.highlight]="even"
    >
      <td
        *ngFor="let column of columns"
        [ngClass]="[classes.td?.host ?? '', classes.td?.specific?.[column.id] ?? '']"
      >
        <ng-container *ngIf="row[column.id]">
          <ng-container
            *ngIf="row[column.id] | isObject"
            [ngTemplateOutlet]="templates[column.id]"
            [ngTemplateOutletContext]="{ data: row[column.id] }"
          >
          </ng-container>
          <span *ngIf="!(row[column.id] | isObject)">{{
            row[column.id] ?? ''
          }}</span>
        </ng-container>
      </td>
    </tr>
  </tbody>
</table>

<app-button
  *ngIf="
    config.pagination &&
    config.pagination.moreButton &&
    data.length > (config.pagination?.count ?? 3)
  "
  [text]="
    config.pagination.displayAll
      ? 'Show less'
      : 'Show ' + (data.length - (config.pagination.count ?? 3)) + ' more'
  "
  (click)="config.pagination.displayAll = !config.pagination.displayAll"
  classes="px-[2rem] py-[0.5rem]"
  class="button-show-more"
></app-button>
