import { Injectable } from '@angular/core';
import { PortfolioAddTickerRequest, PortfolioAddTickerResponse } from '../../../../interfaces/api/main/portfolio/portfolio-add-ticker';
import { PortfolioPageResponse } from '../../../../interfaces/api/main/portfolio/portfolio-page';
import { PortfolioRemoveTickerRequest, PortfolioRemoveTickerResponse } from '../../../../interfaces/api/main/portfolio/portfolio-remove-ticker';
import {
  PortfolioSearchTickersRequest,
  PortfolioSearchTickersResponse,
} from '../../../../interfaces/api/main/portfolio/portfolio-search-tickers';
import { EventType, EventsService } from 'src/app/services/events.service';
import { MainHttpClient } from 'src/app/services/main-http-client.service';
import { ShareHttpResultService } from 'src/app/services/share-http-result.service';

@Injectable({
  providedIn: 'root',
})
export class PortfolioService {

  constructor(private http: MainHttpClient,
              private eventService: EventsService,
              private shareHttpResultService: ShareHttpResultService) {}

  async searchTicker(
    data: PortfolioSearchTickersRequest,
  ): Promise<PortfolioSearchTickersResponse> {
    return this.http.post<PortfolioSearchTickersResponse>(`/portfolio/search-tickers`, data);
  }

  public async addTicker(data: PortfolioAddTickerRequest): Promise<PortfolioAddTickerResponse> {
    const res = this.http.post<PortfolioAddTickerResponse>(`/portfolio/add-ticker`, data);
    this.eventService.emit(EventType.NumberOfStocksInPortfolioChanged);
    return res;
  }

  async removeTicker(data: PortfolioRemoveTickerRequest): Promise<PortfolioRemoveTickerResponse> {
    const res = this.http.post<PortfolioRemoveTickerResponse>(`/portfolio/remove-ticker`, data);
    this.eventService.emit(EventType.NumberOfStocksInPortfolioChanged);
    return res;
  }

  async loadTickers(shareResult: boolean = false): Promise<PortfolioPageResponse> {
    if(shareResult) {
      const key = "portfolio-service:load-tickers";
      return this.shareHttpResultService.invoke(key, () => this.http.get<PortfolioPageResponse>(`/portfolio`));
    }
    const response = this.http.get<PortfolioPageResponse>(`/portfolio`);
    return response;
  }
}
