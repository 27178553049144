<div
  class="flex flex-col bg-white w-screen md:max-w-[480px] h-screen md:h-auto md:rounded-2xl shadow-shadow-modal-shadow"
>
  <div class="flex justify-end !p-4">
    <button (click)="close()">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.7349 12L6 7.26512L7.26512 6L12 10.7349L16.7349 6L18 7.26512L13.2651 12L18 16.7349L16.7349 18L12 13.2651L7.26512 18L6 16.7349L10.7349 12Z"
          fill="#333333"
        />
      </svg>
    </button>
  </div>
  <div class="flex flex-col items-center !gap-6 !p-8 !pt-0">
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 32C0 14.3269 14.3269 0 32 0C49.6731 0 64 14.3269 64 32C64 49.6731 49.6731 64 32 64C14.3269 64 0 49.6731 0 32Z"
        fill="#E8F6EC"
      />
      <path
        d="M43.6693 25.332H42.0026V20.332C42.0026 19.0059 41.4758 17.7342 40.5381 16.7965C39.6005 15.8588 38.3287 15.332 37.0026 15.332H20.3359C19.0099 15.332 17.7381 15.8588 16.8004 16.7965C15.8627 17.7342 15.3359 19.0059 15.3359 20.332V40.332C15.3376 40.6611 15.4366 40.9823 15.6205 41.2551C15.8044 41.528 16.0649 41.7403 16.3693 41.8654C16.5671 41.9587 16.784 42.0043 17.0026 41.9987C17.2219 42 17.4394 41.9579 17.6424 41.875C17.8455 41.792 18.0302 41.6698 18.1859 41.5154L22.8693 36.8154H25.3359V39.2154C25.3359 40.5414 25.8627 41.8132 26.8004 42.7509C27.7381 43.6886 29.0099 44.2154 30.3359 44.2154H41.8693L45.8193 48.182C45.975 48.3365 46.1597 48.4587 46.3628 48.5416C46.5658 48.6246 46.7833 48.6666 47.0026 48.6654C47.2212 48.671 47.4381 48.6253 47.6359 48.532C47.9403 48.407 48.2008 48.1947 48.3847 47.9218C48.5686 47.6489 48.6676 47.3277 48.6693 46.9987V30.332C48.6693 29.0059 48.1425 27.7342 47.2048 26.7965C46.2671 25.8588 44.9954 25.332 43.6693 25.332ZM25.3359 30.332V33.482H22.1859C21.9666 33.4808 21.7492 33.5228 21.5461 33.6057C21.343 33.6887 21.1583 33.8109 21.0026 33.9654L18.6693 36.3154V20.332C18.6693 19.89 18.8449 19.4661 19.1574 19.1535C19.47 18.841 19.8939 18.6654 20.3359 18.6654H37.0026C37.4446 18.6654 37.8686 18.841 38.1811 19.1535C38.4937 19.4661 38.6693 19.89 38.6693 20.332V25.332H30.3359C29.0099 25.332 27.7381 25.8588 26.8004 26.7965C25.8627 27.7342 25.3359 29.0059 25.3359 30.332ZM45.3359 42.982L43.6693 41.3154C43.5149 41.1579 43.3308 41.0326 43.1277 40.9468C42.9246 40.8609 42.7065 40.8163 42.4859 40.8154H30.3359C29.8939 40.8154 29.47 40.6398 29.1574 40.3272C28.8449 40.0146 28.6693 39.5907 28.6693 39.1487V30.332C28.6693 29.89 28.8449 29.4661 29.1574 29.1535C29.47 28.841 29.8939 28.6654 30.3359 28.6654H43.6693C44.1113 28.6654 44.5352 28.841 44.8478 29.1535C45.1603 29.4661 45.3359 29.89 45.3359 30.332V42.982Z"
        fill="#62C37D"
      />
    </svg>
    <div class="flex flex-col justify-center !gap-2 text-default-neutral-1">
      <span class="text-interface-title-3-semibold text-center">
        Can we talk about issues you have faced with?
      </span>
      <p class="text-interface-body-1-regular text-center">
        We can find out some solutions for you
      </p>
    </div>
    <div class="flex !gap-2 w-full">
      <button
        class="kw-btn kw-btn-lg kw-btn-active kw-btn-light w-full"
        type="button"
        (click)="onCancel()"
      >
        <span class="block"> Cancel Anyway </span>
      </button>
      <a
        class="kw-btn kw-btn-lg kw-btn-active kw-btn-primary w-full"
        href="https://calendly.com/visualsectors/30min"
        target="_blank"
        (click)="onGetCalendly()"
      >
        <span class="block"> Schedule a Call </span>
      </a>
    </div>
  </div>
</div>
