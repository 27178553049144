<ng-container *ngIf="!hidden">
    <svg class="onboarding-hint-arrow" width="36" height="8" viewBox="0 0 36 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M18.5 0C21.5 0 24.4999 8 28.5 8H8.5C12.4749 8 15.5 0 18.5 0Z" fill="#F2F3F7"/>
    </svg>    
    <div class="onboarding-hint">
        <div class="onboarding-hint-top">
            <svg (click)="close()" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.5286 5.00011L2.7643 3.23581L3.2357 2.7644L5 4.5287L6.7643 2.7644L7.2357 3.23581L5.4714 5.00011L7.2357 6.7644L6.7643 7.23581L5 5.47151L3.2357 7.23581L2.7643 6.7644L4.5286 5.00011Z" fill="#333333"/>
            </svg>            
        </div>
        <div class="onboarding-hint-content">
            <ng-content></ng-content>
        </div>
    </div>
</ng-container>