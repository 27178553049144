import { Component, EventEmitter, Output } from '@angular/core';
import { LoginRequest } from 'src/app/interfaces/api/auth/login';
import { UtmService } from 'src/app/modules/shared/modules/utm/services/utm.service';
import { AnalyticService } from 'src/app/services/analytic.service';
import { EventsService, EventType } from 'src/app/services/events.service';
import { AuthService } from '../../services/auth.service';
import { SocialAuthService } from '../../services/social-auth.service';
import { LoginFormData } from './interfaces/login-form-data';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent {
  @Output() goToSignUp = new EventEmitter<void>();
  @Output() goResetPassword = new EventEmitter<void>();
  @Output() loginSuccessful = new EventEmitter<void>();

  constructor(public socialAuthService: SocialAuthService) {}
}
