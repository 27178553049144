import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnInit,
} from '@angular/core';

@Directive({
  selector: '[appImageThumb]',
})
export class ImageThumbDirective {
  @Input() thumb = '/assets/images/image-thumb.png';

  constructor(private element: ElementRef) {}

  get image(): HTMLImageElement {
    return this.element?.nativeElement;
  }

  @HostListener('error') onError() {
    this.image.src = this.thumb;
  }
}
