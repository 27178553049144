import { Component, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { LoginRequest } from 'src/app/interfaces/api/auth/login';
import { UtmService } from 'src/app/modules/shared/modules/utm/services/utm.service';
import { AnalyticService } from 'src/app/services/analytic.service';
import { AuthService } from '../../../services/auth.service';
import { SocialAuthService } from '../../../services/social-auth.service';

@Component({
  selector: 'app-login-form-content',
  templateUrl: './login-form-content.component.html',
  styleUrls: ['./login-form-content.component.scss'],
})
export class LoginFormContentComponent {
  form = new FormGroup({
    email: new FormControl(''),
    password: new FormControl(''),
  });
  passwordFieldType: 'text' | 'password' = 'password';
  errorMessage?: string;

  @Output() goToSignUp = new EventEmitter<void>();
  @Output() loginSuccessful = new EventEmitter<void>();

  constructor(
    private authService: AuthService,
    private analyicService: AnalyticService,
    private utmService: UtmService,
    public socialAuthService: SocialAuthService,
  ) {
    this.analyicService.send('login_show');
  }

  async signIn() {
    try {
      const loginData: LoginRequest = {
        email: this.form.controls['email'].value.toLocaleLowerCase(),
        password: this.form.controls['password'].value,
      };

      const utm = this.utmService.getUtmData();
      if (utm) {
        loginData.utm = utm;
      }

      const { userId } = await this.authService.login(loginData);

      this.utmService.clearUtmData();
      this.loginSuccessful.emit();
      this.analyicService.send('login_success', new Map([['user_id', userId]]));
      this.errorMessage = '';
    } catch (error: any) {
      this.errorMessage = error.message;
    }
  }

  togglePasswordFieldEye() {
    if (this.passwordFieldType === 'password') {
      this.passwordFieldType = 'text';
    } else {
      this.passwordFieldType = 'password';
    }
  }
}
