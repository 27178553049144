import { SafeHtml } from '@angular/platform-browser';

export interface CashFlowForecast {
  ticker: string;
  expectation_type: CashFlowForecastIconKey;
  date: string;
  is_faked: boolean;
}

export interface CashFlowForecastPrepare {
  ticker: string;
  expectation_type: CashFlowForecastIconKey;
  date: string;
  is_faked: boolean;
  width: number;
  positionX: number;
  icon: SafeHtml;
  gradient: string;
}

export type CashFlowForecastIcon = {
  [key in CashFlowForecastIconKey]: (x: number, y: number) => string;
};

export type CashFlowForecastIconKey =
  | 'Sideways'
  | 'Mixed'
  | 'Range'
  | 'Bullish profit taking'
  | 'Bearish profit taking'
  | 'Bullish trend'
  | 'Bearish trend'
  | 'Short squeeze'
  | 'Long squeeze'
  | 'Support test'
  | 'Resistance test'
  | 'Bullish pivot'
  | 'Bearish pivot'
  | 'Bullish breakout'
  | 'Bearish breakout'
  | 'Pullback'
  | 'Bounce back';

export const CASH_FLOW_FORECAST_ICON_OBJ: CashFlowForecastIcon = {
  Sideways: (x: number, y: number): string => {
    return `
      <svg x="${x}" y="${y}" width="33" height="32" viewBox="0 0 33 32" style="cursor: pointer;" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.75" data-name="Sideways">
          <path d="M0.5 16C0.5 7.16344 7.66344 0 16.5 0C25.3366 0 32.5 7.16344 32.5 16C32.5 24.8366 25.3366 32 16.5 32C7.66344 32 0.5 24.8366 0.5 16Z" fill="#F1B418"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M20.965 12.4628C21.3555 12.0723 21.9886 12.0723 22.3792 12.4628L25.2076 15.2912C25.3951 15.4788 25.5005 15.7331 25.5005 15.9983C25.5005 16.2636 25.3951 16.5179 25.2076 16.7055L22.3792 19.5339C21.9886 19.9244 21.3555 19.9244 20.965 19.5339C20.5744 19.1434 20.5744 18.5102 20.965 18.1197L22.0863 16.9983L8.50049 16.9983C7.9482 16.9983 7.50049 16.5506 7.50049 15.9983C7.50049 15.4461 7.9482 14.9983 8.50049 14.9983L22.0863 14.9983L20.965 13.877C20.5744 13.4865 20.5744 12.8533 20.965 12.4628Z" fill="white"/>
        </g>
      </svg>
    `;
  },
  Mixed: (x: number, y: number): string => {
    return `
      <svg x="${x}" y="${y}" width="32" height="32" viewBox="0 0 33 32" style="cursor: pointer;" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.75" data-name="Mixed">
          <path d="M0.5 16C0.5 7.16344 7.66344 0 16.5 0C25.3366 0 32.5 7.16344 32.5 16C32.5 24.8366 25.3366 32 16.5 32C7.66344 32 0.5 24.8366 0.5 16Z" fill="#6d6f72"/>
          <circle cx="9" cy="16" r="2" fill="white"/>
          <circle cx="16" cy="16" r="2" fill="white"/>
          <circle cx="23" cy="16" r="2" fill="white"/>
        </g>
      </svg>
    `;
  },
  Range: (x: number, y: number): string => {
    return `
      <svg x="${x}" y="${y}" width="32" height="32" viewBox="0 0 32 32" style="cursor: pointer;" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.75" data-name="Range">
          <path d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z" fill="#F1B418"/>
          <path d="M7 8.99902C7 8.44674 7.44772 7.99902 8 7.99902H24C24.5523 7.99902 25 8.44674 25 8.99902C25 9.55131 24.5523 9.99902 24 9.99902H8C7.44772 9.99902 7 9.55131 7 8.99902Z" fill="white"/>
          <path d="M7 23C7 22.4477 7.44772 22 8 22H24C24.5523 22 25 22.4477 25 23C25 23.5523 24.5523 24 24 24H8C7.44772 24 7 23.5523 7 23Z" fill="white"/>
          <path d="M24.8825 13.4708C25.1424 12.9835 24.9581 12.3777 24.4708 12.1178C23.9835 11.8579 23.3777 12.0423 23.1178 12.5296L20.8002 16.8752L18.4826 12.5296C18.3088 12.2037 17.9695 12.0002 17.6002 12.0002C17.2309 12.0002 16.8916 12.2037 16.7178 12.5296L14.4002 16.8752L12.0826 12.5296C11.9088 12.2037 11.5695 12.0002 11.2002 12.0002C10.8309 12.0002 10.4916 12.2037 10.3178 12.5296L7.11784 18.5296C6.85795 19.0169 7.0423 19.6227 7.52961 19.8826C8.01692 20.1424 8.62265 19.9581 8.88255 19.4708L11.2002 15.1252L13.5178 19.4708C13.6916 19.7966 14.0309 20.0002 14.4002 20.0002C14.7695 20.0002 15.1088 19.7966 15.2826 19.4708L17.6002 15.1252L19.9178 19.4708C20.0916 19.7966 20.4309 20.0002 20.8002 20.0002C21.1695 20.0002 21.5088 19.7966 21.6826 19.4708L24.8825 13.4708Z" fill="white"/>
        </g>
      </svg>
    `;
  },
  'Bullish profit taking': (x: number, y: number): string => {
    return `
      <svg x="${x}" y="${y}" width="32" height="32" viewBox="0 0 32 32" style="cursor: pointer;" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.75" data-name="Bullish profit taking">
          <path d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z" fill="#41B1A6"/>
          <path d="M12.5858 14.4142C12.9609 14.7893 13.4696 15 14 15H18C19.0609 15 20.0783 15.4214 20.8284 16.1716C20.8284 16.1716 21.0137 16.3561 21.1463 16.5858C21.2789 16.8155 21.3148 17.0884 21.2462 17.3446C21.1775 17.6008 21.0099 17.8192 20.7802 17.9518C20.5506 18.0844 20.2776 18.1204 20.0214 18.0517C19.7652 17.9831 19.4142 17.5858 19.4142 17.5858C19.0391 17.2107 18.5304 17 18 17H14C12.9391 17 11.9217 16.5786 11.1716 15.8284C10.4214 15.0783 10 14.0609 10 13C10 11.9391 10.4214 10.9217 11.1716 10.1716C11.9217 9.42143 12.9391 9 14 9H15V7C15 6.73478 15.1054 6.48043 15.2929 6.29289C15.4804 6.10536 15.7348 6 16 6C16.2652 6 16.5196 6.10536 16.7071 6.29289C16.8946 6.48043 17 6.73478 17 7V9H19C19.7956 9 20.5587 9.31607 21.1213 9.87868C21.6839 10.4413 22 11.2044 22 12C22 12.2652 21.8946 12.5196 21.7071 12.7071C21.5196 12.8946 21.2652 13 21 13C20.7348 13 20.4804 12.8946 20.2929 12.7071C20.1054 12.5196 20 12.2652 20 12C20 11.7348 19.8946 11.4804 19.7071 11.2929C19.5196 11.1054 19.2652 11 19 11H14C13.4696 11 12.9609 11.2107 12.5858 11.5858C12.2107 11.9609 12 12.4696 12 13C12 13.5304 12.2107 14.0391 12.5858 14.4142Z" fill="white"/>
          <path d="M12.2929 20.7071C12.4804 20.8946 12.7348 21 13 21H17C17.2652 21 17.5196 21.1054 17.7071 21.2929C17.8946 21.4804 18 21.7348 18 22C18 22.2652 17.8946 22.5196 17.7071 22.7071C17.5196 22.8946 17.2652 23 17 23V25C17 25.2652 16.8946 25.5196 16.7071 25.7071C16.5196 25.8946 16.2652 26 16 26C15.7348 26 15.4804 25.8946 15.2929 25.7071C15.1054 25.5196 15 25.2652 15 25V23H13C12.2044 23 11.4413 22.6839 10.8787 22.1213C10.3161 21.5587 10 20.7956 10 20C10 19.7348 10.1054 19.4804 10.2929 19.2929C10.4804 19.1054 10.7348 19 11 19C11.2652 19 11.5196 19.1054 11.7071 19.2929C11.8946 19.4804 12 19.7348 12 20C12 20.2652 12.1054 20.5196 12.2929 20.7071Z" fill="white"/>
          <path d="M25 21H24V20C24 19.7348 23.8946 19.4804 23.7071 19.2929C23.5196 19.1054 23.2652 19 23 19C22.7348 19 22.4804 19.1054 22.2929 19.2929C22.1054 19.4804 22 19.7348 22 20V21H21C20.7348 21 20.4804 21.1054 20.2929 21.2929C20.1054 21.4804 20 21.7348 20 22C20 22.2652 20.1054 22.5196 20.2929 22.7071C20.4804 22.8946 20.7348 23 21 23H22V24C22 24.2652 22.1054 24.5196 22.2929 24.7071C22.4804 24.8946 22.7348 25 23 25C23.2652 25 23.5196 24.8946 23.7071 24.7071C23.8946 24.5196 24 24.2652 24 24V23H25C25.2652 23 25.5196 22.8946 25.7071 22.7071C25.8946 22.5196 26 22.2652 26 22C26 21.7348 25.8946 21.4804 25.7071 21.2929C25.5196 21.1054 25.2652 21 25 21Z" fill="white"/>
        </g>
      </svg>
    `;
  },
  'Bearish profit taking': (x: number, y: number): string => {
    return `
      <svg x="${x}" y="${y}" width="32" height="32" viewBox="0 0 32 32" style="cursor: pointer;" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.75" data-name="Bearish profit taking">
          <path d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z" fill="#F16A6A"/>
          <path d="M12.5858 14.4142C12.9609 14.7893 13.4696 15 14 15H18C19.0609 15 20.0783 15.4214 20.8284 16.1716C20.8284 16.1716 21.0137 16.3561 21.1463 16.5858C21.2789 16.8155 21.3148 17.0884 21.2462 17.3446C21.1775 17.6008 21.0099 17.8192 20.7802 17.9518C20.5506 18.0844 20.2776 18.1204 20.0214 18.0517C19.7652 17.9831 19.4142 17.5858 19.4142 17.5858C19.0391 17.2107 18.5304 17 18 17H14C12.9391 17 11.9217 16.5786 11.1716 15.8284C10.4214 15.0783 10 14.0609 10 13C10 11.9391 10.4214 10.9217 11.1716 10.1716C11.9217 9.42143 12.9391 9 14 9H15V7C15 6.73478 15.1054 6.48043 15.2929 6.29289C15.4804 6.10536 15.7348 6 16 6C16.2652 6 16.5196 6.10536 16.7071 6.29289C16.8946 6.48043 17 6.73478 17 7V9H19C19.7956 9 20.5587 9.31607 21.1213 9.87868C21.6839 10.4413 22 11.2044 22 12C22 12.2652 21.8946 12.5196 21.7071 12.7071C21.5196 12.8946 21.2652 13 21 13C20.7348 13 20.4804 12.8946 20.2929 12.7071C20.1054 12.5196 20 12.2652 20 12C20 11.7348 19.8946 11.4804 19.7071 11.2929C19.5196 11.1054 19.2652 11 19 11H14C13.4696 11 12.9609 11.2107 12.5858 11.5858C12.2107 11.9609 12 12.4696 12 13C12 13.5304 12.2107 14.0391 12.5858 14.4142Z" fill="white"/>
          <path d="M12.2929 20.7071C12.4804 20.8946 12.7348 21 13 21H17C17.2652 21 17.5196 21.1054 17.7071 21.2929C17.8946 21.4804 18 21.7348 18 22C18 22.2652 17.8946 22.5196 17.7071 22.7071C17.5196 22.8946 17.2652 23 17 23V25C17 25.2652 16.8946 25.5196 16.7071 25.7071C16.5196 25.8946 16.2652 26 16 26C15.7348 26 15.4804 25.8946 15.2929 25.7071C15.1054 25.5196 15 25.2652 15 25V23H13C12.2044 23 11.4413 22.6839 10.8787 22.1213C10.3161 21.5587 10 20.7956 10 20C10 19.7348 10.1054 19.4804 10.2929 19.2929C10.4804 19.1054 10.7348 19 11 19C11.2652 19 11.5196 19.1054 11.7071 19.2929C11.8946 19.4804 12 19.7348 12 20C12 20.2652 12.1054 20.5196 12.2929 20.7071Z" fill="white"/>
          <path d="M25 21H24V20C24 19.7348 23.8946 19.4804 23.7071 19.2929C23.5196 19.1054 23.2652 19 23 19C22.7348 19 22.4804 19.1054 22.2929 19.2929C22.1054 19.4804 22 19.7348 22 20V21H21C20.7348 21 20.4804 21.1054 20.2929 21.2929C20.1054 21.4804 20 21.7348 20 22C20 22.2652 20.1054 22.5196 20.2929 22.7071C20.4804 22.8946 20.7348 23 21 23H22V24C22 24.2652 22.1054 24.5196 22.2929 24.7071C22.4804 24.8946 22.7348 25 23 25C23.2652 25 23.5196 24.8946 23.7071 24.7071C23.8946 24.5196 24 24.2652 24 24V23H25C25.2652 23 25.5196 22.8946 25.7071 22.7071C25.8946 22.5196 26 22.2652 26 22C26 21.7348 25.8946 21.4804 25.7071 21.2929C25.5196 21.1054 25.2652 21 25 21Z" fill="white"/>
        </g>
      </svg>
    `;
  },
  'Bullish trend': (x: number, y: number): string => {
    return `
      <svg x="${x}" y="${y}" width="33" height="32" viewBox="0 0 33 32" style="cursor: pointer;" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.75" data-name="Bullish trend">
          <path d="M0.188797 16C0.188797 7.16344 7.35224 0 16.1888 0C25.0254 0 32.1888 7.16344 32.1888 16C32.1888 24.8366 25.0254 32 16.1888 32C7.35224 32 0.188797 24.8366 0.188797 16Z" fill="#41B1A6"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M19.1888 12C19.1888 11.4477 19.6365 11 20.1888 11L24.1888 11C24.454 11 24.7084 11.1054 24.8959 11.2929C25.0834 11.4804 25.1888 11.7348 25.1888 12V16C25.1888 16.5523 24.7411 17 24.1888 17C23.6365 17 23.1888 16.5523 23.1888 16V14.4142L17.8959 19.7071C17.5054 20.0976 16.8722 20.0976 16.4817 19.7071L13.1888 16.4142L8.8959 20.7071C8.50538 21.0976 7.87221 21.0976 7.48169 20.7071C7.09117 20.3166 7.09117 19.6834 7.48169 19.2929L12.4817 14.2929C12.8722 13.9024 13.5054 13.9024 13.8959 14.2929L17.1888 17.5858L21.7746 13H20.1888C19.6365 13 19.1888 12.5523 19.1888 12Z" fill="white"/>
        </g>
      </svg>
    `;
  },
  'Bearish trend': (x: number, y: number): string => {
    return `
      <svg x="${x}" y="${y}" width="32" height="32" viewBox="0 0 32 32" style="cursor: pointer;" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.75" data-name="Bearish trend">
          <path d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z" fill="#F16A6A"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M7.29289 11.2929C7.68342 10.9024 8.31658 10.9024 8.70711 11.2929L13 15.5858L16.2929 12.2929C16.6834 11.9024 17.3166 11.9024 17.7071 12.2929L23 17.5858V16C23 15.4477 23.4477 15 24 15C24.5523 15 25 15.4477 25 16V20C25 20.2652 24.8946 20.5196 24.7071 20.7071C24.5196 20.8946 24.2652 21 24 21L20 21C19.4477 21 19 20.5523 19 20C19 19.4477 19.4477 19 20 19H21.5858L17 14.4142L13.7071 17.7071C13.3166 18.0976 12.6834 18.0976 12.2929 17.7071L7.29289 12.7071C6.90237 12.3166 6.90237 11.6834 7.29289 11.2929Z" fill="white"/>
        </g>
      </svg>
    `;
  },
  'Short squeeze': (x: number, y: number): string => {
    return `
      <svg x="${x}" y="${y}" width="32" height="32" viewBox="0 0 32 32" style="cursor: pointer;" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.75" data-name="Short squeeze">
          <path d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z" fill="#41B1A6"/>
          <path d="M13.5 8C14.0523 8 14.5 8.44772 14.5 9C14.5 13.9477 18.0523 17.5 23 17.5C23.5523 17.5 24 17.9477 24 18.5C24 19.0523 23.5523 19.5 23 19.5C16.9477 19.5 12.5 15.0523 12.5 9C12.5 8.44772 12.9477 8 13.5 8Z" fill="white"/>
          <path d="M6 15.5C6 14.9477 6.44772 14.5 7 14.5C13.0523 14.5 17.5 18.9477 17.5 25C17.5 25.5523 17.0523 26 16.5 26C15.9477 26 15.5 25.5523 15.5 25C15.5 20.0523 11.9477 16.5 7 16.5C6.44772 16.5 6 16.0523 6 15.5Z" fill="white"/>
          <path d="M18 9C18 9.55228 18.4477 10 19 10H20.5858L18.2929 12.2929C17.9024 12.6834 17.9024 13.3166 18.2929 13.7071C18.6834 14.0976 19.3166 14.0976 19.7071 13.7071L22 11.4142V13C22 13.5523 22.4477 14 23 14C23.5523 14 24 13.5523 24 13V9C24 8.44772 23.5523 8 23 8H19C18.4477 8 18 8.44772 18 9Z" fill="white"/>
        </g>
      </svg>
    `;
  },
  'Long squeeze': (x: number, y: number): string => {
    return `
      <svg x="${x}" y="${y}" width="33" height="32" viewBox="0 0 33 32" style="cursor: pointer;" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.75" data-name="Long squeeze">
          <path d="M0.236969 16C0.236969 7.16344 7.40041 0 16.237 0C25.0735 0 32.237 7.16344 32.237 16C32.237 24.8366 25.0735 32 16.237 32C7.40041 32 0.236969 24.8366 0.236969 16Z" fill="#F16A6A"/>
          <path d="M17.737 7C17.737 6.44772 17.2893 6 16.737 6C16.1847 6 15.737 6.44772 15.737 7C15.737 11.9477 12.1847 15.5 7.23697 15.5C6.68468 15.5 6.23697 15.9477 6.23697 16.5C6.23697 17.0523 6.68468 17.5 7.23697 17.5C13.2893 17.5 17.737 13.0523 17.737 7Z" fill="white"/>
          <path d="M12.737 23C12.737 16.9477 17.1847 12.5 23.237 12.5C23.7893 12.5 24.237 12.9477 24.237 13.5C24.237 14.0523 23.7893 14.5 23.237 14.5C18.2893 14.5 14.737 18.0523 14.737 23C14.737 23.5523 14.2893 24 13.737 24C13.1847 24 12.737 23.5523 12.737 23Z" fill="white"/>
          <path d="M18.5299 18.2929C18.9204 17.9024 19.5536 17.9024 19.9441 18.2929L22.237 20.5858V19C22.237 18.4477 22.6847 18 23.237 18C23.7893 18 24.237 18.4477 24.237 19V23C24.237 23.5523 23.7893 24 23.237 24H19.237C18.6847 24 18.237 23.5523 18.237 23C18.237 22.4477 18.6847 22 19.237 22H20.8228L18.5299 19.7071C18.1393 19.3166 18.1393 18.6834 18.5299 18.2929Z" fill="white"/>
        </g>
      </svg>
    `;
  },
  'Support test': (x: number, y: number): string => {
    return `
      <svg x="${x}" y="${y}" width="32" height="32" viewBox="0 0 32 32" style="cursor: pointer;" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.75" data-name="Support test">
          <path d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z" fill="#41B1A6"/>
          <path d="M17.0023 9.00098C17.0023 8.44869 16.5545 8.00098 16.0023 8.00098C15.45 8.00098 15.0023 8.44869 15.0023 9.00098L15.0023 16.5868L13.8809 15.4654C13.4904 15.0749 12.8572 15.0749 12.4667 15.4654C12.0762 15.856 12.0762 16.4891 12.4667 16.8797L15.2951 19.7081C15.6857 20.0986 16.3188 20.0986 16.7094 19.7081L19.5378 16.8797C19.9283 16.4891 19.9283 15.856 19.5378 15.4654C19.1473 15.0749 18.5141 15.0749 18.1236 15.4654L17.0023 16.5868L17.0023 9.00098Z" fill="white"/>
          <path d="M8 22C7.44772 22 7 22.4477 7 23C7 23.5523 7.44772 24 8 24H24C24.5523 24 25 23.5523 25 23C25 22.4477 24.5523 22 24 22H8Z" fill="white"/>
        </g>
      </svg>
    `;
  },
  'Resistance test': (x: number, y: number): string => {
    return `
      <svg x="${x}" y="${y}" width="32" height="32" viewBox="0 0 32 32" style="cursor: pointer;" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.75" data-name="Resistance test">
          <path d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z" fill="#F16A6A"/>
          <path d="M8 7.99902C7.44772 7.99902 7 8.44674 7 8.99902C7 9.55131 7.44772 9.99902 8 9.99902H24C24.5523 9.99902 25 9.55131 25 8.99902C25 8.44674 24.5523 7.99902 24 7.99902H8Z" fill="white"/>
          <path d="M16.7055 12.2919C16.3149 11.9014 15.6818 11.9014 15.2912 12.2919L12.4628 15.1203C12.0723 15.5109 12.0723 16.144 12.4628 16.5346C12.8533 16.9251 13.4865 16.9251 13.877 16.5346L14.9983 15.4132V22.999C14.9983 23.5513 15.4461 23.999 15.9983 23.999C16.5506 23.999 16.9983 23.5513 16.9983 22.999V15.4132L18.1197 16.5346C18.5102 16.9251 19.1434 16.9251 19.5339 16.5346C19.9244 16.144 19.9244 15.5109 19.5339 15.1203L16.7055 12.2919Z" fill="white"/>
        </g>
      </svg>
    `;
  },
  'Bullish pivot': (x: number, y: number): string => {
    return `
      <svg x="${x}" y="${y}" width="32" height="32" viewBox="0 0 32 32" style="cursor: pointer;" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.75" data-name="Bullish pivot">
          <path d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z" fill="#41B1A6"/>
          <path d="M8.7433 8.33104C8.37384 7.92053 7.74155 7.88726 7.33104 8.25672C6.92053 8.62618 6.88726 9.25847 7.25672 9.66897L16.2567 19.669C16.4403 19.8729 16.6995 19.9925 16.9738 19.9997C17.248 20.0069 17.5132 19.9011 17.7071 19.7071L23 14.4142V16C23 16.5523 23.4478 17 24 17C24.5523 17 25 16.5523 25 16V12C25 11.4477 24.5523 11 24 11L20 11C19.4478 11 19 11.4477 19 12C19 12.5523 19.4478 13 20 13H21.5858L17.0382 17.5476L8.7433 8.33104Z" fill="white"/>
          <path d="M8 22C7.44772 22 7 22.4477 7 23C7 23.5523 7.44772 24 8 24H24C24.5523 24 25 23.5523 25 23C25 22.4477 24.5523 22 24 22H8Z" fill="white"/>
        </g>
      </svg>
    `;
  },
  'Bearish pivot': (x: number, y: number): string => {
    return `
      <svg x="${x}" y="${y}" width="32" height="32" viewBox="0 0 32 32" style="cursor: pointer;" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.75" data-name="Bearish pivot">
          <path d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z" fill="#F16A6A"/>
          <path d="M8 7.99902C7.44772 7.99902 7 8.44674 7 8.99902C7 9.55131 7.44772 9.99902 8 9.99902H24C24.5523 9.99902 25 9.55131 25 8.99902C25 8.44674 24.5523 7.99902 24 7.99902H8Z" fill="white"/>
          <path d="M17.7071 12.2919C17.5132 12.0979 17.248 11.9922 16.9738 11.9994C16.6995 12.0066 16.4403 12.1261 16.2567 12.3301L7.25672 22.3301C6.88726 22.7406 6.92053 23.3729 7.33104 23.7423C7.74155 24.1118 8.37384 24.0785 8.7433 23.668L17.0382 14.4514L21.5858 18.999H20C19.4478 18.999 19 19.4467 19 19.999C19 20.5513 19.4478 20.999 20 20.999L24 20.999C24.2653 20.999 24.5196 20.8937 24.7071 20.7061C24.8947 20.5186 25 20.2642 25 19.999L25 15.999C25 15.4467 24.5523 14.999 24 14.999C23.4478 14.999 23 15.4467 23 15.999V17.5848L17.7071 12.2919Z" fill="white"/>
        </g>
      </svg>
    `;
  },
  'Bullish breakout': (x: number, y: number): string => {
    return `
      <svg x="${x}" y="${y}" width="32" height="32" viewBox="0 0 32 32" style="cursor: pointer;" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.75" data-name="Bullish breakout">
          <path d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z" fill="#41B1A6"/>
          <path d="M23.9658 7.74045C23.8228 7.20699 23.2745 6.8904 22.741 7.03335L18.8773 8.06862C18.3438 8.21156 18.0273 8.7599 18.1702 9.29337C18.3131 9.82683 18.8615 10.1434 19.395 10.0005L21.0383 9.56013L14.2975 22.2494L11.7526 19.341C11.554 19.1141 11.2637 18.9889 10.9624 19.0002C10.661 19.0116 10.3809 19.1583 10.2 19.3995L7.20006 23.3995C6.86869 23.8413 6.95823 24.4681 7.40006 24.7995C7.84189 25.1309 8.46869 25.0413 8.80006 24.5995L11.0598 21.5864L13.7476 24.658C13.9642 24.9055 14.2885 25.0307 14.6152 24.9929C14.9419 24.955 15.229 24.7591 15.3833 24.4686L22.6913 10.7116L23.0692 12.1218C23.2121 12.6553 23.7605 12.9718 24.2939 12.8289C24.8274 12.686 25.144 12.1376 25.001 11.6042L23.9658 7.74045Z" fill="white"/>
          <path d="M8 15H15.8837L14.8213 17H8C7.44772 17 7 16.5523 7 16C7 15.4477 7.44772 15 8 15Z" fill="white"/>
          <path d="M24 17H21.6153L22.6778 15H24C24.5523 15 25 15.4477 25 16C25 16.5523 24.5523 17 24 17Z" fill="white"/>
        </g>
      </svg>
    `;
  },
  'Bearish breakout': (x: number, y: number): string => {
    return `
      <svg x="${x}" y="${y}" width="32" height="32" viewBox="0 0 32 32" style="cursor: pointer;" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.75" data-name="Bearish breakout">
          <path d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z" fill="#F16A6A"/>
          <path d="M8.80006 7.40104C8.46869 6.95921 7.84189 6.86966 7.40006 7.20103C6.95823 7.5324 6.86869 8.1592 7.20006 8.60103L10.2 12.601C10.3809 12.8423 10.661 12.989 10.9624 13.0003C11.2637 13.0117 11.554 12.8865 11.7526 12.6596L14.2975 9.75116L21.0383 22.4404L19.395 22.0001C18.8615 21.8571 18.3131 22.1737 18.1702 22.7072C18.0273 23.2406 18.3438 23.789 18.8773 23.9319L22.741 24.9672C23.2745 25.1101 23.8228 24.7936 23.9658 24.2601L25.001 20.3964C25.144 19.8629 24.8274 19.3146 24.2939 19.1716C23.7605 19.0287 23.2121 19.3453 23.0692 19.8787L22.6913 21.2889L15.3833 7.5319C15.229 7.24149 14.9419 7.04549 14.6152 7.00767C14.2885 6.96985 13.9642 7.09504 13.7476 7.34251L11.0598 10.4141L8.80006 7.40104Z" fill="white"/>
          <path d="M8 15H14.8212L15.8836 17H8C7.44772 17 7 16.5523 7 16C7 15.4477 7.44772 15 8 15Z" fill="white"/>
          <path d="M24 17H22.6777L21.6153 15H24C24.5523 15 25 15.4477 25 16C25 16.5523 24.5523 17 24 17Z" fill="white"/>
        </g>
      </svg>
    `;
  },
  Pullback: (x: number, y: number): string => {
    return `
      <svg x="${x}" y="${y}" width="32" height="32" viewBox="0 0 32 32" style="cursor: pointer;" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.75" data-name="Pullback">
          <path d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z" fill="#41B1A6"/>
          <path d="M19.9983 9C19.9983 8.44772 19.5506 8 18.9983 8C18.4461 8 17.9983 8.44772 17.9983 9V12.2426L16.877 11.1213C16.4865 10.7308 15.8533 10.7308 15.4628 11.1213C15.0723 11.5118 15.0723 12.145 15.4628 12.5355L18.2912 15.364C18.6818 15.7545 19.3149 15.7545 19.7055 15.364L22.5339 12.5355C22.9244 12.145 22.9244 11.5118 22.5339 11.1213C22.1434 10.7308 21.5102 10.7308 21.1197 11.1213L19.9983 12.2426V9Z" fill="white"/>
          <path d="M24.7682 17.6402C25.1218 17.2159 25.0645 16.5854 24.6402 16.2318C24.2159 15.8782 23.5854 15.9356 23.2318 16.3598L18.9328 21.5186L13.7071 16.2929C13.5085 16.0942 13.2354 15.9883 12.9547 16.0011C12.674 16.0138 12.4117 16.144 12.2318 16.3598L7.2318 22.3598C6.87824 22.7841 6.93556 23.4147 7.35984 23.7682C7.78412 24.1218 8.41468 24.0645 8.76825 23.6402L13.0672 18.4814L18.2929 23.7071C18.4916 23.9058 18.7647 24.0117 19.0453 23.999C19.326 23.9863 19.5884 23.8561 19.7682 23.6402L24.7682 17.6402Z" fill="white"/>
        </g>
      </svg>
    `;
  },
  'Bounce back': (x: number, y: number): string => {
    return `
      <svg x="${x}" y="${y}" width="32" height="32" viewBox="0 0 32 32" style="cursor: pointer;" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.75" data-name="Bounce back">
          <path d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z" fill="#F16A6A"/>
          <path d="M22.5339 19.4637L19.7055 16.6353C19.3149 16.2448 18.6818 16.2448 18.2912 16.6353L15.4628 19.4637C15.0723 19.8543 15.0723 20.4874 15.4628 20.8779C15.8533 21.2685 16.4865 21.2685 16.877 20.8779L17.9983 19.7566V22.9993C17.9983 23.5515 18.4461 23.9993 18.9983 23.9993C19.5506 23.9993 19.9983 23.5515 19.9983 22.9993V19.7566L21.1197 20.8779C21.5102 21.2685 22.1434 21.2685 22.5339 20.8779C22.9244 20.4874 22.9244 19.8543 22.5339 19.4637Z" fill="white"/>
          <path d="M8.76825 8.35947C8.41468 7.9352 7.78412 7.87787 7.35984 8.23144C6.93556 8.585 6.87824 9.21557 7.2318 9.63984L12.2318 15.6398C12.4117 15.8557 12.674 15.9859 12.9547 15.9986C13.2354 16.0114 13.5085 15.9054 13.7071 15.7068L18.9328 10.4811L23.2318 15.6398C23.5854 16.0641 24.2159 16.1214 24.6402 15.7679C25.0645 15.4143 25.1218 14.7838 24.7682 14.3595L19.7682 8.35947C19.5884 8.14363 19.326 8.01341 19.0453 8.00068C18.7647 7.98795 18.4916 8.09388 18.2929 8.29255L13.0672 13.5182L8.76825 8.35947Z" fill="white"/>
        </g>
      </svg>
    `;
  },
};
