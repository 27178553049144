import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

export const ANIM_SCALE = [
  trigger('scale', [
    state('void', style({ opacity: 0, transform: 'scale(0)' })),
    transition(':enter, :leave', animate(150)),
  ]),
];

export const ANIM_SCALE_Y = [
  trigger('scaleY', [
    state('void', style({ opacity: 0, transform: 'scaleY(0)' })),
    transition(
      ':enter, :leave',
      animate(150),
    ),
  ]),
];
