import { Component, EventEmitter, Output } from '@angular/core';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-reset-password-form',
  templateUrl: './reset-password-form.component.html',
  styleUrls: ['./reset-password-form.component.scss'],
})
export class ResetPasswordFormComponent {
  
  @Output() goToSignIn = new EventEmitter<string>();
  errorMessage?: string;
  email: string = '';

  constructor(private authService: AuthService) {
  }

  async resetPassword() {
    const email = this.email.toLocaleLowerCase();
    try {
      await this.authService.resetPassword({ email });
      this.errorMessage = '';
      this.goToSignIn.emit();
    } catch (error: any) {
      this.errorMessage = error.message;
    }
  }
}
