<ng-container *ngIf="tips.length > 0">
  <div class="tips--top">
    <div class="counter">
      <span class="current-index">{{ activeTip + 1 }}</span> / <span>{{ tips.length }}</span>
    </div>
    <svg (click)="displayTips = false" width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M9.963 11.0001L6.08154 7.11863L7.11863 6.08154L11.0001 9.963L14.8815 6.08154L15.9186 7.11863L12.0372 11.0001L15.9186 14.8815L14.8815 15.9186L11.0001 12.0372L7.11863 15.9186L6.08154 14.8815L9.963 11.0001Z" fill="#5F6388"/>
    </svg>
  </div>

  <div class="tips--title">
    <svg width="17" height="19" viewBox="0 0 17 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.115 7.18296C14.1215 6.24832 13.8776 5.32899 13.4088 4.5204C12.94 3.71182 12.2633 3.04347 11.4489 2.58472C10.6346 2.12598 9.71233 1.89357 8.77784 1.91162C7.84335 1.92967 6.93073 2.19753 6.13472 2.68738C5.33871 3.17724 4.68833 3.87123 4.25109 4.69731C3.81385 5.5234 3.6057 6.45146 3.64825 7.38515C3.6908 8.31884 3.98249 9.22413 4.49305 10.007C5.40476 11.4051 5.94453 11.919 5.94453 13.5827C5.94453 13.737 6.00582 13.885 6.11493 13.9941C6.22404 14.1032 6.37202 14.1645 6.52632 14.1645H11.2314C11.3857 14.1645 11.5337 14.1032 11.6428 13.9941C11.7519 13.885 11.8132 13.737 11.8132 13.5827C11.8132 11.8789 12.417 11.3731 13.3227 9.92399C13.8366 9.10178 14.1109 8.15254 14.115 7.18296Z" fill="#C8E7D4" stroke="#62C37D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M7.28223 17.0891L10.4755 17.0891" stroke="#62C37D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <div class="tips--title-content">{{ tips[activeTip].title }}</div>
  </div>
  <div class="tips--content">
    {{ tips[activeTip].content }}
  </div>
  <div class="tips--navigation flex">
    <svg [class.disabled]="activeTip === 0" (click)="back()" class="tips--navigation-back" width="10" height="17" viewBox="0 0 10 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.5 16C5.23519 13.8498 3.56224 12.3299 1.52716 9.30419C1.20045 8.81846 1.20045 8.18154 1.52716 7.6958C3.56224 4.67009 5.23519 3.15018 8.5 1" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <svg [class.disabled]="activeTip === tips.length - 1" (click)="forward()" class="tips--navigation-forward" width="10" height="17" viewBox="0 0 10 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 16C4.26481 13.8498 5.93776 12.3299 7.97284 9.30419C8.29955 8.81846 8.29955 8.18154 7.97284 7.6958C5.93776 4.67009 4.26481 3.15018 1 1" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </div>
</ng-container>
