<div
  class="flex flex-col bg-white w-screen md:max-w-[480px] h-screen md:h-auto md:rounded-2xl shadow-shadow-modal-shadow"
>
  <div class="flex justify-end !p-4">
    <button (click)="close()">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.7349 12L6 7.26512L7.26512 6L12 10.7349L16.7349 6L18 7.26512L13.2651 12L18 16.7349L16.7349 18L12 13.2651L7.26512 18L6 16.7349L10.7349 12Z"
          fill="#333333"
        />
      </svg>
    </button>
  </div>
  <div class="flex flex-col items-center !gap-6 !p-8 !pt-0">
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 32C0 14.3269 14.3269 0 32 0C49.6731 0 64 14.3269 64 32C64 49.6731 49.6731 64 32 64C14.3269 64 0 49.6731 0 32Z"
        fill="#E8F6EC"
      />
      <path
        d="M45.5992 20.3349C43.8324 18.5636 41.4905 17.4825 38.9964 17.2869C36.5023 17.0912 34.0204 17.794 31.9992 19.2682C29.8786 17.691 27.2391 16.9758 24.6124 17.2666C21.9856 17.5575 19.5666 18.8329 17.8424 20.8359C16.1183 22.8389 15.2172 25.4208 15.3205 28.0616C15.4238 30.7024 16.5239 33.206 18.3992 35.0682L28.7492 45.4349C29.6159 46.2879 30.7831 46.7659 31.9992 46.7659C33.2152 46.7659 34.3825 46.2879 35.2492 45.4349L45.5992 35.0682C47.5451 33.1104 48.6374 30.462 48.6374 27.7016C48.6374 24.9411 47.5451 22.2928 45.5992 20.3349ZM43.2492 32.7682L32.8992 43.1182C32.7814 43.2372 32.6412 43.3316 32.4867 43.396C32.3322 43.4604 32.1665 43.4936 31.9992 43.4936C31.8318 43.4936 31.6661 43.4604 31.5116 43.396C31.3571 43.3316 31.2169 43.2372 31.0992 43.1182L20.7492 32.7182C19.4421 31.3822 18.7102 29.5874 18.7102 27.7182C18.7102 25.8491 19.4421 24.0543 20.7492 22.7182C22.0811 21.4032 23.8774 20.6659 25.7492 20.6659C27.6209 20.6659 29.4172 21.4032 30.7492 22.7182C30.9041 22.8745 31.0884 22.9984 31.2915 23.0831C31.4946 23.1677 31.7125 23.2112 31.9325 23.2112C32.1525 23.2112 32.3704 23.1677 32.5735 23.0831C32.7766 22.9984 32.9609 22.8745 33.1158 22.7182C34.4478 21.4032 36.2441 20.6659 38.1158 20.6659C39.9875 20.6659 41.7839 21.4032 43.1158 22.7182C44.4409 24.0368 45.1968 25.8219 45.2217 27.6911C45.2466 29.5602 44.5386 31.3648 43.2492 32.7182V32.7682Z"
        fill="#62C37D"
      />
    </svg>
    <span
      class="text-interface-title-3-semibold text-default-neutral-1 text-center"
    >
      Thank you for staying with us!
    </span>
    <p
      class="text-interface-caption-1-regular text-default-neutral-3 text-center"
    >
      This window will automatically close in
      <span class="text-default-secondary-2">0{{ intervalValue }}</span> sec
    </p>
  </div>
</div>
