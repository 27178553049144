import { CashFlowChartSignal } from '@/app/modules/main/cash-flow/models/cash-flow-indicators.model';
import { Component, Input } from '@angular/core';

import { SignalName } from '@shared/components/signal-icon/signal-icon.model';

@Component({
  selector: 'shared-signal-icon',
  templateUrl: './signal-icon.component.html',
})
export class SignalIconComponent {
  // TODO add required: true when update Angular
  @Input() signalName: SignalName;
  // TODO maybe use one input instead of three?
  @Input() moneynessAvgPrev: CashFlowChartSignal['data']['moneyness_avg_prev'];
  @Input() upside: CashFlowChartSignal['data']['upside'];
  @Input() downside: CashFlowChartSignal['data']['downside'];

  public readonly SignalName = SignalName;
}
