<div class="flex flex-col justify-center items-center text-center gap-4">
  <div class="flex !p-3 bg-default-primary-1 rounded-full">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
    >
      <path
        d="M31.1663 12C30.4997 11.3333 29.4997 11.3333 28.833 12L16.333 24.5L11.1663 19.3333C10.4997 18.6667 9.49967 18.6667 8.83301 19.3333C8.16634 20 8.16634 21 8.83301 21.6667L15.1663 28C15.4997 28.3333 15.833 28.5 16.333 28.5C16.833 28.5 17.1663 28.3333 17.4997 28L31.1663 14.3333C31.833 13.6667 31.833 12.6667 31.1663 12Z"
        fill="#62C37D"
      />
    </svg>
  </div>

  <div class="flex flex-col gap-2">
    <p class="text-interface-title-3-semibold text-default-neutral-1">
      Your e-mail has been successfully changed
    </p>

    <p class="text-interface-body-1-regular text-medium">
      We save your new e-mail address:
      <span class="text-default-neutral-1">{{ email$ | async }}</span>
    </p>
  </div>

  <p class="text-interface-caption-1-regular text-medium">
    This window will automatically close in
    <span class="text-default-secondary-2">
      {{ closeTime$ | async | number: '2.0' }}
    </span>
    sec
  </p>
</div>
