import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {
  CashFlowChartData,
  CashFlowChartDataCandle,
  CashFlowChartDataCandleColorMap,
  CashFlowExposureTooltipObj,
  CashFlowNetflowTooltipObj,
  ExpTypeKey,
  SvgSetting,
} from '../../models/cash-flow-chart.model';
import * as d3Selection from 'd3-selection';
import { Selection } from 'd3';
import * as d3Scale from 'd3-scale';
import * as d3Drag from 'd3-drag';
import { transition } from 'd3-transition';
import { filter, Subscription } from 'rxjs';
import { ExpirationTypeService } from '../../services/expiration-type.service';
import { ChartService } from '../../services/chart.service';
import { UserTypeModel } from '../../../../shared/models/user-type.model';
import { AuthService } from '../../../../auth/services/auth.service';
import { AnalyticService } from '@app/services/analytic.service';

@Component({
  selector: 'app-cash-flow-chart',
  templateUrl: './cash-flow-chart.component.html',
  styleUrls: ['./cash-flow-chart.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CashFlowChartComponent implements OnInit, OnDestroy {
  cleanChartService$!: Subscription;
  expirationTypeService$!: Subscription;

  // prettier-ignore
  @Input()
  set appCashFlowChartData(cashFlowChartData: CashFlowChartData) {
    if (cashFlowChartData.candlesticks[0].ticker === this.tickerName) {
      this.cashFlowChartData = cashFlowChartData;

      if (cashFlowChartData?.minStrike && cashFlowChartData?.maxStrike) {
        this.setScaleYData(cashFlowChartData.minStrike, cashFlowChartData.maxStrike);
      }

      this.cashFlowChartDataDate = this.cashFlowChartData.candlesticks.map((candle: CashFlowChartDataCandle) => new Date(candle.date));

      if (this.cashFlowChartDataInUpdate) {
        this.cashFlowChartDataInUpdate = false;

        this.setUpdateSvg();
      } else {
        this.setSvg();
      }

      this.tickerName && this.svg?.attr('data-ticker-name', this.tickerName);
    }
  }

  @Input()
  set appCashFlowTickerName(tickerName: string) {
    this.tickerName = tickerName;

    this.svg?.attr('data-ticker-name', this.tickerName);
  }

  // prettier-ignore
  @Output() cashFlowChartDataDateUpdate: EventEmitter<Date> = new EventEmitter();

  // prettier-ignore
  monthNames: string[] = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  cashFlowChartData!: CashFlowChartData;
  cashFlowChartDataDate: Date[] = [];

  cashFlowChartDataActiveExpType: ExpTypeKey = 'month';

  cashFlowChartDataInUpdate: boolean = false;

  cashFlowChartDataDateUpdateLastDirection: 'left' | 'right' = 'left';
  cashFlowChartDataDateUpdateRightIsBlocked: boolean = false;
  cashFlowChartDataDateUpdateLeftIsBlocked: boolean = false;

  svg: Selection<any, any, any, any>;

  svgScaleY: d3Scale.ScaleLinear<number, number>;
  svgScaleYData: number[] = [];
  svgScaleYDataLevels: number = 18; // кол-во уровней для сетки

  svgGridStep: number = 18; // 64 - week, 18 - month, 12 - quarter
  svgGridYScreen: number = 34560; // 34560 * 2 - week, 34560 - month, 34560 - quarter

  svgSetting: SvgSetting = {
    width: 1540, // 1540
    height: 728,
    heightMain: 612,
    marginTop: 5,
    marginRight: 80,
    marginBottom: 0,
    marginLeft: 0,
  };

  candleEmptyLimit: number = 730; // кол-во дней для добавления пустых свечек
  isAddedEmptyCandle: boolean = false;

  svgTranslateX: number = 0;

  svgSelector: string = '#cash-flow-chart';
  svgSelectorDraggableLong: string = '#cash-flow-chart-draggable-long';
  svgSelectorDraggableShort: string = '#cash-flow-chart-draggable-short';
  svgSelectorDraggableCandle: string = '#cash-flow-chart-draggable-candles';
  svgSelectorDraggableY: string = '#cash-flow-chart-draggable-y';
  svgSelectorDraggableYTicker: string = '#cash-flow-chart-draggable-y-ticker';
  svgSelectorDraggableX: string = '#cash-flow-chart-draggable-x';
  svgSelectorDraggableXTicker: string = '#cash-flow-chart-draggable-x-ticker';
  svgSelectorExposureTooltip: string = '#cash-flow-chart-exposure-tooltip';
  svgSelectorNetflowTooltip: string = '#cash-flow-chart-netflow-tooltip';

  candleWidth: number = 14; // 60 - week, 14 - month, 10 - quarter
  candleColorMap: CashFlowChartDataCandleColorMap = {
    green: { stroke: '#7AC9C1', fill: '#D9EFED' },
    red: { stroke: '#F69997', fill: '#FCE2E1' },
  };

  exposureTooltipList: CashFlowExposureTooltipObj[] = [];

  netflowTooltip: CashFlowNetflowTooltipObj = {
    net_flow: 0,
    color: '',
    period: '',
    type: '',
  };

  tickerName!: string;

  userType: UserTypeModel = UserTypeModel.Unregistered;

  constructor(
    private chartService: ChartService,
    private expirationTypeService: ExpirationTypeService,
    private authService: AuthService,
    private analyticService: AnalyticService,
  ) {}

  // prettier-ignore
  ngOnInit(): void {
    this.authService.getUserType().then((userTypeModel: UserTypeModel) => {
      this.userType = userTypeModel;

      // update clip-path-short
      // prettier-ignore
      this.svg
        .select('#cash-flow-chart-clip-path-short > rect')
        .attr('x', 0)
        .attr('y', '0')
        .attr('width', this.svgSetting.width - this.svgSetting.marginRight - (this.userType < 2 ? 196 : 246))
        .attr('height', this.svgSetting.height);
    });

    /** переключение тикера */
    this.cleanChartService$ = this.chartService.cleanSubject
      .pipe(filter((clean: boolean) => clean))
      .subscribe(() => {
        /** делаем настройки для зума */
        this.setSittingsForZoom();

        /** чистим для отрисовки заново */
        this.cleanSvg();
      });

    /** переключение зума */
    this.expirationTypeService$ = this.expirationTypeService.currentExpTypeSubject
      .pipe(filter((expTypeKey: ExpTypeKey) => this.cashFlowChartDataActiveExpType !== expTypeKey))
      .subscribe((expTypeKey: ExpTypeKey) => {
        /** ставим активный зум */
        this.cashFlowChartDataActiveExpType = expTypeKey;

        /** делаем настройки для зума */
        this.setSittingsForZoom();

        /** чистим для отрисовки заново */
        this.cleanSvg();
      });
  }

  ngOnDestroy(): void {
    [this.cleanChartService$, this.expirationTypeService$].forEach(
      ($: Subscription) => $?.unsubscribe(),
    );
  }

  setScaleYData(min: number, max: number) {
    this.svgScaleYData = [];

    // добавляем сверху\\снизу чтоб свечки не прилипли к краям
    min = Math.floor(min - (max - min) * 0.4);
    max = Math.ceil(max + (max - min) * 0.4);

    const step: number = Math.ceil((max - min) / this.svgScaleYDataLevels);

    let arr: number[] = [];

    for (let i: number = 0; i < this.svgScaleYDataLevels; i++) {
      arr.push(max - i * step);
    }

    this.svgScaleYData = arr.reverse();
  }

  setUpdateSvg(): void {
    // SETUP
    this.getSvgScaleY();

    // DRAW GRID

    this.setSvgGridY();
    this.setSvgGridX();

    // DRAW DATES

    this.setSvgDate();

    // DRAW CANDLES

    this.setSvgCandles();

    // INTERACTION

    this.setSvgDrag();

    this.setSvgBrushIntersection();
  }

  setSvg(): void {
    /** чтоб функция попала в билд */
    transition();

    /** Make SVG */
    this.svg = d3Selection
      .select(this.svgSelector)
      .append('svg')
      .attr(
        'viewBox',
        `0 0 ${this.svgSetting.width} ${this.svgSetting.height}`,
      );

    // SETUP

    this.getSvgScaleY();

    // DRAW GRID

    this.setSvgGrid();
    this.setSvgGridY();
    this.setSvgGridX();

    // DRAW DATES

    this.setSvgDate();

    // DRAW CANDLES

    this.setSvgCandles();

    // INTERACTION

    this.setSvgDrag();

    this.setSvgBrushIntersection();
  }

  setSittingsForZoom(): void {
    const setForWeekly = (): void => {
      this.svgGridStep = 64;
      this.svgGridYScreen = 34560 * 2;

      this.candleWidth = 60;
    };

    const setForMonthly = (): void => {
      this.svgGridStep = 18;
      this.svgGridYScreen = 34560;

      this.candleWidth = 14;
    };

    const setForQuarterly = (): void => {
      this.svgGridStep = 12;
      this.svgGridYScreen = 34560;

      this.candleWidth = 10;
    };

    this.cashFlowChartDataActiveExpType === 'week' && setForWeekly();
    this.cashFlowChartDataActiveExpType === 'month' && setForMonthly();
    this.cashFlowChartDataActiveExpType === 'quarter' && setForQuarterly();
  }

  /** удаляем ненужное для отрисовки заново */
  // prettier-ignore
  cleanSvg(): void {
    /** удаляем линии на оси Х */
    d3Selection.selectAll(this.svgSelectorDraggableX + ' > line').remove();

    /** удаляем линии на оси Y */
    d3Selection.selectAll(this.svgSelectorDraggableY + ' > line').remove();
    d3Selection.selectAll(this.svgSelectorDraggableY + ' > g').remove();
    d3Selection.selectAll(this.svgSelectorDraggableYTicker + ' > g').remove();

    /** удаляем свечки */
    d3Selection.selectAll(this.svgSelectorDraggableCandle + ' > g').remove();

    /** удаляем разделители месяцев */
    d3Selection.select(this.svgSelectorDraggableXTicker + ' > g[data-id="lines"]').remove();

    /** удаляем даты месяцев */
    d3Selection.select(this.svgSelectorDraggableXTicker + ' > g[data-id="days"]').remove();

    /** удаляем месяцы */
    d3Selection.select(this.svgSelectorDraggableXTicker + ' > g[data-id="month"]').remove();

    this.cashFlowChartDataInUpdate = true;

    this.cashFlowChartDataDateUpdateRightIsBlocked = false;
    this.cashFlowChartDataDateUpdateLeftIsBlocked = false;

    this.isAddedEmptyCandle = false;

    this.svgTranslateX = 0;
    this.setSvgDragDraggable(this.svgTranslateX);

    this.cashFlowChartData.candlesticks = [];
  }

  // SETUP

  // prettier-ignore
  getSvgScaleY(): void {
    const extraBottomPadding: number = 53;
    const extraTopPadding: number = 28.55;

    // @ts-ignore
    this.svgScaleY = d3Scale
      .scaleLinear()
      .domain([
        this.svgScaleYData[0],
        this.svgScaleYData[this.svgScaleYData.length - 1],
      ])
      .range([
        this.svgSetting.heightMain - this.svgSetting.marginBottom - extraBottomPadding,
        this.svgSetting.marginTop + extraTopPadding,
      ])
      .clamp(true);
  }

  // GRID

  setSvgGrid(): void {
    /**
     * https://jenkov.com/tutorials/svg/clip-path.html
     * Рисуем клиппас для overflow эффекта для драгабл элемента
     */

    const svgSelectorClipPath: string = '#cash-flow-chart-clip-path';

    // CLIP LONG

    this.svg
      .append('clipPath')
      .attr('id', svgSelectorClipPath.substring(1))
      .append('rect')
      .attr('x', 0)
      .attr('y', '0')
      .attr('width', this.svgSetting.width - this.svgSetting.marginRight)
      .attr('height', this.svgSetting.height);

    // WRAPPER LONG

    this.svg
      .append('g')
      .style('clip-path', `url(${svgSelectorClipPath})`)
      .append('g')
      .attr('id', this.svgSelectorDraggableLong.substring(1))
      .attr('transform', `translate(0,0)`);

    // CLIP SHORT | "246" - width "total exposure"

    const svgSelectorClipPathShort: string = '#cash-flow-chart-clip-path-short';

    this.svg
      .append('clipPath')
      .attr('id', svgSelectorClipPathShort.substring(1))
      .append('rect')
      .attr('x', 0)
      .attr('y', '0')
      .attr('width', this.svgSetting.width - this.svgSetting.marginRight - 246)
      .attr('height', this.svgSetting.height);

    // WRAPPER SHORT

    this.svg
      .append('g')
      .style('clip-path', `url(${svgSelectorClipPathShort})`)
      .append('g')
      .attr('id', this.svgSelectorDraggableShort.substring(1))
      .attr('transform', `translate(0,0)`);
  }

  setSvgGridY(): void {
    // LINE

    let l = () => d3Selection.select(this.svgSelectorDraggableY);

    if (l().empty()) {
      d3Selection
        .select(this.svgSelectorDraggableLong)
        .append('g')
        .attr('id', this.svgSelectorDraggableY.substring(1));
    }

    l()
      .selectAll('line')
      .data(this.svgScaleYData)
      .join('line')
      .attr('stroke', '#E2E2E2')
      .attr('stroke-width', 1)
      .attr('y1', (d) => this.svgScaleY(d))
      .attr('y2', (d) => this.svgScaleY(d))
      .attr('x1', 0 - this.svgGridYScreen)
      .attr('x2', this.svgGridYScreen);

    /** линии разделяющие числа месяцев и названия месяцев (низ графика) */
    // SPECIAL LINE

    // prettier-ignore
    let sl = () => d3Selection.select(this.svgSelectorDraggableY + ' > g[data-id="special-line-y"]');

    if (sl().empty()) {
      l().append('g').attr('data-id', 'special-line-y');
    }

    sl()
      .selectAll('line')
      .data(() => [0, 25, 53])
      .join('line')
      .attr('stroke', '#E2E2E2')
      .attr('stroke-width', 1)
      .attr('y1', (d) => this.svgSetting.height - d - 0.5)
      .attr('y2', (d) => this.svgSetting.height - d - 0.5)
      .attr('x1', 0 - this.svgGridYScreen)
      .attr('x2', this.svgGridYScreen);

    /** короткие линии слева от текста */
    // LINE FOR TEXT

    let t = () => d3Selection.select(this.svgSelectorDraggableYTicker);

    if (t().empty()) {
      d3Selection
        .select(this.svgSelector + '> svg')
        .append('g')
        .attr('id', this.svgSelectorDraggableYTicker.substring(1));
    }

    // prettier-ignore
    let lineForText = () => d3Selection.select(this.svgSelectorDraggableYTicker + ' > g[data-id="line-for-text"]');

    if (lineForText().empty()) {
      t().append('g').attr('data-id', 'line-for-text');
    }

    lineForText()
      .selectAll('g')
      .data(this.svgScaleYData)
      .join('line')
      .attr('stroke', '#E2E2E2')
      .attr('stroke-width', 1)
      .attr('y1', (d) => this.svgScaleY(d))
      .attr('y2', (d) => this.svgScaleY(d))
      .attr('x1', this.svgSetting.width - this.svgSetting.marginRight)
      .attr('x2', this.svgSetting.width - this.svgSetting.marginRight + 8);

    /** уровни цены */
    // TEXT

    // prettier-ignore
    let forText = () => d3Selection.select(this.svgSelectorDraggableYTicker + ' > g[data-id="text"]');

    if (forText().empty()) {
      t().append('g').attr('data-id', 'text');
    }

    forText()
      .selectAll('g')
      .data(this.svgScaleYData)
      .join('text')
      .attr('x', this.svgSetting.width - this.svgSetting.marginRight + 16)
      .attr('y', (d) => this.svgScaleY(d) + 3)
      .style('fill', '#717579')
      .style('font-family', 'B612 Mono')
      .style('font-size', '10px')
      .style('font-weight', 400)
      .text((d) => d.toFixed(2));
  }

  // prettier-ignore
  setSvgGridX(): void {
    // LINE

    const extraBottomPadding: number = 53;

    d3Selection.select(this.svgSelectorDraggableLong).call((g) => {
      let l = () => d3Selection.select(this.svgSelectorDraggableX);

      if (l().empty()) {
        g.append('g').attr('id', this.svgSelectorDraggableX.substring(1));
      }

      /** вертикальные линии на всю доступную ширину */
      l()
        .selectAll('line')
        .data([...new Array(Math.floor(this.svgGridYScreen * 2 / this.svgGridStep)).fill(0).map((_, i: number) => i)])
        .join('line')
        .attr('stroke', (d) => d % 5 === 0 ? '#E2E2E2' : '#DFDFDF')
        .attr('stroke-opacity', 1)
        .attr('stroke-width', 1)
        .attr('stroke-dasharray', (d) => d % 5 === 0 ? '0' : '2 6')
        .attr('x1', (d) => 0.5 + (-1 * this.svgGridYScreen) + d * this.svgGridStep)
        .attr('x2', (d) => 0.5 + (-1 * this.svgGridYScreen) + d * this.svgGridStep)
        .attr('y1', this.svgSetting.marginTop)
        .attr('y2', this.svgSetting.height - this.svgSetting.marginBottom - extraBottomPadding);
    });
  }

  // DATE ON BOTTOM

  //prettier-ignore
  setSvgDate(): void {
    const {newCandleList, shift, select} = this.getNewCandleList();

    d3Selection.select(this.svgSelectorDraggableShort).call((g) => {
      /** разделитель месяцев */
        // SPECIAL LINE

      const isLastDay = (current: string, next: string | undefined): boolean => {
        if (this.cashFlowChartDataActiveExpType === 'quarter') {
          const indexStartMonthInQuarterList: number[] = [0, 3, 6, 9];

          return !!next && new Date(current).getMonth() !== new Date(next).getMonth() && indexStartMonthInQuarterList.includes(new Date(next).getMonth());
        } else {
          return !!next && new Date(current).getMonth() !== new Date(next).getMonth();
        }
      };

      newCandleList.forEach((d, i) => {
        const direction: number = !!select ? 1 : -1;

        let ticker = () => d3Selection.select(this.svgSelectorDraggableXTicker);

        if (ticker().empty()) {
          g.append('g').attr('id', this.svgSelectorDraggableXTicker.substring(1));
        }

        /** SEPARATORS OF MONTHS */

        let l = () => d3Selection.select(this.svgSelectorDraggableXTicker + ' > g[data-id="lines"]');

        if (l().empty()) {
          g.select(this.svgSelectorDraggableXTicker).append('g').attr('data-id', 'lines');
        }

        l()
          .insert('line', select)
          .data([d])
          .attr('data-date', d.date)
          .attr('stroke', '#E2E2E2')
          .attr('stroke-width', 1)
          .style('opacity', isLastDay(d.date, newCandleList[i + (-1 * direction)]?.date) ? 1 : 0)
          .attr('y1', this.svgSetting.height - 25)
          .attr('y2', this.svgSetting.height)
          .attr('x1', () => (direction === -1 ? i : newCandleList.length - 1 - i) * this.svgGridStep + this.svgGridStep / 2 + shift)
          .attr('x2', () => (direction === -1 ? i : newCandleList.length - 1 - i) * this.svgGridStep + this.svgGridStep / 2 + shift);

        /** DAYS */

        let t = () => d3Selection.select(this.svgSelectorDraggableXTicker + ' > g[data-id="days"]');

        if (t().empty()) {
          g.select(this.svgSelectorDraggableXTicker).append('g').attr('data-id', 'days');
        }

        /** числа месяца */
        t()
          .insert('text', select)
          .attr('data-date', d.date)
          .data([d])
          .attr('x', () => (direction === -1 ? i : newCandleList.length - 1 - i) * this.svgGridStep + shift)
          .attr('y', this.svgSetting.height - this.svgSetting.marginTop - 30)
          .attr('text-anchor', 'middle')
          .style('fill', '#717579')
          .style('font-family', 'B612 Mono')
          .style('font-size', '10px')
          .style('font-weight', 400)
          .text(() => new Date(d.date).getDate());
      });

      /** прозрачность для каждого второго числа в месяце если это квартальный зум */
      if (this.cashFlowChartDataActiveExpType === 'quarter') {
        d3Selection.selectAll(this.svgSelectorDraggableXTicker + ' > g[data-id="days"] > text:nth-child(odd)').style('opacity', 1)
        d3Selection.selectAll(this.svgSelectorDraggableXTicker + ' > g[data-id="days"] > text:nth-child(even)').style('opacity', 0)
      }

      /** MONTHS */

      let m = () => d3Selection.select(this.svgSelectorDraggableXTicker + ' > g[data-id="month"]');

      if (m().empty()) {
        g.select(this.svgSelectorDraggableXTicker).append('g').attr('data-id', 'month');
      }

      const separatorMonth = d3Selection.selectAll(this.svgSelectorDraggableXTicker + ' > g[data-id="lines"] > line[style*="opacity: 1"]').nodes();

      separatorMonth.forEach((separator, index) => {
        index === 0 && m().selectAll('text').remove();

        const candleData: CashFlowChartDataCandle = d3Selection.select(separator).data()[0] as CashFlowChartDataCandle;

        const lastSeparatorX: number = Number(d3Selection.select(separator).attr('x1'));
        const firstSeparatorX: number = index === 0 ? lastSeparatorX - 360 : Number(d3Selection.select(separatorMonth[index - 1]).attr('x1'));

        const getMonth = (date: Date): string => {
          if (this.cashFlowChartDataActiveExpType === 'quarter') {
            const monthNamesInQuarter: {[key: number]: string} = {
              2: 'Jan-Mar',
              5: 'Apr-Jun',
              8: 'Jul-Sep',
              11: 'Oct-Dec'
            };

            return monthNamesInQuarter[date.getMonth()];
          } else {
            return this.monthNames[date.getMonth()];
          }
        }

        m()
          .insert('text')
          .attr('x', (firstSeparatorX + lastSeparatorX) / 2)
          .attr('y', this.svgSetting.height - 9)
          .attr('text-anchor', 'middle')
          .style('fill', '#717579')
          .style('font-family', 'B612 Mono')
          .style('font-size', '10px')
          .style('font-weight', 700)
          .text(getMonth(new Date(candleData.date)) + ', ' + new Date(candleData.date).getFullYear());

        /** последний не полный месяц */
        if (index === separatorMonth.length - 1) {
          const month: string = this.monthNames[new Date(candleData.date).getMonth() + 1 > 11 ? 0 : new Date(candleData.date).getMonth() + 1]
          const year: number = new Date(candleData.date).getMonth() + 1 > 11 ? new Date(candleData.date).getFullYear() + 1 : new Date(candleData.date).getFullYear();


          m()
            .insert('text')
            .attr('x', (lastSeparatorX + lastSeparatorX + 360) / 2)
            .attr('y', this.svgSetting.height - 9)
            .attr('text-anchor', 'middle')
            .style('fill', '#717579')
            .style('font-family', 'B612 Mono')
            .style('font-size', '10px')
            .style('font-weight', 700)
            .text(month + ', ' + year);
        }
      });
    });
  }

  // CANDLES

  // prettier-ignore
  getNewCandleList(): { newCandleList: CashFlowChartDataCandle[]; shift: number; select: string | undefined; } {
    let shift: number = 0;
    let direction: number = -1; // 1 OR -1

    /** координата X и DATA первой и последней свечек */
    const [firstCandle, lastCandle] = this.getPositionLeftRightCandles();
    const firstCandleIndex: number = this.cashFlowChartData.candlesticks.indexOf(firstCandle.data);
    const lastCandleIndex: number = this.cashFlowChartData.candlesticks.indexOf(lastCandle.data);

    const candleNodeList = d3Selection
      .select(this.svgSelectorDraggableCandle)
      .selectAll('g')
      .nodes();

    let select: string | undefined = undefined;

    if (candleNodeList.length && candleNodeList.length < this.cashFlowChartData.candlesticks.length) {
      if (firstCandleIndex > 0) {
        direction = -1;

        shift = firstCandleIndex * this.svgGridStep * direction + firstCandle.x;

        select = ':first-child';
      }

      if (lastCandleIndex < this.cashFlowChartData.candlesticks.length - 1) {
        direction = 1;

        shift = this.svgGridStep * direction + lastCandle.x;
      }

      const start: number = direction === 1 ? lastCandleIndex + 1 : 0;
      const end: number = direction === 1 ? this.cashFlowChartData.candlesticks.length : firstCandleIndex;

      const newCandleList: CashFlowChartDataCandle[] = direction === -1 ? [...this.cashFlowChartData.candlesticks.slice(start, end)].reverse() : [...this.cashFlowChartData.candlesticks.slice(start, end)];

      return {newCandleList, shift, select};
    }

    if (candleNodeList.length === this.cashFlowChartData.candlesticks.length) {
      if (!this.cashFlowChartDataDateUpdateRightIsBlocked) {
        this.cashFlowChartDataDateUpdateRightIsBlocked = lastCandle.data.date === this.cashFlowChartData.candlesticks.slice().reverse()[0].date && this.cashFlowChartDataDateUpdateLastDirection === 'right';
      }

      if (!this.cashFlowChartDataDateUpdateLeftIsBlocked) {
        this.cashFlowChartDataDateUpdateLeftIsBlocked = firstCandle.data.date === this.cashFlowChartData.candlesticks[0].date && this.cashFlowChartDataDateUpdateLastDirection === 'left';
      }

      /** если справа нечего грузить, то заполняем пустыми датами */
      if (!this.isAddedEmptyCandle && this.cashFlowChartDataDateUpdateRightIsBlocked) {
        const newCandleList: CashFlowChartDataCandle[] = [];

        // @ts-ignore
        const currentDate: number = new Date(lastCandle.data.date).getTime();

        shift = this.svgGridStep + lastCandle.x;

        /** отдаем пустую свечку с датой */
        const getEmptyCandle = (currentDate: number): CashFlowChartDataCandle => {
          const year: number = new Date(currentDate).getFullYear();
          const month: number | string = new Date(currentDate).getMonth() + 1 < 10 ? '0' + (new Date(currentDate).getMonth() + 1) : new Date(currentDate).getMonth() + 1;
          const day: number | string = new Date(currentDate).getDate() < 10 ? '0' + new Date(currentDate).getDate() : new Date(currentDate).getDate();

          return {
            ticker: this.cashFlowChartData.candlesticks[0].ticker,
            date: `${year}-${month}-${day}`,
            high: 0,
            low: 0,
            open: 0,
            close: 0,
            color: 'red',
          };
        }

        for (let i: number = 1; i <= this.candleEmptyLimit; i++) {
          const dayOfWeek: number = new Date(currentDate + (86400000 * i)).getDay();
          const currentDay: number = new Date(currentDate + (86400000 * i)).getDate();
          const currentMonth: number = new Date(currentDate + (86400000 * i)).getMonth();

          /** все выходные, кроме января */
          const isWeekend: boolean = currentMonth !== 0 && (dayOfWeek === 0 || dayOfWeek === 6);

          /** кроме января и последних месяцев квартала, для остальных оставляем каждый третий день */
          const isRareDayInQuarter: boolean = !(![0, 2, 5, 8, 11].includes(currentMonth) && currentDay % 3 !== 0);

          /**
           * Если квартальный зум, то игнорим выходные и
           * сокращаем кол-во дней в первых двух месяцах квартала (кроме января, т.к. там еще есть годовая бабочка)
          */
          if (this.cashFlowChartDataActiveExpType !== 'quarter' || (!isWeekend && isRareDayInQuarter)) {
            newCandleList.push(getEmptyCandle(currentDate + (86400000 * i)));
          }
        }

        this.cashFlowChartData.candlesticks.push(...newCandleList);

        this.isAddedEmptyCandle = true;

        return {newCandleList, shift, select: undefined};
      }

      return {newCandleList: [], shift: 0, select: undefined};
    }

    return {newCandleList: [...this.cashFlowChartData.candlesticks], shift, select};
  }

  // prettier-ignore
  setSvgCandles(): void {
    const { newCandleList, shift, select} = this.getNewCandleList();
    const direction: number = !!select ? 1 : -1;

    d3Selection.select(this.svgSelectorDraggableShort).call((g) => {
      let p = () => d3Selection.select(this.svgSelectorDraggableCandle);

      if (p().empty()) {
        g.append('g').attr('id', this.svgSelectorDraggableCandle.substring(1)).attr('opacity', 0.4);
      }

      newCandleList.forEach((d, i) => {
        /** линия для максимума */
        p()
          .insert('g', select)
          .attr('data-date', d.date)
          .data([d])
          .append('line')
          .attr('data-line', 'max')
          .attr('stroke', this.candleColorMap[d.color]?.stroke || '#333')
          .attr('stroke-width', 1)
          .attr('x1', 0.5 + (direction === -1 ? i : newCandleList.length - 1 - i) * this.svgGridStep + shift)
          .attr('x2', 0.5 + (direction === -1 ? i : newCandleList.length - 1 - i) * this.svgGridStep + shift)
          .attr('y1', this.svgScaleY(d.high))
          .attr('y2', this.svgScaleY(Math.max(d.open, d.close)))
          .attr('opacity', (_, index, n) => {
            return !d3Selection.select(n[index]).attr('opacity') ? 0 : 1;
          })
          .transition()
          .duration(300)
          .delay((d, index, n) => {
            if (d3Selection.select(n[index]).attr('opacity') === '0') {
              return direction === -1 ? (newCandleList.length - i) * 20 : i * 20;
            } else {
              return 0;
            }
          })
          .attr('opacity', 1);

        /** линия для минимума */
        p()
          .selectAll('g[data-date="' + d.date + '"]')
          .append('line')
          .attr('data-line', 'min')
          .attr('stroke', this.candleColorMap[d.color]?.stroke || '#333')
          .attr('stroke-width', 1)
          .attr('x1', 0.5 + (direction === -1 ? i : newCandleList.length - 1 - i) * this.svgGridStep + shift)
          .attr('x2', 0.5 + (direction === -1 ? i : newCandleList.length - 1 - i) * this.svgGridStep + shift)
          .attr('y1', this.svgScaleY(Math.min(d.open, d.close)))
          .attr('y2', this.svgScaleY(d.low))
          .attr('opacity', (d, index, n) => {
            return !d3Selection.select(n[index]).attr('opacity') ? 0 : 1;
          })
          .transition()
          .duration(500)
          .delay((_, index, n) => {
            if (d3Selection.select(n[index]).attr('opacity') === '0') {
              return direction === -1 ? (newCandleList.length - i) * 20 : i * 20;
            } else {
              return 0;
            }
          })
          .attr('opacity', 1);

        /** тело свечки */
        p()
          .selectAll('g[data-date="' + d.date + '"]')
          .append('rect')
          .attr('x', 0.5 + (direction === -1 ? i : newCandleList.length - 1 - i) * this.svgGridStep - this.candleWidth / 2 + shift)
          .attr('y', this.svgScaleY(Math.max(d.open, d.close)))
          .attr('width', this.candleWidth)
          .attr('height', Math.abs(this.svgScaleY(d.open) - this.svgScaleY(d.close)))
          .attr('stroke', this.candleColorMap[d.color]?.stroke || '#333')
          .attr('fill', this.candleColorMap[d.color]?.fill || '#333')
          .attr('opacity', (_, index, n) => {
            return !d3Selection.select(n[index]).attr('opacity') ? 0 : 0.65;
          })
          .transition()
          .duration(500)
          .delay((_, index, n) => {
            if (d3Selection.select(n[index]).attr('opacity') === '0') {
              return direction === -1 ? (newCandleList.length - i) * 20 : i * 20;
            } else {
              return 0;
            }
          })
          .attr('opacity', 0.65);
      });
    });

    /** скролим к сегодняшнему дню */
    this.setScrollToToday();
  }

  setScrollToToday(): void {
    if (this.svgTranslateX === 0) {
      const rightCandleX: number = this.getPositionLeftRightCandles()[1].x;

      this.svgTranslateX = -1 * (rightCandleX - (this.svgSetting.width - 1200));

      this.setSvgDragDraggable(this.svgTranslateX);

      /** проверяем что в видимой области */
      this.setSvgBrushIntersection();
    }
  }

  // INTERACTIVE

  getSvgDragTranslate(): number[] {
    const node: any = d3Selection.select(this.svgSelectorDraggableLong).node();
    const nodeMatrix: any = node.transform.baseVal.getItem(0).matrix;

    return [nodeMatrix.e, nodeMatrix.f];
  }

  setSvgDrag(): void {
    let dragX: number = 0;

    const drag: any = d3Drag
      .drag()
      .on('start', () => {
        const [x, y]: number[] = this.getSvgDragTranslate();

        dragX = x;
      })
      .on('drag', (event: any) => {
        const getNewDragX = () => {
          const subject = event.subject;

          if (event.x >= subject.x) {
            return dragX + (event.x - subject.x);
          } else {
            return dragX - (subject.x - event.x);
          }
        };

        this.svgTranslateX = getNewDragX();

        // MOVE DRAGGABLE
        this.setSvgDragDraggable(this.svgTranslateX);
      })
      .on('end', (event: any) => {
        /** проверяем что в видимой области */
        this.setSvgBrushIntersection();

        /** отправка метрик */
        this.analyticService.send('stock_chart_drag');
      });

    d3Selection.select(this.svgSelector).call(drag);
  }

  // prettier-ignore
  setSvgDragDraggable(dragX: number): void {
    d3Selection.selectAll(this.svgSelectorDraggableLong).attr('transform', () => `translate(${dragX},0)`);

    d3Selection.selectAll(this.svgSelectorDraggableShort).attr('transform', () => `translate(${dragX},0)`);
  }

  // prettier-ignore
  getVisibleArea(): number[] {
    return [-1 * this.svgTranslateX, this.svgSetting.width - this.svgSetting.marginRight + this.svgTranslateX * -1];
  }

  // prettier-ignore
  getPositionLeftRightCandles(): Array<{ x: number; data: CashFlowChartDataCandle; }> {
    const dayList = d3Selection.select(this.svgSelectorDraggableCandle).selectAll('g');

    if (!d3Selection.select(dayList.nodes()[0]).empty()) {
      const firstCandleNode = d3Selection.select(dayList.nodes()[0]);
      const lastCandleNode = d3Selection.select(dayList.nodes().slice().reverse()[0]);

      /** координата X первой свечки */
      const firstCandleX: number = Number(firstCandleNode.select('line').attr('x1')) - 0.5;
      const firstCandle: CashFlowChartDataCandle = firstCandleNode.data()[0] as CashFlowChartDataCandle;

      /** координата X последней свечки */
      const lastCandleX: number = Number(lastCandleNode.select('line').attr('x1')) - 0.5;
      const lastCandle: CashFlowChartDataCandle = lastCandleNode.data()[0] as CashFlowChartDataCandle;

      return [
        {x: firstCandleX, data: firstCandle},
        {x: lastCandleX, data: lastCandle},
      ];
    } else {
      return [
        {x: 0, data: {} as CashFlowChartDataCandle},
        {x: 0, data: {} as CashFlowChartDataCandle}
      ];
    }
  }

  // prettier-ignore
  setSvgBrushIntersection(): void {
    const reserveWidth: number = 2500;

    const [startX, endX] = this.getVisibleArea();

    /** координата X и DATA первой и последней свечек */
    const [firstCandle, lastCandle] = this.getPositionLeftRightCandles();

    /** надо грузить слева */
    if (!this.cashFlowChartDataDateUpdateLeftIsBlocked && !this.cashFlowChartDataInUpdate && firstCandle.x > startX - reserveWidth && firstCandle.data.date) {
      this.cashFlowChartDataDateUpdate.emit(new Date(firstCandle.data.date));

      this.cashFlowChartDataInUpdate = true;

      this.cashFlowChartDataDateUpdateLastDirection = 'left';
    }

    /** надо грузить  справа */
    if (!this.cashFlowChartDataDateUpdateRightIsBlocked && !this.cashFlowChartDataInUpdate && lastCandle.x < endX + reserveWidth && lastCandle.data.date) {
      this.cashFlowChartDataDateUpdate.emit(new Date(lastCandle.data.date));

      this.cashFlowChartDataInUpdate = true;

      this.cashFlowChartDataDateUpdateLastDirection = 'right';
    }
  }
}
