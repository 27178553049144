<ng-container>
  <ul
    class="fixed inset-0 z-[9999] pointer-events-none gap-[8px] flex flex-col items-end justify-start p-[16px]"
  >
    <li
      class="flex gap-[8px] rounded-[12px] p-[16px] items-start shadow-shadow-popup-sm-shadow pointer-events-auto max-w-[360px] w-full sm:w-auto"
      *ngFor="let snack of snackbarList"
      [ngClass]="$any(snack.options?.classList)"
      (click)="onClose(snack.uuid)"
    >
      <i
        class="block min-w-[24px] min-h-[24px]"
        [ngSwitch]="snack.options?.icon"
      >
        <svg
          *ngSwitchCase="'bolt'"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19.89 9.54998C19.8069 9.38507 19.6798 9.24638 19.5227 9.14931C19.3656 9.05223 19.1847 9.00054 19 8.99998H14V2.99998C14.0107 2.78067 13.949 2.56391 13.8243 2.38318C13.6996 2.20245 13.5188 2.06779 13.31 1.99998C13.1092 1.93393 12.8927 1.93318 12.6915 1.99786C12.4902 2.06254 12.3147 2.18931 12.19 2.35998L4.19 13.36C4.08976 13.5048 4.02958 13.6736 4.01553 13.8492C4.00148 14.0248 4.03407 14.201 4.11 14.36C4.17992 14.5417 4.30139 14.6991 4.45949 14.8128C4.61759 14.9265 4.80545 14.9915 5 15H10V21C10.0002 21.2109 10.067 21.4163 10.1909 21.5869C10.3148 21.7575 10.4895 21.8846 10.69 21.95C10.7905 21.9811 10.8948 21.998 11 22C11.1578 22.0004 11.3134 21.9635 11.4542 21.8922C11.595 21.8209 11.7169 21.7174 11.81 21.59L19.81 10.59C19.9177 10.4408 19.9822 10.2647 19.9963 10.0812C20.0105 9.89774 19.9737 9.71391 19.89 9.54998ZM12 17.92V14C12 13.7348 11.8946 13.4804 11.7071 13.2929C11.5196 13.1053 11.2652 13 11 13H7L12 6.07998V9.99998C12 10.2652 12.1054 10.5196 12.2929 10.7071C12.4804 10.8946 12.7348 11 13 11H17L12 17.92Z"
          />
        </svg>
        <svg
          *ngSwitchCase="'exclamation-circle'"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM12 17C11.4 17 11 16.6 11 16C11 15.4 11.4 15 12 15C12.6 15 13 15.4 13 16C13 16.6 12.6 17 12 17ZM13 12C13 12.6 12.6 13 12 13C11.4 13 11 12.6 11 12V8C11 7.4 11.4 7 12 7C12.6 7 13 7.4 13 8V12Z"
          />
        </svg>
        <svg
          *ngSwitchCase="'check-circle'"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM16.2 10.3L11.4 15.1C11 15.5 10.4 15.5 10 15.1L7.8 12.9C7.4 12.5 7.4 11.9 7.8 11.5C8.2 11.1 8.8 11.1 9.2 11.5L10.7 13L14.8 8.9C15.2 8.5 15.8 8.5 16.2 8.9C16.6 9.3 16.6 9.9 16.2 10.3Z"
          />
        </svg>
      </i>
      <span class="text-interface-caption-1-semibold block py-[3px]">
        {{ snack.message }}
      </span>
    </li>
  </ul>
</ng-container>
