<ul class="flex gap-1">
  <!-- BACK BTN -->
  <li>
    <button
      class="kw-btn kw-btn-sm kw-btn-pagination"
      type="button"
      [disabled]="pageCurrent === 1 || disableBtn"
      (click)="selectedPage.emit(pageCurrent - 1)"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="currentColor"
      >
        <path
          d="M10.4712 2.86177C10.7316 3.12212 10.7316 3.54423 10.4712 3.80458L6.27598 7.99984L10.4712 12.1951C10.7316 12.4554 10.7316 12.8776 10.4712 13.1379C10.2109 13.3983 9.78878 13.3983 9.52843 13.1379L4.86177 8.47124C4.60142 8.21089 4.60142 7.78878 4.86177 7.52843L9.52843 2.86177C9.78878 2.60142 10.2109 2.60142 10.4712 2.86177Z"
        />
      </svg>
    </button>
  </li>
  <!-- ALL BTNS-->
  <ng-container
    *ngFor="let page of pageList; let index = index; let last = last"
  >
    <li
      *ngIf="!last"
      [ngClass]="pageList.length - 2 === index ? 'hidden md:block' : ''"
    >
      <!-- prettier-ignore -->
      <button
        class="kw-btn kw-btn-sm kw-btn-pagination min-w-[36px]"
        type="button"
        [ngClass]="pageCurrent === page ? '!bg-active-secondary-1 !text-active-secondary-1' : ''"
        [disabled]="pageCurrent === page || disableBtn"
        (click)="selectedPage.emit(page)"
      >
        <span class="block">{{ page }}</span>
      </button>
    </li>
  </ng-container>
  <li *ngIf="pageCurrent <= pagesCount - maxButtonCount">
    <button
      class="kw-btn kw-btn-sm kw-btn-pagination"
      type="button"
      [disabled]="disableBtn"
    >
      <span class="block">...</span>
    </button>
  </li>
  <!-- LAST BTN -->
  <li>
    <!-- prettier-ignore -->
    <button
      class="kw-btn kw-btn-sm kw-btn-pagination min-w-[36px]"
      type="button"
      [ngClass]="pageCurrent === pagesCount ? '!bg-active-secondary-1 !text-active-secondary-1' : ''"
      [disabled]="pageCurrent === pagesCount || disableBtn"
      (click)="selectedPage.emit(pagesCount)"
    >
      <span class="block">{{ pagesCount }}</span>
    </button>
  </li>
  <!-- FORWARD BTN -->
  <li>
    <button
      class="kw-btn kw-btn-sm kw-btn-pagination"
      type="button"
      [disabled]="pageCurrent === pagesCount || disableBtn"
      (click)="selectedPage.emit(pageCurrent + 1)"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="currentColor"
      >
        <path
          d="M5.52858 2.86195C5.26823 3.1223 5.26823 3.54441 5.52858 3.80476L9.72384 8.00002L5.52858 12.1953C5.26823 12.4556 5.26823 12.8777 5.52858 13.1381C5.78892 13.3984 6.21103 13.3984 6.47138 13.1381L11.1381 8.47143C11.3984 8.21108 11.3984 7.78897 11.1381 7.52862L6.47138 2.86195C6.21103 2.6016 5.78892 2.6016 5.52858 2.86195Z"
        />
      </svg>
    </button>
  </li>
</ul>
