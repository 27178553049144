import { Injectable } from '@angular/core';
import { AuthService } from '../../auth/services/auth.service';

/**
 * Declare third-party LinkMink library in global scope.
 * The library is loaded using GTM and may not be available at the startup.
 */
declare global {
  interface Window { LinkMink?: (publicKey?: string) => LinkMinkLibrary; }
}
interface LinkMinkLibrary {
  initTracking: (options?: []) => Promise<void>;
  getReferralData: () => string;
  conversion: (email: string, options?: {
    type: string;
    status: string
    livemode: boolean;
  }) => Promise<void>;
}

interface LinkMinkData {
  referralCode: string;
  referralData: string;
}

@Injectable()
export class LinkMinkService {
  private libraryCheckIntervalTimeMs = 500;
  private linkMinkDataStorageKey = 'linkMinkData';

  public constructor(private authService: AuthService) {}

  public async isReferral(): Promise<boolean> {
    const isAlreadyRegistered = await this.authService.isAuthenticated();
    if (!isAlreadyRegistered) {
      // Verify that a user is being referred by checking the query string parameter.
      return new URL(window.location.toString()).searchParams.has('lmref');
    } else {
      // Registered users cannot be referred.
      return false;
    }
  }

  public async retrieveAndSaveReferralDataToLocalStorage(): Promise<void> {
    const referralCode = this.getReferralCodeFromSearchString();
    const referralData = await this.getRefferalDataFromLinkMink();
    if (referralCode && referralData) {
      this.saveReferralDataToLocalStorage({ referralCode, referralData });
    } else {
      console.warn('Attempt to save referral data, but it cannot be retrieved.');
    }
  }

  public getReferralDataFromLocalStorage(): LinkMinkData | null {
    const linkMinkDataString = localStorage.getItem(this.linkMinkDataStorageKey);
    return linkMinkDataString ? JSON.parse(linkMinkDataString) : null;
  }

  public clearReferralData(): void {
    localStorage.removeItem(this.linkMinkDataStorageKey);
  }

  private saveReferralDataToLocalStorage(data: LinkMinkData): void {
    localStorage.setItem(this.linkMinkDataStorageKey, JSON.stringify(data));
  }

  private getReferralCodeFromSearchString(): string | null {
    return new URL(window.location.toString()).searchParams.get('lmref');
  }

  private async getRefferalDataFromLinkMink(): Promise<string | null> {
    const linkMink = await this.getLinkMinkLibrary();
    return linkMink.getReferralData() || null;
  }

  private async getLinkMinkLibrary(): Promise<LinkMinkLibrary> {
    return new Promise<LinkMinkLibrary>((resolve) => {
      if (window.LinkMink) {
        // The library is ready to use.
        resolve(window.LinkMink());
      } else {
        // Wait for the library to become available.
        const runningLibraryChecker = setInterval(() => {
          if (window.LinkMink) {
            clearInterval(runningLibraryChecker);
            resolve(window.LinkMink());
          } else {
            console.info(`The LinkMink library not available yet. Try again after ${this.libraryCheckIntervalTimeMs} ms`);
          }
        }, this.libraryCheckIntervalTimeMs);
      }
    })
  }
}