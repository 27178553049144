import { Component, Input, TemplateRef } from '@angular/core';
import {
  ITableData,
  ITableColumn,
  ITableConfig,
  IHorizontalTableGridClasses,
} from '../../../structures/ui';
import { TABLE_DEFAULT_CONFIG } from '../../../utils/table.data';

@Component({
  selector: 'app-table-grid-horizontal',
  templateUrl: './table-grid-horizontal.component.html',
  styleUrls: ['./table-grid-horizontal.component.scss'],
})
export class TableGridHorizontalComponent {
  @Input() columns: ITableColumn[] = [];
  @Input() data: ITableData[] = [];
  @Input() templates: { [templateName: string]: TemplateRef<any> } = {};
  @Input() classes: IHorizontalTableGridClasses = {};
  @Input() config: ITableConfig = TABLE_DEFAULT_CONFIG;

  constructor() {}
}
