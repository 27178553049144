import { Component, Inject, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-agreement-cookie',
  templateUrl: './agreement-cookie.component.html',
})
export class AgreementCookieComponent implements OnInit {
  isShow: boolean = false;

  constructor(
    @Inject(DOCUMENT)
    private document: Document,
    private cookieService: CookieService,
  ) {}

  ngOnInit(): void {
    /** разрешение на использование кук */
    this.isShow = !this.cookieService.check('isAcceptCookie');

    this.checkStateAgreementCookie();
  }

  checkStateAgreementCookie(): void {
    if (this.cookieService.get('isAcceptCookie') === '1') {
      this.removeHeadScripts();
      this.removeBodyScripts();
    }
  }

  // prettier-ignore
  removeHeadScripts(): void {
    ['gtm_in_head', 'mpc_in_head', 'mpc_in_head_noscript'].forEach((id: string) => {
      this.document.querySelector('#' + id)?.remove();
    });
  }

  // prettier-ignore
  removeBodyScripts(): void {
    ['gtm_in_body', 'roistat_in_body', 'facebook_in_body', 'facebook_in_body2', 'twitter_in_body'].forEach((id: string) => {
      this.document.querySelector('#' + id)?.remove();
    });
  }

  onHide(): void {
    this.isShow = false;
  }

  onAccept(): void {
    /** ставим куку на год */
    this.cookieService.set('isAcceptCookie', '2', {
      expires: new Date(Date.now() + 86400 * 365 * 1000),
      path: '/',
    });

    this.checkStateAgreementCookie();
    this.onHide();
  }

  onReject(): void {
    /** ставим куку на 30 дней */
    this.cookieService.set('isAcceptCookie', '1', {
      expires: new Date(Date.now() + 86400 * 30 * 1000),
      path: '/',
    });

    this.checkStateAgreementCookie();
    this.onHide();
  }
}
