import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthFormService } from '../services/auth-form.service';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthorizedGuard implements CanActivate {
  constructor(
    private auth: AuthService,
    private router: Router,
    private authFormService: AuthFormService,
  ) {}

  // prettier-ignore
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const isAuthenticated = await this.auth.isAuthenticated();

    if (!isAuthenticated) {
      this.router.navigate(['/']).then(() => {
        this.authFormService.openSignUp({ redirectUrl: state.url });
      });
    }

    return isAuthenticated;
  }
}
