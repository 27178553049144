/** @format */

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { SnackbarService } from '../../../../services/snackbar.service';
import { HelperService } from '../../../../services/helper.service';
import { Snackbar } from '../../models/snackbar.model';

@Component({
  selector: 'app-snackbar, [appSnackbar]',
  templateUrl: './snackbar.component.html',
})
export class SnackbarComponent implements OnInit, OnDestroy {
  snackbar$: Subscription | undefined;
  snackbarList: Partial<Snackbar>[] = [];

  constructor(
    private snackbarService: SnackbarService,
    private helperService: HelperService,
  ) {}

  ngOnInit(): void {
    this.snackbar$ = this.snackbarService.snackbar$.subscribe({
      next: (snack: Snackbar) => {
        this.onPush({
          uuid: this.helperService.getUUID(),
          ...snack,
        });
      },
      error: (error: any) => console.error(error),
    });
  }

  ngOnDestroy(): void {
    [this.snackbar$].forEach(($) => $?.unsubscribe());
  }

  onPush(snack: Snackbar): void {
    /** Start timer */

    // @ts-ignore
    snack.options = {
      ...snack.options,
      duration: {
        // @ts-ignore
        value: snack.options?.duration.value,
        timeout: setTimeout(() => {
          this.onClose(snack.uuid);

          // @ts-ignore
          clearTimeout(snack.options.duration.timeout);

          // @ts-ignore
        }, snack.options.duration.value),
      },
    };

    /** Push snack */

    this.snackbarList.push(snack);
  }

  onClose(uuid: string = ''): void {
    // prettier-ignore
    // tslint:disable-next-line:no-shadowed-variable
    const snack: Partial<Snackbar> | undefined = this.snackbarList.find((snack: Partial<Snackbar>) => {
      return snack.uuid === uuid
    })

    /** Stop timer */

    clearTimeout(snack?.options?.duration?.timeout);

    /** Filter list */

    // prettier-ignore
    // tslint:disable-next-line:no-shadowed-variable
    this.snackbarList = this.snackbarList.filter((snack: Partial<Snackbar>) => {
      return snack.uuid !== uuid
    })
  }
}
