export enum WidgetType {
  SectorTrendsEconomic,
  SectorTrendsMonetary,
  RiskRewardHeatmap,
  MoneyFlow,
  BuildingPortfolio,
  PortfolioRiskHeatmap,
  Portfolio,
  SingleStock,
  KeyEvents
}
