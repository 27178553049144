import { Component, Input } from '@angular/core';
import { ComponentColor, Icon } from '../../structures/ui';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
})
export class IconComponent {
  @Input() icon!: Icon | string;
  @Input() classes = 'w-5 h-5';
  @Input() color: ComponentColor = {
    fill: {
      base: 'fill-base-300',
      content: 'fill-base-500',
    },
    stroke: 'stroke-base-500 stroke-2',
  };

  get iconName() {
    return Icon;
  }

  constructor() {}

}
