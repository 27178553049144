import { Injectable } from '@angular/core';

@Injectable()
export class MessageService {
  alert(message: string) {
    this.alert(message);
  }

  async confirm(message: string): Promise<boolean> {
    return new Promise((resolve) => {
      const confirmed = confirm(message);
      resolve(confirmed);
    });
  }
}
