import { Component, Input } from '@angular/core';
import { Selection } from 'd3';
import * as d3Selection from 'd3-selection';
import * as d3Shape from 'd3-shape';
import {
  EventTimeLineDetailsMarketReaction,
  EventTimeLineDetailsMarketReactionDataReaction,
} from '../../../main/events-timeline/models/event-timeline-details.model';

// prettier-ignore
@Component({
  selector: 'shared-snapshot-market-reaction, [sharedSnapshotMarketReaction]',
  templateUrl: './snapshot-market-reaction.component.html',
  styleUrls: ['./snapshot-market-reaction.component.scss'],
})
export class SnapshotMarketReactionComponent {
  @Input()
  set appEventTimeLineDetailsMarketReaction(eventTimeLineDetailsMarketReaction: EventTimeLineDetailsMarketReaction) {
    this.snapshotMarketReaction = eventTimeLineDetailsMarketReaction;

    this.setSvg();
  }

  snapshotMarketReaction: EventTimeLineDetailsMarketReaction;

  svg: Selection<any, any, any, any>;

  svgWidth: number = 0;
  svgHeight: number = 0;
  svgPadding: number = 37;

  barWidth: number = 85;
  barMaxHeight: number = 76;
  barBetween: number = 32;
  barPadding: number = this.svgPadding + 20;

  svgSelector: string = '#events-timeline-details-past-chart';

  setSvg(): void {
    this.svgWidth = 666;
    this.svgHeight = 254;

    d3Selection.select(this.svgSelector).select('svg').remove();

    this.svg = d3Selection
      .select(this.svgSelector)
      .append('svg')
      .attr('viewBox', `0 0 ${this.svgWidth} ${this.svgHeight}`);

    this.setSvgBaseLine();
    this.setSvgBars();
    this.setSvgLine();
    this.setSvgDot();

    this.setSvgBarText();
    this.setSvgDotText();
    this.setSvgBottomText();
  }

  setSvgBaseLine(): void {
    this.svg
      .append('line')
      .attr('stroke', '#808080')
      .attr('y1', 100)
      .attr('y2', 100)
      .attr('x1', this.svgPadding)
      .attr('x2', this.svgWidth - this.svgPadding);
  }

  setSvgBars(): void {
    const colorList: string[] = ['#1F968B', '#DE5B5B'];

    // самое большее значение
    const biggestValue = Math.max(...this.snapshotMarketReaction.dataReaction.map((data: any) => Math.abs(Number(data.barValue))));

    // множитель высоты бара
    const heightMultiplier: number = this.barMaxHeight / biggestValue;

    this.svg.append('g').attr('class', 'bars');

    this.snapshotMarketReaction.dataReaction.forEach((data: any, index: number) => {
      // prettier-ignore
      const x: number = this.barPadding + index * (this.barWidth + this.barBetween);
      // prettier-ignore
      const y: number = Number(data.barValue) > 0 ? 100 - Number(data.barValue) * heightMultiplier : 100;
      const height: number = Math.abs(Number(data.barValue)) * heightMultiplier;

      this.svg
        .select(this.svgSelector + ' .bars')
        .append('rect')
        .attr('x', x)
        .attr('y', y)
        .attr('width', this.barWidth)
        .attr('height', height)
        .attr('fill', data.barValue > 0 ? colorList[0] : colorList[1]);
    });
  }

  setSvgLine(): void {
    // самое большее значение
    const biggestValue: number = Math.max(...this.snapshotMarketReaction.dataReaction.map((data: any) => Math.abs(Number(data.lineValue))));

    // множитель высоты бара
    const heightMultiplier: number = this.barMaxHeight / biggestValue;

    const curve = d3Shape.line().curve(d3Shape.curveLinear);

    const lineList: any = this.snapshotMarketReaction.dataReaction.map((data: any, index: number) => {
      // prettier-ignore
      const startX: number = this.barPadding + index * (this.barWidth + this.barBetween) + this.barWidth / 2;
      // prettier-ignore
      const startY: number = Number(data.lineValue) > 0 ? 100 - Number(data.lineValue) * heightMultiplier : 100 + Math.abs(Number(data.lineValue)) * heightMultiplier;

      let endX!: number;
      let endY!: number;

      if (index !== this.snapshotMarketReaction.dataReaction.length - 1) {
        endX = this.barPadding + (index + 1) * (this.barWidth + this.barBetween) + this.barWidth / 2;

        endY = (Number(this.snapshotMarketReaction.dataReaction[index + 1].lineValue) > 0
          ? 100 - Number(this.snapshotMarketReaction.dataReaction[index + 1].lineValue) * heightMultiplier
          : 100 + Math.abs(Number(this.snapshotMarketReaction.dataReaction[index + 1].lineValue)) * heightMultiplier);
      } else {
        endX = startX;
        endY = startY;
      }

      return [
        [startX, startY],
        [endX, endY],
      ];
    });

    this.svg
      .append('g')
      .attr('class', 'line')
      .selectAll('path')
      .data(this.snapshotMarketReaction.dataReaction)
      .enter()
      .append('path')
      .attr('d', (d, i) => curve(lineList[i]))
      .attr('stroke', '#FDCD25')
      .attr('stroke-width', 2)
      .attr('fill', 'none');
  }

  setSvgDot(): void {
    // самое большее значение
    const biggestValue = Math.max(...this.snapshotMarketReaction.dataReaction.map((data: any) => Math.abs(Number(data.lineValue))));

    // множитель высоты бара
    const heightMultiplier = this.barMaxHeight / biggestValue;

    this.svg.append('g').attr('class', 'dots');

    this.snapshotMarketReaction.dataReaction.forEach((data: EventTimeLineDetailsMarketReactionDataReaction, index: number) => {
      // отслуп + (индекс * (ширина бара + растояние между баров)) + (половина ширины бара)
      // prettier-ignore
      const cx: number = this.barPadding + index * (this.barWidth + this.barBetween) + this.barWidth / 2;
      // prettier-ignore
      const cy: number = Number(data.lineValue) > 0 ? 100 - Number(data.lineValue) * heightMultiplier : 100 + Math.abs(Number(data.lineValue)) * heightMultiplier;

      const lineValue: string = String(this.snapshotMarketReaction.dataReaction[index].lineValue);
      const lineWidth: number = (lineValue.length * 7) + 10;

      this.svg
        .select(this.svgSelector + ' .dots')
        .append('rect')
        .attr('rx', 8)
        .attr('ry', 8)
        .attr('x', cx - (lineWidth / 2))
        .attr('y', cy - 8)
        .attr('width', () => lineWidth)
        .attr('height', 16)
        .attr('fill', '#FDCD25');
    });
  }

  setSvgBarText(): void {
    // самое большее значение
    const biggestValue = Math.max(...this.snapshotMarketReaction.dataReaction.map((data: any) => Math.abs(data.barValue)));

    // множитель высоты бара
    const heightMultiplier = this.barMaxHeight / biggestValue;

    this.svg.append('g').attr('class', 'text-bars');

    this.snapshotMarketReaction.dataReaction.forEach((data: any, index: number) => {
      const height: number = Math.abs(data.barValue) * heightMultiplier;

      // prettier-ignore
      const x: number = this.barPadding + index * (this.barWidth + this.barBetween) + this.barWidth / 2;
      // prettier-ignore
      const y: number = data.barValue > 0 ? (100 - data.barValue * heightMultiplier) - 8 : 100 + height + 20;

      this.svg
        .select(this.svgSelector + ' .text-bars')
        .append('text')
        .attr('x', x)
        .attr('y', y)
        .attr('text-anchor', 'middle')
        .style('fill', '#333')
        .style('font-family', 'B612 Mono')
        .style('font-size', '12px')
        .style('font-weight', 700)
        .text(data.barValue);
    });
  }

  setSvgDotText(): void {
    // самое большее значение
    const biggestValue = Math.max(...this.snapshotMarketReaction.dataReaction.map((data: any) => Math.abs(Number(data.lineValue))));

    // множитель высоты бара
    const heightMultiplier = this.barMaxHeight / biggestValue;

    this.svg.append('g').attr('class', 'text-dots');

    this.snapshotMarketReaction.dataReaction.forEach((data: any, index: number) => {
      // prettier-ignore
      const x: number = this.barPadding + index * (this.barWidth + this.barBetween) + this.barWidth / 2;
      // prettier-ignore
      let y: number = Number(data.lineValue) > 0 ? 100 - Number(data.lineValue) * heightMultiplier : 100 + Math.abs(Number(data.lineValue)) * heightMultiplier;

      y = y + 4;

      this.svg
        .select(this.svgSelector + ' .text-dots')
        .append('text')
        .attr('x', x)
        .attr('y', y)
        .attr('text-anchor', 'middle')
        .style('fill', '#333')
        .style('font-family', 'B612 Mono')
        .style('font-size', '10px')
        .style('font-weight', 700)
        .text(data.lineValue);
    });
  }

  setSvgBottomText(): void {
    this.svg.append('g').attr('class', 'text-bottom');

    this.snapshotMarketReaction.dataReaction.forEach((data: any, index: number) => {
      // prettier-ignore
      const x: number = this.barPadding + index * (this.barWidth + this.barBetween);

      this.svg
        .select(this.svgSelector + ' .text-bottom')
        .append('text')
        .attr('x', x)
        .attr('y', 220)
        .attr('text-anchor', 'start')
        .style('fill', '#333')
        .style('font-family', 'Averta CY')
        .style('font-size', '16px')
        .style('font-weight', 600)
        .style('line-height', 14)
        .text(data.month);

      this.svg
        .select(this.svgSelector + ' .text-bottom')
        .append('text')
        .attr('x', x)
        .attr('y', 242)
        .attr('text-anchor', 'start')
        .style('fill', '#7C7C7C')
        .style('font-family', 'Averta CY')
        .style('font-size', '13px')
        .style('font-weight', 400)
        .style('line-height', 15.6)
        .text(data.monthDesc);
    });
  }
}
