import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LandingVideoDialogComponent } from '../landing-video-dialog/landing-video-dialog.component';
import { LandingChartDialogComponent } from '../landing-chart-dialog/landing-chart-dialog.component';
import { LandingResearchPapersDialogComponent } from '../landing-research-papers-dialog/landing-research-papers-dialog.component';
import { LandingAlreadySignedInDialogComponent } from '../landing-already-signed-in-dialog/landing-already-signed-in-dialog.component';
import { LandingResearchPapersSuccessDialogComponent } from '../landing-research-papers-success-dialog/landing-research-papers-success-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class LandingDialogService {
  constructor(private dialog: MatDialog) {}

  openAnalyticToolsVideo(data: { urlVideo: string; title: string }) {
    this.dialog.open(LandingVideoDialogComponent, { data });
  }

  openAnalyticToolsChart(): void {
    this.dialog.open(LandingChartDialogComponent, {
      maxWidth: '100%',
      height: '100vh',
      id: 'events-timeline-dialog-news',
    });
  }

  openResearchPapersDetails(data: { researchPaper: any }): void {
    this.dialog.open(LandingResearchPapersDialogComponent, { data });
  }

  openResearchPapersSuccess(data: { email: string; signUp: boolean }): void {
    this.dialog.open(LandingResearchPapersSuccessDialogComponent, { data });
  }

  openAlreadySignerIn(): void {
    this.dialog.open(LandingAlreadySignedInDialogComponent);
  }
}
