<ng-container *ngIf="data; else shimmer">
  <span class="text">Switch to annual payment to</span>
  <span *ngIf="data.discount" class="desc"
    >Save more than {{ data.discount }}!</span
  >

  <app-button
    [text]="data.active ? 'Unsubscribe' : 'Pay ' + data.amount"
    [color]="{
      bg: data.active ? 'bg-danger-500' : 'bg-primary-700',
      text: 'text-primary-content'
    }"
    class="mt-4 self-center"
    classes="py-[1rem] px-8 min-w-[200px]"
    (click)="payClick()"
  ></app-button>
</ng-container>

<ng-template #shimmer>
  <div class="flex flex-col gap-[0.5rem] min-w-[22rem]">
    <div
      *ngFor="let item of ['h-4 w-1/2', 'h-4 w-4/5', 'h-20']"
      [class]="item"
      class="rounded-md bg-base-200"
    ></div>
  </div>
</ng-template>
