import { Injectable } from '@angular/core';
import { AuthService } from '../../auth/services/auth.service';
import { TuneAffiliateData } from '../types/TuneAffiliateData';
import { AffiliateLinkParametersError } from '../errors/AffiliateLinkParametersErrror';
import { ApplicationError } from '../../../errors/ApplicationError';

@Injectable()
export class TuneAffiliateService {
  private dataStorageKey = 'tuneAffiliateData';

  public constructor(
    private authService: AuthService,
  ) {}

  public async isAffiliateLink(): Promise<boolean> {
    const isAlreadyRegistered = await this.authService.isAuthenticated();
    if (!isAlreadyRegistered) {
      // Verify that a user is being referred by checking the query string parameter.
      const currentUrl = new URL(window.location.toString())
      return currentUrl.searchParams.has('cid') 
        && currentUrl.searchParams.has('pid') 
        && currentUrl.searchParams.has('oid');
    } else {
      // Registered users cannot be referred.
      return false;
    }
  }

  public async retrieveDataFromAffiliateLinkAndStoreIt(): Promise<void | ApplicationError> {
    const affiliateDataResult = this.retrieveDataFromRequest();

    if (affiliateDataResult instanceof ApplicationError) {
      const error = affiliateDataResult
      return error;
    }

    const affiliateData = affiliateDataResult;
    this.storeData(affiliateData);
  }
  
  public retrieveData(): TuneAffiliateData | null {
    const dataString = localStorage.getItem(this.dataStorageKey);
    return dataString ? JSON.parse(dataString) : null;
  }

  public clearData(): void {
    localStorage.removeItem(this.dataStorageKey);
  }
  
  private storeData(data: TuneAffiliateData): void {
    localStorage.setItem(this.dataStorageKey, JSON.stringify(data));
  }

  private retrieveDataFromRequest(): TuneAffiliateData | AffiliateLinkParametersError {
    const currentUrl = new URL(window.location.toString());
    const cid = currentUrl.searchParams.get('cid');
    const pid = currentUrl.searchParams.get('pid');
    const oid = currentUrl.searchParams.get('oid');

    if (!oid || !pid || !cid) {
      return new AffiliateLinkParametersError();
    }

    const affiliateData: TuneAffiliateData = {
      cid,
      pid,
      oid,
    };

    return affiliateData;
  }
}