import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { INavigationMenu } from '../../structures/app';
import {
  OnboardingHintEventService,
  OnboardingHintEventType,
} from '../onboarding-hint/onboarding-hint-event.service';
import { ONBOARDING_PASSED_NAME } from 'src/app/constants/onboarding-constants';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss'],
})
export class NavMenuComponent {
  @Input() items: INavigationMenu[] = [];
  @Output() itemClick = new EventEmitter<INavigationMenu>();

  constructor(
    private router: Router,
    private eventService: OnboardingHintEventService,
  ) {}

  get url(): string {
    return this.router.url;
  }

  closedOnboardingHint(item: INavigationMenu) {
    if (item.route?.includes('portfolio')) {
      item.onboardingHint = undefined;
      localStorage.setItem(ONBOARDING_PASSED_NAME, 'TRUE');
      this.eventService.emit(OnboardingHintEventType.Alerts);
    } else if (item.route?.includes('alerts')) {
      item.onboardingHint = undefined;
    }
  }
}
