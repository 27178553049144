import { Injectable } from '@angular/core';
import { lastValueFrom, Subject } from 'rxjs';
import {
  LoginOauthRequest,
  LoginResponse,
} from 'src/app/interfaces/api/auth/login';
import { MainHttpClient } from 'src/app/services/main-http-client.service';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';
import { UtmService } from '../../shared/modules/utm/services/utm.service';
import { LinkMinkService } from '../../affiliate/services/LinkMink.service';
import { TuneAffiliateService } from '../../affiliate/services/TuneAffiliateService';

@Injectable({
  providedIn: 'root',
})
export class SocialAuthService {
  private DIALOG_PARAMS =
    'width=600,height=679,scrollbars=1,toolbar=0,location=1';

  private googleAuthListener: any;
  private facebookAuthListener: any;

  constructor(
    private http: MainHttpClient,
    private authService: AuthService,
    private utmService: UtmService,
    private linkMinkService: LinkMinkService,
    private tuneAffiliateService: TuneAffiliateService,
  ) {}

  async googleAuth(): Promise<void> {
    window.removeEventListener('message', this.googleAuthListener);
    window.removeEventListener('message', this.facebookAuthListener);
    const subject = new Subject<void>();

    const dialogUrl = this.buildUri(
      'https://accounts.google.com/o/oauth2/v2/auth',
      {
        gsiwebsdk: 3,
        client_id: environment.googleClientId,
        scope: 'email profile',
        redirect_uri: `storagerelay://${window.location.origin.replace(
          '://',
          '/',
        )}?id=googAuth`,
        prompt: 'consent',
        access_type: 'offline',
        response_type: 'code',
        include_granted_scopes: true,
        enable_serial_consent: true,
        service: 'lso',
        o2v: 2,
        flowName: 'GeneralOAuthFlow',
      },
    );

    this.googleAuthListener = async (ev: any) => {
      window.removeEventListener('message', this.googleAuthListener);

      const code = JSON.parse(ev.data).params.authResult.code;
      const utmData = this.utmService.getUtmData();

      const registrationOrLoginData: LoginOauthRequest = {
        code,
        redirect_uri: window.location.origin,
        utm: utmData,
      };

      const referralData =
        this.linkMinkService.getReferralDataFromLocalStorage();
      if (referralData) {
        registrationOrLoginData.referralData = referralData;
      }

      const tuneAffiliateData = this.tuneAffiliateService.retrieveData();
      if (tuneAffiliateData) {
        if (!registrationOrLoginData.affiliateData) {
          registrationOrLoginData.affiliateData = {};
        }
        registrationOrLoginData.affiliateData.tune = tuneAffiliateData;
      }

      const response = await this.http.post<LoginResponse>(
        '/v2/oauth/google/callback',
        registrationOrLoginData,
      );
      await this.authService.setTokens(response.token, response.refreshToken);
      subject.next();
      subject.complete();
    };
    window.addEventListener('message', this.googleAuthListener);

    window.open(dialogUrl, 'Google Auth', this.DIALOG_PARAMS);
    return lastValueFrom(subject.asObservable());
  }

  async facebookAuth(): Promise<void> {
    window.removeEventListener('message', this.googleAuthListener);
    window.removeEventListener('message', this.facebookAuthListener);
    const subject = new Subject<void>();

    const fancyReturnUrl = this.buildUri(
      'https://staticxx.facebook.com/x/connect/xd_arbiter/',
      {
        cb: '',
        domain: window.location.hostname,
        is_canvas: false,
        origin: window.location.href,
        relation: 'opener',
        frame: '',
      },
      '#',
    );

    const dialogUrl = this.buildUri('https://www.facebook.com/dialog/oauth', {
      app_id: environment.facebookAppId,
      cbt: Date.now().toString(),
      channel_url: fancyReturnUrl,
      client_id: environment.facebookAppId,
      display: 'popup',
      domain: window.location.hostname,
      fallback_redirect_uri: window.location.href,
      locale: 'en_US',
      redirect_uri: fancyReturnUrl,
      response_type: 'code',
      scope: 'email',
    });

    this.facebookAuthListener = async (ev: any) => {
      window.removeEventListener('message', this.facebookAuthListener);
      ev.source.close();
      const code = new URLSearchParams(ev.data).get('code')!;
      const utmData = this.utmService.getUtmData();

      const registrationOrLoginData: LoginOauthRequest = {
        code,
        redirect_uri: window.location.origin,
        utm: utmData,
      };

      const referralData =
        this.linkMinkService.getReferralDataFromLocalStorage();
      if (referralData) {
        registrationOrLoginData.referralData = referralData;
      }

      const tuneAffiliateData = this.tuneAffiliateService.retrieveData();
      if (tuneAffiliateData) {
        if (!registrationOrLoginData.affiliateData) {
          registrationOrLoginData.affiliateData = {};
        }
        registrationOrLoginData.affiliateData.tune = tuneAffiliateData;
      }

      const response = await this.http.post<LoginResponse>(
        '/auth/login/facebook',
        registrationOrLoginData,
      );
      await this.authService.setTokens(response.token, response.refreshToken);
      subject.next();
      subject.complete();
    };
    window.addEventListener('message', this.facebookAuthListener);

    window.open(dialogUrl, 'Facebook Auth', this.DIALOG_PARAMS);
    return lastValueFrom(subject.asObservable());
  }

  private buildUri(url: string, params: any, splitter = '?') {
    const urlParams = new URLSearchParams(params);
    return url + splitter + urlParams.toString();
  }
}
