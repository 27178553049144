import { Component, Input } from '@angular/core';
import { ComponentColor } from '../../structures/ui';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input() text!: string;
  @Input() color: ComponentColor = {
    bg: 'bg-primary-0/80 hover:bg-primary-0/100',
    text: 'text-primary-content',
  };
  @Input() classes = 'px-8 py-4';
  @Input() iconData?: {
    icon: string;
    classes?: {
      host?: string;
      content?: string;
    };
    color?: ComponentColor;
  };
  constructor() {}
}
