import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  SubscriptionChangeTo,
  SubscriptionsNew,
} from '../models/subscriptions-new.model';
import {
  SubscriptionCancelDialogComponent,
  SubscriptionCanceledDialogComponent,
  SubscriptionCanTalkDialogComponent,
  SubscriptionChangedDialogComponent,
  SubscriptionChangeDialogComponent,
  SubscriptionGetDiscountDialogComponent,
  SubscriptionPayCancelDialogComponent,
  SubscriptionPaySuccessDialogComponent,
  SubscriptionReasonsDialogComponent,
  SubscriptionRenewDialogComponent,
  SubscriptionRenewedDialogComponent,
  SubscriptionThankYouDialogComponent,
} from '../dialogs';
import {
  MeteredUsageInfo,
  Tariff,
} from '../../../premium-new/models/premium-new.model';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionDialogService {
  constructor(private dialog: MatDialog) {}

  openCancelSubscription(data: {
    mySubscriptions: SubscriptionsNew;
    meteredUsageInfo: MeteredUsageInfo | undefined;
  }): void {
    this.dialog.open(SubscriptionCancelDialogComponent, { data });
  }

  openCanceledSubscription(): void {
    this.dialog.open(SubscriptionCanceledDialogComponent);
  }

  // prettier-ignore
  openChangeSubscription(data: {
    mySubscriptions: SubscriptionsNew;
    meteredUsageInfo: MeteredUsageInfo | undefined;
    tariffList: Tariff[];
    changeTo: SubscriptionChangeTo;
    tariffNameFrom: string;
    tariffNameTo: string;
  }): void {
    this.dialog.open(SubscriptionChangeDialogComponent, { data });
  }

  openChangedSubscription(data: {
    tariffName: string;
    tariffType: string;
  }): void {
    this.dialog.open(SubscriptionChangedDialogComponent, { data });
  }

  openRenewSubscription(data: { mySubscriptions: SubscriptionsNew }): void {
    this.dialog.open(SubscriptionRenewDialogComponent, { data });
  }

  openRenewedSubscription(): void {
    this.dialog.open(SubscriptionRenewedDialogComponent);
  }

  openReasonsSubscription(data: { mySubscriptions: SubscriptionsNew }): void {
    this.dialog.open(SubscriptionReasonsDialogComponent, { data });
  }

  openCanTalkSubscription(data: { mySubscriptions: SubscriptionsNew }): void {
    this.dialog.open(SubscriptionCanTalkDialogComponent, { data });
  }

  openThankYouSubscription(): void {
    this.dialog.open(SubscriptionThankYouDialogComponent);
  }

  openGetDiscountSubscription(): void {
    this.dialog.open(SubscriptionGetDiscountDialogComponent);
  }

  openPayCancelDialog(): void {
    this.dialog.open(SubscriptionPayCancelDialogComponent);
  }

  openPaySuccessDialog(data: { tariffName: string }): void {
    this.dialog.open(SubscriptionPaySuccessDialogComponent, { data });
  }
}
