import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormService } from '../../../../../../../services/form.service';
import { UnsubscriptionReason } from '../../models/subscription.model';
import { SubscriptionsDialogService } from '../services/dialog.service';

@Component({
  selector: 'app-reason-dialog',
  templateUrl: './reason-dialog.component.html',
})
export class ReasonDialogComponent {
  formForm: FormGroup;
  formList: Array<{ label: string; value: UnsubscriptionReason }> = [
    {
      label: "I didn't figure how to make money with the data",
      value: 'reason-1',
    },
    {
      label: "It's too complicated",
      value: 'reason-2',
    },
    {
      label: "I am a day trader, it doesn't work for me",
      value: 'reason-3',
    },
    {
      label: 'I am not really trading/investing right now',
      value: 'reason-4',
    },
    {
      label: 'I think you charge too much',
      value: 'reason-5',
    },
    {
      label: 'I think the data is low quality',
      value: 'reason-6',
    },
  ];

  constructor(
    private dialog: MatDialogRef<ReasonDialogComponent>,
    private formBuilder: FormBuilder,
    private formService: FormService,
    private dialogService: SubscriptionsDialogService,
  ) {
    this.formForm = this.formBuilder.group({
      reason: this.formBuilder.control('', [Validators.required]),
    });
  }

  close(success: boolean = false): void {
    this.dialog.close(success);
  }

  onSubmit(): void {
    this.formForm = this.formService.onFormSubmit(this.formForm);

    if (this.formForm.valid) {
      const reason: UnsubscriptionReason = this.formForm.get('reason')?.value;

      this.formForm.reset();
      this.close();

      switch (reason) {
        case 'reason-1':
        case 'reason-2':
        case 'reason-5':
        case 'reason-6':
          this.dialogService.openConsultationDialog();
          break;
        case 'reason-3':
        case 'reason-4':
          this.dialogService.openDiscountDialog();
          break;
        default:
          console.debug('default switch reason');
          break;
      }
    }
  }
}
