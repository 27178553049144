<div class="header" (click)="!locked && toggle()">
  <div class="title-block">
    <app-icon *ngIf="locked" icon="lock" class="icon" [color]="{}"></app-icon>
    <span class="title">{{ text }}</span>
  </div>
  <app-icon icon="arrow-down"></app-icon>
</div>
<div *ngIf="visible" @scaleY class="flex flex-grow origin-top">
  <ng-content></ng-content>
</div>
