import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Selection } from 'd3';
import * as d3Selection from 'd3-selection';
import {
  CashFlowExposureData,
  CashFlowExposureDataBetTypes,
  CashFlowExposureDataBetTypesSorted,
  CashFlowExposureDataChangeTypeKey,
  CashFlowExposureDataLevels,
  CashFlowExposureDataLocked,
  CashFlowExposureDataPreparation,
} from '../../models/cash-flow-exposure.model';
import { filter, first, from, Subscription } from 'rxjs';
import { MainHttpClient } from '@app/services/main-http-client.service';
import { Router } from '@angular/router';
import * as d3Scale from 'd3-scale';
import { AuthService } from '../../../../auth/services/auth.service';
import { UserTypeModel } from '../../../../shared/models/user-type.model';
import { AnalyticService } from '@app/services/analytic.service';
import { AuthFormService } from '../../../../auth/services/auth-form.service';
import { ExpirationTypeService } from '../../services/expiration-type.service';
import {
  CashFlowExposureTooltipObj,
  ExpTypeKey,
  SvgSetting,
} from '../../models/cash-flow-chart.model';
import { arrow, computePosition, flip, offset } from '@floating-ui/dom';
import { DOCUMENT } from '@angular/common';
import { ExposureChangeTypeService } from '../../services/exposure-change-type.service';
import { ChartService } from '../../services/chart.service';
import { ScaleService } from '../../../../shared/services/scale.service';
import { HttpErrorResponse } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-cash-flow-exposure',
  templateUrl: './cash-flow-exposure.component.html',
})
export class CashFlowExposureComponent implements OnInit, OnDestroy {
  expirationTypeService$!: Subscription;
  cleanChartService$!: Subscription;
  exposureChangeTypeService$!: Subscription;

  // prettier-ignore
  @Input()
  set appCashFlowExposureSettingsSvg(settings: { selector: string; tickerName: string }) {
    this.svgSelector = settings.selector;
    this.cashFlowExposureDataTicker = settings.tickerName;

    this.svg = d3Selection.select(this.svgSelector + ' > ' + this.svgExposureSelector);

    if (this.svg.empty()) {
      this.svg = d3Selection.select(this.svgSelector).append('g').attr('id', this.svgExposureSelector.substring(1));
    }

    /** на загрузку */
    this.checkCashFlowExposureData();
  }

  // prettier-ignore
  @Input()
  set appCashFlowExposureScaleY(svgScaleY: d3Scale.ScaleLinear<number, number>) {
    this.svgScaleY = svgScaleY;
  }

  @Input()
  set appCashFlowExposureTranslateX(svgTranslateX: number) {
    this.svgTranslateX = svgTranslateX;

    /** проверка на догрузку */
    this.checkCashFlowExposureData();
  }

  @Input()
  set appCashFlowExposureDataUpdate(cashFlowChartDataUpdate: boolean) {
    if (cashFlowChartDataUpdate) {
      this.checkCashFlowExposureData();
    }
  }

  @Input()
  set appCashFlowExposureScaleYData(scaleYData: number[]) {
    this.cashFlowExposureScaleYData = scaleYData;
  }

  @Input()
  set appCashFlowExposureSvgSetting(svgSetting: SvgSetting) {
    this.svgSetting = svgSetting;
  }

  // prettier-ignore
  @Output() cashFlowExposureTooltip: EventEmitter<CashFlowExposureTooltipObj[]> = new EventEmitter();

  cashFlowExposureData: CashFlowExposureData;
  cashFlowExposureDataUniqueDate: string[] = [];
  cashFlowExposureScaleYData: number[] = [];

  cashFlowExposureDataInUpdate: boolean = false;
  cashFlowExposureDataDateUpdateLastDirection: 'left' | 'right' = 'left';
  cashFlowExposureDataDateUpdateLeftIsBlocked: boolean = false;
  cashFlowExposureDataDateUpdateRightIsBlocked: boolean = false;
  // @ts-ignore
  cashFlowExposureDataDateUpdateTimeout: NodeJS.Timeout | undefined;

  cashFlowExposureDataTicker!: string; // SPY
  cashFlowExposureDataActiveExpType: ExpTypeKey = 'month';
  cashFlowExposureActivePeriod: string = '1d'; // 1d | 5d | 30d

  // prettier-ignore
  cashFlowExposureLockedList: Array<{ top: number; left: number; date: Date; }> = [];

  svg: Selection<any, any, any, any>;
  svgSetting: SvgSetting;
  svgScaleY: d3Scale.ScaleLinear<number, number>;

  svgBetTypesHeight: number = 6; // height
  svgBetTypesMaxWidth: number = 220;
  svgTranslateX: number = 0;

  svgSelector: string;
  svgExposureSelector: string = '#cash-flow-chart-draggable-exposure';
  // prettier-ignore
  svgExposureSelectorXTickerDays: string = '#cash-flow-chart-draggable-x-ticker > g[data-id="days"]';
  // prettier-ignore
  svgSelectorExposureTooltip: string = '#cash-flow-chart-exposure-tooltip';
  svgSelectorExposureTooltipPopper: any;

  userType: UserTypeModel = UserTypeModel.Unregistered;

  exposureTooltipList: CashFlowExposureTooltipObj[] = [];

  countError: number = 0;

  // @ts-ignore
  timeoutIdBars: NodeJS.Timeout | undefined = undefined;

  constructor(
    @Inject(DOCUMENT)
    private document: Document,
    private http: MainHttpClient,
    private router: Router,
    private analyticService: AnalyticService,
    private authFormService: AuthFormService,
    private authService: AuthService,
    private expirationTypeService: ExpirationTypeService,
    private chartService: ChartService,
    private exposureChangeTypeService: ExposureChangeTypeService,
    private scaleService: ScaleService,
    private cookieService: CookieService,
  ) {}

  // prettier-ignore
  ngOnInit(): void {
    this.authService.getUserType().then((userTypeModel: UserTypeModel) => {
      this.userType = userTypeModel;
    });

    /** переключение тикера */
    this.cleanChartService$ = this.chartService.cleanSubject
      .pipe(filter((clean: boolean) => clean))
      .subscribe(() => {
        /** чистим для отрисовки заново */
        this.cleanSvg();
      });

    /** переключение зума */
    this.expirationTypeService$ = this.expirationTypeService.currentExpTypeSubject
      .pipe(filter((expTypeKey: ExpTypeKey) => this.cashFlowExposureDataActiveExpType !== expTypeKey))
      .subscribe((expTypeKey: ExpTypeKey) => {
        this.cashFlowExposureDataActiveExpType = expTypeKey;

        /** чистим для отрисовки заново */
        this.cleanSvg();
      });

    /** переключение периода индикатора и запуск отрисовки */
    this.exposureChangeTypeService$ = this.exposureChangeTypeService.currentExposureChangeTypeSubject
      .pipe(filter((changeTypeKey: CashFlowExposureDataChangeTypeKey) => this.cashFlowExposureActivePeriod !== changeTypeKey))
      .subscribe((changeTypeKey: CashFlowExposureDataChangeTypeKey) => {
        this.cashFlowExposureActivePeriod = changeTypeKey;

        this.preparationData(true); // true - update bets & levels
      });
  }

  ngOnDestroy(): void {
    [this.expirationTypeService$, this.exposureChangeTypeService$].forEach(
      ($) => $?.unsubscribe(),
    );
  }

  /** удаляем ненужное для отрисовки заново */
  // prettier-ignore
  cleanSvg(): void {
    /** удаляем данные */
    this.cashFlowExposureData = {} as CashFlowExposureData;

    /** убираем блокировки */
    this.cashFlowExposureDataDateUpdateLeftIsBlocked = false;
    this.cashFlowExposureDataDateUpdateRightIsBlocked = false;

    /** удаляем LockedList */
    this.cashFlowExposureLockedList = [];

    /** удаляем Bets & Levels */
    d3Selection.selectAll(this.svgExposureSelector + '> g').remove();
  }

  // prettier-ignore
  setSvgExposureBetsTooltipEvents(): void {
    const tooltip: HTMLElement | null = this.document.querySelector(this.svgSelectorExposureTooltip);
    const tooltipArrow: HTMLElement | null = this.document.querySelector(this.svgSelectorExposureTooltip + ' > .arrow');
    const tooltipContext: HTMLElement | null = this.document.querySelector(this.svgSelectorExposureTooltip + ' > .context');

    /** Сначала чистим слушателей, потом добавляем заново */
    d3Selection
      .select(this.svgExposureSelector)
      .selectAll('g[data-strike]')
      .on('mouseover', null)
      .on('mouseout', null)
      .on('mouseover', (event: any) => {
        const parent: any = d3Selection.select(event.target.parentElement);
        const strike: number = Number(event.target.parentElement.dataset.strike);

        const betsData: CashFlowExposureDataBetTypesSorted = parent.data()[0].betTypesSorted.filter((bets: CashFlowExposureDataBetTypes) => bets.strike === strike)[0];

        if (betsData && tooltip && tooltipArrow && tooltipContext) {
          this.svgSelectorExposureTooltipPopper = computePosition(
            event.target,
            tooltip,
            {
              middleware: [
                flip(),
                offset(18.5),
                arrow({
                  element: tooltipArrow,
                }),
              ],
              placement: 'right',
            },
          ).then(({ x, y, middlewareData }) => {
            if (middlewareData.arrow) {
              const { x, y } = middlewareData.arrow;

              Object.assign(tooltipArrow.style, {
                left: x != null ? `${x}px` : '',
                top: y != null ? `${y}px` : '',
              });
            }

            Object.assign(tooltip.style, {
              left: `${x}px`,
              top: `${y}px`,
            });
          });

          /** устанавливаем значения для тултипа и передаем наверх */
          this.setTooltip(betsData, null);

          tooltip.classList.remove('!hidden');

          const parentTranslateX: number = parent.attr('x') * -0.25;
          const parentTranslateY: number = parent.attr('y') * -1 - 6;

          parent
            .transition()
            .duration(200)
            .attr('transform', 'translate(' + parentTranslateX + ',' + parentTranslateY + ') scale(1.25, 2)');
        }

        /** Отправка метрики, если смотришь на тултип дольше 3-х сек. */
        this.timeoutIdBars = setTimeout(() => {
          this.analyticService.send('stock_chart_bars_view');
        }, 3000);
      })
      .on('mouseout', (event: any) => {
        const parent: any = d3Selection.select(event.target.parentElement);

        if (tooltip && tooltipContext) {
          tooltip.classList.add('!hidden');

          parent.transition().duration(200).attr("transform", "translate(0,0) scale(1)");
        }

        /** Прерывание отправки метрик */
        if (this.timeoutIdBars) {
          clearTimeout(this.timeoutIdBars);
          this.timeoutIdBars = undefined;
        }
      });
  }

  // prettier-ignore
  setSvgExposureLevelsTooltipEvents(): void {
    const tooltip: HTMLElement | null = this.document.querySelector(this.svgSelectorExposureTooltip);
    const tooltipArrow: HTMLElement | null = this.document.querySelector(this.svgSelectorExposureTooltip + ' > .arrow');
    const tooltipContext: HTMLElement | null = this.document.querySelector(this.svgSelectorExposureTooltip + ' > .context');

    /** Сначала чистим слушателей, потом добавляем заново */
    d3Selection
      .select(this.svgExposureSelector)
      .selectAll('g[data-line]')
      .on('mouseover', null)
      .on('mouseout', null)
      .on('mouseover', (event: any) => {
        const nameLine: string = event.target.parentElement.dataset.line;
        const level: any = d3Selection.select(event.target.parentElement);
        const levels: CashFlowExposureDataLevels[] = level.data()[0].levels;
        const context: CashFlowExposureDataLevels = levels.filter((level) => nameLine === level.ivLevel)[0];

        const parent: any = d3Selection.select(event.target.parentElement.parentElement);
        const grow: any = parent.select('rect[data-grow-bets]');
        const decline: any = parent.select('rect[data-decline-bets]');

        const betsData: CashFlowExposureDataBetTypes = this.cashFlowExposureData.betTypes.filter((bets: CashFlowExposureDataBetTypes) => bets.strike === context.strike && bets.date === context.date)[0];

        const isTextNode: boolean = event.target.nodeName === 'text';

        if (betsData && tooltip && tooltipArrow && tooltipContext) {
          this.svgSelectorExposureTooltipPopper = computePosition(
            event.target,
            tooltip,
            {
              middleware: [
                flip(),
                offset(18.5),
                arrow({
                  element: tooltipArrow,
                }),
              ],
              placement: 'right',
            },
          ).then(({ x, y, middlewareData }) => {
            if (middlewareData.arrow) {
              const { x, y } = middlewareData.arrow;

              Object.assign(tooltipArrow.style, {
                left: x != null ? `${x}px` : '',
                top: y != null ? `${y}px` : '',
              });
            }

            Object.assign(tooltip.style, {
              left: `${x + (isTextNode ? 5 : 0) }px`,
              top: `${y}px`,
            });
          });

          /** устанавливаем значения для тултипа и передаем наверх */
          this.setTooltip({
            strike: betsData.strike,
            main1d: betsData,
            main5d: betsData,
            main30d: betsData,
            tooltip1d: [betsData],
            tooltip5d: [betsData],
            tooltip30d: [betsData],
          }, context.ivLevel);

          tooltip.classList.remove('!hidden');

          grow.attr('opacity', 1);
          decline.attr('opacity', 1);
        }
      })
      .on('mouseout', (event: any) => {
        const parent: any = d3Selection.select(event.target.parentElement.parentElement);
        const grow: any = parent.select('rect[data-grow-bets]');
        const decline: any = parent.select('rect[data-decline-bets]');

        if (tooltip && tooltipContext) {
          tooltip.classList.add('!hidden');

          grow.attr('opacity', 0.5);
          decline.attr('opacity', 0.5);
        }
      });
  }

  // prettier-ignore
  setTooltip(betsDataSorted: CashFlowExposureDataBetTypesSorted, ivLevel: string | null): void {
    this.exposureTooltipList = [];

    const maxWidthBar: number = 176;

    // @ts-ignore
    const betsTypeList: CashFlowExposureDataBetTypes[] = betsDataSorted['tooltip' + this.cashFlowExposureActivePeriod];

    let maxNumberList: number[] = [];

    betsTypeList.forEach((betsTypeData: CashFlowExposureDataBetTypes) => {
      const maxNumber: number = Math.max(
        Math.abs(betsTypeData.growthOiSum),
        // @ts-ignore
        Math.abs(betsTypeData.growthOiSum) + Math.abs(betsTypeData['growthBets' + this.cashFlowExposureActivePeriod]),
        Math.abs(betsTypeData.declineOiSum),
        // @ts-ignore
        Math.abs(betsTypeData.declineOiSum) + Math.abs(betsTypeData['declineBets' + this.cashFlowExposureActivePeriod])
      );

      maxNumberList.push(maxNumber);
    });

    betsTypeList.forEach((betsTypeData: CashFlowExposureDataBetTypes) => {
      const maxNumber: number = Math.max(...maxNumberList);
      const multiplier: number = maxWidthBar / maxNumber;

      // @ts-ignore
      const growPercentWidth: number = multiplier * Math.abs(betsTypeData['growthBets' + this.cashFlowExposureActivePeriod])
      // @ts-ignore
      const declinePercentWidth: number = multiplier * Math.abs(betsTypeData['declineBets' + this.cashFlowExposureActivePeriod])

      this.exposureTooltipList.push({
        ivLevel,
        winRate: betsTypeData.winRate,
        strike: betsTypeData.strike,
        growSum: Math.abs(betsTypeData.growthOiSum),
        growSumWidth: multiplier * Math.abs(betsTypeData.growthOiSum) > 4 ? multiplier * Math.abs(betsTypeData.growthOiSum) : 4,
        // @ts-ignore
        growPercentWidth: growPercentWidth > 4 ? growPercentWidth : 4,
        // @ts-ignore
        growPercent: betsTypeData['growthBets' + this.cashFlowExposureActivePeriod + 'PerOiSumPercent'],
        declineSumWidth: multiplier * Math.abs(betsTypeData.declineOiSum) > 4 ? multiplier * Math.abs(betsTypeData.declineOiSum) : 4,
        declineSum: Math.abs(betsTypeData.declineOiSum),
        // @ts-ignore
        declinePercentWidth: declinePercentWidth > 4 ? declinePercentWidth : 4,
        // @ts-ignore
        declinePercent: betsTypeData['declineBets' + this.cashFlowExposureActivePeriod + 'PerOiSumPercent'],
        period: this.cashFlowExposureActivePeriod
      });
    });

    this.cashFlowExposureTooltip.emit(this.exposureTooltipList);
  }

  /** LABELS */
  // prettier-ignore
  setSvgLabel(exposureData: CashFlowExposureDataPreparation): void {
    let betTypesParent = () => d3Selection.select(this.svgExposureSelector);

    const labelParent = () => betTypesParent().select('g[data-date="' + exposureData.date + '"] > g[data-name="label"]');

    if (labelParent().empty()) {
      betTypesParent().select('g[data-date="' + exposureData.date + '"]').append('g').attr('data-name', 'label');
    }

    if (labelParent().select('rect').empty()) {
      const monthNames: string[] = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
      const strike: number = Math.max(
        ...exposureData.betTypesSorted.map((bets) => bets.strike),
        ...exposureData.locked.map((locked) => locked.strike)
      );

      let text: string = ((): string => {
        if (this.cashFlowExposureDataActiveExpType === 'week') {
          const currentDay: Date = new Date(exposureData.date);
          const nextDay: Date = new Date(new Date(exposureData.date).getTime() + (6 * 86400000));

          const isDifferentMonth: boolean = new Date(currentDay).getMonth() !== new Date(nextDay).getMonth()

          // prettier-ignore
          return `${currentDay.getDate()} ${isDifferentMonth ? monthNames[new Date(currentDay).getMonth()].substring(0, 3) : ''} - ${nextDay.getDate()} ${monthNames[new Date(nextDay).getMonth()].substring(0, 3)}`;
        }

        if (this.cashFlowExposureDataActiveExpType === 'month') {
          return monthNames[new Date(exposureData.date).getMonth()];
        }

        if (this.cashFlowExposureDataActiveExpType === 'quarter') {
          if (new Date(exposureData.date).getMonth() < 3) {
            return new Date(exposureData.date).getMonth() === 0 ? 'Annual' : 'Q1';
          }
          if (new Date(exposureData.date).getMonth() < 6) {
            return 'Q2';
          }
          if (new Date(exposureData.date).getMonth() < 9) {
            return 'Q3';
          }
          if (new Date(exposureData.date).getMonth() > 8) {
            return 'Q4';
          }

          return '';
        }

        return '';
      })();

      labelParent()
        .append('rect')
        .attr('fill', '#F1F5F9')
        .attr('class', 'badge-shadow-svg')
        .attr('opacity', 0.9)
        .attr('x', exposureData.x)
        .attr('y', this.svgScaleY(strike) - 32)
        .attr('width', 0)
        .attr('height', 20)
        .attr("rx", 8);

      labelParent()
        .append('text')
        // @ts-ignore
        .attr('x', exposureData.x + 6)
        .attr('y', this.svgScaleY(strike) - 18)
        .attr('opacity', 0)
        .style('fill', '#393939')
        .style('font-family', 'B612 Mono')
        .style('font-size', '10px')
        .style('font-weight', 400)
        .style('line-height', '12px')
        .text(text + ' Exposure');

      const textElement: Element | null = this.document.querySelector(this.svgExposureSelector + ' > g[data-date="' + exposureData.date + '"] > g[data-name="label"] > text');

      /** берем ширину текста и сетим ректанглу */
      if (textElement) {
        const width: number = textElement.getBoundingClientRect().width + (textElement.getBoundingClientRect().width * (1 - this.scaleService.currentScaleSubject.value));

        const isLocked: boolean = exposureData.locked.filter((locked: CashFlowExposureDataLocked): boolean => new Date(locked.date).getTime() === new Date(exposureData.date).getTime()).length !== 0;

        labelParent()
          .select('rect')
          // @ts-ignore
          .attr('x', isLocked ? exposureData.x - width / 2 : exposureData.x)
          .transition()
          .duration(500)
          .attr('width', width + 12);

        labelParent()
          .select('text')
          // @ts-ignore
          .attr('x', isLocked ? (exposureData.x + 6) - width / 2 : exposureData.x + 6)
          .transition()
          .duration(700)
          .attr('opacity', 1);
      }
    }
  }

  /** LOCKED */
  // prettier-ignore
  setSvgLocked(exposureData: CashFlowExposureDataPreparation): void {
    const strike: number = Math.max(
      ...exposureData.betTypesSorted.map((bets: CashFlowExposureDataBetTypesSorted) => bets.strike),
      ...exposureData.locked.map((locked: CashFlowExposureDataLocked) => locked.strike)
    );
    const top: number = this.svgScaleY(strike) - 2;

    const left: number = exposureData.x ? exposureData.x - 119 : 0; // 119 - half width of block locked

    const isIncludeInList: boolean = this.cashFlowExposureLockedList.filter((locked: {top: number, left: number, date: Date}): boolean => locked.date.getTime() === new Date(exposureData.date).getTime()).length !== 0;
    const isIncludeInLocked: boolean = exposureData.locked.filter((locked:  CashFlowExposureDataLocked): boolean => new Date(locked.date).getTime() === new Date(exposureData.date).getTime()).length !== 0;

    if (!isIncludeInList && isIncludeInLocked) {
      this.cashFlowExposureLockedList.push({top, left, date: new Date(exposureData.date)});
    }
  }

  /** BETS */
  // prettier-ignore
  setSvgBetTypes(exposureData: CashFlowExposureDataPreparation, update: boolean = false): void {
    let betTypesParent = () => d3Selection.select(this.svgExposureSelector);

    const reserveWidth: number = 1000;
    const [startX, endX] = this.getVisibleArea();

    if (exposureData.x && exposureData.x > (startX - reserveWidth) && exposureData.x < (endX + reserveWidth)) {
      const isCreated: boolean = !betTypesParent().select('g[data-date="' + exposureData.date + '"]').empty();

      if (!isCreated) {
        betTypesParent()
          .append('g')
          .attr('data-date', exposureData.date)
          .data([exposureData]);
      }

      exposureData.betTypesSorted.forEach((line, index: number) => {
        setTimeout(() => {
          // @ts-ignore
          if (exposureData.x && line['main' + this.cashFlowExposureActivePeriod]) {
            /** GROW */

            /** Sum */
            const growSumRect = () => betTypesParent()
              .select('g[data-date="' + exposureData.date + '"] > g[data-strike="' + line.strike + '"] > rect[name="grow-sum"]');

            // @ts-ignore
            const growSumWidth: number = exposureData['multiplier' + this.cashFlowExposureActivePeriod] * Math.abs(line['main' + this.cashFlowExposureActivePeriod].growthOiSum);

            if (growSumRect().empty()) {
              /** create */
              betTypesParent()
                .select('g[data-date="' + exposureData.date + '"]')
                .append('g')
                .attr('data-strike', line.strike)
                .attr('x', exposureData.x)
                .attr('y', this.svgScaleY(line.strike) - this.svgBetTypesHeight)
                .append('rect')
                .data([line])
                // @ts-ignore
                .attr('data-grow-sum', line['main' + this.cashFlowExposureActivePeriod].growthOiSum)
                .attr('name', 'grow-sum')
                .attr('fill', '#41B1A6')
                .attr('opacity', 0.7)
                .attr('x', exposureData.x)
                .attr('y', this.svgScaleY(line.strike) - this.svgBetTypesHeight)
                .attr('width', 0)
                .attr('height', this.svgBetTypesHeight)
                .style('cursor', 'pointer')
                .transition()
                .duration(500)
                .delay(300)
                .attr('width', growSumWidth < 4 ? 4 : growSumWidth);
            } else {
              /** update */
              if (update) {
                growSumRect()
                  .transition()
                  .duration(500)
                  .attr('x', exposureData.x)
                  .attr('y', this.svgScaleY(line.strike) - this.svgBetTypesHeight)
                  .attr('width', growSumWidth < 4 ? 4 : growSumWidth)
                  .attr('height', this.svgBetTypesHeight);
              }
            }

            /** Bets */
            const growBetsRect = () => betTypesParent()
              .select('g[data-date="' + exposureData.date + '"] > g[data-strike="' + line.strike + '"] > rect[name="grow-bets"]');

            // @ts-ignore
            let growBets: number = line['main' + this.cashFlowExposureActivePeriod]['growthBets' + this.cashFlowExposureActivePeriod];

            if (growBetsRect().empty()) {
              /** create */
              betTypesParent()
                .select('g[data-date="' + exposureData.date + '"] > g[data-strike="' + line.strike + '"]')
                .append('rect')
                .attr('data-grow-bets', growBets)
                .attr('name', 'grow-bets')
                .attr('fill', '#41B1A6')
                .attr('opacity', 0.3)
                .data([line])
                .attr('x', exposureData.x + (growSumWidth < 4 ? 4 : growSumWidth))
                .attr('y', this.svgScaleY(line.strike) - this.svgBetTypesHeight)
                .attr('width', 0)
                .attr('height', this.svgBetTypesHeight)
                .style('cursor', 'pointer')
                .transition()
                .duration(500)
                .delay(300)
                .attr('width', () => {
                  // @ts-ignore
                  const growBetsWidth: number = exposureData['multiplier' + this.cashFlowExposureActivePeriod] * Math.abs(growBets);

                  return growBetsWidth < 4 ? !!growBets ? 4 : 0 : growBetsWidth;
                });
            } else {
              /** update */
              if (update) {
                growBetsRect()
                  .transition()
                  .duration(500)
                  .attr('opacity', 0.3)
                  .attr('x', exposureData.x + (growSumWidth < 4 ? 4 : growSumWidth))
                  .attr('y', this.svgScaleY(line.strike) - this.svgBetTypesHeight)
                  .attr('width', () => {
                    // @ts-ignore
                    const growBetsWidth: number = exposureData['multiplier' + this.cashFlowExposureActivePeriod] * Math.abs(growBets);

                    return growBetsWidth < 4 ? !!growBets ? 4 : 0 : growBetsWidth;
                  })
                  .attr('height', this.svgBetTypesHeight);
              }
            }

            /** ШТРИХ */
            const streakGrow = () => betTypesParent()
              .select('g[data-date="' + exposureData.date + '"] > g[data-strike="' + line.strike + '"] > rect#rect-strokes-grow-' + exposureData.date + '_' + index);

            if (growBets <= 0) {
              if (streakGrow().empty()) {
                /** create */
                betTypesParent()
                  .select('g[data-date="' + exposureData.date + '"] > g[data-strike="' + line.strike + '"]')
                  .append('rect')
                  .attr('id', 'rect-strokes-grow-' + exposureData.date + '_' + index)
                  .attr('x', exposureData.x + (growSumWidth < 4 ? 4 : growSumWidth))
                  .attr('y', this.svgScaleY(line.strike) - this.svgBetTypesHeight)
                  .attr('width', 0)
                  .attr('height', this.svgBetTypesHeight)
                  .style('cursor', 'pointer')
                  .attr('fill', 'url(#pattern-strokes-grow-' + exposureData.date + '_' + index + ')')
                  .attr('fill-opacity', 0.4)
                  .transition()
                  .duration(500)
                  .delay(300)
                  .attr('width', () => {
                    // @ts-ignore
                    const growBetsWidth: number = exposureData['multiplier' + this.cashFlowExposureActivePeriod] * Math.abs(growBets);

                    return growBetsWidth < 4 ? !!growBets ? 4 : 0 : growBetsWidth;
                  });

                betTypesParent()
                  .select('g[data-date="' + exposureData.date + '"] > g[data-strike="' + line.strike + '"]')
                  .append('defs')
                  .attr('id', 'defs-strokes-grow-' + exposureData.date + '_' + index)
                  .append('pattern')
                  .attr('id', 'pattern-strokes-grow-' + exposureData.date + '_' + index)
                  .attr('width', 6)
                  .attr('height', 6)
                  .attr('patternUnits', 'userSpaceOnUse')
                  .attr('patternTransform', 'rotate(45 50 50), scale(0.4)')
                  .append('line')
                  .attr('stroke', '#000')
                  .attr('stroke-width', '2px')
                  .attr('y2', 8);
              } else {
                /** update */
                if (update) {
                  streakGrow()
                    .transition()
                    .duration(500)
                    .attr('x', exposureData.x + (growSumWidth < 4 ? 4 : growSumWidth))
                    .attr('y', this.svgScaleY(line.strike) - this.svgBetTypesHeight)
                    .attr('width', () => {
                      // @ts-ignore
                      const growBetsWidth: number = exposureData['multiplier' + this.cashFlowExposureActivePeriod] * Math.abs(growBets);

                      return growBetsWidth < 4 ? !!growBets ? 4 : 0 : growBetsWidth;
                    })
                    .attr('height', this.svgBetTypesHeight)
                    .attr('fill', 'url(#pattern-strokes-grow-' + exposureData.date + '_' + index + ')')
                    .attr('fill-opacity', 0.4);
                }
              }

            } else {
              /** hide */
              streakGrow()
                .attr('x', exposureData.x + (growSumWidth < 4 ? 4 : growSumWidth))
                .attr('y', this.svgScaleY(line.strike) - this.svgBetTypesHeight)
                .attr('width', 0)
                .attr('height', this.svgBetTypesHeight)
                .attr('fill-opacity', 0);
            }

            /** DECLINE */

            /** Sum */
            const declineSumRect = () => betTypesParent()
              .select('g[data-date="' + exposureData.date + '"] > g[data-strike="' + line.strike + '"] > rect[name="decline-sum"]');

            // @ts-ignore
            const declineSumWidth: number = exposureData['multiplier' + this.cashFlowExposureActivePeriod] * Math.abs(line['main' + this.cashFlowExposureActivePeriod].declineOiSum);

            if (declineSumRect().empty()) {
              /** create */
              betTypesParent()
                .select('g[data-date="' + exposureData.date + '"] > g[data-strike="' + line.strike + '"]')
                .append('rect')
                .data([line])
                // @ts-ignore
                .attr('data-decline-sum', line['main' + this.cashFlowExposureActivePeriod].declineOiSum)
                .attr('name', 'decline-sum')
                .attr('fill', '#F16D6A')
                .attr('opacity', 0.7)
                .attr('x', exposureData.x)
                .attr('y', this.svgScaleY(line.strike))
                .attr('width', 0)
                .attr('height', this.svgBetTypesHeight)
                .style('cursor', 'pointer')
                .transition()
                .duration(500)
                .delay(300)
                .attr('width', declineSumWidth < 4 ? 4 : declineSumWidth);
            } else {
              /** update */
              if (update) {
                declineSumRect()
                  .transition()
                  .duration(500)
                  .attr('x', exposureData.x)
                  .attr('y', this.svgScaleY(line.strike))
                  .attr('width', declineSumWidth < 4 ? 4 : declineSumWidth)
                  .attr('height', this.svgBetTypesHeight);
              }
            }

            /** Bets */
            const declineBetsRect = () => betTypesParent()
              .select('g[data-date="' + exposureData.date + '"] > g[data-strike="' + line.strike + '"] > rect[name="decline-bets"]');

            // @ts-ignore
            let declineBets: number = line['main' + this.cashFlowExposureActivePeriod]['declineBets' + this.cashFlowExposureActivePeriod];

            if (declineBetsRect().empty()) {
              /** create */
              betTypesParent()
                .select('g[data-date="' + exposureData.date + '"] > g[data-strike="' + line.strike + '"]')
                .append('rect')
                .data([line])
                .attr('data-decline-bets', declineBets)
                .attr('name', 'decline-bets')
                .attr('fill', '#F16D6A')
                .attr('opacity', 0.3)
                .attr('x', exposureData.x + (declineSumWidth < 4 ? 4 : declineSumWidth))
                .attr('y', this.svgScaleY(line.strike))
                .attr('width', 0)
                .attr('height', this.svgBetTypesHeight)
                .style('cursor', 'pointer')
                .transition()
                .duration(500)
                .delay(300)
                .attr('width', () => {
                  // @ts-ignore
                  const width: number = exposureData['multiplier' + this.cashFlowExposureActivePeriod] * Math.abs(declineBets);

                  return width < 4 ? !!declineBets ? 4 : 0 : width;
                });
            } else {
              /** update */
              if (update) {
                declineBetsRect()
                  .transition()
                  .duration(500)
                  .attr('opacity', 0.3)
                  .attr('x', exposureData.x + (declineSumWidth < 4 ? 4 : declineSumWidth))
                  .attr('y', this.svgScaleY(line.strike))
                  .attr('width', () => {
                    // @ts-ignore
                    const width: number = exposureData['multiplier' + this.cashFlowExposureActivePeriod] * Math.abs(declineBets);

                    return width < 4 ? !!declineBets ? 4 : 0 : width;
                  })
                  .attr('height', this.svgBetTypesHeight);
              }
            }

            /** ШТРИХ */
            const streakDecline = () => betTypesParent()
              .select('g[data-date="' + exposureData.date + '"] > g[data-strike="' + line.strike + '"] > rect#rect-strokes-decline-' + exposureData.date + '_' + index);

            if (declineBets <= 0) {
              if (streakDecline().empty()) {
                /** create */
                betTypesParent()
                  .select('g[data-date="' + exposureData.date + '"] > g[data-strike="' + line.strike + '"]')
                  .append('rect')
                  .attr('id', 'rect-strokes-decline-' + exposureData.date + '_' + index)
                  .attr('x', exposureData.x + (declineSumWidth < 4 ? 4 : declineSumWidth))
                  .attr('y', this.svgScaleY(line.strike))
                  .attr('width', 0)
                  .attr('height', this.svgBetTypesHeight)
                  .style('cursor', 'pointer')
                  .attr('fill', 'url(#pattern-strokes-decline-' + exposureData.date + '_' + index + ')')
                  .attr('fill-opacity', 0.4)
                  .transition()
                  .duration(500)
                  .delay(300)
                  .attr('width', () => {
                    // @ts-ignore
                    const width: number = exposureData['multiplier' + this.cashFlowExposureActivePeriod] * Math.abs(declineBets);

                    return width < 4 ? !!declineBets ? 4 : 0 : width;
                  });

                betTypesParent()
                  .select('g[data-date="' + exposureData.date + '"] > g[data-strike="' + line.strike + '"]')
                  .append('defs')
                  .attr('id', 'defs-strokes-decline-' + exposureData.date + '_' + index)
                  .append('pattern')
                  .attr('id', 'pattern-strokes-decline-' + exposureData.date + '_' + index)
                  .attr('width', 6)
                  .attr('height', 6)
                  .attr('patternUnits', 'userSpaceOnUse')
                  .attr('patternTransform', 'rotate(45 50 50), scale(0.4)')
                  .append('line')
                  .attr('stroke', '#000')
                  .attr('stroke-width', '2px')
                  .attr('y2', 8);
              } else {
                /** update */
                if (update) {
                  streakDecline()
                    .transition()
                    .duration(500)
                    .attr('x', exposureData.x + (declineSumWidth < 4 ? 4 : declineSumWidth))
                    .attr('y', this.svgScaleY(line.strike))
                    .attr('width', () => {
                      // @ts-ignore
                      const width: number = exposureData['multiplier' + this.cashFlowExposureActivePeriod] * Math.abs(declineBets);

                      return width < 4 ? !!declineBets ? 4 : 0 : width;
                    })
                    .attr('height', this.svgBetTypesHeight)
                    .attr('fill', 'url(#pattern-strokes-decline-' + exposureData.date + '_' + index + ')')
                    .attr('fill-opacity', 0.4);
                }
              }
            } else {
              /** hide */
              streakDecline()
                .attr('x', exposureData.x + (declineSumWidth < 4 ? 4 : declineSumWidth))
                .attr('y', this.svgScaleY(line.strike))
                .attr('width', 0)
                .attr('height', this.svgBetTypesHeight)
                .attr('fill-opacity', 0);
            }
          }
        }, update ? 4 : 0);
      });
    } else {
      betTypesParent().selectAll('g[data-date="' + exposureData.date + '"] > g[data-strike]').remove();
    }
  }

  /** LEVELS */
  // prettier-ignore
  setSvgLevels(exposureData: CashFlowExposureDataPreparation, update: boolean = false): void {
    let betTypesParent = () => d3Selection.select(this.svgExposureSelector);

    const reserveWidth: number = 1000;
    const [startX, endX] = this.getVisibleArea();

    if (exposureData.x && exposureData.x > (startX - reserveWidth) && exposureData.x < (endX + reserveWidth)) {
      exposureData.levels.forEach((line) => {
        if (exposureData.x) {
          /** LINE */

          const levelLineParent = () => betTypesParent().select('g[data-date="' + exposureData.date + '"] > g[data-strike="' + line.strike + '"]');

          if (levelLineParent().empty()) {
            betTypesParent().select('g[data-date="' + exposureData.date + '"]').append('g').attr('data-strike', line.strike).data([exposureData]);
          }

          const levelLine = () => betTypesParent()
            .select('g[data-date="' + exposureData.date + '"] > g[data-strike="' + line.strike + '"] > g[data-line="' + line.ivLevel + '"] > line');

          if (levelLine().empty()) {
            /** create */
            betTypesParent()
              .select('g[data-date="' + exposureData.date + '"] > g[data-strike="' + line.strike + '"]')
              .append('g')
              .attr('data-line', line.ivLevel)
              .append('line')
              .attr('stroke', line.ivLevel === 'Resistance' ? '#F16D6A' : '#41B1A6')
              .attr('stroke-width', 2)
              .attr('x1', exposureData.x)
              .attr('x2', exposureData.x)
              .attr('y1', this.svgScaleY(line.strike))
              .attr('y2', this.svgScaleY(line.strike))
              .style('cursor', 'pointer')
              .transition()
              .duration(500)
              .delay(300)
              .attr('x2', exposureData.x + 242);
          } else {
            /** update */
            if (update) {
              levelLine()
                .transition()
                .duration(500)
                .attr('stroke', line.ivLevel === 'Resistance' ? '#F16D6A' : '#41B1A6')
                .attr('stroke-width', 2)
                .attr('x1', exposureData.x)
                .attr('x2', exposureData.x + 242)
                .attr('y1', this.svgScaleY(line.strike))
                .attr('y2', this.svgScaleY(line.strike));
            }
          }

          /** CIRCLE */

          const levelCircle = () => betTypesParent()
            .select('g[data-date="' + exposureData.date + '"] > g[data-strike="' + line.strike + '"] > g[data-line="' + line.ivLevel + '"] > circle');

          if (levelCircle().empty()) {
            /** create */
            betTypesParent()
              .select('g[data-date="' + exposureData.date + '"] > g[data-strike="' + line.strike + '"] > g[data-line="' + line.ivLevel + '"]')
              .append('circle')
              .attr('fill', line.ivLevel === 'Resistance' ? '#F16D6A' : '#41B1A6')
              .attr('r', 0)
              .attr('cx', exposureData.x)
              .attr('cy', this.svgScaleY(line.strike))
              .style('cursor', 'pointer')
              .transition()
              .duration(500)
              .delay(300)
              .attr('r', 8)
              .attr('cx', exposureData.x + 234);
          } else {
            /** update */
            if (update) {
              levelCircle()
                .transition()
                .duration(500)
                .attr('fill', line.ivLevel === 'Resistance' ? '#F16D6A' : '#41B1A6')
                .attr('r', 8)
                .attr('cx', exposureData.x + 234)
                .attr('cy', this.svgScaleY(line.strike));
            }
          }

          /** TEXT */

          const levelText = () => betTypesParent()
            .select('g[data-date="' + exposureData.date + '"] > g[data-strike="' + line.strike + '"] > g[data-line="' + line.ivLevel + '"] > text');

          if (levelText().empty()) {
            /** create */
            betTypesParent()
              .select('g[data-date="' + exposureData.date + '"] > g[data-strike="' + line.strike + '"] > g[data-line="' + line.ivLevel + '"]')
              .append('text')
              .attr('x', exposureData.x + 234)
              .attr('y', this.svgScaleY(line.strike) + 3)
              .attr('opacity', 0)
              .attr('text-anchor', 'middle')
              .style('fill', '#FFFFFF')
              .style('font-family', 'B612 Mono')
              .style('font-size', '9px')
              .style('font-weight', 700)
              .style('line-height', 9)
              .style('cursor', 'pointer')
              .text(line.ivLevel[0])
              .transition()
              .duration(500)
              .delay(300)
              .attr('opacity', 1);
          } else {
            /** update */
            if (update) {
              levelText()
                .transition()
                .duration(500)
                .attr('x', exposureData.x + 234)
                .attr('y', this.svgScaleY(line.strike) + 3)
                .style('fill', '#FFFFFF')
                .attr('opacity', 1);
            }
          }
        }
      });
    }
  }

  /** запрос к бэку за данными, старт отрисовки и запуск проверки на догрузку */
  // prettier-ignore
  getCashFlowExposureData(dateFrom?: Date): void {
    this.cashFlowExposureDataInUpdate = true;

    const body: any = {
      ticker: this.cashFlowExposureDataTicker,
      expType: this.cashFlowExposureDataActiveExpType,
    };

    const nowMilliseconds: number = new Date().getTime();
    const monthMilliseconds: number = 24 * 60 * 60 * 1000 * 30; // month in milliseconds;

    if (!dateFrom) {
      dateFrom = new Date(nowMilliseconds - monthMilliseconds);
    }

    body.dateFrom = dateFrom.toJSON().slice(0, 10);

    from(this.http.post<{ projection: CashFlowExposureData }>('/stock-page/projection', body))
      .pipe(first())
      .subscribe({
        next: (cashFlowExposureData: { projection: CashFlowExposureData }) => {
          this.cashFlowExposureDataInUpdate = false;

          // @ts-ignore
          if (body.expType === this.cashFlowExposureDataActiveExpType && this.document.querySelector('#cash-flow-chart > svg')?.dataset.tickerName === this.cashFlowExposureDataTicker ) {
            if (this.cashFlowExposureData && dateFrom) {
              /** Обновляемся */
              this.updateCashFlowExposureData(cashFlowExposureData.projection);
            } else {
              this.cashFlowExposureData = cashFlowExposureData.projection;
            }

            /** подготовка и отрисовка данных */
            this.preparationData();

            /** проверка на догрузку */
            this.checkCashFlowExposureData();
          }
        },
        complete: () => (this.cashFlowExposureDataInUpdate = false),
        error: (error: HttpErrorResponse) => {
          if (error.status === 500 && this.countError < 3) {
            this.countError++;

            this.getCashFlowExposureData(dateFrom);
          }
          console.error(error);
        },
      });
  }

  /** подготовка данных и запуск отрисовки */
  // prettier-ignore
  preparationData(update: boolean = false): void {
    const minPrice: number = Math.min(...this.cashFlowExposureScaleYData);
    const maxPrice: number = Math.max(...this.cashFlowExposureScaleYData);
    const step: number = (maxPrice - minPrice) / 26 // "26" - допустимое кол-во баров на всю высоту

    const getDayData = (date: string): CashFlowExposureDataPreparation => {
      let betTypesSorted: CashFlowExposureDataBetTypesSorted[] = [];
      let sortedBetTypeList: CashFlowExposureDataBetTypes[][] = [];

      const day = d3Selection.select(this.svgExposureSelectorXTickerDays).select('text[data-date="' + date + '"]').node();

      const betTypes: CashFlowExposureDataBetTypes[] = this.cashFlowExposureData.betTypes.filter(data => date === data.date);
      const levels: CashFlowExposureDataLevels[] = this.cashFlowExposureData.levels.filter(data => date === data.date);
      const locked: CashFlowExposureDataLocked[] = this.cashFlowExposureData.locked.filter(data => date === data.date);

      for (let i: number = 0; i <= 25; i++) {
        const isFirst: boolean = i === 0;
        const isLast: boolean = i === 25;

        sortedBetTypeList.push(
          betTypes.filter((betType: CashFlowExposureDataBetTypes) => {
            if (isFirst) {
              return betType.strike > maxPrice - step * i - step / 2;
            }

            if (isLast) {
              return betType.strike < maxPrice - step * i + step / 2;
            }

            return betType.strike < maxPrice - step * i + step / 2 && betType.strike > maxPrice - step * i - step / 2;
          })
        );
      }

      sortedBetTypeList.forEach((betTypeList: CashFlowExposureDataBetTypes[], index: number) => {
        const isFirst: boolean = index === 0;
        const isLast: boolean = index === sortedBetTypeList.length - 1;

        const strike: number = maxPrice - step * index;

        const getDataSorted = (data: CashFlowExposureDataBetTypes[], change: string): CashFlowExposureDataBetTypes[] => {
          return data.sort((a: CashFlowExposureDataBetTypes, b: CashFlowExposureDataBetTypes) => {
            // @ts-ignore
            const maxA: number = Math.max(Math.abs(a['growthBets' + change]), Math.abs(a['declineBets' + change]));
            // @ts-ignore
            const maxB: number = Math.max(Math.abs(b['growthBets' + change]), Math.abs(b['declineBets' + change]));

            return maxB - maxA;
          });
        }

        const getDataFiltered = (betTypeList: CashFlowExposureDataBetTypes[], i: number): CashFlowExposureDataBetTypes[] => {
          return betTypeList.filter((betType: CashFlowExposureDataBetTypes) => {
            return betType.strike < maxPrice - step * i + step / 2 && betType.strike > maxPrice - step * i - step / 2;
          });
        }

        const FirstOrLastDataBetTypes: CashFlowExposureDataBetTypes[] = isFirst ? getDataFiltered(betTypeList, 0) : isLast ? getDataFiltered(betTypeList, sortedBetTypeList.length - 1) : [];

        const getFirstOrLastDataFiltered = (betTypeList: CashFlowExposureDataBetTypes[], change: string): CashFlowExposureDataBetTypes[] => {
          const restDataBetTypes: CashFlowExposureDataBetTypes[] = betTypeList.filter((betType: CashFlowExposureDataBetTypes) => FirstOrLastDataBetTypes[0] && betType.strike !== FirstOrLastDataBetTypes[0].strike)

          return [...getDataSorted([FirstOrLastDataBetTypes[0]], change), ...getDataSorted(restDataBetTypes, change)];
        }

        /** 1D */
        const sorted1d: CashFlowExposureDataBetTypes[] = [
          ...isFirst || isLast ? getFirstOrLastDataFiltered(betTypeList, '1d') : getDataSorted(betTypeList, '1d')
        ].reduce((accumulator: CashFlowExposureDataBetTypes[], current: CashFlowExposureDataBetTypes) => {
          // чистим от дублей по страйку
          if (accumulator.filter((item: CashFlowExposureDataBetTypes) => item.strike === current.strike).length === 0) {
            accumulator.push(current);
          }

          return accumulator;
        }, []);

        /** 5D */
        const sorted5d: CashFlowExposureDataBetTypes[] = [
          ...isFirst || isLast ? getFirstOrLastDataFiltered(betTypeList, '5d') : getDataSorted(betTypeList, '5d')
        ].reduce((accumulator: CashFlowExposureDataBetTypes[], current: CashFlowExposureDataBetTypes) => {
          // чистим от дублей по страйку
          if (accumulator.filter((item: CashFlowExposureDataBetTypes) => item.strike === current.strike).length === 0) {
            accumulator.push(current);
          }

          return accumulator;
        }, []);

        /** 30D */
        const sorted30d: CashFlowExposureDataBetTypes[] = [
          ...isFirst || isLast ? getFirstOrLastDataFiltered(betTypeList, '30d') : getDataSorted(betTypeList, '30d')
        ].reduce((accumulator: CashFlowExposureDataBetTypes[], current: CashFlowExposureDataBetTypes) => {
          // чистим от дублей по страйку
          if (accumulator.filter((item: CashFlowExposureDataBetTypes) => item.strike === current.strike).length === 0) {
            accumulator.push(current);
          }

          return accumulator;
        }, []);

        betTypesSorted.push({
          strike,
          main1d: sorted1d[0] || null,
          main5d: sorted5d[0] || null,
          main30d: sorted30d[0] || null,
          tooltip1d: sorted1d.slice(0, 5) || [],
          tooltip5d: sorted5d.slice(0, 5) || [],
          tooltip30d: sorted30d.slice(0, 5) || [],
        });
      });

      // const getMultiplierFn = (max: number) => {
      //   return (value: number): number => {
      //     const max1: number = max / 5;
      //     const max2: number = max / 5 * 2;
      //     const max3: number = max / 5 * 3;
      //     const max4: number = max / 5 * 4;
      //     const max5: number = max;
      //
      //     const level1: number = this.svgBetTypesMaxWidth / 5;
      //     const level2: number = this.svgBetTypesMaxWidth / 5 * 2;
      //     const level3: number = this.svgBetTypesMaxWidth / 5 * 3;
      //     const level4: number = this.svgBetTypesMaxWidth / 5 * 4;
      //     const level5: number = this.svgBetTypesMaxWidth;
      //
      //     if (value < max1) return level5 / max1;
      //
      //     if (value < max2) return level5 / max2;
      //
      //     if (value < max3) return level5 / max3;
      //
      //     if (value < max4) return level5 / max4;
      //
      //     return level5 / max5;
      //   }
      // }

      // const max1d: number = Math.max(
      //   ...betTypes.map((bet) => Math.abs(bet.declineBets1d)),
      //   ...betTypes.map((bet) => Math.abs(bet.growthBets1d)),
      //   ...betTypes.map((bet) => Math.abs(bet.declineOiSum)),
      //   ...betTypes.map((bet) => Math.abs(bet.growthOiSum))
      // );

      const max1d: number = Math.max(
        ...betTypesSorted.map((bet) => Math.abs(bet.main1d ? bet.main1d.declineBets1d : 0) + Math.abs(bet.main1d ? bet.main1d.declineOiSum : 0)),
        ...betTypesSorted.map((bet) => Math.abs(bet.main1d ? bet.main1d.growthBets1d : 0) + Math.abs(bet.main1d ? bet.main1d.growthOiSum : 0))
      );
      const multiplier1d: number = this.svgBetTypesMaxWidth / max1d;

      const max5d: number = Math.max(
        ...betTypesSorted.map((bet) => Math.abs(bet.main5d ? bet.main5d.declineBets5d : 0) + Math.abs(bet.main5d ? bet.main5d.declineOiSum : 0)),
        ...betTypesSorted.map((bet) => Math.abs(bet.main5d ? bet.main5d.growthBets5d : 0) + Math.abs(bet.main5d ? bet.main5d.growthOiSum : 0)),
      );
      const multiplier5d: number = this.svgBetTypesMaxWidth / max5d;

      const max30d: number = Math.max(
        ...betTypesSorted.map((bet) => Math.abs(bet.main30d ? bet.main30d.declineBets30d : 0) + Math.abs(bet.main30d ? bet.main30d.declineOiSum : 0)),
        ...betTypesSorted.map((bet) => Math.abs(bet.main30d ? bet.main30d.growthBets30d : 0) + Math.abs(bet.main30d ? bet.main30d.growthOiSum : 0))
      );
      const multiplier30d: number = this.svgBetTypesMaxWidth / max30d;

      // prettier-ignore
      if (day) {
        return {
          betTypesSorted,
          levels,
          locked,
          date,
          x: Number(d3Selection.select(day).attr('x')),
          multiplier1d,
          multiplier5d,
          multiplier30d
        };
      }

      return {betTypesSorted, levels, locked, date, x: null, multiplier1d, multiplier5d, multiplier30d};
    };

    const dateList: string[] = [
      ...this.cashFlowExposureData.betTypes.map((bet: CashFlowExposureDataBetTypes) => bet.date),
      ...this.cashFlowExposureData.locked.map((locked: CashFlowExposureDataLocked) => locked.date)
    ];

    this.cashFlowExposureDataUniqueDate = [...new Set(dateList)].map((date: string) => date);

    const dayDataList: CashFlowExposureDataPreparation[] = this.cashFlowExposureDataUniqueDate.map((date: string) => {
      return getDayData(date);
    });

    dayDataList.forEach((dayData: CashFlowExposureDataPreparation) => {
      const day = d3Selection.select(this.svgExposureSelectorXTickerDays).select('text[data-date="' + dayData.date + '"]').node();

      if (day && dayData.x) {
        setTimeout(() => {
          this.setSvgBetTypes(dayData, update);
          this.setSvgLevels(dayData, update);
          this.setSvgLabel(dayData);
          this.setSvgLocked(dayData);
        }, 4);
      }
    });

    this.setSvgExposureBetsTooltipEvents();
    this.setSvgExposureLevelsTooltipEvents();
  }

  /** проверка на догрузку */
  // prettier-ignore
  checkCashFlowExposureData(): void {
    !!this.cashFlowExposureDataDateUpdateTimeout && clearTimeout(this.cashFlowExposureDataDateUpdateTimeout);

    this.cashFlowExposureDataDateUpdateTimeout = setTimeout(() => {
      const isEmptyBetTypes: boolean = !this.cashFlowExposureData?.betTypes;
      const isEmptyLocked: boolean = !this.cashFlowExposureData?.locked;

      if (!this.cashFlowExposureDataInUpdate) {
        if (this.cashFlowExposureData && !isEmptyBetTypes && !isEmptyLocked) {
          const reserveWidth: number = 4500;
          const [startX, endX] = this.getVisibleArea();

          /** доступные даты на графике */
          const dateList: d3Selection.BaseType[] = d3Selection.selectAll(this.svgExposureSelectorXTickerDays + ' > text').nodes();

          /** крайняя левая дата на графике */
          // @ts-ignore
          const dateLeft: Date = new Date(d3Selection.select(dateList[0]).data()[0].date).getTime();

          /** крайняя правая дата на графике */
          // @ts-ignore
          const dateRight: Date = new Date(d3Selection.select(dateList.slice().reverse()[0]).data()[0].date).getTime();

          /** крайняя левая дата индикатора */
          const dateIndicatorLeft: any = Math.min(
            ...this.cashFlowExposureData.betTypes.map((bets: CashFlowExposureDataBetTypes) => new Date(bets.date).getTime()),
            ...this.cashFlowExposureData.locked.map((locked: CashFlowExposureDataLocked) => new Date(locked.date).getTime())
          );

          const leftYear: number = new Date(dateIndicatorLeft).getFullYear();
          const leftMonth: number | string = new Date(dateIndicatorLeft).getMonth() + 1 < 10 ? '0' + (new Date(dateIndicatorLeft).getMonth() + 1) : new Date(dateIndicatorLeft).getMonth() + 1;
          const leftDay: number | string = new Date(dateIndicatorLeft).getDate() < 10 ? '0' + new Date(dateIndicatorLeft).getDate() : new Date(dateIndicatorLeft).getDate();

          const indicatorLeftX: number = dateLeft < dateIndicatorLeft ? Number(d3Selection.selectAll(this.svgExposureSelectorXTickerDays + ' > text[data-date="' + `${leftYear}-${leftMonth}-${leftDay}` + '"]').attr('x')) : 0;

          /** крайняя правая дата индикатора */
          const dateIndicatorRight: any = Math.max(
            ...this.cashFlowExposureData.betTypes.map((bets: CashFlowExposureDataBetTypes) => new Date(bets.date).getTime()),
            ...this.cashFlowExposureData.locked.map((locked: CashFlowExposureDataLocked) => new Date(locked.date).getTime())
          );

          const rightYear: number = new Date(dateIndicatorRight).getFullYear();
          const rightMonth: number | string = new Date(dateIndicatorRight).getMonth() + 1 < 10 ? '0' + (new Date(dateIndicatorRight).getMonth() + 1) : new Date(dateIndicatorRight).getMonth() + 1;
          const rightDay: number | string = new Date(dateIndicatorRight).getDate() < 10 ? '0' + new Date(dateIndicatorRight).getDate() : new Date(dateIndicatorRight).getDate();

          const indicatorRightNode = d3Selection.selectAll(this.svgExposureSelectorXTickerDays + ' > text[data-date="' + `${rightYear}-${rightMonth}-${rightDay}` + '"]')
          const indicatorRightX: number | null = !indicatorRightNode.empty() ? Number(indicatorRightNode.attr('x')) : null;

          /** если слева есть место для индикатора */
          if (!this.cashFlowExposureDataInUpdate && !this.cashFlowExposureDataDateUpdateLeftIsBlocked && dateLeft < dateIndicatorLeft && indicatorLeftX > startX - reserveWidth) {
            this.cashFlowExposureDataDateUpdateLastDirection = 'left';

            this.getCashFlowExposureData(new Date(dateIndicatorLeft));
          }

          /** если справа есть место для индикатора */
          if (!this.cashFlowExposureDataInUpdate && !this.cashFlowExposureDataDateUpdateRightIsBlocked && dateRight > dateIndicatorRight && indicatorRightX && indicatorRightX < endX + reserveWidth) {
            this.cashFlowExposureDataDateUpdateLastDirection = 'right';

            this.getCashFlowExposureData(new Date(dateIndicatorRight));
          }

          /** если не пошли на загрузку, то пробуем отрисовать что есть */
          if (!this.cashFlowExposureDataInUpdate) {
            this.preparationData();
          }
        } else {
          this.getCashFlowExposureData();
        }
      }
    }, 500);
  }

  /** Обновление массивов данных(добавляем без дублей) */
  // prettier-ignore
  updateCashFlowExposureData(cashFlowExposureData: CashFlowExposureData): void {
    const uniqueDateBetTypes: number[] = this.cashFlowExposureData?.betTypes ? [...new Set([...this.cashFlowExposureData.betTypes.map((bets: CashFlowExposureDataBetTypes) => new Date(bets.date).getTime())])].map((date: number) => date) : [];
    const uniqueDateLevels: number[] = this.cashFlowExposureData?.levels ? [...new Set([...this.cashFlowExposureData.levels.map((levels: CashFlowExposureDataLevels) => new Date(levels.date).getTime())])].map((date: number) => date) : [];
    const uniqueDateLocked: number[] = this.cashFlowExposureData?.locked ? [...new Set([...this.cashFlowExposureData.locked.map((locked: CashFlowExposureDataLocked) => new Date(locked.date).getTime())])].map((date: number) => date) : [];

    const oldBetTypes: CashFlowExposureDataBetTypes[] = this.cashFlowExposureData?.betTypes ? this.cashFlowExposureData?.betTypes : [];
    const oldLevels: CashFlowExposureDataLevels[] = this.cashFlowExposureData?.levels ? this.cashFlowExposureData?.levels : [];
    const oldLocked: CashFlowExposureDataLocked[] = this.cashFlowExposureData?.locked ? this.cashFlowExposureData?.locked : [];

    const newBetTypes: CashFlowExposureDataBetTypes[] = cashFlowExposureData.betTypes.filter((bets: CashFlowExposureDataBetTypes) => !uniqueDateBetTypes.includes(new Date(bets.date).getTime()));
    const newLevels: CashFlowExposureDataLevels[] = cashFlowExposureData.levels.filter((levels: CashFlowExposureDataLevels) => !uniqueDateLevels.includes(new Date(levels.date).getTime()));
    const newLocked: CashFlowExposureDataLocked[] = cashFlowExposureData.locked.filter((locked: CashFlowExposureDataLocked) => !uniqueDateLocked.includes(new Date(locked.date).getTime()));

    /** блокируем загрузку, если не получаем ни чего нового */
    if (!newBetTypes.length && !newLevels.length && !newLocked.length) {
      if (this.cashFlowExposureDataDateUpdateLastDirection === 'left') this.cashFlowExposureDataDateUpdateLeftIsBlocked = true;
      if (this.cashFlowExposureDataDateUpdateLastDirection === 'right') this.cashFlowExposureDataDateUpdateRightIsBlocked = true;
    }

    this.cashFlowExposureData = {
      betTypes: [...oldBetTypes, ...newBetTypes],
      levels: [...oldLevels, ...newLevels],
      locked: [...oldLocked, ...newLocked],
    }
  }

  // prettier-ignore
  getVisibleArea(): number[] {
    return [-1 * this.svgTranslateX, this.svgSetting.width - this.svgSetting.marginRight + this.svgTranslateX * -1];
  }

  // CLICK BUTTON

  /** клик по заблокированному индикатору */
  onShowIndicator(): void {
    /** запоминаем страницу с которой пошли на покупку */
    this.cookieService.delete('page-before-payment', '/');
    this.cookieService.set('page-before-payment', this.router.url, {
      path: '/',
    });

    /** Не авторизован */
    if (this.userType === 0) {
      this.analyticService.send(
        'click_sign_up',
        new Map([['name', 'В индикаторе "бабочка"']]),
      );
      this.authFormService.openSignUp();
    }

    /** Авторизован */
    if (this.userType > 0) {
      // prettier-ignore
      this.router.navigate(['/premium'], { fragment: 'tariffs' }).then(() => console.debug('Route changed'));
    }
  }
}
