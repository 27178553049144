import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';
import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { TipsComponent } from './tips/tips.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NpsVotingComponent } from './nps-voting/nps-voting.component';
import { RouterModule } from '@angular/router';
import { DesktopSidebarComponent } from './sidebar/desktop-sidebar/desktop-sidebar.component';
import { MobileSidebarComponent } from './sidebar/mobile-sidebar/mobile-sidebar.component';
import { HeaderAccountComponent } from './header/components/header-account/header-account.component';
import { HeaderSearchComponent } from './header/components/header-search/header-search.component';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BasicModule } from '../shared/modules/basic/basic.module';
import { HeaderAccountPremiumComponent } from './header/components/header-account/header-account-premium/header-account-premium.component';
import { LikeButtonComponent } from './like/components/like-button/like-button.component';
import { FeedbackDialogComponent } from './like/components/feedback-dialog/feedback-dialog.component';
import { HeaderNewComponent } from './header-new/header-new.component';
import { HeaderNewAccountComponent } from './header-new/header-new-account/header-new-account.component';
import { HeaderNewBurgerAccountComponent } from './header-new/header-new-burger-account/header-new-burger-account.component';

@NgModule({
  declarations: [
    HeaderComponent,
    HeaderNewComponent,
    SidebarComponent,
    TipsComponent,
    NpsVotingComponent,
    DesktopSidebarComponent,
    MobileSidebarComponent,
    HeaderAccountComponent,
    HeaderSearchComponent,
    HeaderAccountPremiumComponent,
    LikeButtonComponent,
    FeedbackDialogComponent,
    HeaderNewAccountComponent,
    HeaderNewBurgerAccountComponent,
  ],
  imports: [
    CommonModule,
    NgbModule,
    RouterModule,
    MatMenuModule,
    BasicModule,
    MatDividerModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [
    HeaderComponent,
    HeaderNewComponent,
    SidebarComponent,
    TipsComponent,
    NpsVotingComponent,
    LikeButtonComponent,
  ],
})
export class LayoutModule {}
