<div class="hidden lg:flex bg-base-200 p-4 pt-10 pb-16 justify-center">
  <div class="flex justify-between max-w-[50rem] w-full">
    <div *ngFor="let footerItem of items" class="flex flex-col">
      <span>{{ footerItem.title.default }}</span>

      <div class="flex mt-3" [class]="footerItem.classes ?? 'flex-col'">
        <ng-container *ngFor="let item of footerItem.items">
          <a
            *ngIf="
              item.route &&
              !item.route.includes('http') &&
              !item.route.includes('?')
            "
            [routerLink]="item.route"
            [class]="footerItem.itemsClasses ?? ''"
            class="py-1"
          >
            <span
              *ngIf="item.text?.default"
              class="opacity-60 cursor-pointer"
              [innerHTML]="item.text?.default"
            ></span>

            <app-icon
              *ngIf="item.icon"
              [icon]="item.icon"
              class="cursor-pointer"
              classes="w-4 h-4"
            ></app-icon>
          </a>
          <a
            *ngIf="
              item.route &&
              (item.route.includes('http') || item.route.includes('?'))
            "
            [href]="item.route"
            [class]="footerItem.itemsClasses ?? ''"
            [target]="item.route.includes('http') ? '_blanc' : ''"
            class="py-1"
          >
            <span
              *ngIf="item.text?.default"
              class="opacity-60 cursor-pointer"
              >{{ item.text?.default }}</span
            >

            <app-icon
              *ngIf="item.icon"
              [icon]="item.icon"
              class="cursor-pointer"
              classes="w-4 h-4"
            ></app-icon>
          </a>

          <ng-container *ngIf="!item.route">
            <span [style]="item.style" *ngIf="item.text">
              {{ item.text.default }}
            </span>

            <div
              *ngIf="item.html"
              [innerHTML]="sanitizer.bypassSecurityTrustHtml(item.html)"
            ></div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<!-- VS-930 -->
<!--<app-nav-menu
  [items]="menuItems"
  class="flex fixed left-0 right-0 bottom-0 lg:hidden bg-base-200 z-[1000] footer-nav"
></app-nav-menu>-->
