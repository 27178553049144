<div class="title">My Subscriptions</div>
<div *ngIf="trialAvailable" class="content">
  <div class="trial-block">
    <div class="texts">
      <span class="main">Are you eligible for a free trial?</span>
      <span class="desc">(no payment details required)</span>
    </div>

    <div class="options">
      <div *ngFor="let item of trialOptions" class="item">
        <div class="text-block">
          <app-checkbox [checked]="item.active"></app-checkbox>
          <span class="text">{{ item.text }}</span>
        </div>
        <a
          *ngIf="item.link"
          [routerLink]="item.link.url ?? ''"
          (click)="optionClick(item)"
          class="link"
          >{{ item.link.text }}</a
        >
      </div>
    </div>
  </div>

  <app-button
    text="Activate"
    class="button"
    classes="px-[14px] py-2 text-[18px] rounded-md"
    [color]="!trialButtonActive ? { bg: 'bg-base-500', text: 'text-primary-content' } : { bg: 'bg-primary-0/80 hover:bg-primary-0/100', text: 'text-primary-content' }"
    (click)="activate()"
  ></app-button>
</div>
