<div
  class="flex flex-col bg-white w-screen md:max-w-[480px] h-screen md:h-auto md:rounded-2xl shadow-shadow-modal-shadow"
>
  <div class="flex justify-end !p-4">
    <button (click)="close()">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.7349 12L6 7.26512L7.26512 6L12 10.7349L16.7349 6L18 7.26512L13.2651 12L18 16.7349L16.7349 18L12 13.2651L7.26512 18L6 16.7349L10.7349 12Z"
          fill="#333333"
        />
      </svg>
    </button>
  </div>
  <div class="flex flex-col items-center !gap-6 !p-8 !pt-0">
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 32C0 14.3269 14.3269 0 32 0C49.6731 0 64 14.3269 64 32C64 49.6731 49.6731 64 32 64C14.3269 64 0 49.6731 0 32Z"
        fill="#E8F6EC"
      />
      <path
        d="M43.1663 24C42.4997 23.3333 41.4997 23.3333 40.833 24L28.333 36.5L23.1663 31.3333C22.4997 30.6667 21.4997 30.6667 20.833 31.3333C20.1663 32 20.1663 33 20.833 33.6667L27.1663 40C27.4997 40.3333 27.833 40.5 28.333 40.5C28.833 40.5 29.1663 40.3333 29.4997 40L43.1663 26.3333C43.833 25.6667 43.833 24.6667 43.1663 24Z"
        fill="#62C37D"
      />
    </svg>
    <span
      class="text-interface-title-3-semibold text-default-neutral-1 text-center"
    >
      Your subscription is renewed
    </span>
    <p
      class="text-interface-caption-1-regular text-default-neutral-3 text-center"
    >
      This window will automatically close in
      <span class="text-default-secondary-2">0{{ intervalValue }}</span> sec
    </p>
  </div>
</div>
