import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/modules/auth/services/auth.service';
import { TrialDataService } from 'src/app/modules/main/services/trial-data.service';
import { EventType, EventsService } from 'src/app/services/events.service';

@Component({
  selector: 'app-header-account-premium',
  templateUrl: './header-account-premium.component.html',
  styleUrls: ['./header-account-premium.component.scss'],
})
export class HeaderAccountPremiumComponent implements OnInit {

  text: string = "";

  constructor(private authService: AuthService,
              private trialService: TrialDataService,
              private eventService: EventsService) {
    this.eventService.subscription().subscribe(async type => {
      if (type === EventType.UserTypeChanged) {
        await this.updateText();
      }
    });
  }

  async ngOnInit(): Promise<void> {
    await this.updateText();
  }

  async updateText(): Promise<void> {
    if (await this.authService.hasPremiumSubscription() || 
        await this.trialService.isTrialActive()) {
      this.text = "Premium";
    } else {
      this.text = "Free trial";
    }
  }

}
