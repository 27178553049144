import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { first, from, Subscription } from 'rxjs';

import { AuthService } from '@app/modules/auth/services/auth.service';
import { JWTPayloadModel } from '@app/modules/shared/models/jwt-playload.modet';
import { UserTypeModel } from '@app/modules/shared/models/user-type.model';
import { EventsService, EventType } from '@app/services/events.service';
import { MainHttpClient } from '@app/services/main-http-client.service';
import { SnackbarService } from '@app/services/snackbar.service';
import { LandingDialogService } from '@main/landing/dialogs/services/dialog.service';
import {
  LandingResearchPapers,
  ResearchPapers,
} from '@main/landing/models/landing-papers.model';
import { ScaleService } from '@app/modules/shared/services/scale.service';

@Component({
  selector: 'shared-research-papers, [sharedResearchPapers]',
  templateUrl: './research-papers.component.html',
})
export class ResearchPapersComponent implements OnInit, OnDestroy {
  @Input() disableAdaptive = false;

  eventType$!: Subscription;

  userEmail: string | undefined;
  userType: UserTypeModel = UserTypeModel.Unregistered;

  papersList: ResearchPapers[] = [];

  constructor(
    private http: MainHttpClient,
    private dialogService: LandingDialogService,
    private authService: AuthService,
    private eventService: EventsService,
    private httpClient: MainHttpClient,
    private snackbarService: SnackbarService,
    private scaleService: ScaleService,
  ) {}

  ngOnInit(): void {
    /** GET PAPERS */
    from(this.http.get<LandingResearchPapers>('/v2/research-papers'))
      .pipe(first())
      .subscribe({
        next: (landingResearchPapers: LandingResearchPapers): void => {
          this.papersList = landingResearchPapers.data.items;

          this.scaleService.changeScaleSubject.next(true);
        },
        error: (error: any) => console.error(error),
      });

    /** смотрим изменение роли юзера */
    this.eventType$ = this.eventService
      .subscription()
      .subscribe((type: EventType): void => {
        if (type === EventType.UserTypeChanged) {
          this.setUserInfo();
        }
      });

    this.setUserInfo();
  }

  ngOnDestroy(): void {
    [this.eventType$].forEach(($: Subscription) => $?.unsubscribe());
  }

  setUserInfo(): void {
    this.authService.getUserType().then((userType: UserTypeModel): void => {
      this.userType = userType;

      this.setEmail();
    });
  }

  // prettier-ignore
  setEmail(): void {
    const jwtPayloadModel: JWTPayloadModel | null = this.authService.getPayload();

    if (jwtPayloadModel && jwtPayloadModel.userEmail) {
      this.userEmail = jwtPayloadModel.userEmail;
    } else {
      this.userEmail = undefined;
    }
  }

  // prettier-ignore
  onClickReadArticle(researchPaper: any): void {
    if (this.userType === UserTypeModel.Unregistered || this.userEmail === undefined) {
      this.dialogService.openResearchPapersDetails({ researchPaper });
    } else {
      // if isLogged and isHasEmail then send paper
      const body: any = { email: this.userEmail, signUp: false, terms: true };

      from(this.httpClient.post('/v2/research-papers/' + researchPaper.id + '/send-to-email', body))
        .pipe(first())
        .subscribe({
          next: () => {
            // @ts-ignore
            this.dialogService.openResearchPapersSuccess({ email: this.userEmail, signUp: false });
          },
          error: () => this.snackbarService.error('Server Error'),
        });
    }
  }
}
