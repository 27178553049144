import { Component, Input, OnInit } from '@angular/core';
import { AuthFormService } from 'src/app/modules/auth/services/auth-form.service';

@Component({
  selector: 'app-trial-banner',
  templateUrl: './trial-banner.component.html',
  styleUrls: ['./trial-banner.component.scss']
})
export class TrialBannerComponent {

  @Input() buttonText: string;

  constructor(public authFormService: AuthFormService) { }
}
