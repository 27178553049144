import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pay-cancel-dialog',
  templateUrl: './pay-cancel-dialog.component.html',
})
export class PayCancelDialogComponent {
  constructor(
    public dialog: MatDialogRef<PayCancelDialogComponent>,
    private router: Router,
  ) {}

  // prettier-ignore
  close(success: boolean = false): void {
    this.dialog.close(success);

    this.router.navigate([], {
        queryParams: { 'pay-status': null },
        queryParamsHandling: 'merge',
      })
      .then(() => console.debug('Router changed'));
  }
}
