import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first, from, Subscription } from 'rxjs';
import { AuthFormService } from '../../../../auth/services/auth-form.service';
import { SnackbarService } from '@app/services/snackbar.service';
import { ResearchPapers } from '../../models/landing-papers.model';
import { MainHttpClient } from '@app/services/main-http-client.service';
import { UserTypeModel } from '@shared/models/user-type.model';
import { AuthService } from '../../../../auth/services/auth.service';
import { JWTPayloadModel } from '@shared/models/jwt-playload.modet';
import { EventsService, EventType } from '@app/services/events.service';
import { RegistrationService } from '../../../../auth/services/registration.service';
import { LandingDialogService } from '../services/dialog.service';
import { HelperService } from '@app/services/helper.service';

@Component({
  selector: 'app-landing-research-papers-dialog',
  templateUrl: './landing-research-papers-dialog.component.html',
})
export class LandingResearchPapersDialogComponent
  implements AfterViewInit, OnInit, OnDestroy
{
  eventType$!: Subscription;

  formForm: FormGroup;

  userEmail: string;
  userType: UserTypeModel = UserTypeModel.Unregistered;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { researchPaper: ResearchPapers },
    private dialog: MatDialogRef<LandingResearchPapersDialogComponent>,
    private dialogService: LandingDialogService,
    private httpClient: MainHttpClient,
    private authFormService: AuthFormService,
    private formBuilder: FormBuilder,
    private snackbarService: SnackbarService,
    private authService: AuthService,
    private eventService: EventsService,
    private registrationService: RegistrationService,
    private helperService: HelperService,
  ) {
    this.formForm = this.formBuilder.group({
      email: this.formBuilder.control('', [
        Validators.required,
        Validators.email,
      ]),
      signUp: this.formBuilder.control(false, []),
      terms: this.formBuilder.control(false, [Validators.requiredTrue]),
    });
  }

  ngOnInit(): void {
    /** смотрим изменение роли юзера */
    this.eventType$ = this.eventService
      .subscription()
      .subscribe((type: EventType): void => {
        if (type === EventType.UserTypeChanged) {
          this.authService
            .getUserType()
            .then((userType: UserTypeModel): void => {
              this.userType = userType;

              // if unregistered
              if (this.userType === 0) {
                this.formForm.get('singUp')?.setValue(true);
              }

              this.setEmail();
            });
        }
      });

    this.setEmail();
  }

  ngAfterViewInit(): void {
    this.authService.getUserType().then((userType: UserTypeModel) => {
      this.userType = userType;

      // if unregistered
      if (this.userType === 0) {
        this.formForm.patchValue({ signUp: true });
      }
    });
  }

  ngOnDestroy(): void {
    [this.eventType$].forEach(($: Subscription) => $?.unsubscribe());
  }

  // prettier-ignore
  setEmail() {
    const jwtPayloadModel: JWTPayloadModel | null = this.authService.getPayload();

    if (jwtPayloadModel) {
      this.userEmail = jwtPayloadModel.userEmail;
    }
  }

  close(success: boolean = false): void {
    this.dialog.close(success);
  }

  onSubmit(): void {
    if (this.helperService.getFormValidation(this.formForm)) {
      const signUp: boolean = this.formForm.get('signUp')?.value;
      const terms: boolean = this.formForm.get('terms')?.value;

      this.formForm.disable();

      const body: any = {
        ...this.formForm.value,
      };

      // prettier-ignore
      from(this.httpClient.post('/v2/research-papers/' + this.data.researchPaper.id + '/send-to-email', body))
        .pipe(first())
        .subscribe({
          next: () => {
            this.close();

            this.dialogService.openResearchPapersSuccess({ email: this.formForm.get('email')?.value, signUp: signUp && terms });

            this.formForm?.reset();
            this.formForm.enable();
          },
          error: () => this.snackbarService.error('Server Error'),
        });

      // Регистрируем новый акк.
      if (signUp && terms) {
        const body: any = {
          email: this.formForm.get('email')?.value,
          agreedToTerms: terms,
        };

        this.registrationService
          .signUp(body)
          .then((): void => console.debug('signUp'));
      }
    }
  }

  signIn(): void {
    this.close();

    this.authFormService.openSignIn();
  }
}
