import { NgModule } from '@angular/core';
import { TuneAffiliateService } from './services/TuneAffiliateService';
import { LinkMinkService } from './services/LinkMink.service';

@NgModule({
  providers: [
    TuneAffiliateService,
    LinkMinkService,
  ]
})
export class AffiliateModule { }