import { Component, Input, OnInit } from '@angular/core';
import { BannerPage, SidebarPage } from '../../models/sidebar.model';

@Component({
  selector: 'app-desktop-sidebar',
  templateUrl: './desktop-sidebar.component.html',
  styleUrls: ['./desktop-sidebar.component.scss']
})
export class DesktopSidebarComponent implements OnInit {

  @Input() banner: BannerPage | null;

  @Input() page: SidebarPage | undefined;

  constructor() { }

  ngOnInit(): void {
  }

}
