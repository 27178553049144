import { ITableConfig } from '../structures/ui';

export const TABLE_DEFAULT_CONFIG: ITableConfig = {
  pagination: {
    count: 3,
    moreButton: true,
  },
  horizontalMode: {
    mobile: true,
    always: false,
  },
  highlight: {
    even: true,
    mobile: true,
  },
};
