<div
  class="flex flex-col bg-white w-screen md:max-w-[480px] h-screen md:h-auto md:rounded-2xl shadow-shadow-modal-shadow"
>
  <div class="flex justify-end !p-4">
    <button (click)="close()" type="button">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.7349 12L6 7.26512L7.26512 6L12 10.7349L16.7349 6L18 7.26512L13.2651 12L18 16.7349L16.7349 18L12 13.2651L7.26512 18L6 16.7349L10.7349 12Z"
          fill="#333333"
        />
      </svg>
    </button>
  </div>
  <div class="flex flex-col !gap-6 !p-8 !pt-0">
    <span class="text-interface-title-3-semibold text-default-neutral-1">
      Please, tell us reasons why you cancel subscription?
    </span>
    <form
      class="flex flex-col !gap-6"
      [formGroup]="formForm"
      (ngSubmit)="onSubmit()"
    >
      <div class="flex flex-col !gap-2">
        <!-- prettier-ignore -->
        <p
          *ngIf="formForm.get('reason')?.touched && formForm.get('reason')?.invalid"
          class="text-interface-caption-1-regular text-default-tertiary-2"
        >
          Please, select some variant
        </p>
        <ng-container *ngFor="let reason of formList; let index = index">
          <!-- prettier-ignore -->
          <fieldset
            class="kw-form-control-radio-special checked"
            [class.checked]="reason.value === formForm.get('reason')?.value"
            (click)="reason.value === formForm.get('reason')?.value ? onResetForm($event) : formForm.setValue({'reason': reason.value})"
          >
            <input
              type="radio"
              class="kw-form-control-input"
              formControlName="reason"
              [value]="reason.value"
            />
            <label
              class="kw-form-control-radio-mark"
            ></label>
            <label
              class="kw-form-control-radio-text text-interface-control-2-regular text-high-on-light"
            >
              {{ reason.label }}
            </label>
          </fieldset>
        </ng-container>
      </div>
      <button
        type="submit"
        class="kw-btn kw-btn-lg kw-btn-active kw-btn-primary w-full"
      >
        <span class="block">Submit Answer</span>
      </button>
    </form>
  </div>
</div>
