import { ITranslation } from './app';

export enum Colors {
  Primary = 'primary',
  PrimaryContent = 'primary-content',
  Primary100 = 'primary-100',
  Accent = 'accent',
  Secondary = 'secondary',
  Base100 = 'base-100',
  Base200 = 'base-200',
  Base300 = 'base-300',
  Danger100 = 'danger-100',
  Danger500 = 'danger-500',
}

export enum Icon {
  AppName = 'app-name',
  Search = 'search',
  MoneyFlows = 'money-flows',
  KeyEvents = 'key-events',
  SectorData = 'sector-data',
  MyPortfolio = 'my-portfolio',
  SetAlets = 'set-alerts',
  Crown = 'crown',
  CheckMark = 'check-mark',
  Profile = 'profile',
  Facebook = 'facebook',
  Instagram = 'instagram',
  Reddit = 'reddit',
  Youtube = 'youtube',
  Tiktok = 'tiktok',
  Twitter = 'twitter',
  X = 'x',
  ArrowDown = 'arrow-down',
  ArrowLeft = 'arrow-left',
  ArrowRight = 'arrow-right',
  ArrowUp = 'arrow-up',
  Plus = 'plus',
  Loader = 'loader',
  KeyLevels = 'key-levels',
  NetFlows = 'net-flows',
  PopularTrades = 'popular-trades',
  Lock = 'lock',
  Info = 'info',
  Battery = 'battery',
  Wifi = 'wifi',
  Scenarios = 'scenarios',
  File = 'file',
  Lamp = 'lamp',
  FileCheckMark = 'file-check-mark',
  Comment = 'comment',
  Star = 'star',
  Graph = 'graph',
  Overview = 'overview',
  Fundamentals = 'fundamentals',
  MoneyFlowsBill = 'money-flows-bill',
  Technicals = 'technicals',
  Filter = 'filter',
  Clock = 'clock',
  Edit = 'edit',
  Delete = 'delete',
}

export interface ComponentColor {
  bg?: string;
  text?: string;
  fill?: {
    base?: string;
    content?: string;
  };
  stroke?: string;
}

export interface IMenu {
  id: any;
  text: ITranslation;
}

export interface IFooterItem {
  title: ITranslation;
  items: {
    html?: string;
    text?: ITranslation;
    route?: string;
    icon?: Icon | string;
    style?: string;
  }[];
  classes?: string;
  itemsClasses?: string;
}

export enum DeviceSize {
  Mobile = 767,
  Tab = 1023,
  Laptop = 1280,
}

export interface ContextMenuItem {
  id: string;
  text: string;
  icon?: Icon;
  classes?: string;
  toggle?: boolean;
}

export interface Point {
  x: number;
  y: number;
}

export interface ITabItem {
  id: string;
  text: string;
}

export type TableDataType =
  | Object
  | string
  | number
  | boolean
  | null
  | undefined;

export interface ITableData {
  [column: string]: TableDataType;
}

export interface ITableColumn {
  id: string;
  text: string;
  classes?: string;
}

export interface IHorizontalTableGridClasses {
  grid?: string;
  row?: {
    host?: string;
    header?: {
      host?: string;
      specific?: { [column: string]: string };
    };
    content?: {
      host?: string;
      specific?: { [item: string]: string };
    };
  };
}

export interface ITableGridClasses {
  grid?: string;
  header?: {
    host?: string;
    item?: {
      host?: string;
      specific?: { [column: string]: string };
    };
  };
  content?: {
    host?: string;
    row?: {
      host?: string;
      item?: {
        host?: string;
        specific?: { [item: string]: string };
      };
    };
  };
  columnCount?: string;
  horizontal?: IHorizontalTableGridClasses;
}

export interface ITableConfig {
  pagination?: {
    count?: number;
    moreButton?: boolean;
    displayAll?: boolean;
  };
  horizontalMode?: {
    mobile?: boolean;
    always?: boolean;
  };
  highlight?: {
    even?: boolean;
    odd?: boolean;
    mobile?: boolean;
  };
}
