import { ANIM_SCALE_Y } from 'src/app/modules/shared/modules/basic/animations/scale.animation';
import { ANIM_SCALE } from './../../../../shared/modules/basic/animations/scale.animation';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';
import { Ticker } from 'src/app/interfaces/api/domain/ticker';
import { HeaderService } from '../../services/header.service';
import { DomUtil } from 'src/app/modules/shared/modules/basic/utils/dom.util';
import { AnalyticService } from 'src/app/services/analytic.service';
import { PortfolioService } from 'src/app/modules/widgets/portfolio/services/portfolio.service';

class Stock {
  ticker: Ticker;
  added: boolean;
}

declare var $: any;

@Component({
  selector: 'app-header-search',
  templateUrl: './header-search.component.html',
  styleUrls: ['./header-search.component.scss'],
  animations: [ANIM_SCALE, ANIM_SCALE_Y],
})
export class HeaderSearchComponent implements AfterViewInit {
  private searchTextChangeLimiter = new Subject<string>();
  _focused = false;

  searchResultsTitle: string = 'Popular';

  searchResults: Array<Stock> = [];

  popularStocks: Array<Stock> = [
    {
      added: false,
      ticker: {
        id: '',
        name: '',
        ticker: 'TSLA',
      },
    },
    {
      added: false,
      ticker: {
        id: '',
        name: '',
        ticker: 'AAPL',
      },
    },
    {
      added: false,
      ticker: {
        id: '',
        name: '',
        ticker: 'AMZN',
      },
    },
  ];

  addedStocks: Array<Stock> = [];

  textFieldValue: string;

  get focused() {
    return this.headerService.searchFocused;
  }

  set focused(value: boolean) {
    this.headerService.searchFocused = value;

    if (value) {
      this.headerService.mobileAccountVisible = false;
    } else {
      this.headerService.mobileSearchClearVisible = false;
    }
  }

  constructor(
    private router: Router,
    public headerService: HeaderService,
    private portfolioService: PortfolioService,
    private analyticService: AnalyticService
  ) {
    this.initSearchTextLimiter();
    this.searchResults = this.popularStocks;
    this.loadPopularStocks();
  }

  private async loadPopularStocks(): Promise<void> {
    const searchText = this.popularStocks.map(x => x.ticker.ticker).join(' ');
    const response = await this.portfolioService.searchTicker({
      searchText: searchText,
      searchByWords: true,
      itemsPerPage: this.popularStocks.length,
    });
    this.popularStocks = this.popularStocks.map((s) => {
      const foundTicker = response.tickers.find(x => x.ticker === s.ticker.ticker);
      if (!foundTicker) {
        return s;
      }
      s.ticker.id = foundTicker.id;
      s.ticker.name = foundTicker.name;
      return s;
    })
  }

  ngAfterViewInit(): void {
    const container = $('app-header-search');
    $(document).click((e: any) => {
      const childs = container.find(e.target);
      const hasSearchClass = DomUtil.hasClass(e.target, 'header-search');

      if (
        childs.length === 0 &&
        !hasSearchClass
      ) {
        this.focused = false;
      }
    });
  }

  focusIn() {
    this.focused = true;
    if (this.textFieldValue === undefined) {
      return;
    }
    this.setSearchResultsTitle(this.textFieldValue);
    this.loadSearchResults(this.textFieldValue);
  }

  async addStock(stock: Stock): Promise<void> {
    stock.added = true;
    this.addedStocks.push(stock);

    const tickersInPortfolio = await this.portfolioService.loadTickers();
    const name = stock.ticker.ticker;
    this.analyticService.send('add_ticker', new Map([
      ["name", name],
      ["count_tickers", `${tickersInPortfolio.tickers.length + 1}`]
    ]));

    await this.portfolioService.addTicker({
      tickerId: this.addedStocks.map((x) => x.ticker.id),
      price: 0,
      quantity: 0,
    });
  }

  async removeStock(stock: Stock): Promise<void> {
    stock.added = false;
    const index = this.addedStocks.findIndex((s) => s.ticker === stock.ticker);
    this.addedStocks.splice(index, 1);

    await this.portfolioService.removeTicker({
      tickerId: stock.ticker.id,
    });
  }

  openStockPage(stock: Stock) {
    this.router.navigate([
      'single-stock',
      {
        ticker: stock.ticker.ticker,
      },
    ]);
    this.focused = false;
  }

  onTextChanged(searchText: any) {
    const value = searchText.target.value;
    this.setSearchResultsTitle(value);
    this.searchTextChangeLimiter.next(value);
  }

  private setSearchResultsTitle(value: string) {
    if (value.length > 0) {
      this.searchResultsTitle = `Search by \"${value.substring(0, 10)}\"`;
    } else {
      this.searchResultsTitle = 'Popular';
      this.searchResults = this.popularStocks;
    }
  }

  async loadSearchResults(searchText: string) {
    const tickers = await this.portfolioService.searchTicker({
      searchText: searchText,
      searchByWords: true,
      itemsPerPage: 20,
    });

    const mapTicker = (ticker: Ticker): Stock => {
      return {
        ticker: {
          id: ticker.id,
          ticker: ticker.ticker,
          name: ticker.name,
        },
        added: false,
      };
    };
    this.searchResults = tickers.tickers.sort().map(mapTicker);
  }

  private initSearchTextLimiter() {
    this.searchTextChangeLimiter
      .pipe(debounceTime(200), distinctUntilChanged())
      .subscribe((searchText: string) => this.loadSearchResults(searchText));
  }

  openList() {
    this.router.navigate(['portfolio']);
    this.focused = false;
    this.addedStocks = [];
    this.searchResults = [];
  }
}
