import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { SubscriptionsDialogService } from '../services/dialog.service';
import { first, from } from 'rxjs';
import { MainHttpClient } from '../../../../../../../services/main-http-client.service';
import { SnackbarService } from '../../../../../../../services/snackbar.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-discount-dialog',
  templateUrl: './discount-dialog.component.html',
})
export class DiscountDialogComponent {
  isDisabled: boolean = false;

  constructor(
    private dialog: MatDialogRef<DiscountDialogComponent>,
    private dialogService: SubscriptionsDialogService,
    private httpClient: MainHttpClient,
    private snackbarService: SnackbarService,
    private router: Router,
  ) {}

  close(success: boolean = false): void {
    this.dialog.close(success);
  }

  onBuyWithDiscount(): void {
    this.close();

    this.dialogService.openGetDiscountDialog();
  }

  onUnsubscribe(): void {
    this.isDisabled = true;

    // одноразовый метод для оплаты со скидкой 50%
    from(this.httpClient.get('/v2/cancel'))
      .pipe(first())
      .subscribe({
        next: () => {
          this.close();
          this.isDisabled = false;

          this.router
            .navigate(['/profile/sorry'])
            .then(() => console.debug('Router change'));
        },
        error: () => {
          this.snackbarService.error('Server Error');

          this.close();
          this.isDisabled = false;
        },
      });
  }
}
