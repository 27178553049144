import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { LoaderParameterModel } from "../models/loader-parameter.model";

@Injectable({
    providedIn: "root"
})
export class LoaderService {

    private subject = new Subject<LoaderParameterModel>();

    show(name: string | undefined = undefined) {
        this.subject.next({ name: name, value: true });
    }

    hide(name: string | undefined = undefined) {
        this.subject.next({ name: name, value: false });
    }

    subscription(): Observable<LoaderParameterModel> {
        return this.subject.asObservable();
    }
}